import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../redux/store/Store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IAppData } from "./types/dashboardAppTypes";
import { apiErrorHandle } from "../../components/common/hooksAndFuncts/ApiErrorHandle";
import {
  faBullhorn,
  faCalendarAlt,
  faCompass,
  faEye,
  faEyeSlash,
  faLongArrowAltRight,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { getAppUrl } from "../../constants/Urls";
import dashbordI from "../../assets/dashboard/assets/img/illustrations/data-report.svg";
import GenaralModal from "../scenario/detail/components/modals/GenaralModal";
import AddNewScenarioForm from "../scenario/detail/components/forms/AddScenarioForm";
import DoughnutChart from "../../components/common/charts/DoughnutChart";
import LineChart from "../../components/common/charts/LineChart";
import axios from "axios";
import { cloneDeep } from "lodash";
import moment from "moment";
import "./dashboard.css"

const DashboardPage = () => {
  const strDot = "•";

  const [scenarioList, setscenarioList] = useState<IAppData | any | []>(null);
  const [appId, setappId] = useState<string>("");
  const [appSecret, setappSecret] = useState<string>("");
  const [orgAppSecret, setorgAppSecret] = useState<string>("");
  const [hidetoggle, sethidetoggle] = useState<boolean>(true);
  const [hideToggleText, sethideToggleText] = useState<string>("show");
  const [uniqueSessions, setuniqueSessions] = useState<string>("");
  const [totalScenarios, settotalScenarios] = useState<string>("");

  const [setElasticAppData, setsetElasticAppData] = useState<any>({});
  const [lineData, setlineData] = useState<number[]>([]);
  const [totalEvents, settotalEvents] = useState<any>("");
  const [dayLabels, setdayLabels] = useState<any>([]);

  const [sessionGraphData, setsessionGraphData] = useState<number[]>([]);
  const [sessionGraphLabels, setsessionGraphLabels] = useState<string[]>([]);
  const [totalSessions, settotalSessions] = useState<number>(0);

  const dateRef = useRef<HTMLDivElement>(null);

  var addNewScenario: any = useSelector(
    (state: RootStore) => state.putNewScenarioReducer
  );

  useEffect(() => {
    document.title = 'Dashboard - Herogi Growth Panel';
  }, []);

  var eventMonthlyQuery = {
    "query": {
      "bool": {
        "must": {
          "match_all": {}
        },
        "filter": {
          "range": {
            "entry_time": {
              "gte": "###StartDate###",
              "lt": "###EndDate###"
            }
          }
        }
      }
    },
    "aggs": {
      "dates_with_holes": {
        "date_histogram": {
          "field": "entry_time",
          "calendar_interval": "day",
          "min_doc_count": 0,
          "extended_bounds": {
            "min": "###StartDate###",
            "max": "###EndDate###"
          }
        }
      }
    }
  };

  const generateQuery = (rawQuery: any) => {
    var query = cloneDeep(rawQuery);
    var now = new Date();

    var startDate = new Date(now.getFullYear(), now.getMonth(), 1);
    var lastDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);

    var filter =
      query["query"]["bool"]["filter"]["range"]["entry_time"];

    filter["gte"] = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
    filter["lt"] = moment(lastDate).format("YYYY-MM-DD HH:mm:ss");

    var extension =
      query["aggs"]["dates_with_holes"]["date_histogram"][
        "extended_bounds"
      ];
    extension["min"] = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
    extension["max"] = moment(lastDate).format("YYYY-MM-DD HH:mm:ss");

    return JSON.stringify(query);
  };

  const mapEventData = (
    data: { key_as_string: string; key: number; doc_count: number }[]
  ) => {
    var eventData: number[] = [];
    var total = 0;

    if(data.length >0){
      data.forEach(
        (bucket: { key_as_string: string; key: number; doc_count: number }) => {
          total += bucket["doc_count"];
  
          eventData.push(bucket["doc_count"]);
          var dayLabel = moment(bucket["key_as_string"]).format("YYYY-MM-DD");
  
          dayLabels.push(dayLabel);
        }
      );
    }
    
    settotalEvents(total);
    setlineData(eventData);
  };

  const mapSessionData = (data: { key: string; doc_count: number }[]) => {
    var sessionData: number[] = [];
    var totalSessions: number = 0;
    var scenarioLookupMap: any = {};

    if (scenarioList.length > 0) {
      
      scenarioList.forEach((ws: any) => {
        scenarioLookupMap["sl_" + ws.scenario.id] = ws.scenario;
      });

      data.forEach((bucket: { key: string; doc_count: number }) => {
        totalSessions += bucket["doc_count"];

        sessionData.push(bucket["doc_count"]);
        if (scenarioLookupMap[bucket["key"]]) {
          sessionGraphLabels.push(scenarioLookupMap[bucket["key"]].name);
        }
      });
    }

    settotalSessions(totalSessions);
    //setsessionGraphData(sessionData);
  };

  const getSessionsData = () => {

    var elasticSessionResponseData:any[] =[]

    axios
      .post("/elastic/sessions")
      .then((response) => {
        elasticSessionResponseData = response.data.byindex.buckets;
        if(elasticSessionResponseData!=[]){
          mapSessionData(elasticSessionResponseData);
        }
      })
      .catch((error) => {
        console.log("elasticAppApiError", error);
      });
  };

  const getEventsData = () => {

    var elasticGetEventsData:any[] =[]

    var request: { [key: string]: string } = {};
    request["query"] = generateQuery(eventMonthlyQuery);

    axios
      .post("/elastic/app", request)
      .then((response) => {
        elasticGetEventsData = response.data.dates_with_holes.buckets;
        if(elasticGetEventsData !=[]){
          mapEventData(elasticGetEventsData);
        }
      })
      .catch((error) => {
        console.log("elasticAppApiError", error);
      });

  };

  useEffect(() => {
    getApp();

    getEventsData();

    if (dateRef.current) {
      dateRef.current.innerText = Date();
    }
  }, []);

  useEffect(() => {
    if (scenarioList) {
      getSessionsData();
    }
  }, [scenarioList]);

  useEffect(() => {
    if (addNewScenario.data?.statusText === "OK") {
      if (scenarioList) {
        setscenarioList([...scenarioList, addNewScenario.data.data]);
      }
      settotalScenarios(totalScenarios + 1);
      $("#AddNewScenarioModal").modal("hide");
    }
  }, [addNewScenario.data]);

  const getApp = () => {
    axios
      .get(getAppUrl, {})
      .then((response) => {
        setappId(JSON.stringify(response.data.data.appId));
        setorgAppSecret(JSON.stringify(response.data.data.appSecret));
        setappSecret(strDot.repeat(response.data.data.appSecret.length));
        if (response.data.data.scenarioList) {
          setscenarioList(response.data.data.scenarioList);
        }
        settotalScenarios(response.data.data.scenarioList.length);
      })
      .catch((error) => {
        apiErrorHandle(error, "getAppApiError");
      });
  };

  const hideAppSecretToggle = () => {
    var str = "•";
    sethidetoggle(!hidetoggle);
    hidetoggle ? sethideToggleText("hide") : sethideToggleText("show");

    if (!hidetoggle) {
      setappSecret(str.repeat(appSecret.length));
    } else {
      setappSecret(orgAppSecret);
    }
  };

  const renderHidebutton = () => {
    if (hidetoggle) {
      return <FontAwesomeIcon icon={faEyeSlash} className="mr-2" />;
    } else {
      return <FontAwesomeIcon icon={faEye} className="mr-2" />;
    }
  };

  const renderRunningDraft = () => {
    var running = 0;
    var draft = 0;

    scenarioList
      ? scenarioList.map((item: any) =>
          item.status.status === "DEPLOYED" ? running++ : draft++
        )
      : (running = 0);

    return (
      <div className="dashboard">
        <div className="scenario-info">
          <p className="text-success">
          <strong>{running}</strong> Running Scenarios
          </p>
        </div>

        <div className="scenario-info">
          <p className="">
            <strong>{draft}</strong> Draft Scenarios
          </p>
        </div>

        <div>
            <div className="event-header">{totalEvents} of 5M</div>
            <div className="event-sub">events processed in this month</div>
            <div className="scenario-info-big mt-3">
              <p>
                <strong>{totalSessions}</strong> unique sessions in total <strong>{totalScenarios}</strong> scenarios
              </p>
        </div>
        
        </div>

       
      </div>
    );
  };

  return (
    <>
      <div className="dashboard-main">
        <div className="d-flex justify-content-start align-items-sm-center flex-column flex-sm-row mb-4 shadow-sm p-3 rounded">
          <div className="mr-4 mb-3 mb-sm-0 ">
            <div className="medium">
              <span className="font-weight-500 text-primary">
                App Id &emsp;&emsp;:{" "}
              </span>

              {appId ? appId.replace(/["']/g, "") : "NAN"}
            </div>

            <div className="medium">
              <span className="font-weight-500 text-primary"></span>
            </div>

            <div className="medium">
              <span id="appSecretSpan" className="font-weight-500 text-primary">
                App Secret :{" "}
              </span>
              {appSecret ? appSecret.replace(/["']/g, "") : "NAN"}
            </div>
          </div>

          <div className="mr-4 mb-3 mb-sm-0 ">
            <div>
              <button
                className="btn btn-dark mt-2 btn-sm"
                type="button"
                onClick={() => {
                  hideAppSecretToggle();
                }}
              >
                {renderHidebutton()}
                {hideToggleText.toLowerCase()}
              </button>
            </div>
          </div>
        </div>
        <div className="card mb-4 mt-5 shadow-sm">
          <div className="card-body p-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-5 lg-2  ">
                {renderRunningDraft()}

                <button
                  className="btn btn-primary p-2 mt-3"
                  type="button"
                  data-toggle="modal"
                  data-target="#AddNewScenarioModal"
                >
                  Create New
                  <FontAwesomeIcon
                    icon={faLongArrowAltRight}
                    className="ml-2"
                  />
                </button>
              </div>

              <div className="col-md-3 d-none d-lg-block mt-xxl-n4">
                <img className="img-fluid px-xl-4 mt-xxl-n5" src={dashbordI} />
              </div>
              <div className="col-md-3 d-none d-lg-block mt-xxl-n4">
                <div className="row">
                  <div className="col-md-12">
                    <h2>
                    <FontAwesomeIcon icon={faCompass} /> Templates
                    </h2>
                  </div>
                </div>
                <hr/>
                <div className="row">
                  <div className="col-md-12">
                    <a href="https://blog.herogi.com/template-abandoned-cart-email-campaign/" target="_blank">
                      <div className="row">
                        <div className="col">
                          <h5>Abandoned Cart Email</h5>
                          <p className="text-sm">Send an email when customer abandones chart</p>
                        </div>
                      </div>
                    </a>
                    <hr/>
                    <a href="https://blog.herogi.com/abandoned-cart-webpush-campaign/" target="_blank">
                      <div className="row">
                        <div className="col">
                          <h5>Abandoned Cart Webpush</h5>
                          <p className="text-sm">Send webpush when customer abandones chart</p>
                        </div>
                      </div>
                    </a>
                    <hr/>
                    <a href="https://blog.herogi.com/tag/template-campaign/" target="_blank"> Show More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-4 shadow-sm">
          <LineChart graphData={lineData} labelData={dayLabels} />
        </div>
      </div>

      <GenaralModal
        modalId="AddNewScenarioModal"
        modalName="New Scenario"
        formComp={AddNewScenarioForm}
      />
    </>
  );
};

export default DashboardPage;
