import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { editValidationAction } from "../../../../../redux/actions/ValidationActions";
import { RootStore } from "../../../../../redux/store/Store";
import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";


const EditValidationManageForm = () => {
  const dispatch = useDispatch();

  const selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  const validationOperand: any = useSelector((state: RootStore) => state.getValidationOperandReducer);
  const selectedValidation: any = useSelector((state: RootStore) => state.sendSelectedValidationDataReducer);
  const selectedValidationId: any = useSelector((state: RootStore) => state.sendSelectedValidationIdReducer);
  const editValidationError: any = useSelector((state: RootStore) => state.postValidationReducer);

  const [editValidationEData, seteditValidationEData] = useState<any>(null);
  const [validationOptionState, setvalidationOptionState] = useState<any>(null);

  const [leftButtonState, setleftButtonState] = useState(false);
  const [rightButtonState, setrightButtonState] = useState(false);
  const [operandValue, setoperandValue] = useState("");
  const [validationNameValue, setvalidationNameValue] = useState("");
  const [descriptionValue, setdescriptionValue] = useState("");

  const [leftEditorValue, setleftEditorValue] = useState("");
  const [rightEditorValue, setrightEditorValue] = useState("");
  const [editorValue, seteditorValue] = useState("Create a validation string by using left/right and operand");
  const [validationNameError, setvalidationNameError] = useState("");

  useEffect(() => {
    if (editValidationError.data) {
      if (editValidationError.data.message) {
        seteditValidationEData(editValidationError.data.message);
      }
    }
  }, [editValidationError]);

  useEffect(() => {
    if (selectedValidation.data) {
      var valData: any = selectedValidation.data;
      setvalidationNameValue(valData.name);
      setdescriptionValue(valData.description);
      setleftEditorValue(valData.left);
      setrightEditorValue(valData.right);
      setoperandValue(valData.operand);
    }
  }, [selectedValidation.data, selectedValidationId.data]);

  useEffect(() => {
    if (validationOperand.data) {
      setvalidationOptionState(validationOperand.data?.data);
    }
  }, [validationOperand.data]);

  useEffect(() => {
    if (validationOptionState) {
      setValidationOptions();
    }
  }, [validationOptionState]);

  useEffect(() => {
    if (operandValue !== "") {
      seteditorValue(leftEditorValue + " " + operandValue + " " + rightEditorValue);
    }
  }, [leftEditorValue, rightEditorValue, operandValue]);

  useEffect(() => {
    formValidationControl();
  }, [validationNameValue, operandValue, descriptionValue]);

  const validationOnSubmitHandler = () => {
    var bodyObj = {
      name: validationNameValue,
      left: leftEditorValue,
      operand: operandValue,
      right: rightEditorValue,
      description: descriptionValue,
    };
    dispatch(editValidationAction(bodyObj, selectedScenarioId.data, selectedValidationId.data));
  };

  const setValidationOptions = () => {
    return (
      <>
        <option value="" disabled selected>
          Operand
        </option>
        <optgroup label="Numeric">{validationOptionState ? Object.values(validationOptionState.numeric).map((item: any) => <option value={item.name}>{item.name}</option>) : null}</optgroup>
        <optgroup label="String">{validationOptionState ? Object.values(validationOptionState.string).map((item: any) => <option value={item.name}>{item.name}</option>) : null}</optgroup>
      </>
    );
  };

  const leftOnChangeHandler = (e: any) => {
    setleftEditorValue(e.target.value);
  };

  const rightOnChangeHandler = (e: any) => {
    setrightEditorValue(e.target.value);
  };

  const operandOnChange = (e: any) => {
    setoperandValue(e.target.value);
  };

  const validationNameHandleChange = (e: any) => {
    setvalidationNameValue(e.target.value);
    if (e.target.value === "") {
      setvalidationNameError("Validation name is required");
    } else {
      setvalidationNameError("");
    }
  };

  const descriptionHandleChange = (e: any) => {
    setdescriptionValue(e.target.value);
  };

  const formValidationControl = (): boolean => {
    if (validationNameValue === "" || operandValue === "") {
      return true;
    } else {
      return false;
    }
  };

  const renderAddNewValidationForm = () => {
    return (
      <>
        <div>
          <div>
            <label className="form-label" htmlFor="validationName">
              Validation Name
            </label>
          </div>
          <input
            className="form-control"
            name="validationName"
            type="text"
            value={validationNameValue}
            onChange={(e) => {
              validationNameHandleChange(e);
            }}
          />
          <ErrorMessageComp name={validationNameError} />
          {editValidationEData ? <ErrorMessageComp name={editValidationEData} /> : null}
        </div>

        <div className="row">
          <div className="col-sm">
            <button
              className="form-control  btn-outline-white  mt-3 w-100"
              data-toggle="modal"
              data-target="#AddNewValidationLeftModal"
              onClick={() => {
                setleftButtonState(true);
              }}
            >
              Left{" "}
            </button>
          </div>
          <div className="col-sm">
            <div>
              <div className="mt-3"></div>
              <select
                className="form-control w-100"
                name="sessionType"
                onChange={(e) => {
                  operandOnChange(e);
                }}
              >
                {setValidationOptions()}
              </select>
            </div>
          </div>
          <div className="col-sm">
            <button
              className="form-control  btn-outline-white  mt-3 w-100"
              data-toggle="modal"
              data-target="#AddNewValidationRightModal"
              onClick={() => {
                setrightButtonState(true);
              }}
            >
              Right{" "}
            </button>
          </div>
        </div>

        <div style={{ borderStyle: "dotted", borderWidth: 1 }} className=" rounded mt-2 p-2 pl-3">
          <span>{editorValue}</span>
        </div>

        <div className="mt-3">
          <label className="form-label" htmlFor="description">
            Description
          </label>
          <textarea
            className="form-control"
            name="description"
            value={descriptionValue}
            rows={3}
            onChange={(e) => {
              descriptionHandleChange(e);
            }}
          ></textarea>
        </div>

        <div className="modal-footer">
          <button
            id="addNewValidationCloseButton"
            className="btn btn-primary p-2 mt-3"
            type="button"
            data-dismiss="modal"
            onClick={() => {
              if (editValidationEData) {
                seteditValidationEData("");
              }
            }}
          >
            Close
          </button>
          <button
            id="addNewValidationCreateButton"
            className="btn btn-success p-2 mt-3"
            disabled={formValidationControl() || !(leftEditorValue !== "" && rightEditorValue !== "")}
            onClick={() => {
              validationOnSubmitHandler();
            }}
          >
            Create
          </button>
        </div>
      </>
    );
  };

  const renderAddNewValidationLeftModal = () => {
    return (
      <>
        <label>
          {" "}
          <strong>left</strong>
        </label>
        <input className="form-control" type="text" defaultValue={leftEditorValue} onChange={(e) => leftOnChangeHandler(e)} />

        <div className="modal-footer">
          <button
            id="renderAddNewValidationLeftModalButton"
            className="btn btn-primary p-2 mt-3"
            type="button"
            onClick={() => {
              setleftButtonState(false);
            }}
          >
            Close
          </button>
          <button
            id="renderAddNewValidationLeftModalSaveButton"
            onClick={() => {
              setleftButtonState(false);
            }}
            className="btn btn-success p-2 mt-3"
            type="submit"
          >
            Save
          </button>
        </div>
      </>
    );
  };

  const renderAddNewValidationRightModal = () => {
    return (
      <>
        <label>
          {" "}
          <strong>right</strong>
        </label>
        <input className="form-control" type="text" defaultValue={rightEditorValue} onChange={(e) => rightOnChangeHandler(e)} />

        <div className="modal-footer">
          <button
            id="renderAddNewValidationRightModalButton"
            className="btn btn-primary p-2 mt-3"
            type="button"
            onClick={() => {
              setrightButtonState(false);
            }}
          >
            Close
          </button>
          <button
            id="renderAddNewValidationRightModalSaveButton"
            onClick={() => {
              setrightButtonState(false);
            }}
            className="btn btn-success p-2 mt-3"
            type="submit"
          >
            Save
          </button>

          <div className="mt-3"></div>
        </div>
      </>
    );
  };

  const renderModalWithCondition = () => {
    return <>{leftButtonState ? renderAddNewValidationLeftModal() : rightButtonState ? renderAddNewValidationRightModal() : renderAddNewValidationForm()}</>;
  };

  return <>{renderModalWithCondition()}</>;
};

export default EditValidationManageForm;
