import { useDispatch, useSelector } from "react-redux";
import { deleteEventAction } from "../../../../../redux/actions/EventActions";
import { RootStore } from "../../../../../redux/store/Store";


const DeleteEventManageForm = () => {
  const dispatch = useDispatch();

  const selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  const selectedEventId: any = useSelector((state: RootStore) => state.sendEventIdReducer);

  return (
    <div>
      <p>Are you sure to delete event? This operation is not returnable and all event data will be deleted permanently.</p>

      <div className="modal-footer">
        <button id="DeleteEventManageModalCloseButton" className="btn btn-light mt-3" type="button" data-dismiss="modal" onClick={() => {}}>
          Cancel
        </button>
        <button
          id="DeleteEventManageModalSaveButton"
          className="btn btn-danger mt-3"
          type="button"
          onClick={() => {
            dispatch(deleteEventAction(selectedScenarioId.data, selectedEventId.data));
          }}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteEventManageForm;
