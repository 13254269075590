import { useEffect } from "react";
import "./analytic.css"


export interface ProgressItem {
    label: string;
    value: number;
    total: number;


}
  export interface ProgressBarProps {
    items: ProgressItem[];
}

const ProgressBar = ({ items}: ProgressBarProps ) => {

  useEffect(() => {
    // Initialize tooltips when the component mounts
    $('[data-toggle="tooltip"]').tooltip();
    
    // Clean up tooltips when the component unmounts
    return () => {
      $('[data-toggle="tooltip"]').tooltip('dispose');
    };
  }, []); // Empty dependency array ensures this effect runs only once

    function calculatePercentage(item: ProgressItem): number {
        return (item.value / item.total) * 100;
      }

    return (
        <div className="multiline-progress-wrapper">
          {items.map((item, index) => (
            <div key={index} className="progress-row" data-toggle="tooltip" data-placement="top" title={item.label}>
              <span className="progress-label">{item.label}</span>
              <div className="progress-bar">
                <div className="progress-bar-inner" style={{ width: `${calculatePercentage(item).toFixed(0)}%` }}>
                  <span className="percentage-text">{item.value + " (" + calculatePercentage(item).toFixed(0)}%)</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
  };

  export default ProgressBar;