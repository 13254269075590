import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { sessionTypeOptions } from "./utils/formUtils";
import { RootStore } from "../../../../../redux/store/Store";
import { postSessionAction } from "../../../../../redux/actions/sessionActions";
import * as Yup from "yup";
import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";
import $ from 'jquery';



const EditSessionManageForm = () => {
  const dispatch = useDispatch();

  const selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  const editSessionData: any = useSelector((state: RootStore) => state.sendSelectedSessionDataReducer);
  const editSessionError: any = useSelector((state: RootStore) => state.postSessionReducer);

  const [editSessionEData, seteditSessionEData] = useState<any>(null);

  useEffect(() => {
    $("#EditSessionManageModalCloseIconButton").click(function () {
      formik.resetForm();
    });
  }, []);

  useEffect(() => {
    if (editSessionError.data) {
      if (editSessionError.data.message) {
        seteditSessionEData(editSessionError.data.message);
      }
    }
  }, [editSessionError]);

  const handleSubmitSession = (bodyObj: any, formObj: any) => {
    const sendObj: any = {
      description: bodyObj.description,
      id: editSessionData.data.id,
      propertyName: bodyObj.propertyName,
      propertyType: bodyObj.propertyType,
    };

    dispatch(postSessionAction(sendObj, selectedScenarioId.data, formObj));
  };

  const setSessionTypeOptions = (selectedItem: any) => {
    return (
      <>
        {selectedItem ? <option value={selectedItem} label={selectedItem} /> : null}
        {sessionTypeOptions
          .filter((item) => item.key !== selectedItem)
          .map((item, index) => (
            <option key={index} value={item.value} label={item.key} />
          ))}
      </>
    );
  };

  const validationSchema = Yup.object().shape({
    propertyName: Yup.string().required("Session definition name is required"),
  });

  const formik = useFormik({
    initialValues: {
      propertyName: editSessionData.data ? editSessionData.data.propertyName : "",
      propertyType: "STRING",
      description: editSessionData.data ? editSessionData.data.description : "",
    },
    onSubmit: () => {},
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <div>
          <label className="form-label" htmlFor="sessionName">
            Session Name
          </label>
        </div>
        <input className="form-control" id="propertyName" name="propertyName" type="text" onChange={formik.handleChange} value={formik.values.propertyName} />

        {editSessionEData ? <ErrorMessageComp name={editSessionEData} /> : formik.errors.propertyName ? <ErrorMessageComp name={formik.errors.propertyName} /> : null}
      </div>

      <div>
        <div className="mt-3">
          <label className="form-label" htmlFor="sessionType">
            Session Type
          </label>
        </div>
        <select className="form-control" id="propertyType" name="propertyType" value={formik.values.propertyType} onChange={formik.handleChange}>
          {setSessionTypeOptions(formik.values.propertyType)}
        </select>
      </div>

      <div>
        <div className="mt-3">
          <label className="form-label" htmlFor="description">
            Description
          </label>
        </div>
        <textarea className="form-control" id="description" name="description" rows={3} onChange={formik.handleChange} value={formik.values.description} />
      </div>

      <div className="modal-footer">
        <button
          id="AddNewSessionDefinitionCloseButton"
          className="btn btn-primary p-2 mt-3"
          type="button"
          data-dismiss="modal"
          onClick={() => {
            formik.resetForm();
            seteditSessionEData("");
          }}
        >
          Close
        </button>
        <button
          id="AddNewSessionDefinitionSaveButton"
          className="btn btn-success p-2 mt-3"
          type="submit"
          disabled={!formik.isValid}
          onClick={() => {
            handleSubmitSession(formik.values, formik);
          }}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default EditSessionManageForm;
