import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { sendSelectScenarioIdAction } from "../../../../../redux/actions/scenarioActions";
import { RootStore } from "../../../../../redux/store/Store";
import { faLink, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getTemplateActions,
  sendSelectedActionDataAction,
  sendSelectedActionIdAction,
  sendSelectedActionParamDataAction,
  sendSelectedActionParamIdAction,
  sendSelectedActionParamsDataAction,
  sendSelectedActionTemplateParamsDataAction,
} from "../../../../../redux/actions/ActionActions";
import { IAddActionParam, IDeleteAction, IDeleteActionParam, IEditAction, IEditActionParam, IEditActionReturns, INewAction } from "../../../types/actionTypes";
import { ISingleScenario } from "../../../types/scenarioTypes";
import { sortBy } from "lodash";
import AddActionReturnsLinkForm from "../forms/AddActionReturnsLinkForm";
import AddNewActionForm from "../forms/AddNewActionForm";
import AddNewActionParamForm from "../forms/AddNewActionParamForm";
import DeleteActionForm from "../forms/DeleteActionForm";
import DeleteActionParamForm from "../forms/DeleteActionParamForm";
import EditActionManageForm from "../forms/EditActionManageForm";
import EditActionParamForm from "../forms/EditActionParamForm";
import GenaralModal from "../modals/GenaralModal";
import ViewActionParamForm from "../forms/ViewActionParamForm";


const ActionTab = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const singleScenario: ISingleScenario = useSelector((state: RootStore) => state.singleScenarioReducer.data);
  const newAction: INewAction = useSelector((state: RootStore) => state.putActionReducer.data);
  const editAction: IEditAction = useSelector((state: RootStore) => state.postActionReducer.data);
  const deleteAction: IDeleteAction = useSelector((state: RootStore) => state.deleteActionReducer.data);
  const actionReturns: IEditActionReturns = useSelector((state: RootStore) => state.postActionReturnsReducer.data);
  const addActionParam: IAddActionParam = useSelector((state: RootStore) => state.putActionParamReducer.data);
  const editActionParam: IEditActionParam = useSelector((state: RootStore) => state.postActionParamReducer.data);
  const deleteActionParam: IDeleteActionParam = useSelector((state: RootStore) => state.deleteActionParamReducer.data);

  const [selectedScenarioData, setSelectedScenarioData] = useState<ISingleScenario | null>(null);
  const [actionMapData, setActionMapData] = useState<any>(null);
  const [selectedActionData, setSelectedActionData] = useState<any>(null);
  const [selectedActionName, setSelectedActionName] = useState("");
  const [selectedActionId, setselectedActionId] = useState("");
  const [selectedActionParamId, setselectedActionParamId] = useState("");
  const [actionParamsData, setactionParamsData] = useState<any>(null);
  const [deletedActionParamName, setdeletedActionParamName] = useState("");

  useEffect(() => {
    if (id) {
      dispatch(sendSelectScenarioIdAction(id));
    }
    dispatch(getTemplateActions());
  }, []);

  useEffect(() => {
    if (singleScenario) {
      setSelectedScenarioData(singleScenario);
    }
  }, [singleScenario]);

  useEffect(() => {
    setActionMap();
    setSelectedAction();
    setSelectedActionParams();
  }, [selectedScenarioData, selectedActionName, deletedActionParamName]);

  const setActionMap = () => {
    if (selectedScenarioData) {
      setActionMapData(selectedScenarioData.scenario?.actionMap);
    }
  };

  const setSelectedAction = () => {
    if (selectedScenarioData) {
      if (selectedActionName) {
        setSelectedActionData(selectedScenarioData.scenario.actionMap[selectedActionName]);
        dispatch(sendSelectedActionDataAction(selectedScenarioData.scenario.actionMap[selectedActionName]));
      }
    }
  };

  const setSelectedActionParams = () => {
    if (selectedScenarioData) {
      if (selectedActionName) {
        setactionParamsData(selectedScenarioData.scenario.actionMap[selectedActionName].params);
        dispatch(sendSelectedActionTemplateParamsDataAction(selectedScenarioData.scenario.actionMap[selectedActionName].template.templateParams));
        dispatch(sendSelectedActionParamsDataAction(selectedScenarioData.scenario.actionMap[selectedActionName].params));
      }
    }
  };

  useEffect(() => {
    if (selectedScenarioData) {
      if (newAction?.status === "success") {
        const newSingleScenarioObj = { ...selectedScenarioData };
        newSingleScenarioObj.scenario.actionMap[newAction.data.name] = newAction.data;
        setSelectedScenarioData(newSingleScenarioObj);
        setselectedActionId(newAction.data.id.toString());
        setSelectedActionName(newAction.data.name);
        dispatch(sendSelectedActionIdAction(newAction.data.id.toString()));
        $("#AddNewActionModal").modal("hide");
        $(function () {
          $("#actionList").children().removeClass("active");
          $("#actionList")
            .children("#" + newAction.data.name)
            .addClass("active");
        });
      }
    }
  }, [newAction]);

  useEffect(() => {
    if (selectedScenarioData) {
      if (editAction?.response?.status === "success") {
        const singleScenarioObj = { ...selectedScenarioData };
        delete singleScenarioObj.scenario.actionMap[selectedActionName];
        const newSingleScenarioObj = { ...singleScenarioObj };
        newSingleScenarioObj.scenario.actionMap[editAction.newObj?.name] = editAction.newObj;
        setSelectedActionName(editAction.newObj?.name);
        setSelectedScenarioData(newSingleScenarioObj);
        $("#EditActionManageModal").modal("hide");
        $(function () {
          $("#actionList").children().removeClass("active");
          $("#actionList")
            .children("#" + editAction.newObj?.name)
            .addClass("active");
        });
      }
    }
  }, [editAction]);

  useEffect(() => {
    if (selectedScenarioData) {
      if (deleteAction?.statusText === "OK") {
        const singleScenarioObj = { ...selectedScenarioData };
        delete singleScenarioObj.scenario.actionMap[selectedActionName];
        setSelectedScenarioData(singleScenarioObj);
        setSelectedActionName("");
        setselectedActionId("");
        setSelectedActionData(null);
        deleteAction.statusText = "";
        $("#DeleteActionManageModal").modal("hide");
        $("#actionList").children().removeClass("active");
      }
    }
  }, [deleteAction]);

  useEffect(() => {
    if (selectedScenarioData) {
      if (actionReturns?.response?.statusText === "OK") {
        const singleScenarioObj = { ...selectedScenarioData };
        delete singleScenarioObj.scenario.actionMap[selectedActionName].returningEvent;
        const newSingleScenarioObj = { ...singleScenarioObj };
        newSingleScenarioObj.scenario.actionMap[selectedActionName].returningEvent = actionReturns.bodyObj;
        actionReturns.response.statusText = "";
        setSelectedScenarioData(newSingleScenarioObj);
        $("#AddNewActionReturningEventParamModal").modal("hide");
      }
    }
  }, [actionReturns]);

  useEffect(() => {
    if (selectedScenarioData) {
      if (addActionParam?.statusText === "OK") {
        const newSingleScenarioObj = { ...selectedScenarioData };
        const addedParamName = addActionParam.data.key;
        if (newSingleScenarioObj.scenario.actionMap[selectedActionName].params[addedParamName]) {
          newSingleScenarioObj.scenario.actionMap[selectedActionName].params[addedParamName].push(addActionParam.data);
        } else {
          newSingleScenarioObj.scenario.actionMap[selectedActionName].params[addedParamName] = [addActionParam.data];
        }
        addActionParam.statusText = "";
        setSelectedScenarioData(newSingleScenarioObj);
        $("#AddNewActionParamModal").modal("hide");
      }
    }
  }, [addActionParam]);

  useEffect(() => {
    if (selectedScenarioData) {
      if (editActionParam?.statusText === "OK") {
        const singleScenarioObj = { ...selectedScenarioData };
        const addedParamName = editActionParam.data.key;
        if (editActionParam?.config?.params.field) {
          singleScenarioObj.scenario.actionMap[selectedActionName].params[addedParamName] = singleScenarioObj.scenario.actionMap[selectedActionName].params[addedParamName].filter(
            (p: any) => p.field !== editActionParam?.config?.params.field
          );
          const newSingleScenarioObj = { ...singleScenarioObj };
          newSingleScenarioObj.scenario.actionMap[selectedActionName].params[addedParamName].push(editActionParam.data);
          setSelectedScenarioData(newSingleScenarioObj);
        } else {
          delete singleScenarioObj.scenario.actionMap[selectedActionName].params[addedParamName];
          const newSingleScenarioObj = { ...singleScenarioObj };
          newSingleScenarioObj.scenario.actionMap[selectedActionName].params[addedParamName] = [editActionParam.data];
          setSelectedScenarioData(newSingleScenarioObj);
        }
        editActionParam.statusText = "";

        $("#EditActionParamModal").modal("hide");
      }
    }
  }, [editActionParam]);

  useEffect(() => {
    if (selectedScenarioData) {
      if (deleteActionParam?.response?.statusText === "OK") {
        const singleScenarioObj = { ...selectedScenarioData };
        const deletedActionParamName = deleteActionParam.deleteObj[0].key;
        if (deleteActionParam?.response?.config?.params?.field) {
          if (singleScenarioObj.scenario.actionMap[selectedActionName].params[deletedActionParamName].length === 1) {
            delete singleScenarioObj.scenario.actionMap[selectedActionName].params[deletedActionParamName];
            setSelectedScenarioData(singleScenarioObj);
          } else {
            singleScenarioObj.scenario.actionMap[selectedActionName].params[deletedActionParamName] = singleScenarioObj.scenario.actionMap[selectedActionName].params[deletedActionParamName].filter(
              (p: any) => p.field !== deleteActionParam?.response?.config?.params?.field
            );
            const newSingleScenarioObj = { ...singleScenarioObj };
            setSelectedScenarioData(newSingleScenarioObj);
          }
        } else {
          delete singleScenarioObj.scenario.actionMap[selectedActionName].params[deletedActionParamName];
          setSelectedScenarioData(singleScenarioObj);
        }
        deleteActionParam.response.statusText = "";
        setdeletedActionParamName(deletedActionParamName);
        $("#DeleteActionParamModal").modal("hide");
      }
    }
  }, [deleteActionParam]);

  const renderActionManageButton = () => {
    return (
      <>
        <button className="btn btn-outline-primary dropdown-toggle pt-2 pb-2 pl-2 pr-2 ml-1" type="button" id="ActionManageDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Manage
        </button>
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="ActionManageDropdown">
          <button className="dropdown-item" type="button" data-toggle="modal" data-target="#EditActionManageModal">
            Edit
          </button>
          <button className="dropdown-item" type="button" data-toggle="modal" data-target="#DeleteActionManageModal">
            Delete
          </button>
        </div>
      </>
    );
  };

  const renderActionList = () => {
    return (
      <>
        <div className="list-group list-group-flush " id="actionList" role="tablist">
          {actionMapData
            ? Object.values(actionMapData).length !== 0
              ? sortBy(Object.values(actionMapData), "name").map((ai: any) => (
                  <a
                    className="list-group-item list-group-item-action p-1"
                    data-toggle="list"
                    id={ai.name}
                    href={"#" + ai.name}
                    role="tab"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setselectedActionId(ai.id);
                      setSelectedActionName(ai.name);
                      dispatch(sendSelectedActionIdAction(ai.id));
                    }}
                  >
                    {ai.name}
                  </a>
                ))
              : " There is no action created"
            : " There is no action created"}
        </div>
      </>
    );
  };

  const renderActionPreview = () => {
    return (
      <>
        <div className="list-group list-group-flush ">
          <div className="list-group-item border-0 p-1 ">
            <div className="row">
              <div className="col-auto mr-auto">
                <span>
                  {selectedActionData.name} <small>({selectedActionData.template.name})</small>{" "}
                </span>
              </div>
              <div className="col-auto">{renderActionManageButton()}</div>
            </div>
          </div>

          <div className="list-group-item border-0 p-1">
            <span style={{ color: "#EF9222" }}>{"Description: "}</span>
            {selectedActionData.description ? selectedActionData.description : "-"}
          </div>
        </div>
      </>
    );
  };

  const renderTemplateReturns = () => {
    return (
      <>
        {selectedActionData.template?.returns !== null ? (
          <div className="card  mb-4 mt-3 shadow-sm">
            <div className="row p-2">
              <div className="col-auto mr-auto">
                {selectedActionData.template ? (
                  selectedActionData.returningEvent ? (
                    <small>
                      {"Action returns ("}
                      {Object.values(selectedActionData.returningEvent).length}
                      {")"}
                    </small>
                  ) : (
                    <small>{"Action returns (0)"}</small>
                  )
                ) : null}
              </div>
              <div className="col-auto">
                <span className="span-button" data-toggle="modal" data-target="#AddNewActionReturningEventParamModal">
                  <FontAwesomeIcon icon={faLink} /> Link
                </span>
              </div>
            </div>

            <table className="table table-hover ">
              <thead>
                <tr></tr>
              </thead>

              <thead className="bg-light">
                <tr>
                  <th scope="col">Event</th>
                  <th scope="col">Template</th>
                </tr>
              </thead>

              <tbody>
                {selectedActionData.template
                  ? selectedActionData.returningEvent
                    ? Object.values(selectedActionData.returningEvent).map((re: any) => (
                        <tr>
                          <td>{re.name}</td>
                          <td>{re.template}</td>
                        </tr>
                      ))
                    : null
                  : null}
              </tbody>
            </table>
          </div>
        ) : null}
      </>
    );
  };

  const renderActionParams = () => {
    return (
      <div className="card  mb-4 mt-3 shadow-sm">
        <div className="row p-2">
          <div className="col-auto mr-auto">
            <strong> Action parameters </strong>
            <small> ({actionParamsData ? Object.values(actionParamsData).length : null})</small>
          </div>
          <div className="col-auto">
            <span className="span-button" data-toggle="modal" data-target="#AddNewActionParamModal">
              <FontAwesomeIcon icon={faPlus} /> Add New
            </span>
          </div>
        </div>

        <table className="table table-hover ">
          <thead>
            <tr></tr>
          </thead>

          <thead className="bg-light">
            <tr>
              <th scope="col">Key</th>
              <th scope="col">Field/value</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {actionParamsData
              ? actionParamsData.length !== 0
                ? sortBy(Object.values(actionParamsData), "[0].key").map((param: any) => (
                    <tr>
                      <td className="key" scope="row">
                        {param[0]?.key}
                      </td>
                      <td>
                        {Object.values(param).length > 0
                          ? Object.values(param).map((p: any) => (
                              <div className="multiple-param">
                                {p.field ? (
                                  <div className="field">
                                    <span>{p.field}</span>
                                  </div>
                                ) : (
                                  <div className="field">
                                    <span></span>
                                  </div>
                                )}
                                {p.value ? (
                                  <div className="value">
                                    {(p?.value as string).length > 300 ? (p?.value as string).substring(0,300) + '...' : <span>{p?.value}</span> }
                                    {(p?.value as string).length > 300 ?  <div>
                                      <a className="show-more" href="" 
                                       onClick={() => {
                                        $('#EditActionParamModal').modal('hide');
                                        dispatch(sendSelectedActionParamDataAction(param));
                                      }}
                                      data-toggle="modal" data-target="#ViewActionParamModal">Show More</a>
                                    </div>  :null }


                                  </div>
                                ) : (
                                  <div className="value">
                                    <span></span>
                                  </div>
                                )}
                              </div>
                            ))
                          : null}
                      </td>

                      <td style={{ width: "100px" }}>
                        <span
                          className="span-button"
                          data-toggle="modal"
                          data-target="#EditActionParamModal"
                          onClick={() => {
                            setselectedActionParamId(param[0].id);
                            dispatch(sendSelectedActionParamIdAction(param[0].id));
                            dispatch(sendSelectedActionParamDataAction(param));
                          }}
                        >
                          Edit
                        </span>
                        <span
                          className="span-button"
                          data-toggle="modal"
                          data-target="#DeleteActionParamModal"
                          onClick={() => {
                            setselectedActionParamId(param[0].id);
                            dispatch(sendSelectedActionParamIdAction(param[0].id));
                            dispatch(sendSelectedActionParamDataAction(param));
                          }}
                        >
                          Delete
                        </span>
                      </td>
                    </tr>
                  ))
                : null
              : null}
          </tbody>
        </table>
      </div>
    );
  };

  const renderActionTab = () => {
    return (
      <h5 className="card-title">
        <div className="row">
          <div className="col-2 border-right">
            <div className="list-group list-group-flush ">
              <div className="list-group-item p-1 ">
                <span>
                  <strong> {"Action List"} </strong>
                  <small>({actionMapData ? Object.values(actionMapData).length : null}) </small>
                </span>
                <div>
                  <button className="btn btn-success p-1 mt-2 mb-2" data-toggle="modal" data-target="#AddNewActionModal" onClick={() => {}}>
                    Add New Action{" "}
                  </button>
                </div>
              </div>
            </div>

            {renderActionList()}
          </div>
          <div className="col-10 ">
            <div>
              {selectedActionId ? (
                selectedActionData ? (
                  <>
                    {renderActionPreview()}
                    {renderTemplateReturns()}
                    {renderActionParams()}
                  </>
                ) : (
                  <strong>There is no action created</strong>
                )
              ) : (
                <strong>Select action</strong>
              )}
            </div>
          </div>
        </div>
      </h5>
    );
  };

  const renderModals = () => {
    return (
      <>
        <GenaralModal modalId="AddNewActionModal" modalName="New Action" size="" formComp={AddNewActionForm} />
        <GenaralModal modalId="EditActionManageModal" modalName="Edit Action" size="" formComp={EditActionManageForm} />
        <GenaralModal modalId="DeleteActionManageModal" modalName="Delete Action" size="lg" formComp={DeleteActionForm} />
        <GenaralModal modalId="AddNewActionReturningEventParamModal" modalName="Add New Returning Event" size="" formComp={AddActionReturnsLinkForm} />
        <GenaralModal modalId="AddNewActionParamModal" modalName="Add New Action Parameter" size="" formComp={AddNewActionParamForm} />
        <GenaralModal modalId="DeleteActionParamModal" modalName="Delete Action Paramater" size="lg" formComp={DeleteActionParamForm} />
        <GenaralModal modalId="EditActionParamModal" modalName="Edit Action Paramater" size="" formComp={EditActionParamForm} />
        <GenaralModal modalId="AddActionParamModal" modalName="Add Action Paramater" size="" formComp={AddNewActionParamForm} />
        <GenaralModal modalId="ViewActionParamModal" modalName="Action Parameter Value" size="" formComp={ViewActionParamForm} />

      </>
    );
  };

  return (
    <>
      {renderActionTab()}
      {renderModals()}
    </>
  );
};

export default ActionTab;
