import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "../../../../../redux/store/Store";
import { getIntegrationVendorsAction, putActionAction } from "../../../../../redux/actions/ActionActions";
import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";
import $ from 'jquery';


const AddNewActionForm = () => {
  const dispatch = useDispatch();

  const template: any = useSelector((state: RootStore) => state.getTemplateReducer);
  const integrationVendors: any = useSelector((state: RootStore) => state.getIntegrationVendorsReducer);
  const selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  const newActionError: any = useSelector((state: RootStore) => state.putActionReducer);

  const [newActionErrorData, setnewActionErrorData] = useState<any>(null);
  const [integrationForValue, setintegrationForValue] = useState("");
  const [isThereIntegration, setisThereIntegration] = useState(false);
  const [actionNameValue, setactionNameValue] = useState("");
  const [actionNameValueAlert, setactionNameValueAlert] = useState("");

  const [templateData, settemplateData] = useState<any>(null);
  const [integrationVendorsData, setintegrationVendorsData] = useState<any>(null);
  const [selectedVendorId, setselectedVendorId] = useState("");
  const [actionGroupName, setactionGroupName] = useState("");
  const [selectedIntegrationVendorId, setselectedIntegrationVendorId] = useState("");
  const [templateName, settemplateName] = useState("");
  const [description, setdescription] = useState("");
  const [integrationId, setintegrationId] = useState("");

  useEffect(() => {
    if (newActionError.data) {
      if (newActionError.data.message) {
        setnewActionErrorData(newActionError.data.message);
      }
    }
    if (newActionError?.data?.status === "success") {
      formReset();
    }
  }, [newActionError.data]);

  useEffect(() => {
    if (template.data) {
      settemplateData(template.data);
    }
  }, [template.data]);

  useEffect(() => {
    if (selectedVendorId) {
      dispatch(getIntegrationVendorsAction(selectedVendorId));
    }
  }, [selectedVendorId]);

  useEffect(() => {
    if (actionGroupName) {
      setselectedVendorId("");
      setselectedIntegrationVendorId("");
    }
  }, [actionGroupName]);

  useEffect(() => {
    if (integrationVendors.data) {
      setintegrationVendorsData(integrationVendors.data);
      if (integrationVendors.data[0]) {
        setintegrationForValue(integrationVendors.data[0].productName);
        setintegrationId(integrationVendors.data[0].id);
        setisThereIntegration(true);
      } else {
        setisThereIntegration(false);
      }
    }
  }, [integrationVendors.data]);

  useEffect(() => {
    $("#AddNewActionModalCloseIconButton").click(function () {
      formReset();
    });
  }, []);

  const setActionGroupOption = () => {
    return (
      <>
        <option value="" disabled selected label="Choose a group" />
        {templateData ? Object.values(templateData).map((t: any) => <option value={t.name} label={t.name} />) : null}
      </>
    );
  };

  const setVendorOption = () => {
    return (
      <>
        <option value="" disabled selected label="Choose a vendor" />
        {templateData
          ? Object.values(templateData)
              .filter((tf: any) => tf.name === actionGroupName)
              .map((t: any) => (t.vendors ? Object.values(t.vendors).map((t: any) => <option value={t.integration ? t.integration.apiId : t.id} label={t.name} />) : null))
          : null}
      </>
    );
  };

  const setIntegrationVendorsOption = () => {
    return (
      <>
        <option value="" disabled selected label="Choose a integration" />
        {integrationVendorsData ? Object.values(integrationVendorsData).map((iv: any) => <option value={iv.id} label={iv.name} />) : null}
      </>
    );
  };

  const setTemplatesOption = () => {
    return (
      <>
        <option value="" disabled selected label="Choose a template" />
        {templateData
          ? Object.values(templateData)
              .filter((item: any) => item.name === actionGroupName)
              .map((item: any) =>
                item.vendors
                  ? Object.values(item.vendors)
                      .filter((item: any) => (item.integration ? item.integration.apiId == selectedVendorId : item.id == selectedVendorId))
                      .map((item: any) => (item.templates ? Object.values(item.templates).map((item: any) => <option value={item.id} label={item.name} />) : null))
                  : null
              )
          : null}
      </>
    );
  };

  const addActionSubmitHandler = () => {
    var bodyObj: any = {
      description: description,
      name: actionNameValue,
      templateId: parseInt(templateName),
    };
    if (isThereIntegration) {
      bodyObj["integrationId"] = parseInt(integrationId);
    }
    dispatch(putActionAction(bodyObj, selectedScenarioId.data));
  };

  const handleActionNameChange = (e: any) => {
    setactionNameValue(e.target.value);
    setnewActionErrorData("");
    if (!e.target.value) {
      setactionNameValueAlert("Action name is required");
    }
  };

  const handleActionGroupChange = (e: any) => {
    setactionGroupName(e.target.value);
  };

  const handleVendorChange = (e: any) => {
    setselectedVendorId(e.target.value);
  };

  const handleIntegrationChange = (e: any) => {
    setselectedIntegrationVendorId(e.target.value);
  };

  const handleTemplateChange = (e: any) => {
    settemplateName(e.target.value);
  };

  const handleDescriptionChange = (e: any) => {
    setdescription(e.target.value);
  };

  const formValidationControl = (): boolean => {
    if (isThereIntegration) {
      if (actionNameValue && actionGroupName && setselectedVendorId && templateName && setselectedIntegrationVendorId) {
        return true;
      } else {
        return false;
      }
    } else {
      if (actionNameValue && actionGroupName && setselectedVendorId && templateName) {
        return true;
      } else {
        return false;
      }
    }
  };

  const formReset = () => {
    setactionNameValue("");
    setactionNameValueAlert("");
    setactionGroupName("");
    setselectedVendorId("");
    settemplateName("");
    setselectedIntegrationVendorId("");
    setdescription("");
    setnewActionErrorData("");
  };

  return (
    <>
      <div>
        <div>
          <label className="form-label" htmlFor="actionName">
            Action Name
          </label>
        </div>
        <input className="form-control" name="actionName" type="text" onChange={(e) => handleActionNameChange(e)} value={actionNameValue} />
        <ErrorMessageComp name={actionNameValue ? "" : actionNameValueAlert} />
        {newActionErrorData ? <ErrorMessageComp name={newActionErrorData} /> : null}
      </div>
      <div>
        <div className="mt-3"></div>
        <label className="form-label" htmlFor="actionGroup">
          Action Group
        </label>
        <select
          className="form-control"
          name="actionGroup"
          value={actionGroupName}
          onChange={(e) => {
            handleActionGroupChange(e);
          }}
        >
          {setActionGroupOption()}
        </select>
      </div>

      {actionGroupName !== "" ? (
        <div>
          <div className="mt-3"></div>
          <label className="form-label" htmlFor="vendor">
            Vendor
          </label>
          <select
            className="form-control"
            name="vendor"
            value={selectedVendorId}
            onChange={(e) => {
              handleVendorChange(e);
            }}
          >
            {setVendorOption()}
          </select>
        </div>
      ) : null}

      {actionGroupName !== "" && selectedVendorId ? (
        <div>
          {isThereIntegration ? (
            <div>
              <div className="mt-3"></div>
              <label className="form-label" htmlFor="integration">
                Integration for ({integrationForValue})
              </label>
              <select
                className="form-control"
                name="integration"
                value={selectedIntegrationVendorId}
                onChange={(e) => {
                  handleIntegrationChange(e);
                }}
              >
                {setIntegrationVendorsOption()}
              </select>
            </div>
          ) : null}

          <div>
            <div className="mt-3"></div>

            <label className="form-label" htmlFor="template">
              Template
            </label>
            <select
              className="form-control"
              name="template"
              onChange={(e) => {
                handleTemplateChange(e);
              }}
            >
              {setTemplatesOption()}
            </select>
          </div>
        </div>
      ) : null}

      <div>
        <div className="mt-3">
          <label className="form-label" htmlFor="description">
            Description
          </label>
        </div>
        <textarea className="form-control" name="description" rows={3} value={description} onChange={(e) => handleDescriptionChange(e)} />
      </div>

      <div className="modal-footer">
        <button
          id="addNewEventCloseButton"
          className="btn btn-primary p-2 mt-3"
          type="button"
          data-dismiss="modal"
          onClick={() => {
            formReset();
          }}
        >
          Close
        </button>
        <button
          id="addNewEventCreateButton"
          onClick={() => {
            addActionSubmitHandler();
          }}
          className="btn btn-success p-2 mt-3"
          disabled={!formValidationControl()}
        >
          Create
        </button>
      </div>
    </>
  );
};

export default AddNewActionForm;
