import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/landing/herogi-logo.svg";

const NotFoundPage = () => {
  return (
    <div className="bg-white">
      <div id="layoutError">
        <div id="layoutError_content">
          <main>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="text-center mt-4">
                    <img className="img-fluid p-4 w-75" src={logo} alt="" />
                    <p className="lead">This requested URL was not found on this server.</p>
                    <Link className="text-arrow-icon" to="/">
                      <FontAwesomeIcon icon={faArrowLeft} />
                      <i className="ml-0 mr-1" data-feather="arrow-left"></i>
                      Return to Home Page
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
