import * as CampaignActionTypes from "../types/types";

import * as  initialStates from "../store/InitialStates";


//maybe general reducer can be written
//new rtk (redux tool kit) removes template reducer with slice functions

export const addCampaignReducer = (state = initialStates.addCampaignInitialState, action: any) => {
    switch (action.type) {
      case CampaignActionTypes.ADD_CAMPAIGN_FAIL:
        return {
          data: action.payload,
        };
      case CampaignActionTypes.ADD_CAMPAIGN_SUCCESS:
        return {
          data: action.payload,
        };
      default:
        return state;
    }
  };


  export const editCampaignReducer = (state = initialStates.editCampaignInitialState, action: any) => {
    switch (action.type) {
      case CampaignActionTypes.EDIT_CAMPAIGN_FAIL:
        return {
          data: action.payload,
        };
      case CampaignActionTypes.EDIT_CAMPAIGN_SUCCESS:
        return {
          data: action.payload,
        };
      default:
        return state;
    }
  };

  export const getCampaignListReducer = (state = initialStates.getCampaignListInitialState, action: any) => {
    switch (action.type) {
      case CampaignActionTypes.GET_CAMPAIGN_LIST_FAIL:
        return {
          data: action.payload,
        };
      case CampaignActionTypes.GET_CAMPAIGN_LIST_SUCCESS:
        return {
          data: action.payload,
        };
      default:
        return state;
    }
  };

  export const getCampaignReducer = (state = initialStates.getCampaignListInitialState, action: any) => {
    switch (action.type) {
      case CampaignActionTypes.GET_CAMPAIGN_FAIL:
        return {
          data: action.payload,
        };
      case CampaignActionTypes.GET_CAMPAIGN_SUCCESS:
        return {  
          data: action.payload,
        };
      default:
        return state;
    }
  };


  export const getCampaignInitReducer = (state = initialStates.getCampaignListInitialState, action: any) => {
    switch (action.type) {
      case CampaignActionTypes.GET_CAMPAIGN_INIT_FAIL:
        return {
          data: action.payload,
        };
      case CampaignActionTypes.GET_CAMPAIGN_INIT_SUCCESS:
        return {
          data: action.payload,
        };
      default:
        return state;
    }
  };


  export const deployCampaignReducer = (state = {}, action: any) => {
    switch (action.type) {
      case CampaignActionTypes.DEPLOY_CAMPAIGN_FAIL:
        return {
          data: action.payload,
        };
      case CampaignActionTypes.DEPLOY_CAMPAIGN_SUCCESS:
        return {
          data: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const reDeployCampaignReducer = (state = {}, action: any) => {
    switch (action.type) {
      case CampaignActionTypes.RE_DEPLOY_CAMPAIGN_FAIL:
        return {
          data: action.payload,
        };
      case CampaignActionTypes.RE_DEPLOY_CAMPAIGN_SUCCESS:
        return {
          data: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const unDeployCampaignReducer = (state = {}, action: any) => {
    switch (action.type) {
      case CampaignActionTypes.UN_DEPLOY_CAMPAIGN_FAIL:
        return {
          data: action.payload,
        };
      case CampaignActionTypes.UN_DEPLOY_CAMPAIGN_SUCCESS:
        return {
          data: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const freezeCampaignReducer = (state = {}, action: any) => {
    switch (action.type) {
      case CampaignActionTypes.FREEZE_CAMPAIGN_FAIL:
        return {
          data: action.payload,
        };
      case CampaignActionTypes.FREEZE_CAMPAIGN_SUCCESS:
        return {
          data: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const resumeCampaignReducer = (state = {}, action: any) => {
    switch (action.type) {
      case CampaignActionTypes.RESUME_CAMPAIGN_FAIL:
        return {
          data: action.payload,
        };
      case CampaignActionTypes.RESUME_CAMPAIGN_SUCCESS:
        return {
          data: action.payload,
        };
      default:
        return state;
    }
  };


  export const sendCampaignEventTemplateParamsReducer = (state: any = {}, action: any) => {
    switch (action.type) {
      case CampaignActionTypes.SEND_CAMPAING_EVENT_TEMPLATE_PARAM_FAIL:
        return {
          data: action.payload,
        };
      case CampaignActionTypes.SEND_CAMPAING_EVENT_TEMPLATE_PARAM_SUCCESS:
        return {
          data: action.payload,
        };
      default:
        return state;
    }
  };