import { faCodeMerge, faPlus, faShareNodes, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { randomInt } from "crypto";
import { Field } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../campaign.css"
import { TextField } from "./FormElements";
import RteField from "./RteField";

const NewMessageComposer = (props: any) => {
    
    const {
        composerToggle,
        sharedParams,
        action,
        handleSubmit,
        ...rest
      } = props;

      const [sortedParams, setSortedParams] = useState<any>([]);
      const [extraParams, setExtraParams] = useState<any>([]);
      
      useEffect(() =>{

        //TODO check recurring parameters

        let _params: any[] = [];
        let _extraParams: any[] = [];

        let copyAction = JSON.parse(JSON.stringify(action))

        //Add missing action template params
        copyAction.template.templateParams.map(p => {
            if(copyAction.params[p.key] === undefined || copyAction.params[p.key].length == 0) {
                const newParam = JSON.parse(JSON.stringify(p));
                newParam["field"] = null;
                newParam["value"] = "";
                copyAction.params[p.key] = [newParam];
            }
        });

        sharedParams.map(p => {

            let param = copyAction?.params[p];
            param[0]["richText"] = false;

            _params.push({"key" : p, "params" : param});
        });

       // _params.push([{"params" : [-1]}]);

         for(let p in copyAction.params) {
            let param = copyAction.params[p];
            let exist = sharedParams.find(i => i == param[0].key)
            //console.log(param);
            //console.log(exist);
            if(exist == null) {
                _extraParams.push({"key" : p, "params" : param});
            }
        };
        

        setSortedParams(_params);
        setExtraParams(_extraParams);

    
      },[action]);

    
    const handleChange = (param, value, changeType) => {
        
        if(changeType == "value") {
            param.value = value;
        }
        else if (changeType == "field") {
            param.field = value;
        } else {
            return;
        } 

        // //TODO fix null field match issue | maybe internal field_id
        // sortedParams.map(p => {
        //     if(p.key == param.key && param.isRecurring == false) {
        //         p.params[0] = param;
        //     } else if(p.key == param.key && param.isRecurring == true && param.field == sortedParams.field) {
        //         p.params[0] = param;
        //     }
        // });

        // extraParams.map(p => {
        //     if(p.key == param.key) {
        //         p.params[0] = param;
        //     }
        // });

        setSortedParams([...sortedParams]);
        setExtraParams([...extraParams]);
    }

    const addExtraField = (actionParams) => {
        const newParam = JSON.parse(JSON.stringify(actionParams[0]));
        newParam.field = null;
        newParam.value = "";
        //newParam["_fid"] = randomInt(1000000);

        actionParams.push(newParam);

        setSortedParams([...sortedParams]);
        setExtraParams([...extraParams]);
    }

    const removeExtraField = (actionParams, index) => {
        
        actionParams.splice(index, 1);

        setSortedParams([...sortedParams]);
        setExtraParams([...extraParams]);
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }  

    const renderHeaders = () => {
        return (
            <>
                <div className="row">
                    <div className="col-auto mr-auto">
                        <h4>
                            {action.template.name}
                            { action.template.integrationRequired && action.integration == null ? (
                                <small title="Integration missing"><FontAwesomeIcon className="ml-1 integration-icon icon-warning" icon={faToggleOff} /></small>
                            )     
                            :
                                <small title="Integration provided"><FontAwesomeIcon className="ml-1 integration-icon icon-success" icon={faToggleOn} /></small>
                            }
                        </h4>    
                        {action.integration != null ? (
                        <>
                            <h5>
                                <FontAwesomeIcon className="integration-icon mr-1" icon={faShareNodes} />
                                {action.integration.name}
                                <span className="subHeader">({action.integration.productName})</span>
                            </h5>
                        </>        
                        )
                        : ""}
                        <div>
                            <span style={{color:"orange"}}>Description</span>
                            <p>{action.description ? action.description : "-"}</p>
                        </div>
                    </div>    
                </div>
            </>
        );
    }
    
    const printParams = (anyParams) => {
        console.log(anyParams);
        return anyParams?.map(params => {
            return params.params.map((p, index) => {
                return (
                  <div className="form-group">
                    <label>
                        {capitalizeFirstLetter(params.key)}
                        <small className="ml-1" style={{color: "gray"}}>({p.description})</small>
                    </label>
                    {p.isRecurring 
                        ?
                        <div> 
                            <div className="row">
                                <div className="col-md-3">
                                    <input name={p.key+ "_" +p.field} className="form-control" value={p.field}
                                        onChange={e => handleChange(p, e.target.value, "field")}
                                        placeholder="Field Name"></input>
                                </div>
                                <div className="col-md-7">
                                    <input name={p.key} className="form-control" value={p.value} onChange={e => handleChange(p, e.target.value, "value")} placeholder="Value"></input>
                                </div>
                                {(params.params.length - 1) == index 
                                    ?
                                    <button className="btn btn-outline-green btn-icon mr-2" 
                                        onClick={e => {
                                            e.preventDefault();
                                            addExtraField(params.params);
                                        }}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                    : ""
                                }
                                <button className="btn btn-transparent-dark btn-sm"
                                    onClick={e => {
                                            e.preventDefault();
                                            removeExtraField(params.params, index);
                                    }}>
                                    Remove
                                </button>
                            </div>
                        </div>
                        : 

                        p.key != "body" 
                        ? <input name={p.key} className="form-control" value={p.value} onChange={e => handleChange(p, e.target.value, "value")}></input>
                        : 
                        <RteField
                          onChangeProp={(value) => {
                            handleChange(p ,value, "value")
                          }}
                          onBlurProp={(value) => {
                           // console.log("Hello");
                          }}
                          valueProp={p.value}
                          type="textarea"
                          name={p.key}
                          id={p.key}
                          height="180px"
                          {...rest}
                        />
                    }
                    
                  </div>
                )
            });
        })
       }
    
    const renderParams = () => {

        return (
            <div className="row">
                <div className="col-8 mr-auto">
                    {printParams(sortedParams)}
                    {extraParams.length > 0 ? 
                        <div className="mt-3 mb-3" style={{borderBottom: "1px solid gray", paddingTop: "5px", paddingBottom:"5px"}}>
                            <strong>Additional Parameters</strong>
                        </div>   
                     : ""}
                    {printParams(extraParams)}  
                </div>
            </div>
        );
    }

    return (
        <div>
            {renderHeaders()}
            <form onSubmit={(e) => {
                e.preventDefault(); 
                handleSubmit([...sortedParams, ...extraParams]);   
            }}>
                {renderParams()}
                <div className="row">
                    <div className="col-auto">
                        <button type="submit" className="btn btn-success">Save</button>
                        <button className="ml-3 btn btn btn-light" onClick={ () => {
                            composerToggle(false);
                        }}>Discard</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default NewMessageComposer;