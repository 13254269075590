import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../redux/store/Store";
import { deleteScenarioAction } from "../../../../../redux/actions/scenarioActions";

const DeleteScenarioForm = () => {
  const dispatch = useDispatch();
  const selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);

  return (
    <div>
      <p>
        Are you sure to delete scenario? <br />
        This operation is not returnable and all scenario data will be deleted permanently.
      </p>
      <h5>Items to delete</h5>
      <ul>
        <li>Events</li>
        <li>Actions</li>
        <li>Validations</li>
        <li>Session Data</li>
        <li>States</li>
      </ul>

      <div className="modal-footer">
        <button id="DeleteScenarioModalCloseButton" className="btn btn-primary p-2 mt-3" type="button" data-dismiss="modal">
          Cancel
        </button>
        <button
          id="DeleteScenarioModalSaveButton"
          className="btn btn-danger p-2 mt-3"
          type="button"
          onClick={() => {
            dispatch(deleteScenarioAction(selectedScenarioId.data));
          }}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteScenarioForm;
