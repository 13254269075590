
import * as  initialStates from "../store/InitialStates";
import { GET_AUDIENCE_REPORTING_FAIL, GET_AUDIENCE_REPORTING_LOADING, GET_AUDIENCE_REPORTING_SUCCESS } from "../types/types";

export const getAuidenceReportingReducer = (state = initialStates.getAudienceMetricsInitialState, action: any) => {
    switch (action.type) {
        case GET_AUDIENCE_REPORTING_LOADING:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case GET_AUDIENCE_REPORTING_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          data: null,
        };
      case GET_AUDIENCE_REPORTING_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          data: action.payload,
        };
      default:
        return state;
    }
  };