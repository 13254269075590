import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "../../../../../redux/store/Store";
import { postEventParamAction } from "../../../../../redux/actions/EventActions";
import { aggregationDurationOptions, aggregationTypeOptions, parameterTypeOptions } from "./utils/formUtils";
import * as Yup from "yup";
import moment from "moment";
import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";


const EditEventParamForm = () => {
  const dispatch = useDispatch();

  var eventId: any = useSelector((state: RootStore) => state.sendEventIdReducer);
  var scenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  var selectedEventParamId: any = useSelector((state: RootStore) => state.sendEventParamIdReducer);

  var eventParams: any = useSelector((state: RootStore) => state.sendEventParamsReducer);
  var editEventParamError: any = useSelector((state: RootStore) => state.postEventParamReducer);

  const [parameterState, setparameterState] = useState<any | null>(null);
  const [editEventEData, seteditEventEData] = useState<any>(null);

  useEffect(() => {
    if (editEventParamError.data) {
      if (editEventParamError.data.message) {
        seteditEventEData(editEventParamError.data.message);
      }
    }
  }, [editEventParamError]);

  useEffect(() => {
    setParameterData();
  }, [eventParams.data, selectedEventParamId.data]);

  const setParameterData = () => {
    if (selectedEventParamId.data) {
      if (eventParams.data) {
        var paramState = Object.values(eventParams.data).filter((item: any) => item.propertyName === selectedEventParamId.data);
        setparameterState(paramState[0]);
      }
    }
  };

  const setEventParamOptions = () => {
    return <>{parameterTypeOptions ? Object.values(parameterTypeOptions).map((item: any, index) => <option key={index} value={item.value} label={item.key} />) : null}</>;
  };

  const setAggregationTypeOptions = () => {
    return <>{aggregationTypeOptions ? Object.values(aggregationTypeOptions).map((item: any, index) => <option key={index} value={item.value} label={item.key} />) : null}</>;
  };

  const setAggregationDurationTypeOptions = () => {
    return <>{aggregationDurationOptions ? Object.values(aggregationDurationOptions).map((item: any, index) => <option key={index} value={item.value} label={item.key} />) : null}</>;
  };

  const handleAddNewEventParamApi = (bodyObject: any) => {
    var aggInfo: null | string | {};
    var description: string = bodyObject.descriptionForm;
    var propertyName: string = bodyObject.paramName;
    var propertyType: string = bodyObject.paramType;
    var type: string = "";
    var unit: number = 0;
    var durationType: string = "";
    var baseTime = moment.utc(bodyObject.aggregationBaseDate).local().format("YYYY-MM-DD HH:mm");

    if (bodyObject.aggregationType.toLocaleUpperCase() !== "NONE") {
      if (bodyObject.aggregationType.toUpperCase() === "FIXED") {
        durationType = bodyObject.aggregationDurationFixedType;
        unit = bodyObject.aggregationDurationUnitFixed;
      } else if (bodyObject.aggregationType.toUpperCase() === "SLIDING") {
        durationType = bodyObject.aggregationDurationSlidingType;
        unit = bodyObject.aggregationDurationUnitSliding;
      }
    }

    if (bodyObject.aggregationType.toUpperCase() === "NONE" || bodyObject.aggregationType.toUpperCase() === "" || bodyObject.paramType.toUpperCase() === "STRING") {
      aggInfo = null;
    } else {
      type = bodyObject.aggregationType;

      if (type.toUpperCase() === "FIXED") {
        aggInfo = {baseTime,durationType,type,unit};
      } else {
        aggInfo = {durationType,type,unit};
      }
    }

    var sendObj = {
      aggInfo: aggInfo,
      description: description,
      propertyName: propertyName,
      propertyType: propertyType,
    };

    dispatch(postEventParamAction(sendObj, scenarioId.data, eventId.data, selectedEventParamId.data));
  };

  const validationSchema = Yup.object({
    paramName: Yup.string().required("Parameter name is required"),

    aggregationDurationFixedType: Yup.string().when("aggregationDurationFixed", {
      is: (val: any) => val !== "",
      then: Yup.string().when("aggregationType", {
        is: (val: any) => val === "FIXED",
        then: Yup.string().when("paramType", {
          is: (val: any) => val === "NUMERIC",
          then: Yup.string().required(""),
        }),
      }),
    }),

    aggregationDurationUnitFixed: Yup.number().when("aggregationType", {
      is: (val: any) => val === "FIXED",
      then: Yup.number().when("paramType", {
        is: (val: any) => val === "NUMERIC",
        then: Yup.number()
          .test("", "Duration must be positive number", (val: any) => val >= 0)
          .required("Duration is required"),
      }),
    }),

    aggregationBaseDate: Yup.date().when("aggregationType", {
      is: (val: any) => val === "FIXED",
      then: Yup.date().when("paramType", {
        is: (val: any) => val === "NUMERIC",
        then: Yup.date().required("").min(moment.utc().local().format("YYYY-MM-DD"), "field can not be past date"),
      }),
    }),

    aggregationDurationSlidingType: Yup.string().when("aggregationDurationSliding", {
      is: (val: any) => val !== "",
      then: Yup.string().when("aggregationType", {
        is: (val: any) => val === "SLIDING",
        then: Yup.string().when("paramType", {
          is: (val: any) => val === "NUMERIC",
          then: Yup.string().required(""),
        }),
      }),
    }),

    aggregationDurationUnitSliding: Yup.number().when("aggregationType", {
      is: (val: any) => val === "SLIDING",
      then: Yup.number().when("paramType", {
        is: (val: any) => val === "NUMERIC",
        then: Yup.number()
          .test("", "Duration must be positive number", (val: any) => val >= 0)
          .required("Duration is required"),
      }),
    }),
  });

  const formik = useFormik({
    initialValues: {
      paramName: parameterState ? (parameterState.propertyName ? parameterState.propertyName : "") : "",
      paramType: parameterState ? parameterState.propertyType : "STRING",
      aggregationType: parameterState ? (parameterState.aggInfo ? (parameterState.aggInfo.type ? parameterState.aggInfo.type : "None") : "None") : "None",
      aggregationBaseDate: parameterState ? (parameterState.aggInfo ? (parameterState.aggInfo.baseTime ? parameterState.aggInfo.baseTime : "") : "") : "",
      aggregationDurationUnitFixed: parameterState ? (parameterState.aggInfo ? (parameterState.aggInfo.unit ? parameterState.aggInfo.unit : 0) : 0) : 0,
      aggregationDurationFixedType: parameterState
        ? parameterState.aggInfo
          ? parameterState.aggInfo.durationType
            ? parameterState.aggInfo.durationType
            : "Not Specified"
          : "Not Specified"
        : "Not Specified",
      aggregationDurationUnitSliding: parameterState ? (parameterState.aggInfo ? (parameterState.aggInfo.unit ? parameterState.aggInfo.unit : 0) : 0) : 0,
      aggregationDurationSlidingType: parameterState
        ? parameterState.aggInfo
          ? parameterState.aggInfo.durationType
            ? parameterState.aggInfo.durationType
            : "Not Specified"
          : "Not Specified"
        : "Not Specified",
      description: parameterState ? (parameterState.description ? parameterState.description : "") : "",
    },
    onSubmit: () => {},
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <div>
          <label className="form-label" htmlFor="paramName">
            Parameter Name
          </label>
        </div>
        <input className="form-control" id="paramName" name="paramName" type="text" onChange={(e)=>{formik.handleChange(e); seteditEventEData("")}} value={formik.values.paramName} />
        {editEventEData ? <ErrorMessageComp name={editEventEData} /> : <ErrorMessageComp name={formik.errors.paramName} />}
      </div>

      <div className="mt-3">
        <label className="form-label">Type</label>
        <div>
          <select className="form-control" id="paramType" name="paramType" value={formik.values.paramType} onChange={formik.handleChange}>
            {setEventParamOptions()}
          </select>
        </div>
      </div>

      {formik.values.paramType === "NUMERIC" ? (
        <div className="mt-3">
          <label className="form-label">Aggregation Type</label>
          <div>
            <select className="form-control" id="aggregationType" name="aggregationType" value={formik.values.aggregationType} onChange={formik.handleChange}>
              {setAggregationTypeOptions()}
            </select>
          </div>
        </div>
      ) : null}

      {formik.values.aggregationType === "FIXED" && formik.values.paramType === "NUMERIC" ? (
        <div className="mt-3">
          <div>
            <div>
              <label className="form-label" htmlFor="aggregationBaseDate">
                Aggregation Base Date
              </label>
            </div>
            <input className="form-control" id="aggregationBaseDate" name="aggregationBaseDate" type="date" onChange={formik.handleChange} value={formik.values.aggregationBaseDate} />
            <ErrorMessageComp name={formik.errors.aggregationBaseDate} />
          </div>

          <div className="mt-3">
            <div className="row">
              <div className="col-sm">
                <div>
                  <div>
                    <label className="form-label" htmlFor="aggregationDurationFixed">
                      Aggregation Duration
                    </label>
                  </div>
                  <input
                    className="form-control"
                    id="aggregationDurationUnitFixed"
                    name="aggregationDurationUnitFixed"
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.aggregationDurationUnitFixed}
                  />
                  <ErrorMessageComp name={formik.errors.aggregationDurationUnitFixed} />
                </div>
              </div>
              <div className="col-sm">
                <label className="form-label">Duration Type</label>
                <div>
                  <select
                    className="form-control"
                    id="aggregationDurationFixedType"
                    name="aggregationDurationFixedType"
                    value={formik.values.aggregationDurationFixedType}
                    onChange={formik.handleChange}
                  >
                    {setAggregationDurationTypeOptions()}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {formik.values.aggregationType === "SLIDING" && formik.values.paramType === "NUMERIC" ? (
        <div className="mt-3">
          <div className="mt-3">
            <div className="row">
              <div className="col-sm">
                <div>
                  <div>
                    <label className="form-label" htmlFor="aggregationDurationUnitSliding">
                      Aggregation Duration
                    </label>
                  </div>
                  <input
                    className="form-control"
                    id="aggregationDurationUnitSliding"
                    name="aggregationDurationUnitSliding"
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.aggregationDurationUnitSliding}
                  />
                  <ErrorMessageComp name={formik.errors.aggregationDurationUnitSliding} />
                </div>
              </div>
              <div className="col-sm">
                <label className="form-label">Duration Type</label>
                <div>
                  <select
                    className="form-control"
                    id="aggregationDurationSlidingType"
                    name="aggregationDurationSlidingType"
                    value={formik.values.aggregationDurationSlidingType}
                    onChange={formik.handleChange}
                  >
                    {setAggregationDurationTypeOptions()}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div>
        <div className="mt-3">
          <label className="form-label" htmlFor="description">
            Description
          </label>
        </div>
        <textarea className="form-control" id="description" name="description" rows={3} onChange={formik.handleChange} value={formik.values.description} />
      </div>

      <div>
        <div className="modal-footer">
          <button
            id="addNewEventParameterCloseButton"
            className="btn btn-light mt-3"
            type="button"
            data-dismiss="modal"
            onClick={() => {
              formik.resetForm();
              seteditEventEData("")
            }}
          >
            Close
          </button>
          <button
            id="addNewEventParameterCreateButton"
            onClick={() => {
              handleAddNewEventParamApi(formik.values);
            }}
            className="btn btn-success mt-3"
            type="submit"
            disabled={!formik.isValid}
          >
            Create
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditEventParamForm;
