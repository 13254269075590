import moment, { Moment } from "moment";

export abstract class ESQuery {
  
  protected query: any;

  distinct: boolean;
  multiAgg: boolean;
  aggField: string | undefined;

  getQuery() {
    return this.query;
  }

  constructor(distinct: boolean, multiAgg: boolean, aggField: string | undefined) {
    this.distinct = distinct;
    this.multiAgg = multiAgg;
    this.aggField = aggField;
  }

  public extraFilters(extraFilters: any[]): any[] {
    let filterClauses: any[] = [];
    extraFilters.forEach(filter => {
      let filterClause: any;
      switch (filter.operator) {
        case 'terms':
          filterClause = {
            "terms": {
              [filter.field]: filter.values
            }
          };
          break;
        case 'range':
          filterClause = {
            "range": {
              [filter.field]: filter.values[0]
            }
          };
          break;
        // Add more cases for other operators as needed
        default:
          throw new Error(`Unsupported operator: ${filter.operator}`);
      }
      filterClauses.push(filterClause);
    });

    return filterClauses
  }
}

export class ESHistogramQuery extends ESQuery {
  
  constructor(
    events: string[], 
    scenarios: string[], 
    interval: string, 
    start: Moment, end: 
    Moment, includeDistinct: boolean,
    extraFilters: { field: string, operator: string, values: any[] }[] = []) {

      super(includeDistinct, false, undefined)

    const startDate = start.format("YYYY-MM-DD HH:mm:ss");
    const endDate = end.format("YYYY-MM-DD HH:mm:ss");

    let aggs: any = {};

    let filterClauses: any[] = [
      {
        "range": {
          "entry_time": {
            "gte": startDate,
            "lt": endDate
          }
        }
      },
      {
        "terms": {
          "event_name": events
        }
      },
      {
        "terms": {
          "scenario_name": scenarios
        }
      }
    ];

    filterClauses = filterClauses.concat(this.extraFilters(extraFilters));

    aggs["data_histogram"] = {
      "filter": {
        "bool": {
          "must": filterClauses
        }
      },
      "aggs": {
        "filtered_events": {
          "date_histogram": {
            "field": "entry_time",
            "calendar_interval": "1d",
            "min_doc_count": 0,
            "extended_bounds": {
              "min": startDate,
              "max": endDate
            }
          },
          "aggs": {}
        }
      }
    };

    if (includeDistinct) {
      aggs["data_histogram"]["aggs"]["filtered_events"]["aggs"] = {
        "distinct_sessions_count": {
          "cardinality": {
            "field": "session_id"
          }
        }
      };
    }

    if (includeDistinct) {
      aggs["total_distinct_sessions"] = {
        "filter": {
        "bool": {
          "must": filterClauses
        }
      },
        "aggs": {
          "distinct_sessions_count": {
            "cardinality": {
              "field": "session_id"
            }
          }
        }
      };
    } else {
      aggs["total_events"] = {
        "filter": {
          "bool": {
            "must": filterClauses
          }
        }
      };
    }

    this.query = {
      "size": 0,
      "aggs": aggs
    };
  }
}


export class ESHistogramQueryWithPropAggregation extends ESQuery {
  
  constructor(
    events: string[],
    scenarios: string[],
    field: string,
    interval: string,
    start: Moment,
    end: Moment,
    includeDistinct: boolean,
    extraFilters: { field: string, operator: string, values: any[] }[] = []
  ) {

    super(includeDistinct, true, field)

    const startDate = start.format("YYYY-MM-DD HH:mm:ss");
    const endDate = end.format("YYYY-MM-DD HH:mm:ss");

    let aggs: any = {};

    let filterClauses: any[] = [
      {
        "range": {
          "entry_time": {
            "gte": startDate,
            "lt": endDate
          }
        }
      },
      {
        "terms": {
          "event_name": events
        }
      },
      {
        "terms": {
          "scenario_name": scenarios
        }
      }
    ];

    filterClauses = filterClauses.concat(this.extraFilters(extraFilters));

    aggs["data_histogram"] = {
      "filter": {
        "bool": {
          "must": filterClauses
        }
      },
      "aggs": {
        "filtered_events": {
          "date_histogram": {
            "field": "entry_time",
            "calendar_interval": interval,
            "min_doc_count": 0,
            "extended_bounds": {
              "min": startDate,
              "max": endDate
            }
          },
          "aggs": {}
        }
      }
    };

    if (includeDistinct) {
      aggs["data_histogram"]["aggs"]["filtered_events"]["aggs"]["distinct_sessions_count"] = {
        "cardinality": {
          "field": "session_id"
        }
      };
    }


    aggs["data_histogram"]["aggs"]["filtered_events"]["aggs"][field] = {
      "terms": {
        "field": `${field}`,
      }
    };

    aggs["field_aggs"] =  { 
      "filter": {
          "bool": {
            "must": filterClauses
          }
        },
      "aggs": {
        "eventdata" : {
          "terms": {
            "field": field,
            "size": 100000  // Adjust size as needed
          }
        }
      }
    };

    if (includeDistinct) {
      aggs["total_distinct_sessions"] = {
        "filter": {
          "bool": {
            "must": filterClauses
          }
        },
        "aggs": {
          "distinct_sessions_count": {
            "cardinality": {
              "field": "session_id"
            }
          }
        }
      };
    } else {
      aggs["total_events"] = {
        "filter": {
          "bool": {
            "must": filterClauses
          }
        }
      };
    }

    this.query = {
      "size": 0,
      "aggs": aggs
    };
  }

 
}

export class ESQueryPropAggregation {
  private query: any;

  constructor(events: string[], scenarios: string[], field: String, start: Moment, end: Moment) {

    const startDate = start.format("YYYY-MM-DD HH:mm:ss");
    const endDate = end.format("YYYY-MM-DD HH:mm:ss");

    let aggs: any = {};

    // Add filtered_events aggregation with time range filter
    aggs["filtered_events"] = {
      "filter": {
        "bool": {
          "must": [
            {
              "terms": {
                "event_name": events
              }
            },
            {
              "terms": {
                "scenario_name": scenarios
              }
            },
            {
              "range": {
                "entry_time": {
                  "gte": startDate,
                  "lte": endDate
                }
              }
            }
          ]
        }
      },
      "aggs": {
        "eventprop_type_terms": {
          "terms": {
            "field": field,
            "size": 100000  // Adjust size as needed
          }
        }
      }
    };

    // Construct the final query object
    this.query = {
      "size": 0,
      "aggs": aggs
    };
  }

  getQuery() {
    return this.query;
  }
}

export class ESQueryEventFilterAggregation {
  private query: any;

  constructor(events: string[], scenarios: string[], fieldValuePairs: { [key: string]: string }, start: Moment, end: Moment) {

    const startDate = start.format("YYYY-MM-DD HH:mm:ss");
    const endDate = end.format("YYYY-MM-DD HH:mm:ss");

    let aggs: any = {};

    // Construct the filters array
    let filters: any[] = [
      {
        "terms": {
          "event_name": events
        }
      },
      {
        "terms": {
          "scenario_name": scenarios
        }
      },
      {
        "range": {
          "entry_time": {
            "gte": startDate,
            "lte": endDate
          }
        }
      }
    ];

    // Add field-value pairs to filters
    for (let key in fieldValuePairs) {
      filters.push({
        "term": {
          [key]: fieldValuePairs[key]
        }
      });
    }


    // Add filtered_events aggregation with the constructed filters
    aggs["filtered_events"] = {
      "filter": {
        "bool": {
          "must": filters
        }
      },
      "aggs": {
        "event_count": {
          "value_count": {
            "field": "_index"  // This counts the number of documents
          }
        }
      }
    };

    // Construct the final query object
    this.query = {
      "size": 0,
      "aggs": aggs
    };
  }

  getQuery() {
    return this.query;
  }
}
