import * as ScenarioActionTypes from "../types/types";
import { apiErrorHandle } from "../../components/common/hooksAndFuncts/ApiErrorHandle";
import axios from "axios";

export const getScenarioList = () => async (dispatch: any) => {
  try {
    dispatch({
      type: ScenarioActionTypes.GET_SCENARIO_LIST_LOADING,
    });

    await axios
      .get("/scenario")
      .then((response) => {
        dispatch({
          type: ScenarioActionTypes.GET_SCENARIO_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "getScenarioApiError");
      });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: ScenarioActionTypes.GET_SCENARIO_LIST_FAIL,
      payload: error,
    });
  }
};

export const getSingleScenarioAction = (scenarioId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: ScenarioActionTypes.GET_SINGLE_SCENARIO_LIST_LOADING,
    });

    await axios
      .get(`/scenario/${scenarioId}`)
      .then((response) => {
        dispatch({
          type: ScenarioActionTypes.GET_SINGLE_SCENARIO_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "getSingleScenarioApiError");
      });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: ScenarioActionTypes.GET_SINGLE_SCENARIO_LIST_FAIL,
      payload: error,
    });
  }
};

export const putScenarioAction = (bodyObj: any, formObj: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ScenarioActionTypes.ADD_NEW_SCENARIO_LOADING,
    });
    await axios
      .put(`/scenario`, bodyObj)
      .then((response) => {
        formObj.resetForm();
        dispatch({
          type: ScenarioActionTypes.ADD_NEW_SCENARIO_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "putScenarioApiError");
        dispatch({
          type: ScenarioActionTypes.ADD_NEW_SCENARIO_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const postScenarioAction = (bodyObj: any, scenarioId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: ScenarioActionTypes.EDIT_SCENARIO_LOADING,
    });
    await axios
      .post(`/scenario/${scenarioId}`, bodyObj)
      .then((response) => {
        $("#EditScenarioModal").modal("hide");
        dispatch({
          type: ScenarioActionTypes.EDIT_SCENARIO_SUCCESS,
          payload: { bodyObj, response: response },
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "postScenarioApiError");
        dispatch({
          type: ScenarioActionTypes.EDIT_SCENARIO_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const deleteScenarioAction = (scenarioId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: ScenarioActionTypes.DELETE_SCENARIO_LOADING,
    });
    await axios
      .delete(`/scenario/${scenarioId}`)
      .then((response) => {
        window.location.replace("/scenario");
        dispatch({
          type: ScenarioActionTypes.DELETE_SCENARIO_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        alert(error.response.data.data.detail);
        apiErrorHandle(error, "deleteScenarioAction");
        dispatch({
          type: ScenarioActionTypes.DELETE_SCENARIO_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const deployScenarioAction = (scenarioId: string | number) => async (dispatch: any) => {


  var data = {
    command: "deploy",
  };
  try {
    dispatch({
      type: ScenarioActionTypes.DEPLOY_SCENARIO_LOADING,
    });
    await axios
      .post(`/scenario/${scenarioId}/op`, data)
      .then((response) => {
        dispatch({
          type: ScenarioActionTypes.DEPLOY_SCENARIO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "deployScenarioApiError");
        alert(error.response.data.data.detail);
        dispatch({
          type: ScenarioActionTypes.DEPLOY_SCENARIO_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const reDeployScenarioAction = (scenarioId: string) => async (dispatch: any) => {
  var data = {
    command: "redeploy",
  };
  try {
    dispatch({
      type: ScenarioActionTypes.RE_DEPLOY_SCENARIO_LOADING,
    });
    await axios
      .post(`/scenario/${scenarioId}/op`, data)
      .then((response) => {
        dispatch({
          type: ScenarioActionTypes.RE_DEPLOY_SCENARIO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "reDeployScenarioApiError");
        alert(error.response.data.data.detail);
        dispatch({
          type: ScenarioActionTypes.RE_DEPLOY_SCENARIO_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const unDeployScenarioAction = (scenarioId: string) => async (dispatch: any) => {
  var data = {
    command: "undeploy",
  };
  try {
    dispatch({
      type: ScenarioActionTypes.UN_DEPLOY_SCENARIO_LOADING,
    });
    await axios
      .post(`/scenario/${scenarioId}/op`, data)
      .then((response) => {
        dispatch({
          type: ScenarioActionTypes.UN_DEPLOY_SCENARIO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "unDeployScenarioApiError");
        alert(error.response.data.data.detail);
        dispatch({
          type: ScenarioActionTypes.UN_DEPLOY_SCENARIO_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const freezeScenarioAction = (scenarioId: string) => async (dispatch: any) => {
  var data = {
    command: "freeze",
  };
  try {
    dispatch({
      type: ScenarioActionTypes.FREEZE_SCENARIO_LOADING,
    });
    await axios
      .post(`/scenario/${scenarioId}/op`, data)
      .then((response) => {
        dispatch({
          type: ScenarioActionTypes.FREEZE_SCENARIO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "freezeScenarioApiError");
        alert(error.response.data.data.detail);
        dispatch({
          type: ScenarioActionTypes.FREEZE_SCENARIO_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const resumeScenarioAction = (scenarioId: string) => async (dispatch: any) => {
  var data = {
    command: "resume",
  };
  try {
    dispatch({
      type: ScenarioActionTypes.RESUME_SCENARIO_LOADING,
    });
    await axios
      .post(`/scenario/${scenarioId}/op`, data)
      .then((response) => {
        dispatch({
          type: ScenarioActionTypes.RESUME_SCENARIO_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "resumeScenarioApiError");
        alert(error.response.data.data.detail);
        dispatch({
          type: ScenarioActionTypes.RESUME_SCENARIO_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const getScenarioStatusAction = (scenarioId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: ScenarioActionTypes.GET_SCENARIO_STATUS_LOADING,
    });
    await axios
      .get(`/scenario/status/${scenarioId}`)
      .then((response) => {
        dispatch({
          type: ScenarioActionTypes.GET_SCENARIO_STATUS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "deployScenarioApiError");
        dispatch({
          type: ScenarioActionTypes.GET_SCENARIO_STATUS_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const sendSelectScenarioIdAction = (selectedScenarioId: string) => (dispatch: any) => {
  try {
    dispatch({
      type: ScenarioActionTypes.SEND_SCENARIO_ID_FAIL,
    });
    dispatch({
      type: ScenarioActionTypes.SEND_SCENARIO_ID_SUCCESS,
      payload: selectedScenarioId,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: ScenarioActionTypes.SEND_SCENARIO_ID_LOADING,
      payload: error,
    });
  }
};

export const sendSelectScenarioList = (scenarioList: any) => (dispatch: any) => {
  try {
    dispatch({
      type: ScenarioActionTypes.SEND_SCENARIO_NAME_LIST_LOADING,
    });
    dispatch({
      type: ScenarioActionTypes.SEND_SCENARIO_NAME_LIST_SUCCESS,
      payload: scenarioList,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: ScenarioActionTypes.SEND_SCENARIO_NAME_LIST_FAIL,
      payload: error,
    });
  }
};

export const sendSelectedScenarioDataAction = (bodyObject: any) => (dispatch: any) => {
  try {
    dispatch({
      type: ScenarioActionTypes.SEND_SCENARIO_LOADING,
    });
    dispatch({
      type: ScenarioActionTypes.SEND_SCENARIO_SUCCESS,
      payload: bodyObject,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: ScenarioActionTypes.SEND_SCENARIO_FAIL,
      payload: error,
    });
  }
};

