import * as stateActionTypes from "../types/types";

export const getStatesReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case stateActionTypes.GET_STATES_FAIL:
      return {
        data: action.payload,
      };
    case stateActionTypes.GET_STATES_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const putStateReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case stateActionTypes.ADD_STATE_FAIL:
      return {
        data: action.payload,
      };
    case stateActionTypes.ADD_STATE_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const postStateReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case stateActionTypes.EDIT_STATE_FAIL:
      return {
        data: action.payload,
      };
    case stateActionTypes.EDIT_STATE_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const deleteStateReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case stateActionTypes.DELETE_STATE_FAIL:
      return {
        data: action.payload,
      };
    case stateActionTypes.DELETE_STATE_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendStatesDataReducer = (state:any = [], action: any) => {
  switch (action.type) {
    case stateActionTypes.SEND_STATES_DATA_FAIL:
      return {
        data: action.payload,
      };
    case stateActionTypes.SEND_STATES_DATA_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendStateIdReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case stateActionTypes.SEND_STATE_ID_FAIL:
      return {
        data: action.payload,
      };
    case stateActionTypes.SEND_STATE_ID_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const getStateByIdReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case stateActionTypes.GET_STATE_FAIL:
      return {
        data: action.payload,
      };
    case stateActionTypes.GET_STATE_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};
