import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deployCampaignAction, freezeCampaignAction, getCampaingAction, reDeployCampaignAction, resumeCampaignAction, unDeployCampaignAction } from "../../../redux/actions/campaignActions";
import { getScenarioStatusAction } from "../../../redux/actions/scenarioActions";
import { RootStore } from "../../../redux/store/Store";

const CampaignOperations = () => {

    const dispatch = useDispatch();


    var deployCampaignResponse: any = useSelector((state: RootStore) => state.deployCampaignReducer);
    var unDeployCampaignResponse: any = useSelector((state: RootStore) => state.unDeployCampaignReducer);
    var reDeployCampaignResponse: any = useSelector((state: RootStore) => state.reDeployCampaignReducer);
    var freezeDeployCampaignResponse: any = useSelector((state: RootStore) => state.freezeCampaignReducer);
    var resumeDeployCampaignResponse: any = useSelector((state: RootStore) => state.resumeCampaignReducer);

    const campaignR: any = useSelector((state: RootStore) => state.getCampaignReducer);


    const [campaignScenarioId, setcampaignScenarioId] = useState("");

   const {campaignId} = useParams<{campaignId:string}>();


  useEffect(() => {
    if (campaignR) {
      setcampaignScenarioId(campaignR?.data?.data?.campaign?.scenarioId)
    }
  }, [campaignR]);



   useEffect(() => {
    if (deployCampaignResponse?.data?.status === "success") {
        dispatch(getCampaingAction(campaignId))
        if(campaignScenarioId){
            dispatch(getScenarioStatusAction(campaignScenarioId));
        }
    }

  }, [deployCampaignResponse.data,campaignScenarioId]);

  useEffect(() => {
    if (unDeployCampaignResponse?.data?.status === "success" && campaignScenarioId) {
      dispatch(getScenarioStatusAction(campaignScenarioId));
    }
  }, [unDeployCampaignResponse.data,campaignScenarioId]);

  useEffect(() => {
    if (reDeployCampaignResponse?.data?.status === "success" && campaignScenarioId) {
      dispatch(getScenarioStatusAction(campaignScenarioId));
    }
  }, [reDeployCampaignResponse.data,campaignScenarioId]);

  useEffect(() => {
    if (resumeDeployCampaignResponse?.data?.status === "success" && campaignScenarioId) {
      dispatch(getScenarioStatusAction(campaignScenarioId));
    }
  }, [resumeDeployCampaignResponse.data,campaignScenarioId]);

  useEffect(() => {
    if (freezeDeployCampaignResponse?.data?.status === "success" && campaignScenarioId) {
      dispatch(getScenarioStatusAction(campaignScenarioId));
    }
  }, [freezeDeployCampaignResponse.data,campaignScenarioId]);
  
   

  return (
    <>
      <button
        className="btn btn-primary dropdown-toggle p-2"
        type="button"
        id="campaingViewOperationId"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        OPERATIONS
      </button>
      <div className="dropdown-menu" aria-labelledby="campaingViewOperationId">
      <button
            onClick={() => {
              dispatch(reDeployCampaignAction(campaignId));
            }}
            className="dropdown-item"
            type="button"
          >
            REDEPLOY
          </button>
        <button
            onClick={() => {
              dispatch(deployCampaignAction(campaignId));
            }}
            className="dropdown-item"
            type="button"
          >
            DEPLOY
          </button>
          <button
            onClick={() => {
              dispatch(unDeployCampaignAction(campaignId));
            }}
            className="dropdown-item"
            type="button"
          >
            UNDEPLOY
          </button>
          <button
            onClick={() => {
              dispatch(freezeCampaignAction(campaignId));
            }}
            className="dropdown-item"
            type="button"
          >
            FREEZE
          </button>
          <button
            onClick={() => {
              dispatch(resumeCampaignAction(campaignId));
            }}
            className="dropdown-item"
            type="button"
          >
            RESUME
          </button>
      
      </div>
    </>
  );
};

export default CampaignOperations;
