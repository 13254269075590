import { useDispatch, useSelector } from "react-redux";
import { deleteValidationAction } from "../../../../../redux/actions/ValidationActions";
import { RootStore } from "../../../../../redux/store/Store";

const DeleteValidationManageForm = () => {
  const dispatch = useDispatch();
  const selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  const selectedValiationId: any = useSelector((state: RootStore) => state.sendSelectedValidationIdReducer);

  return (
    <div>
      <p>Are you sure to delete session definition? This operation is not returnable and all session data will be deleted permanently.</p>

      <div className="modal-footer">
        <button id="DeleteValidationManageModalCloseButton" className="btn btn-primary p-2 mt-3" type="button" data-dismiss="modal" onClick={() => {}}>
          Cancel
        </button>
        <button
          id="DeleteValidationManageModalSaveButton"
          onClick={() => {
            dispatch(deleteValidationAction(selectedScenarioId.data, selectedValiationId.data));
          }}
          className="btn btn-danger p-2 mt-3"
          type="button"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteValidationManageForm;
