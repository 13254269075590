export const linkViewUrl = "/scenario/";
export const loginApiUrl = "/v2/login";
export const registerApiUrl = "/v2/register"
export const getAppUrl = "/dashboard/app";
export const logoutURL = "/v2/logout";
export const getUtilTimeZoneUrl = "/util/timezone";
export const getSystemTimeZoneUrl = "/system/timezone";
export const getCustomerUrl = "/customer";
export const postCustomerAppUrl = "/customer/app";
export const postCustomerBasicUrl = "/customer/basic";
export const postCustomerProfileUrl = "/customer/profile";
export const authenticateUrl = "/authenticate"; 



