import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from 'jquery';
import { RootStore } from "../../../redux/store/Store";
import { getIntegrationVendorsAction, getRawActionTemplateById, putActionAction } from "../../../redux/actions/ActionActions";
import ErrorMessageComp from "../../../components/common/ErrorMessageComp";
import { apiErrorHandle } from "../../../components/common/hooksAndFuncts/ApiErrorHandle";


const AddCampaignActionForm = (props: any) => {
  const dispatch = useDispatch();

  const {saveCallback} = props;

  const template: any = useSelector((state: RootStore) => state.getTemplateReducer);
  const integrationVendors: any = useSelector((state: RootStore) => state.getIntegrationVendorsReducer);
  const selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  const newActionError: any = useSelector((state: RootStore) => state.putActionReducer);

  const [newActionErrorData, setnewActionErrorData] = useState<any>(null);
  const [integrationForValue, setintegrationForValue] = useState("");
  const [isThereIntegration, setisThereIntegration] = useState(false);
  const [actionNameValue, setactionNameValue] = useState("");

  const [templateData, settemplateData] = useState<any>(null);
  const [integrationVendorsData, setintegrationVendorsData] = useState<any>(null);
  const [selectedVendorId, setselectedVendorId] = useState("");
  const [actionGroupName, setactionGroupName] = useState("");
  const [selectedIntegrationVendorId, setselectedIntegrationVendorId] = useState("");
  const [selectedIntegration, setSelectedIntegration] = useState<any>(null);
  const [templateName, settemplateName] = useState("");
  const [integrationId, setintegrationId] = useState("");

  useEffect(() => {
    if (newActionError.data) {
      if (newActionError.data.message) {
        setnewActionErrorData(newActionError.data.message);
      }
    }
    if (newActionError?.data?.status === "success") {
      formReset();
    }
  }, [newActionError.data]);

  useEffect(() => {
    if (template.data) {
      settemplateData(template.data);
    }
  }, [template.data]);

  useEffect(() => {
    if (selectedVendorId) {
      dispatch(getIntegrationVendorsAction(selectedVendorId));
    }
  }, [selectedVendorId]);

  useEffect(() => {
    if (actionGroupName) {
      setselectedVendorId("");
      setselectedIntegrationVendorId("");
    }
  }, [actionGroupName]);

  useEffect(() => {
    if (integrationVendors.data) {
      setintegrationVendorsData(integrationVendors.data);
      if (integrationVendors.data[0]) {
        setintegrationForValue(integrationVendors.data[0].productName);
        setintegrationId(integrationVendors.data[0].id);
        setSelectedIntegration(integrationVendors.data[0]);
        setisThereIntegration(true);
      } else {
        setisThereIntegration(false);
      }
    }
  }, [integrationVendors.data]);

  useEffect(() => {
    $("#AddNewActionModalCloseIconButton").click(function () {
      formReset();
    });
  }, []);

  const setActionGroupOption = () => {
    return (
      <>
        <option key={"_default"} value="" disabled selected label="Choose a group" />
        {templateData ? Object.values(templateData).map((t: any) => <option key={t.name} value={t.name} label={t.name} />) : null}
      </>
    );
  };


  const setVendorOption = () => {
    return (
      <>
        <option value="" disabled selected label="Choose a vendor" />
        {templateData
          ? Object.values(templateData)
              .filter((tf: any) => tf.name === actionGroupName)
              .map((t: any) => (t.vendors ? Object.values(t.vendors).map((t: any) => <option key={t.name} value={t.integration ? t.integration.apiId : t.id} label={t.name} />) : null))
          : null}
      </>
    );
  };

  const setIntegrationVendorsOption = () => {
    return (
      <>
        <option value="" disabled selected label="Choose a integration" />
        {integrationVendorsData ? Object.values(integrationVendorsData).map((iv: any) => <option key={iv.id} value={iv.id} label={iv.name} />) : null}
      </>
    );
  };

  //TODO fix integration id used as vendor id 
  const setTemplatesOption = () => {
    return (
      <>
        <option value="" disabled selected label="Choose a template" />
        {templateData
          ? Object.values(templateData)
              .filter((item: any) => item.name === actionGroupName)
              .map((item: any) =>
                item.vendors
                  ? Object.values(item.vendors)
                      .filter((item: any) => (item.integration ? item.integration.apiId == selectedVendorId : item.id == selectedVendorId))
                      .map((item: any) => (item.templates ? Object.values(item.templates).map((item: any) => <option key={item.id} value={item.id} label={item.name} />) : null))
                  : null
              )
          : null}
      </>
    );
  };

  const addActionSubmitHandler = () => {
    var bodyObj: any = {
      templateId: parseInt(templateName),
    };
    if (isThereIntegration) {
      bodyObj["integration"] = selectedIntegration;
    }

    getRawActionTemplateById(templateName).then((response) => {
      const data = response.data;
      bodyObj["actionTemplate"] = data;
      saveCallback(bodyObj);
    }).catch(error => {
      alert(JSON.stringify(error));
      //saveCallback(bodyObj);
      //apiErrorHandle(error, "segmentDetailApi"); //TODO fix
    });

  };

  const handleActionGroupChange = (e: any) => {
    setactionGroupName(e.target.value);
  };

  const handleVendorChange = (e: any) => {
    setselectedVendorId(e.target.value);
  };

  const handleIntegrationChange = (e: any) => {
    setselectedIntegrationVendorId(e.target.value);
    const _integration = integrationVendors.data.filter(i => i.id == e.target.value)[0]!;
    setSelectedIntegration(_integration);
  };

  const handleTemplateChange = (e: any) => {
    settemplateName(e.target.value);
  };

  const formValidationControl = (): boolean => {
    if (isThereIntegration) {
      if (actionGroupName && setselectedVendorId && templateName && setselectedIntegrationVendorId) {
        return true;
      } else {
        return false;
      }
    } else {
      if (actionGroupName && setselectedVendorId && templateName) {
        return true;
      } else {
        return false;
      }
    }
  };

  const formReset = () => {
    setactionGroupName("");
    setselectedVendorId("");
    settemplateName("");
    setselectedIntegrationVendorId("");
    setnewActionErrorData("");
  };

  return (
    <form id="campaignActionAdd">
      <div>
        <div className="mt-3"></div>
        <label className="form-label" htmlFor="actionGroup">
          Action Group
        </label>
        <select
          className="form-control"
          name="actionGroup"
          value={actionGroupName}
          onChange={(e) => {
            handleActionGroupChange(e);
          }}
        >
          {setActionGroupOption()}
        </select>
      </div>

      {actionGroupName !== "" ? (
        <div>
          <div className="mt-3"></div>
          <label className="form-label" htmlFor="vendor">
            Vendor
          </label>
          <select
            className="form-control"
            name="vendor"
            value={selectedVendorId}
            onChange={(e) => {
              handleVendorChange(e);
            }}
          >
            {setVendorOption()}
          </select>
        </div>
      ) : null}

      {actionGroupName !== "" && selectedVendorId ? (
        <div>
          {isThereIntegration ? (
            <div>
              <div className="mt-3"></div>
              <label className="form-label" htmlFor="integration">
                Integration for ({integrationForValue})
              </label>
              <select
                className="form-control"
                name="integration"
                value={selectedIntegrationVendorId}
                onChange={(e) => {
                  handleIntegrationChange(e);
                }}
              >
                {setIntegrationVendorsOption()}
              </select>
            </div>
          ) : null}

          <div>
            <div className="mt-3"></div>

            <label className="form-label" htmlFor="template">
              Template
            </label>
            <select
              className="form-control"
              name="template"
              onChange={(e) => {
                handleTemplateChange(e);
              }}
            >
              {setTemplatesOption()}
            </select>
          </div>
        </div>
      ) : null}

      <div className="modal-footer">
        <button
          id="addNewEventCloseButton"
          className="btn btn-light mt-3"
          type="button"
          data-dismiss="modal"
          onClick={() => {
            formReset();
          }}
        >
          Close
        </button>
        <button
          id="addNewEventCreateButton"
          onClick={(e) => {
            e.preventDefault();
            addActionSubmitHandler();
          }}
          className="btn btn-success mt-3"
          disabled={!formValidationControl()}
        >
          Choose
        </button>
      </div>
    </form>
  );
};

export default AddCampaignActionForm;
