import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { RootStore } from "../../../redux/store/Store";
import { getSingleScenarioAction, sendSelectedScenarioDataAction } from "../../../redux/actions/scenarioActions";
import { faCircle, faClock, faCloudUploadAlt, faCogs, faQuestion, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { linkViewUrl } from "../../../constants/Urls";
import Operations from "../commonComps/Operations";
import EventTab from "./components/tablayout/EventTab";
import SessionTab from "./components/tablayout/SessionTab";
import EditScenarioForm from "./components/forms/EditScenarioForm";
import GenaralModal from "./components/modals/GenaralModal";
import DeleteScenarioForm from "./components/forms/DeleteScenarioForm";
import AddNewSessionDefForm from "./components/forms/AddNewSessionDefForm";
import ActionTab from "./components/tablayout/ActionTab";
import ValidationTab from "./components/tablayout/validationTab";
import AddNewEventForm from "./components/forms/AddNewEventForm";
import AddNewActionForm from "./components/forms/AddNewActionForm";
import AddNewValidationForm from "./components/forms/AddNewValidationForm";
import RichTextEditor from "../commonComps/RichTextEditorAction";
import axios from "axios";
import { getScenarioStatusReducer } from "../../../redux/reducers/scenarioReducers";


const ScenarioDetailPage = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  const singleScenario: any = useSelector((state: RootStore) => state.singleScenarioReducer);
  const editScenario: any = useSelector((state: RootStore) => state.postScenarioReducer);
  const deleteScenario: any = useSelector((state: RootStore) => state.deleteScenarioReducer);

  var scenarioStatusReducer: any = useSelector((state: RootStore) => state.getScenarioStatusReducer);

  const [selectedScenario, setSelectedScenario] = useState<any>(null);
  const [scenarioStatus, setScenarioStatus] = useState<any>(null);

  useEffect(() => {
    dispatch(getSingleScenarioAction(id));
  }, [id]);

  useEffect(() => {
    document.title = "Complex Scenarios | Herogi Growth Panel"
  }, []);

  useEffect(() => {

    if(scenarioStatusReducer) {
      console.log("Scenario status reducer");
      console.log(scenarioStatusReducer);
      if(scenarioStatusReducer.data != null) {
        setScenarioStatus(scenarioStatusReducer.data.data[0].status);
      }
    }

  }, [scenarioStatusReducer]);

  useEffect(() => {
    if (singleScenario.data) {
      setSelectedScenario(singleScenario.data);
      setScenarioStatus(singleScenario.data.status.status);
    }
  }, [singleScenario.data]);

  useEffect(() => {
    if (editScenario?.data?.response?.statusText === "OK") {
      setSelectedScenario({ scenario: editScenario.data.bodyObj });
      editScenario.data.response.statusText = "";
    }
  }, [editScenario.data]);

  useEffect(() => {
    if (selectedScenario) {
      if (deleteScenario.data?.response?.statusText === "OK") {
        $("#DeleteScenarioModal").modal("hide");
        deleteScenario.data.response.statusText = "";
      }
    }
  }, [deleteScenario.data]);

  useEffect(() => {
    const interval = setInterval(() => {
    
        axios
        .get(`/scenario/status/${selectedScenario.scenario.id}`)
        .then((response) => {
          setScenarioStatus(response.data.data[0].status);
        })
  
    }, 5000);
    return () => clearInterval(interval);
  }, [selectedScenario]);

  const renderActionButtons = () => {
    return (
      <div className="card shadow-sm mb-1">
        <div className="card-body p-3">
          <div className="row">
            <div className="col-auto mr-auto">
              <span>
                <Link style={{ textDecoration: "none" }} to={linkViewUrl + id + "/design"}>
                  <button className="btn btn-outline-primary pt-2 pb-2 pl-2 pr-2" type="button">
                    {"Editor"}
                    <FontAwesomeIcon icon={faCogs} style={{ marginLeft: 2 }} />
                  </button>
                </Link>

                <button
                  className="btn btn-outline-white dropdown-toggle pt-2 pb-2 pl-2 pr-2 ml-1"
                  type="button"
                  id="ScenarioManageDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Manage
                </button>
                <div className="dropdown-menu" aria-labelledby="ScenarioManageDropdown">
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => {
                      $("#EditScenarioModal").modal("show");
                      dispatch(sendSelectedScenarioDataAction(selectedScenario));
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => {
                      $("#DeleteScenarioModal").modal("show");
                    }}
                  >
                    Delete
                  </button>
                  <div className="dropdown-divider"></div>
                  <h3 className="dropdown-header font-weight-light">Add Scenario Items</h3>
                  <button className="dropdown-item" data-target="#AddNewEventModal" data-toggle="modal" type="button">
                    Event
                  </button>
                  <button className="dropdown-item" data-target="#AddNewActionModal" data-toggle="modal" type="button">
                    Action
                  </button>
                  <button className="dropdown-item" data-target="#AddNewValidationModal" data-toggle="modal" type="button">
                    Validation
                  </button>
                  <button className="dropdown-item" data-target="#AddNewSessionDefinitionModal" type="button" data-toggle="modal">
                    Session Definition
                  </button>
                </div>
                <span>
                  <Operations  />
                </span>
              </span>
            </div>
            <div className="col-auto"></div>
          </div>
        </div>
      </div>
    );
  };


  const renderPreviewLayout = () => {
    return (
      <div className="card shadow-sm mb-1">
        <div className="card-body p-3">
          <h5 className="card-title">
            {selectedScenario ? (
              <div>
                <div className="row">
                  <div className="col-auto mr-auto">
                    <h4>{selectedScenario.scenario.name}</h4>
                  </div>
                  <div className="col-auto">
                    {scenarioStatus == "DEPLOYED" ? (
                      <div
                        style={{
                          color: "#27AE60",
                        }}
                      >
                        {scenarioStatus}{" "}
                      </div>
                    ) : (
                      <div style={{ color: "#C0392B" }}>{scenarioStatus}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto mr-auto">
                    <label>Last Update:</label>
                    {new Date(selectedScenario.scenario.updateDate).toLocaleString()}
                  </div>
                  <div className="col-auto">
                    {selectedScenario.lastOperationTime == null ? (
                      "N/A"
                    ) : (
                      <div>
                        <label>Deployment Date:</label>
                        <FontAwesomeIcon icon={faClock} />
                        {new Date(selectedScenario.lastOperationTime).toLocaleString()}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col md-6">
                    <label style={{ color: "#ea7e22" }}>Description</label>
                    <div>{selectedScenario.scenario.description}</div>
                  </div>
                  <div className="col md-6"></div>
                </div>
              </div>
            ) : null}
          </h5>
        </div>
      </div>
    );
  };

  const renderTabLayoutDetail = () => {
    return (
      <>
        {id ? (
          <div className="card ">
            <div className="card-header border-bottom">
              <ul className="nav nav-tabs card-header-tabs" id="cardTab" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" id="event-tab" href="#event" data-toggle="tab" role="tab" aria-controls="event" aria-selected="true">
                    Event
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" id="action-tab" href="#action" data-toggle="tab" role="tab" aria-controls="action" aria-selected="false">
                    Actions
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" id="validation-tab" href="#validation" data-toggle="tab" role="tab" aria-controls="action" aria-selected="false">
                    Validations
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="session-tab" href="#session" data-toggle="tab" role="tab" aria-controls="session" aria-selected="false">
                    Session Definition
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="cardTabContent">
                <div className="tab-pane fade active show" id="event" role="tabpanel" aria-labelledby="event-tab">
                  <EventTab />
                </div>

                <div className="tab-pane fade " id="action" role="tabpanel" aria-labelledby="action-tab">
                  <ActionTab />
                </div>

                <div className="tab-pane fade" id="validation" role="tabpanel" aria-labelledby="validation-tab">
                  <ValidationTab />
                </div>

                <div className="tab-pane fade" id="session" role="tabpanel" aria-labelledby="session-tab">
                  <SessionTab />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const renderModals = () => {
    return (
      <>
        <GenaralModal modalId="EditScenarioModal" modalName="Edit Scenario" formComp={EditScenarioForm} />
        <GenaralModal modalId="DeleteScenarioModal" modalName="Delete Scenario" size="lg" formComp={DeleteScenarioForm} />
        <GenaralModal modalId="AddNewSessionDefinitionModal" modalName="New Session Definition" formComp={AddNewSessionDefForm} />
        <GenaralModal modalId="AddNewEventModal" modalName="New Event" formComp={AddNewEventForm} />
        <GenaralModal modalId="AddNewActionModal" modalName="New Action" formComp={AddNewActionForm} />
        <GenaralModal modalId="AddNewValidationModal" modalName="New Validation" formComp={AddNewValidationForm} />
        <GenaralModal modalId="AddNewSessionDefinitionModal" modalName="New Session Definition" formComp={AddNewSessionDefForm} />
      </>
    );
  };

  return (
    <>
      <div className="dashboard-main">
        {/* //MODALS HAD TO BE RENDER BEFORE ACTION BUTTONS */}
        {/* //MODALS HAD TO BE RENDER BEFORE ACTION BUTTONS */}
        {renderModals()}
        {renderActionButtons()}
        {renderPreviewLayout()}
        {renderTabLayoutDetail()}


      </div>
    </>
  );
};

export default ScenarioDetailPage;
function useCallback(arg0: () => Promise<void>, arg1: never[]) {
  throw new Error("Function not implemented.");
}

