import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "../../../../../redux/store/Store";
import { postEditEventSessionIdentiferAction } from "../../../../../redux/actions/EventActions";
import { radioOptions } from "./utils/formUtils";
import * as Yup from "yup";
import $ from 'jquery';



const EditSessionIdentifierManageForm = () => {
  const dispatch = useDispatch();

  const eventId: any = useSelector((state: RootStore) => state.sendEventIdReducer);
  const scenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  const eventParams: any = useSelector((state: RootStore) => state.sendEventParamsReducer);

  const [availableEventParams, setavailableEventParams] = useState<any>(null);

  useEffect(() => {
    setEventFormData();
  }, [eventParams.data]);

  useEffect(() => {
    $("#SessionEventManageModalCloseIconButton").click(function () {
      $("#selectSessionIdentiferCloseButton")[0].click();
    });
  }, []);

  const setEventFormData = () => {
    if (eventParams.data) {
      setavailableEventParams(eventParams.data);
    }
  };

  const setAvailableEventParamOptions = (selectedItem: any) => {
    return (
      <>
        <option value="" disabled label="Select an event parameter" />
        {selectedItem ? <option value={selectedItem} label={selectedItem} /> : null}
        {availableEventParams
          ? Object.values(availableEventParams)
              .filter((item: any) => item.propertyName !== selectedItem)
              .map((item: any, index) => <option key={index} value={item.propertyName} label={item.propertyName} />)
          : null}
      </>
    );
  };

  const handleEventSessionIdentifierSumbit = (sessionDefName: string | null, radioOption: string, formObj: any) => {
    if (radioOption.toUpperCase() === "Use default session identifier".toUpperCase()) {
      sessionDefName = null;
    }
    dispatch(postEditEventSessionIdentiferAction({ sessionDef: sessionDefName }, scenarioId.data, eventId.data, formObj));
  };

  const validationSchema = Yup.object().shape({});

  const formik = useFormik({
    initialValues: {
      radioOption: "Use default session identifier",
      eventParameter: "",
    },
    onSubmit: () => {},
    validationSchema: validationSchema,
    enableReinitialize: true,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        {radioOptions.map((item) => (
          <>
            <div>
              <input type="radio" id="radioOption" name="radioOption" checked={formik.values.radioOption === item.value} onChange={() => formik.setFieldValue("radioOption", item.value)} />{" "}
              <label htmlFor={item.value}>{item.value}</label>
            </div>
          </>
        ))}
      </div>

      {console.log(formik.values.radioOption, "formik.values.radioOption")}

      {formik.values.radioOption == "Override identifier" ? (
        <div>
          <div className="mt-3">
            <label className="form-label" htmlFor="Available Event Parameters">
              Available Event Parameters
            </label>
          </div>
          <select className="form-control" id="eventParameter" name="eventParameter" value={formik.values.eventParameter} onChange={formik.handleChange}>
            {setAvailableEventParamOptions(formik.values.eventParameter)}
          </select>
        </div>
      ) : null}

      <div>
        <div className="modal-footer mt-3">
          <button
            id="selectSessionIdentiferCloseButton"
            className="btn btn-light mt-3"
            type="button"
            data-dismiss="modal"
            onClick={() => {
              formik.resetForm();
            }}
          >
            Close
          </button>
          <button
            id="selectSessionIdentiferCreateButton"
            onClick={() => {
              handleEventSessionIdentifierSumbit(formik.values.eventParameter, formik.values.radioOption, formik);
            }}
            className="btn btn-success mt-3"
            type="submit"
            disabled={!formik.isValid}
          >
            Create
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditSessionIdentifierManageForm;
