import * as ScenarioActionTypes from "../types/types";
import * as InitialStates from "../store/InitialStates";

export const getScenarioListReducer = (state = InitialStates.scenarioListInitialState, action: any) => {
  switch (action.type) {
    case ScenarioActionTypes.GET_SCENARIO_LIST_FAIL:
      return {
        data: action.payload,
      };
    case ScenarioActionTypes.GET_SCENARIO_LIST_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const getSingleScenarioReducer = (state:any = InitialStates.scenarioListInitialState, action: any) => {
  switch (action.type) {
    case ScenarioActionTypes.GET_SINGLE_SCENARIO_LIST_FAIL:
      return {
        data: action.payload,
      };
    case ScenarioActionTypes.GET_SINGLE_SCENARIO_LIST_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const putNewScenarioReducer = (state = InitialStates.addScenarioInitialState, action: any) => {
  switch (action.type) {
    case ScenarioActionTypes.ADD_NEW_SCENARIO_FAIL:
      return {
        data: action.payload,
      };
    case ScenarioActionTypes.ADD_NEW_SCENARIO_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const postScenarioReducer = (state = InitialStates.editScenarioInitialState, action: any) => {
  switch (action.type) {
    case ScenarioActionTypes.EDIT_SCENARIO_FAIL:
      return {
        data: action.payload,
      };
    case ScenarioActionTypes.EDIT_SCENARIO_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const deleteScenarioReducer = (state = InitialStates.deleteScenarioInitialState, action: any) => {
  switch (action.type) {
    case ScenarioActionTypes.DELETE_SCENARIO_FAIL:
      return {
        data: action.payload,
      };
    case ScenarioActionTypes.DELETE_SCENARIO_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const deployScenarioReducer = (state = {}, action: any) => {
  switch (action.type) {
    case ScenarioActionTypes.DEPLOY_SCENARIO_FAIL:
      return {
        data: action.payload,
      };
    case ScenarioActionTypes.DEPLOY_SCENARIO_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const reDeployScenarioReducer = (state = {}, action: any) => {
  switch (action.type) {
    case ScenarioActionTypes.RE_DEPLOY_SCENARIO_FAIL:
      return {
        data: action.payload,
      };
    case ScenarioActionTypes.RE_DEPLOY_SCENARIO_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const unDeployScenarioReducer = (state = {}, action: any) => {
  switch (action.type) {
    case ScenarioActionTypes.UN_DEPLOY_SCENARIO_FAIL:
      return {
        data: action.payload,
      };
    case ScenarioActionTypes.UN_DEPLOY_SCENARIO_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const freezeScenarioReducer = (state = {}, action: any) => {
  switch (action.type) {
    case ScenarioActionTypes.FREEZE_SCENARIO_FAIL:
      return {
        data: action.payload,
      };
    case ScenarioActionTypes.FREEZE_SCENARIO_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const resumeScenarioReducer = (state = {}, action: any) => {
  switch (action.type) {
    case ScenarioActionTypes.RESUME_SCENARIO_FAIL:
      return {
        data: action.payload,
      };
    case ScenarioActionTypes.RESUME_SCENARIO_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const getScenarioStatusReducer = (state = {}, action: any) => {
  switch (action.type) {
    case ScenarioActionTypes.GET_SCENARIO_STATUS_FAIL:
      return {
        data: action.payload,
      };
    case ScenarioActionTypes.GET_SCENARIO_STATUS_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendScenarioIdReducer = (state = {}, action: any) => {
  switch (action.type) {
    case ScenarioActionTypes.SEND_SCENARIO_ID_FAIL:
      return {
        data: action.payload,
      };
    case ScenarioActionTypes.SEND_SCENARIO_ID_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendScenarioNameListReducer = (state = [], action: any) => {
  switch (action.type) {
    case ScenarioActionTypes.SEND_SCENARIO_NAME_LIST_FAIL:
      return {
        data: action.payload,
      };
    case ScenarioActionTypes.SEND_SCENARIO_NAME_LIST_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendScenarioDataReducer = (state = "", action: any) => {
  switch (action.type) {
    case ScenarioActionTypes.SEND_SCENARIO_FAIL:
      return {
        data: action.payload,
      };
    case ScenarioActionTypes.SEND_SCENARIO_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};
