
import React from 'react';

interface SimpleNumberBoxProps {
    title: string;
    value: number | null;
    icon: string;
}

const SimpleNumberBox = ({ title, value, icon }: SimpleNumberBoxProps) => {
    return (
        <div className="card h-100 border-left-lg border-left-primary">
            <div className="card-body">
                <h5 className="font-weight-bold">{title}</h5>
                <div className="text-xl">
                    {value ? value : "N/A"}
                    <span className="ml-2 text-gray-600">{icon}</span>
                </div>
            </div>
        </div>
    );
}

export default SimpleNumberBox;