import { faCircleInfo, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { off } from "process";
import { useEffect, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ErrorHandler } from "../../../components/common/hooksAndFuncts/ApiErrorHandle";
import { getEventsListView } from "../../../services/ReportingService";
import { EventFilterTable } from "./EventFilterTable";
import { EventLog } from "./EventLog";
import { LogModal, ResultModal } from "./Modals";

export const EventData = () => {

    const now = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(now.getDate() + 1);

    const allScenarios = "All Scenarios";
    const allEvents = "All Events";
    
    const [pageSize, setPageSize] = useState(50);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");


    const [scenarioHit, setScenarioHit] = useState(true);
    const [eventHit, setEventHit] = useState(true);
    const [scenarioList, setScenarioList] = useState<Array<string>>([]);
    const [eventList, setEventList] = useState<Array<string>>([]);
    const [selectedScenario, setSelectedScenario] = useState(allScenarios);
    const [selectedEvent, setSelectedEvent] = useState(allEvents);
    const [eventStreams, setEventStreams] = useState<Array<EventLog>>([]);
    const [selectedEventStream, setSelectedEventStream] = useState<EventLog | null>(null);
    const [sizeOfEventStream, setSizeOfEventStream] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);


    const handleDateChange = (type, event) => {
        if (type == "start")
            setStartDate(event.target.value);
        else if (type == "end")
            setEndDate(event.target.value);
    };

    useEffect(() => {
        loadDropdowns();
    
        setStartDate(now.toISOString().substr(0, 10));
        setEndDate(tomorrow.toISOString().substr(0, 10));

    }, []);

    const loadDropdowns = () => {

        axios.get("/scenario")
            .then((response) => {

                var data = response.data;

                let _scenarioList = new Set<string>();
                let _allEvents = new Set<string>();

                _scenarioList.add(allScenarios);
                _allEvents.add(allEvents);

                data.map(i => {
                    _scenarioList.add(i.scenario.name);
                    const eventNames = new Set(Object.keys(i.scenario.eventMap));
                    eventNames.forEach((value) => _allEvents.add(value));
                });

                setScenarioList(Array.from(_scenarioList));
                setEventList(Array.from(_allEvents));
            })
            .catch(err => ErrorHandler(err.response));
    }

    const submitQuery = (currentPage) => {

        let finalScenario = selectedScenario == allScenarios ? null : selectedScenario;
        let finalEvent = selectedEvent == allEvents ? null : selectedEvent;

        setLoaded(true);
        setLoading(true);

        getEventsListView(finalScenario, finalEvent, scenarioHit, eventHit, startDate, endDate, currentPage, pageSize)
            .then((res) => {
                let logs = res.data?.hits?.map((item) => new EventLog(item));
                setEventStreams(logs);
                setSizeOfEventStream(res.data?.total);
                setLoading(false);
            })
            .catch((err) => {
                ErrorHandler(err.response)
                setLoading(false);
            });
    }

    const renderFilters = () => {
        return (
            <>
                <LogModal eventLog={selectedEventStream} />
                <ResultModal eventLog={selectedEventStream} />
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">

                            <div className="col-sm-2">
                                <label className="event-list-label">Select Scenario</label>
                            </div>
                            <div className="col-sm-3">
                                <select className="form-control" id="scenarioList" onChange={(e) => setSelectedScenario(e.target.value)} >
                                    {scenarioList.map((item, index) => {
                                        return <option key={index} label={item} value={item} selected={item == selectedScenario ? true : false}>{item}</option>
                                    })
                                    }
                                </select>
                            </div>

                            <div className="col-sm-2">
                                <div className="form-check mt-2">
                                    <input className="form-check-input" type="checkbox" checked={scenarioHit} onChange={(e) => {
                                        setScenarioHit(e.target.checked);
                                    }} />
                                    <label className="form-check-label">Scenario Hit</label>
                                </div>
                            </div>

                            <div className="col-sm-2">
                                <label className="event-list-date">Start Date</label>
                            </div>

                            <div className="col-sm-3">
                                <input type="date" className="form-control"
                                    value={startDate}
                                    onChange={event => handleDateChange("start", event)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className="row">

                            <div className="col-sm-2">
                                <label className="event-list-label">Select Events</label>
                            </div>
                            <div className="col-sm-3">
                                <select className="form-control" id="eventList" onChange={e => setSelectedEvent(e.target.value)} >
                                    {eventList.map((item, index) => {
                                        return <option key={index} label={item} value={item} selected={item == selectedEvent ? true : false}>{item}</option>
                                    })
                                    }
                                </select>
                            </div>

                            <div className="col-sm-2">
                                <div className="form-check mt-2">
                                    <input className="form-check-input" type="checkbox" checked={eventHit} onChange={(e) => {
                                        setEventHit(e.target.checked);
                                    }} />
                                    <label className="form-check-label">Event Hit</label>
                                </div>
                            </div>

                            <div className="col-sm-2">
                                <label className="event-list-date">End Date</label>
                            </div>

                            <div className="col-sm-3">
                                <input type="date" className="form-control"
                                    value={endDate}
                                    onChange={event => handleDateChange("end", event)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 ">
                        {loading ? 
                            <button className="btn btn-primary float-right mt-3" type="button" disabled>
                            <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            Loading...
                          </button>
                          : 
                          <button className="btn btn-primary float-right mt-3" onClick={e => submitQuery(1)}>Apply</button>   
                        }
                        
                    </div>
                </div>
            </>
        )
    }

    const eventStreamColumns = [
        {
          dataField: "sessionId",
          text: "Session Id",
        },
        {
          dataField: "scenarioName",
          text: "Scenario Name",
        },
        {
          dataField: "eventName",
          text: "Event Name",
        },
        {
          dataField: "entryTime",
          text: "Entry Time",
        },
    
        {
          dataField: "result",
          text: "Execution Details",
        },
        {
          dataField: "logs",
          text: "Logs",
        },
      ];

      const prepareData = () => {
      
        return eventStreams.map((item, index) => {
            let logs = item.getLogs()
            let logHtml = (
                <a style={{ textDecoration: "none", fontSize: "1.2rem" }} 
                        data-toggle="modal"
                        data-target="#EventLogModal" 
                        onClick={(e) => {
                           
                            setSelectedEventStream(item);
                            
                        }}
                    >
                        { logs[0] != 0 ?  <span className="badge badge-info rounded-circle">{logs[0]}</span> : null }
                        { logs[1] != 0 ?  <span className="badge badge-warning rounded-circle">{logs[1]}</span> : null }
                        { logs[2] != 0 ?  <span className="badge badge-danger rounded-circle">{logs[2]}</span> : null }
                    </a>
            )
            return {
                sessionId: item.session_id,
                scenarioName: item.scenario_id ?
                    <a href={"/scenario/" + item.scenario_id.toString()} target="_blank">{item.scenario_name}</a>
                    : "N/A"
                ,
                eventName: item.event_name,
                entryTime: item.entry_time,
                result: 
                    <a  href="#" data-toggle="modal"
                        data-target="#EventResultModal" 
                        onClick={(e) => {
                            setSelectedEventStream(item);
                    
                        }}>
                        <span>show</span>   
                    </a>,
                logs: logHtml
            }
        })
    }


    const renderEventStreamTable = () => {
        return (
          <div className="mt-4">
            <div className="card shadow-sm mb-1">
              <div className="card-body">
                <div>
                    <EventFilterTable 
                        pageSize={pageSize}
                        eventStream = {prepareData()}
                        eventStreamColumns={eventStreamColumns}
                        sizeOfEventStream = {sizeOfEventStream}
                        submitQuery = {submitQuery}
                     />
                </div>
              </div>
            </div>
          </div>
        );
    }

    return (
        <div className="dashboard-main">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <h2 className="card-title">Event Streams</h2>
                                <p className="card-text"><b>Select criterias to list events..</b></p>
                                <div className="mt-5">
                                    {renderFilters()}
                                    {loaded ? renderEventStreamTable() 
                                        : 
                                            <div className="text-center">
                                                Choose filters and click on Apply to list events..
                                            </div>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
