import React, { useState, useEffect, useRef } from "react";
import axios from "axios";


export interface IIntegrationData {
  appId: string;
  appName: string;
  appSecret: string;
  status: string;
  apiProviders: ApiProviders[];
  integrationList: IntegrationList[];
}

export type ApiProviders = {
  apiId: string;
  productName: string;
  description: string;
  iconUrl: string;
  param: ParamApiProviders[];
};

export type ParamApiProviders = {
  encrypted: Boolean;
  key: string;
  nullable: Boolean;
  readableKey: string;
};

export type IntegrationList = {
  apiId: string;
  appid: string;
  description: string;
  id: string;
  name: string;
  productName: string;
  iconUrl: string;
  param: ParamIntegrationList[];
};

export type ParamIntegrationList = {
  encrypted: Boolean;
  key: string;
  nullable: Boolean;
  readableKey: string;
  value: string;
};

export interface IintegrationModalData {
  integrationName: string;
  firstInput: string;
  secondInput: string;
  firstInputPostKey: string;
  secondInputPostKey: string;
  apiId: string;
}

export interface IintegrationEditModalData {
  integrationName: string;
  firstInput: string;
  secondInput: string;
  firstInputPostKey: string;
  secondInputPostKey: string;
  apiId: string;
  name: string;
  firstInputValue: string;
  secondInPutValue: string;
  id: string;
}

export interface IDeleteData {
  code: string;
  message: string;
}

export interface IPutData {
  code: string;
  message: string;
}

const Integrations = (IntegrationPageStyle:any) => {
  const [integrationData, setintegrationData] = useState<IIntegrationData | undefined>(undefined);

  const [integrationModalData, setintegrationModalData] = useState<IintegrationModalData | undefined>(undefined);

  const [integrationEditModalData, setintegrationEditModalData] = useState<IintegrationEditModalData | undefined>(undefined);

  const [deleteData, setdeleteData] = useState<IDeleteData | undefined>(undefined);

  const [putIntegrationData, setputIntegrationData] = useState<IPutData | undefined>(undefined);

  const [intnameAlert, setintnameAlert] = useState("");
  const [intappıdAlert, setintappıdAlert] = useState("");
  const [intappsecretAlert, setintappsecretAlert] = useState("");
  const [statusCreate, setstatusCreate] = useState(true);
  const [hidetoggle, sethidetoggle] = useState(true);
  const [hideToggleText, sethideToggleText] = useState("show");
  const [typeStatus, settypeStatus] = useState("password");

  const intnameRef = useRef<HTMLInputElement>(null);
  const intappRef = useRef<HTMLInputElement>(null);
  const intappSecretRef = useRef<HTMLInputElement>(null);

  const intnameEditRef = useRef<HTMLInputElement>(null);
  const intappEditRef = useRef<HTMLInputElement>(null);
  const intappEditSecretRef = useRef<HTMLInputElement>(null);

  const EditModalUpdateRef = useRef<HTMLButtonElement>(null);
  const ModalCreateRef = useRef<HTMLButtonElement>(null);

  const CreateModalCloseButtonRef = useRef<HTMLButtonElement>(null);
  const EditModalCloseButtonRef = useRef<HTMLButtonElement>(null);

  const getIntegrationUrl = "/integration";
  const postIntegrationUrl = "/integration";
  const deleteIntegrationUrl = "/integration/";
  const putIntegrationUrl = "/integration/";

  useEffect(() => {}, [integrationData, deleteData]);

  useEffect(() => {
    getIntegrations(getIntegrationUrl);
  }, []);

  const getIntegrations = (url: string) => {
    axios
      .get(url, {})
      .then((response) => {
        setintegrationData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error);
        }
      });
  };

  const deleteIntegration = (url: string, integrationId: string) => {
    axios
      .delete(url + `${integrationId}`, {})
      .then((response) => {
        setdeleteData(response.data);
        getIntegrations(getIntegrationUrl);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error);
        }
      });
  };

  const postIntegration = (url: string) => {
    var firstInputPostTemp = "";
    var secondInputPostTemp = "";
    var apiIdTemp = "";

    if (integrationModalData) {
      firstInputPostTemp = integrationModalData.firstInputPostKey;
    } else {
      console.log("integrationModalData is null");
    }

    if (integrationModalData) {
      secondInputPostTemp = integrationModalData.secondInputPostKey;
    } else {
      console.log("integrationModalData is null");
    }

    if (integrationModalData) {
      apiIdTemp = integrationModalData.apiId;
    } else {
      console.log("integrationModalData is null");
    }

    var param: any = {};

    param[firstInputPostTemp] = intappRef.current!.value.trim();

    if(integrationModalData?.integrationName !== "Sendgrid"){
    if (secondInputPostTemp !== "") {
      param[secondInputPostTemp] = intappSecretRef.current!.value;
    }
  }

    axios
      .put(url, {
        apiId: apiIdTemp,
        name: intnameRef.current!.value.trim(),
        param,
      })
      .then((response) => {
        getIntegrations(getIntegrationUrl);
        CreateModalCloseButtonRef.current?.click();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error);
        }
      });
  };

  const setEmptyModalInputs = () => {
    (document.getElementById("providerFormControlInput1") as HTMLInputElement).value = "";
    (document.getElementById("providerFormControlInput2") as HTMLInputElement).value = "";

    if (document.getElementById("providerFormControlInput3")) {
      (document.getElementById("providerFormControlInput3") as HTMLInputElement)!.value = "";
    }
    setintnameAlert("");
    setintappıdAlert("");
    setintappsecretAlert("");
  };

  const renderApiProvidersList = () => {
    return (
      <>
        {integrationData
          ? integrationData!.apiProviders.map((item) => (
              <div
                className="col col lg-2 text-center"
                data-toggle="modal"
                data-target="#providerModal"
                onClick={() => {
                  setintegrationModalData({
                    integrationName: item.productName,
                    firstInput: item.param[0].readableKey,
                    secondInput: item.param[1] ? item.param[1].readableKey : "",
                    firstInputPostKey: item.param[0].key,
                    secondInputPostKey: item.param[1] ? item.param[1].key : "",
                    apiId: item.apiId,
                  });

                  setEmptyModalInputs();
                }}
              >
                <h1 className="text-center">{item.productName}</h1>
                <img id="imgProvider" className="border rounded p-2 shadow-sm" src={process.env.PUBLIC_URL + item.iconUrl} alt="provider-img" />
              </div>
            ))
          : null}
      </>
    );
  };

  const renderIntegrationList = () => {
    return (
      <>
        {integrationData
          ? integrationData.integrationList.map((item) => (
              <tbody>
                <tr>
                  <th scope="row">{item.name}</th>
                  <td>{item.productName}</td>
                  <td>
                    <button
                      className="btn btn-primary pt-1 pb-1 pl-1 pr-1"
                      type="button"
                      data-toggle="modal"
                      data-target="#editIntegrationModal"
                      onClick={() => {
                        setintegrationEditModalData({
                          integrationName: item.productName,
                          firstInput: Object.values(item.param)[0].readableKey,
                          secondInput: Object.values(item.param)[1]?.readableKey,
                          firstInputPostKey: Object.values(item.param)[0].key,
                          secondInputPostKey: Object.values(item.param)[1]?.key,
                          apiId: item.apiId,
                          name: item.name,
                          firstInputValue: Object.values(item.param)[0].value,
                          secondInPutValue: Object.values(item.param)[1]?.value,
                          id: item.id,
                        });
                        intnameEditRef.current!.value = item.name;
                        intappEditRef.current!.value = Object.values(item.param)[0].value;

                        if (intnameEditRef.current!.value) {
                          setintnameAlert("");
                        }
                        if (intappEditRef.current!.value) {
                          setintappıdAlert("");
                        }
                      }}
                    >
                      Edit
                    </button>{" "}
                    /{" "}
                    <button
                      className="btn btn-danger pt-1 pb-1 pl-1 pr-1"
                      type="button"
                      onClick={() => {
                        deleteIntegration(deleteIntegrationUrl, item.id);
                      }}
                    >
                      Delete
                    </button>{" "}
                  </td>
                </tr>
              </tbody>
            ))
          : null}
      </>
    );
  };

  const createIntSubmitHandler = () => {
    checkModalAlert();
  };

  const createIntEditSubmitHandler = () => {
    checkEditModalAlert();
  };

  const putIntegration = (url: string, integrationId: string) => {
    var firstInputPostTemp = "";
    var secondInputPostTemp = "";
    var apiIdTemp = "";

    if (integrationEditModalData) {
      firstInputPostTemp = integrationEditModalData.firstInputPostKey;
    } else {
      console.log("integrationModalData is null");
    }

    if (integrationEditModalData) {
      secondInputPostTemp = integrationEditModalData.secondInputPostKey;
    } else {
      console.log("integrationModalData is null");
    }

    if (integrationEditModalData) {
      apiIdTemp = integrationEditModalData.apiId;
    } else {
      console.log("integrationModalData is null");
    }

    var param: any = {};

    param[firstInputPostTemp] = intappEditRef.current!.value.trim();

    param[secondInputPostTemp] = intappEditSecretRef.current!.value;

    axios
      .post(url + integrationId, {
        apiId: apiIdTemp,
        name: intnameEditRef.current!.value.trim(),
        param,
      })
      .then((response) => {
        setputIntegrationData(response.data);
        getIntegrations(getIntegrationUrl);
        EditModalCloseButtonRef.current?.click();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error);
        }
      });
  };

  const checkModalAlert = () => {



    if (intnameRef.current!.value.trim() === "") {
      setintnameAlert("This field can not be empty");
    } else {
      setintnameAlert("");
    }
   
      if (intappEditRef.current!.value.trim() === "") {
        setintappıdAlert("This field can not be empty");
      } else {
        setintappıdAlert("");
      }
 
    if(integrationModalData?.integrationName !== "Sendgrid"){
    if (intappSecretRef?.current!.value.trim() === "") {
      setintappsecretAlert("This field can not be empty");
    } else {
      setintappsecretAlert("");
    }
  }

    if (intnameRef.current!.value.trim() !== "" && intappRef.current!.value.trim() !== "" ) {
      postIntegration(postIntegrationUrl);
    }
  };

  const checkEditModalAlert = () => {
    if (intnameEditRef.current!.value.trim() === "") {
      setintnameAlert("This field can not be empty");
    } else {
      setintnameAlert("");
    }
   
      if (intappEditRef.current!.value.trim() === "") {
        setintappıdAlert("This field can not be empty");
      } else {
        setintappıdAlert("");
      }
    

    if(integrationModalData?.integrationName !== "Sendgrid"){
    if (intappEditSecretRef.current!.value.trim() === "") {
      setintappsecretAlert("This field can not be empty");
    } else {
      setintappsecretAlert("");
    }
  }

    if (intnameEditRef.current!.value.trim() !== "" && intappEditRef.current!.value.trim() !== "" && intappEditSecretRef.current!.value.trim() !== "") {
      var integrationIdTemp = "";
      if (integrationEditModalData) {
        integrationIdTemp = integrationEditModalData.id;
      } else {
        console.log("integrationId is null");
      }
      putIntegration(putIntegrationUrl, integrationIdTemp);
    }
  };

  const hideAppSecretToggle = () => {
    sethidetoggle(!hidetoggle);
    !hidetoggle ? settypeStatus("password") : settypeStatus("text");
    hidetoggle ? sethideToggleText("hide") : sethideToggleText("show");
  };

  //create and update and delete api call need

  const createIntegrationModal = () => {
    return (
      <> 
        <div style={{boxShadow:"0 0 0 100000px rgba(0,0,0,.2)"}}  className="modal fade mt-5" id="providerModal" tabIndex={-1} role="dialog" aria-labelledby="providerModalLabel" aria-hidden="true">
          <div  className="modal-dialog" role="document">
            <div style={{boxShadow:"0 0 0 100000px rgba(0,0,0,.2)"}}  className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="providerModalLabel">
                  Create {integrationModalData?.integrationName} Integration
                </h5>
                <button className="close" type="button" onClick={()=>{
                  $("#providerModal").modal("hide");
                }} aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label htmlFor="providerFormControlInput1">Integration Name</label>
                    <input className="form-control" id="providerFormControlInput1" type="text" placeholder="" ref={intnameRef} />
                    {intnameAlert ? <label style={{ color: "#a94442", fontWeight: 400 }}>{intnameAlert}</label> : null}
                  </div>

                  <div className="form-group">
                    <label htmlFor="providerFormControlInput2"> {integrationModalData?.firstInput}</label>
                    <input className="form-control" id="providerFormControlInput2" type="text" placeholder="" ref={intappRef} />

                    {intappıdAlert ? <label style={{ color: "#a94442", fontWeight: 400 }}>{intappıdAlert}</label> : null}
                  </div>

                  {integrationModalData?.integrationName !== "Sendgrid" ? (
                    <div className="form-group">
                      <label htmlFor="providerFormControlInput3"> {integrationModalData?.secondInput}</label>

                      <div className="d-flex flex-row">
                        <input className="form-control d-flex flex-grow-1  " id="providerFormControlInput3" type={typeStatus} placeholder="" ref={intappSecretRef} />

                        <div
                          onClick={() => {
                            hideAppSecretToggle();
                          }}
                          className="btn btn-primary d-flex flex-grow-0 "
                        >
                          {hideToggleText}
                        </div>
                      </div>

                      {intappsecretAlert ? <label style={{ color: "#a94442", fontWeight: 400 }}>{intappsecretAlert}</label> : null}
                    </div>
                  ) : null}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-primary"
                  type="button"
                 
                  onClick={() => {
                    $("#providerModal").modal("hide");
                    setEmptyModalInputs();
                  }}
                  ref={CreateModalCloseButtonRef}
                >
                  Close
                </button>
                <button
                  onClick={() => {
                    createIntSubmitHandler();
                  }}
                  className="btn btn-success"
                  type="button"
                  ref={ModalCreateRef}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const createEditIntegrationModal = () => {
    return (
      <>
        <div style={{boxShadow:"0 0 0 100000px rgba(0,0,0,.2)"}} className="modal fade mt-5" id="editIntegrationModal" tabIndex={-1} role="dialog" aria-labelledby="editIntegrationModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div style={{boxShadow:"0 0 0 100000px rgba(0,0,0,.2)"}} className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="editIntegrationModalLabel">
                  Create {integrationEditModalData?.integrationName} Integration
                </h5>
                <button className="close" type="button"  onClick={()=>{
                  $("#editIntegrationModal").modal("hide");
                }} aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label htmlFor="editIntegrationModalFormControlInput1">Integration Name</label>
                    <input className="form-control" id="editIntegrationModalFormControlInput1" type="text" placeholder="" ref={intnameEditRef} />
                    {intnameAlert ? <label style={{ color: "#a94442", fontWeight: 400 }}>{intnameAlert}</label> : null}
                  </div>

                  <div className="form-group">
                    <label htmlFor="editIntegrationModalFormControlInput2"> {integrationEditModalData?.firstInput}</label>
                    <input className="form-control" id="editIntegrationModalFormControlInput2" type="text" placeholder="" ref={intappEditRef} />

                    {intappıdAlert ? <label style={{ color: "#a94442", fontWeight: 400 }}>{intappıdAlert}</label> : null}
                  </div>

                  {integrationEditModalData?.integrationName !== "Sendgrid" ? (
                    <div className="form-group">
                      <label htmlFor="editIntegrationModalFormControlInput3"> {integrationModalData?.secondInput}</label>
                      <div className="d-flex flex-row">
                        <input className="form-control d-flex flex-grow-1  " id="editIntegrationModalFormControlInput3" type={typeStatus} placeholder="" ref={intappEditSecretRef} />

                        <div
                          onClick={() => {
                            hideAppSecretToggle();
                          }}
                          className="btn btn-primary d-flex flex-grow-0 "
                        >
                          {hideToggleText}
                        </div>
                      </div>

                      {intappsecretAlert ? <label style={{ color: "#a94442", fontWeight: 400 }}>{intappsecretAlert}</label> : null}
                    </div>
                  ) : null}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => {
                    $("#editIntegrationModal").modal("hide");
                    setEmptyModalInputs();
                  }}
                  ref={EditModalCloseButtonRef}
                >
                  Close
                </button>
                <button
                  onClick={() => {
                    createIntEditSubmitHandler();
                  }}
                  className="btn btn-success"
                  type="button"
                  ref={EditModalUpdateRef}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div style={{...IntegrationPageStyle}} className="dashboard-main">
      <div className="container">
        <div className="d-flex justify-content-between align-items-sm-center flex-column flex-sm-row mb-4 shadow-sm p-3 rounded">
          <div className="mr-4 mb-3 mb-sm-0 ">Create new integrations. You can select mail provider and save.</div>
        </div>

        <div className="card  mb-4 mt-5 shadow-sm">
          <div className="card-body p-5">
            <div className="row align-items-center justify-content-between">{renderApiProvidersList()}</div>
            {createIntegrationModal()}
            {createEditIntegrationModal()}
          </div>
        </div>

        <div>
          <div className="medium"></div>

          <div className="card mb-4 mt-5 shadow-sm">
            <div className="card-body p-5">
              <div className="row align-items-center justify-content-between">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Integration Name</th>
                      <th scope="col">Integration Api</th>
                      <th scope="col">Operations</th>
                    </tr>
                  </thead>

                  {renderIntegrationList()}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integrations;
