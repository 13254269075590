import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { putNewEventAction } from "../../../../../redux/actions/EventActions";
import { RootStore } from "../../../../../redux/store/Store";
import { useFormik } from "formik";
import { generateArrayOfYears, generateArrayOfDays, generateArrayOfHours, generateArrayOfMinutes, generateArrayOfMonths } from "../forms/utils/formUtils";
import * as Yup from "yup";
import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";
import $ from 'jquery';


const AddNewEventForm = () => {
  const dispatch = useDispatch();

  var selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  var newEventError: any = useSelector((state: RootStore) => state.newEventReducer);

  const [newEventEData, setnewEventEData] = useState<any>(null);

  useEffect(() => {
    if (newEventError.data) {
      if (newEventError.data.message) {
        setnewEventEData(newEventError.data.message);
      }
    }
  }, [newEventError]);

  useEffect(() => {
    $("#AddNewEventModalCloseIconButton").click(function () {
      $("#addNewEventCloseButton")[0].click();
    });
  }, []);

  const eventTypeOptions = () => {
    return (
      <>
        <option value="Regular" label="Regular" />
        <option value="Timer" label="Timer" />
      </>
    );
  };

  const everyYearOptions = () => {
    return (
      <>
        <option value="" label="Every Year" />
        {Object.values(generateArrayOfYears()).map((item, index) => (
          <option key={index} value={item.value} label={item.key} />
        ))}
      </>
    );
  };

  const everyMonthOptions = () => {
    return (
      <>
        <option value="" label="Every Month" />
        {generateArrayOfMonths.map((item, index) => (
          <option key={index} value={item.value} label={item.key} />
        ))}
      </>
    );
  };

  const everyDayOptions = () => {
    return (
      <>
        <option value="" label="Every Day" />
        {Object.values(generateArrayOfDays()).map((item, index) => (
          <option key={index} value={item.value} label={item.key} />
        ))}
      </>
    );
  };

  const everyHourOptions = () => {
    return (
      <>
        <option value="" label="Every Hour" />
        {Object.values(generateArrayOfHours()).map((item, index) => (
          <option key={index} value={item.value} label={item.key} />
        ))}
      </>
    );
  };

  const everyMinutesOptions = () => {
    return (
      <>
        <option value="" label="Every Minutes" />
        {Object.values(generateArrayOfMinutes()).map((item, index) => (
          <option key={index} value={item.value} label={item.key} />
        ))}
      </>
    );
  };

  const handleSubmitAddNewEventApi = (bodyObject: object, formObj: any) => {
    dispatch(putNewEventAction(bodyObject, selectedScenarioId.data, formObj));
  };

  const validationSchema = Yup.object().shape({
    eventName: Yup.string().required("Event name is required"),

    customValidate: Yup.string().when("eventType", {
      is: (eventType: string) => eventType && eventType === "Regular",
      then: Yup.string().when("eventName", {
        is: (eventName: string) => eventName && eventName.length == 0,
        then: Yup.string().required("Event name is required"),
        otherwise: Yup.string(),
      }),
      otherwise: Yup.string(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      eventName: "",
      eventType: "Regular",
      everyYear: "",
      everyMonth: "",
      everyDay: "",
      everyHour: "",
      everyMinute: "",
      description: "",
    },
    onSubmit: () => {},
    validationSchema: validationSchema,
    validateOnChange:true,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <div>
          <label className="form-label" htmlFor="eventName">
            Event Name
          </label>
        </div>
        <input className="form-control" id="eventName" name="eventName" type="text" onChange={formik.handleChange} value={formik.values.eventName} />

        {newEventEData ? <ErrorMessageComp name={newEventEData} /> : <ErrorMessageComp name={formik.errors.eventName} />}
      </div>

      <div>
        <div className="mt-3">
          <label className="form-label" htmlFor="eventType">
            Event Type
          </label>
        </div>
        <select className="form-control" id="eventType" name="eventType" value={formik.values.eventType} onChange={formik.handleChange}>
          {eventTypeOptions()}
        </select>
      </div>

      {formik.values.eventType === "Timer" ? (
        <>
          <div className="mt-3">
            <label className="form-label" htmlFor="TimerFormat">
              Timer Format
            </label>
          </div>

          <div className="row ">
            <label className="col-sm form-label align-self-center">Date:</label>
            <div className="col-sm">
              <select className="form-control" id="everyYear" name="everyYear" value={formik.values.everyYear} onChange={formik.handleChange}>
                {everyYearOptions()}
              </select>
            </div>
            <div className="col-sm">
              <select className="form-control" id="everyMonth" name="everyMonth" value={formik.values.everyMonth} onChange={formik.handleChange}>
                {everyMonthOptions()}
              </select>
            </div>
            <div className="col-sm">
              <select className="form-control" id="everyDay" name="everyDay" value={formik.values.everyDay} onChange={formik.handleChange}>
                {everyDayOptions()}
              </select>
            </div>
          </div>

          <div className="row mt-3">
            <label className="col-sm form-label align-self-center">Time:</label>
            <div className="col-sm">
              <select className="form-control" id="everyHour" name="everyHour" value={formik.values.everyHour} onChange={formik.handleChange}>
                {everyHourOptions()}
              </select>
            </div>
            <div className="col-sm">
              <select className="form-control" id="everyMinute" name="everyMinute" value={formik.values.everyMinute} onChange={formik.handleChange}>
                {everyMinutesOptions()}
              </select>
            </div>
            <div className="col-sm"></div>
          </div>
        </>
      ) : null}

      <div>
        <div className="mt-3">
          <label className="form-label" htmlFor="description">
            Description
          </label>
        </div>
        <textarea className="form-control" id="description" name="description" rows={3} onChange={formik.handleChange} value={formik.values.description} />
      </div>

      <div>
        <div className="modal-footer">
          <button
            id="addNewEventCloseButton"
            className="btn btn-light mt-3"
            type="button"
            data-dismiss="modal"
            onClick={() => {
              formik.resetForm();
              if (newEventEData) {
                setnewEventEData("");
              }
            }}
          >
            Close
          </button>
          <button
            id="addNewEventCreateButton"
            onClick={() => {
              handleSubmitAddNewEventApi(formik.values, formik);
            }}
            className="btn btn-success mt-3"
            type="submit"
            disabled={!(formik.isValid && formik.dirty)}
          >
            Create
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddNewEventForm;
