import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { RootStore } from "../../../../../redux/store/Store";
import { sendSelectScenarioIdAction } from "../../../../../redux/actions/scenarioActions";
import { sendSelectedSessionDataAction, sendSelectedSessionIdAction, sendSessionDataAction } from "../../../../../redux/actions/sessionActions";
import { sortBy } from "lodash";
import GenaralModal from "../modals/GenaralModal";
import AddNewSessionDefForm from "../forms/AddNewSessionDefForm";
import EditSessionDefManageForm from "../forms/EditSessionDefManageForm";
import DeleteSessionManageForm from "../forms/DeleteSessionManageForm";
import { ISingleScenario } from "../../../types/scenarioTypes";
import { IDeleteSession,  IEditSession,  INewSession } from "../../../types/sessionTypes";
import { sendSessionDataReducer } from "../../../../../redux/reducers/sessionReducers";



const SessionTab = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const singleScenario: ISingleScenario = useSelector((state: RootStore) => state.singleScenarioReducer.data);
  const newSession: INewSession = useSelector((state: RootStore) => state.putSessionReducer.data);
  const editSession: IEditSession = useSelector((state: RootStore) => state.postSessionReducer.data);
  const deleteSession: IDeleteSession = useSelector((state: RootStore) => state.deleteSessionReducer.data);

  const [selectedSessionId, setSelectedSessionId] = useState<string>("");
  const [selectedSessionName, setSelectedSessionName] = useState<string>("");
  const [selectedScenarioData, setSelectedScenarioData] =useState<ISingleScenario | null>(null);
  const [sessionMapData, setSessionMapData] = useState<any>(null);
  const [selectedSessionData, setSelectedSessionData] = useState<any>(null);

  useEffect(() => {
    if (id) {
      dispatch(sendSelectScenarioIdAction(id));
    }
  }, []);

  useEffect(() => {
    setSelectedScenarioData(singleScenario);
  }, [singleScenario]);

  useEffect(() => {
    setSessionMap();
    setSelectedSession();
  }, [selectedScenarioData, selectedSessionName]);

  const setSessionMap = () => {
    if (selectedScenarioData) {
      setSessionMapData(selectedScenarioData.scenario.sessionData);
    }
  };

  const setSelectedSession = () => {
    if (selectedScenarioData) {
      dispatch(sendSessionDataAction(selectedScenarioData.scenario.sessionData));
      if (selectedSessionName) {
        setSelectedSessionData(selectedScenarioData.scenario.sessionData[selectedSessionName]);
        dispatch(sendSelectedSessionDataAction(selectedScenarioData.scenario.sessionData[selectedSessionName]));
      }
    }
  };

  useEffect(() => {
    if (selectedScenarioData) {
        if (newSession?.status === "success") {
          const newSingleScenarioObj = { ...selectedScenarioData };
          newSingleScenarioObj.scenario.sessionData[newSession.data.propertyName] = newSession.data;
          setSelectedScenarioData(newSingleScenarioObj);
          setSelectedSessionId((newSession.data.id).toString());
          setSelectedSessionName(newSession.data.propertyName);
          dispatch(sendSelectedSessionIdAction((newSession.data.id).toString()));

          $(function () {
            $("#AddNewSessionDefinitionModal").modal("hide");
            $("#sessionList").children().removeClass("active");
            $("#sessionList")
              .children("#" + newSession.data.propertyName)
              .addClass("active");
          });
      }
    }
  }, [newSession]);

  useEffect(() => {
    if (selectedScenarioData) {
        if (editSession?.response?.status === "success") {
          const singleScenarioObj = { ...selectedScenarioData };
          delete singleScenarioObj.scenario.sessionData[selectedSessionName];
          const newSingleScenarioObj = { ...singleScenarioObj };
          newSingleScenarioObj.scenario.sessionData[editSession.bodyObject?.propertyName] = editSession.bodyObject;
          setSelectedSessionName(editSession.bodyObject?.propertyName);
          setSelectedScenarioData(newSingleScenarioObj);

          $(function () {
            $("#EditSessionManageModal").modal("hide");
            $("#sessionList").children().removeClass("active");
            $("#sessionList")
              .children("#" + editSession.bodyObject?.propertyName)
              .addClass("active");
          });
      }
    }
  }, [editSession]);

  useEffect(() => {
    if (selectedScenarioData) {
        if (deleteSession?.status === "success") {
          const singleScenarioObj = { ...selectedScenarioData };
          delete singleScenarioObj.scenario.sessionData[selectedSessionName];
          setSelectedScenarioData(singleScenarioObj);
          setSelectedSessionName("");
          setSelectedSessionId("");
          setSelectedSessionData(null);
          $(function () {
            $("#DeleteSessionManageModal").modal("hide");
            $("#sessionList").children().removeClass("active");
          });
      }
    }
  }, [deleteSession]);

  const renderSessionManageButton = () => {
    return (
      <>
        <button className="btn btn-outline-primary dropdown-toggle pt-2 pb-2 pl-2 pr-2 ml-1" type="button" id="SessionManageDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Manage
        </button>

        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="SessionManageDropdown">
          <button className="dropdown-item" type="button" data-toggle="modal" data-target="#EditSessionManageModal">
            Edit
          </button>
          <button className="dropdown-item" type="button" data-toggle="modal" data-target="#DeleteSessionManageModal">
            Delete
          </button>
        </div>
      </>
    );
  };

  const renderSessionList = () => {
    return (
      <>
        <div className="list-group list-group-flush " id="sessionList" role="tablist">
          {sessionMapData
            ? Object.values(sessionMapData).length !== 0
              ? sortBy(Object.values(sessionMapData), "propertyName").map((s: any) => (
                  <a
                    className="list-group-item list-group-item-action p-1"
                    data-toggle="list"
                    id={s.propertyName}
                    href={"#" + s.propertyName}
                    role="tab"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedSessionId(s.id);
                      setSelectedSessionName(s.propertyName);
                      dispatch(sendSelectedSessionIdAction(s.id));
                    }}
                  >
                    {s.propertyName}
                  </a>
                ))
              : " There is no session created"
            : " There is no session created"}
        </div>
      </>
    );
  };

  const renderSessionPreview = () => {
    return (
      <>
        <div className="tab-content col-10 ">
          <div className="tab-pane active" id={selectedSessionName} role="tabpanel">
            {selectedSessionId ? (
              selectedSessionData ? (
                <>
                  <div className="list-group list-group-flush ">
                    <div className="list-group-item border-0 p-1 ">
                      <div className="row">
                        <div className="col-auto mr-auto">
                          <span>
                            {" "}
                            {selectedSessionData.propertyName}
                            <small> ({selectedSessionData.propertyType}) </small>
                          </span>
                        </div>
                        <div className="col-auto">{renderSessionManageButton()}</div>
                      </div>
                    </div>

                    <div className="list-group-item border-0 p-1">
                      <span style={{ color: "#EF9222" }}>{"Description: "}</span>
                      {selectedSessionData.description ? selectedSessionData.description : "-"}
                    </div>
                  </div>
                </>
              ) : (
                <strong>There is no session created</strong>
              )
            ) : (
              <strong>Select session definition</strong>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderModals = () => {
    return (
      <>
        <GenaralModal modalId="AddNewSessionDefinitionModal" modalName="New Session Definition"  formComp={AddNewSessionDefForm} />
        <GenaralModal modalId="EditSessionManageModal" modalName="Edit Session Definition" formComp={EditSessionDefManageForm} />
        <GenaralModal modalId="DeleteSessionManageModal" modalName="Delete Session Definition" size="lg" formComp={DeleteSessionManageForm} />
      </>
    );
  };

  const renderSessionTab = () => {
    return (
      <h5 className="card-title">
        <div className="row">
          <div className="col-2 border-right">
            <div className="list-group list-group-flush ">
              <div className="list-group-item p-1 ">
                <span>
                  <strong>{"Session Definition Items"}</strong>
                  {sessionMapData ? <small> ({Object.values(sessionMapData).length})</small> : "(0)"}
                </span>
                <button className="btn btn-success p-1 mt-2 mb-2" data-toggle="modal" data-target="#AddNewSessionDefinitionModal">
                  Add New Session Definition{" "}
                </button>
              </div>
            </div>
            {renderSessionList()}
          </div>
          {renderSessionPreview()}
        </div>
      </h5>
    );
  };

  return (
    <>
      {renderSessionTab()}
      {renderModals()}
    </>
  );
};

export default SessionTab;
