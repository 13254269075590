import * as ActionActionTypes from "../types/types";

export const getTemplateReducer = (state:any = [], action: any) => {
  switch (action.type) {
    case ActionActionTypes.GET_TEMPLATE_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.GET_TEMPLATE_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const getTemplateWithIdReducer = (state:any = [], action: any) => {
  switch (action.type) {
    case ActionActionTypes.GET_TEMPLATE_WITH_ID_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.GET_TEMPLATE_WITH_ID_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const getIntegrationVendorsReducer = (state:any = [], action: any) => {
  switch (action.type) {
    case ActionActionTypes.GET_INTEGRATION_VENDORS_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.GET_INTEGRATION_VENDORS_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const putActionReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case ActionActionTypes.ADD_ACTION_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.ADD_ACTION_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const postActionReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case ActionActionTypes.EDIT_ACTION_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.EDIT_ACTION_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const deleteActionReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case ActionActionTypes.DELETE_ACTION_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.DELETE_ACTION_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const putActionParamReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case ActionActionTypes.ADD_ACTION_PARAM_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.ADD_ACTION_PARAM_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const postActionParamReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case ActionActionTypes.EDIT_ACTION_PARAM_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.EDIT_ACTION_PARAM_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const deleteActionParamReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case ActionActionTypes.DELETE_ACTION_PARAM_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.DELETE_ACTION_PARAM_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const postActionReturnsReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case ActionActionTypes.ACTION_RETURNS_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.ACTION_RETURNS_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendActionMapDataReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case ActionActionTypes.SEND_ACTION_MAP_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.SEND_ACTION_MAP_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendActionDataReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case ActionActionTypes.SEND_ACTION_DATA_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.SEND_ACTION_DATA_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendActionIdReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case ActionActionTypes.SEND_ACTION_ID_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.SEND_ACTION_ID_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendActionParamDataReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case ActionActionTypes.SEND_ACTION_PARAM_DATA_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.SEND_ACTION_PARAM_DATA_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendActionParamsDataReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case ActionActionTypes.SEND_ACTION_PARAMS_DATA_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.SEND_ACTION_PARAMS_DATA_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendActionTemplateParamsDataReducer = (state: any = [], action: any) => {
  switch (action.type) {
    case ActionActionTypes.SEND_ACTION_TEMPLATE_PARAMS_DATA_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.SEND_ACTION_TEMPLATE_PARAMS_DATA_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendActionParamIdReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case ActionActionTypes.SEND_ACTION_PARAM_ID_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.SEND_ACTION_PARAM_ID_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};


export const sendTemplateStringForPreviewReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case ActionActionTypes.SEND_TEMPLATE_STRING_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.SEND_TEMPLATE_STRING_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};


export const putTemplateStringForPreviewReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case ActionActionTypes.PUT_TEMPLATE_PREVIEW_DATA_FAIL:
      return {
        data: action.payload,
      };
    case ActionActionTypes.PUT_TEMPLATE_PREVIEW_DATA_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};
