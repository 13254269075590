import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { authenticateUrl } from "../../constants/Urls";
import axios from "axios";

const PublicRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
}> = (props) => {
  const [condition, setcondition] = useState<any>(null);

  useEffect(() => {
    isAuthenticated();
  }, []);

  const isAuthenticated = () => {
    axios
      .get(authenticateUrl)
      .then((response) => {
        if (response.status === 200) {
          setcondition(true);
        } else {
          setcondition(false);
        }
      })
      .catch((error) => {
        if (
          error.response.status &&
          error.response.status >= 400 &&
          error.response.status <= 599
        ) {
          setcondition(false);
          console.log(error, "authenticateApiError");
        }
      });
  };

  const renderComponent = () => {
    return condition !== null && condition === true ? (
      <Redirect to="/" />
    ) : condition === false ? (
      <Route
        path={props.path}
        exact={props.exact}
        component={props.component}
      />
    ) : null;
  };

  return <>{renderComponent()}</>;
};

export default PublicRoute;
