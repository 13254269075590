import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { RootStore } from "../../../../../redux/store/Store";
import { postScenarioAction } from "../../../../../redux/actions/scenarioActions";
import { cloneDeep } from "lodash";
import * as Yup from "yup";
import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";


const AddNewScenarioForm = () => {
  const dispatch = useDispatch();

  var scenarioData: any = useSelector((state: RootStore) => state.sendScenarioDataReducer);
  var selectedScenariId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  var editScenarioError: any = useSelector((state: RootStore) => state.postScenarioReducer);

  const [editName, seteditName] = useState("");
  const [editDescription, seteditDescription] = useState("");
  const [editScenarioData, seteditScenarioData] = useState<any>(null);

  useEffect(() => {
    if (scenarioData.data) {
      seteditScenarioData(scenarioData.data);
      seteditName(scenarioData.data.scenario.name);
      seteditDescription(scenarioData.data.scenario.description);
    }
  }, [scenarioData]);

  const handleScenarioSubmit = (bodyObject: any) => {
    var singleScenarioObj: any = cloneDeep(editScenarioData);
    singleScenarioObj.scenario["name"] = bodyObject.name;
    singleScenarioObj.scenario["description"] = bodyObject.description;

    dispatch(postScenarioAction(singleScenarioObj.scenario, selectedScenariId.data));
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Scenario name is required").matches(/^\w+$/, "Scenario name should be alphanumeric"),
  });

  const formik = useFormik({
    onSubmit: () => {},
    initialValues: { name: editName ? editName : "", description: editDescription ? editDescription : "" },
    validationSchema: validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <div>
          <div>
            <label className="form-label" htmlFor="name">
              Scenario Name
            </label>
          </div>
          <input className="form-control" id="name" name="name" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} />

          {editScenarioError.data !== undefined ? (
            <ErrorMessageComp name={editScenarioError.data.message} />
          ) : formik.errors.name && formik.touched.name ? (
            <ErrorMessageComp name={formik.errors.name} />
          ) : null}
        </div>

        <div>
          <div className="mt-3">
            <label className="form-label" htmlFor="description">
              Description
            </label>
          </div>
          <textarea className="form-control" id="description" name="description" rows={3} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.description} />

          {formik.errors.description && formik.touched.description ? <ErrorMessageComp name={formik.errors.description} /> : null}
        </div>

        <div>
          <div className="modal-footer">
            <button
              id="AddNewScenarioCloseButton"
              className="btn btn-primary p-2 mt-3"
              type="button"
              data-dismiss="modal"
              onClick={() => {
                formik.resetForm();
                if (editScenarioError?.data) {
                  editScenarioError.data.message = "";
                }
              }}
            >
              Close
            </button>
            <button
              id="AddNewScenarioCreateButton"
              onClick={() => {
                handleScenarioSubmit(formik.values);
              }}
              className="btn btn-success p-2 mt-3"
              type="submit"
              disabled={!formik.isValid}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddNewScenarioForm;
