import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "../../../../../redux/store/Store";
import { getIntegrationVendorsAction, postActionAction } from "../../../../../redux/actions/ActionActions";
import axios from "axios";
import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";
import $ from 'jquery';


const EditActionManageForm = () => {
  const dispatch = useDispatch();

  const template: any = useSelector((state: RootStore) => state.getTemplateReducer);
  const integrationVendors: any = useSelector((state: RootStore) => state.getIntegrationVendorsReducer);
  const selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  const selectedAction: any = useSelector((state: RootStore) => state.sendActionDataReducer);
  const selectedActionId: any = useSelector((state: RootStore) => state.sendActionIdReducer);
  const editActionError: any = useSelector((state: RootStore) => state.postActionReducer);

  const [editActionErrorData, seteditActionErrorData] = useState<any>(null);
  const [integrationForValue, setintegrationForValue] = useState("");
  const [isThereIntegration, setisThereIntegration] = useState(false);
  const [actionNameValue, setactionNameValue] = useState("");
  const [actionNameValueAlert, setactionNameValueAlert] = useState("");   

  const [templateData, settemplateData] = useState<any>(null);
  const [integrationVendorsData, setintegrationVendorsData] = useState<any>(null);
  const [selectedVendorId, setselectedVendorId] = useState("");
  const [actionGroupName, setactionGroupName] = useState("");
  const [selectedIntegrationVendorId, setselectedIntegrationVendorId] = useState("");
  const [templateName, settemplateName] = useState("");
  const [description, setdescription] = useState("");
  const [integrationId, setintegrationId] = useState("");
  const [selectedVendorName, setselectedVendorName] = useState("");
  const [templateId, settemplateId] = useState("");

  useEffect(() => {
    if (editActionError.data) {
      if (editActionError.data.message) {
        seteditActionErrorData(editActionError.data.message);
      }
    }
  }, [editActionError.data]);

  useEffect(() => {
    if (selectedAction.data) {
      setactionNameValue(selectedAction.data.name);
      setdescription(selectedAction.data.description);
      getActionTemplateByTemplateId();
    }
  }, [selectedAction.data]);

  useEffect(() => {
    if (template.data) {
      settemplateData(template.data);
    }
  }, [template.data]);

  useEffect(() => {
    if (selectedIntegrationVendorId) {
      dispatch(getIntegrationVendorsAction(selectedIntegrationVendorId));
    }
  }, [selectedIntegrationVendorId]);

  useEffect(() => {
    if (integrationVendors.data) {
      setintegrationVendorsData(integrationVendors.data);
      if (integrationVendors.data[0]) {
        setintegrationForValue(integrationVendors.data[0].productName);
        setintegrationId(integrationVendors.data[0].id);
        setisThereIntegration(true);
      } else {
        setisThereIntegration(false);
      }
    }
  }, [integrationVendors.data]);

  const setActionGroupOption = () => {
    return <>{templateData ? Object.values(templateData).map((item: any) => <option value={item.name} label={item.name} />) : null}</>;
  };

  const setVendorOption = () => {
    return (
      <>
        {templateData
          ? Object.values(templateData)
              .filter((item: any) => item.name === actionGroupName)
              .map((item: any) => (item.vendors ? Object.values(item.vendors).map((item: any) => <option value={item.integration ? item.integration.apiId : item.id} label={item.name} />) : null))
          : null}
      </>
    );
  };

  const setIntegrationVendorsOption = () => {
    return (
      <>
        <option value="" disabled selected label="Choose a integration" />
        {integrationVendorsData ? Object.values(integrationVendorsData).map((item: any) => <option value={item.id} label={item.name} />) : null}
      </>
    );
  };

  const setTemplatesOption = () => {
    return (
      <>
        <option value="" disabled selected label="Choose a template" />
        <option value="" selected label={templateName} />
        {templateData
          ? Object.values(templateData)
              .filter((item: any) => item.name === actionGroupName)
              .map((item: any) =>
                item.vendors
                  ? Object.values(item.vendors)
                      .filter((item: any) => (item.integration ? item.integration.apiId == selectedVendorId : item.id == selectedVendorId))
                      .map((item: any) => (item.templates ? Object.values(item.templates).map((item: any) => <option value={item.id} label={item.name} />) : null))
                  : null
              )
          : null}
      </>
    );
  };

  const getActionTemplateByTemplateId = () => {
    axios
      .get(`/action/template/${selectedAction.data.template.id}`)
      .then((response) => {
        setactionGroupName(response.data.name);
        setselectedVendorName(response.data.vendors[0].name);
        setselectedVendorId(response.data.vendors[0].id);
        settemplateName(response.data.vendors[0].templates[0].name);
        settemplateId(response.data.vendors[0].templates[0].id);
        if (response.data.vendors[0].integration) {
          setisThereIntegration(true);
          setselectedIntegrationVendorId(response.data.vendors[0].integration.apiId);
          setintegrationForValue(response.data.vendors[0].integration.productName);
        } else {
          setisThereIntegration(false);
          setselectedIntegrationVendorId("");
        }
      })
      .catch((error) => {
        alert("failure message:" + JSON.stringify(error.response.data));
      });
  };

  const addActionSubmitHandler = () => {
    var newObj = { ...selectedAction.data };
    newObj.description = description;
    newObj.name = actionNameValue;
    newObj.template.id = parseInt(templateId);

    var sendObj: any = {
      description: description,
      name: actionNameValue,
      templateId: parseInt(templateId),
    };
    if (isThereIntegration) {
      sendObj["integrationId"] = parseInt(integrationId);
      newObj.integration.id = parseInt(integrationId);
    }
    dispatch(postActionAction(sendObj, selectedScenarioId.data, selectedActionId.data, newObj));
  };

  const handleActionNameChange = (e: any) => {
    setactionNameValue(e.target.value);
    if (!e.target.value) {
      setactionNameValueAlert("Action name is required");
    }
  };

  const handleActionGroupChange = (e: any) => {
    setactionGroupName(e.target.value);
  };

  const handleVendorChange = (e: any) => {
    setselectedVendorId(e.target.value);
  };

  const handleIntegrationChange = (e: any) => {
    setselectedIntegrationVendorId(e.target.value);
  };

  const handleTemplateChange = (e: any) => {
    settemplateName(e.target.value);
  };

  const handleDescriptionChange = (e: any) => {
    setdescription(e.target.value);
  };

  const formValidationControl = (): boolean => {
    if (isThereIntegration) {
      if (actionNameValue && actionGroupName && setselectedVendorId && templateName && setselectedIntegrationVendorId) {
        return true;
      } else {
        return false;
      }
    } else {
      if (actionNameValue && actionGroupName && setselectedVendorId && templateName) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <div>
        <div>
          <label className="form-label" htmlFor="actionName">
            Action Name
          </label>
        </div>
        <input className="form-control" name="actionName" type="text" onChange={(e) => handleActionNameChange(e)} value={actionNameValue} />
        <ErrorMessageComp name={actionNameValue ? "" : actionNameValueAlert} />
        {editActionErrorData ? <ErrorMessageComp name={editActionErrorData} /> : null}
      </div>

      <div>
        <div className="mt-3"></div>
        <label className="form-label" htmlFor="actionGroup">
          Action Group
        </label>
        <select
          disabled
          className="form-control"
          name="actionGroup"
          value={actionGroupName}
          onChange={(e) => {
            handleActionGroupChange(e);
          }}
        >
          {setActionGroupOption()}
        </select>
      </div>

      {actionGroupName !== "" ? (
        <div>
          <div className="mt-3"></div>
          <label className="form-label" htmlFor="vendor">
            Vendor
          </label>
          <select
            disabled
            className="form-control"
            name="vendor"
            defaultValue={selectedVendorName}
            value={selectedVendorId}
            onChange={(e) => {
              handleVendorChange(e);
            }}
          >
            {setVendorOption()}
          </select>
        </div>
      ) : null}

      {actionGroupName !== "" && selectedVendorName !== "" ? (
        <div>
          {isThereIntegration ? (
            <div>
              <div className="mt-3"></div>

              <label className="form-label" htmlFor="integration">
                Integration for ({integrationForValue})
              </label>
              <select
                className="form-control"
                name="integration"
                value={selectedIntegrationVendorId}
                onChange={(e) => {
                  handleIntegrationChange(e);
                }}
              >
                {setIntegrationVendorsOption()}
              </select>
            </div>
          ) : null}

          <div>
            <div className="mt-3"></div>

            <label className="form-label" htmlFor="template">
              Template
            </label>
            <select
              disabled
              className="form-control"
              name="template"
              value={templateName}
              onChange={(e) => {
                handleTemplateChange(e);
              }}
            >
              {setTemplatesOption()}
            </select>
          </div>
        </div>
      ) : null}

      <div>
        <div className="mt-3">
          <label className="form-label" htmlFor="description">
            Description
          </label>
        </div>
        <textarea className="form-control" name="description" rows={3} onChange={(e) => handleDescriptionChange(e)} value={description}></textarea>
      </div>

      <div className="modal-footer">
        <button
          id="addNewEventCloseButton"
          className="btn btn-primary p-2 mt-3"
          type="button"
          data-dismiss="modal"
          onClick={() => {
            if (editActionErrorData) {
              seteditActionErrorData("");
            }
          }}
        >
          Close
        </button>
        <button
          id="addNewEventCreateButton"
          onClick={() => {
            addActionSubmitHandler();
          }}
          className="btn btn-success p-2 mt-3"
          disabled={!formValidationControl()}
        >
          Create
        </button>
      </div>
    </>
  );
};

export default EditActionManageForm;
