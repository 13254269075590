import { useDispatch, useSelector } from "react-redux";
import { deleteActionAction } from "../../../../../redux/actions/ActionActions";
import { RootStore } from "../../../../../redux/store/Store";




const DeleteActionForm = () => {
  const dispatch = useDispatch();
  var selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  var selectedActionId: any = useSelector((state: RootStore) => state.sendActionIdReducer);

  return (
    <div>
      <p>Are you sure to delete action? This operation is not returnable and all action data will be deleted permanently.</p>

      <div className="modal-footer">
        <button id="DeleteActionManageModalCloseButton" className="btn btn-primary p-2 mt-3" type="button" data-dismiss="modal">
          Cancel
        </button>
        <button
          id="DeleteActionManageModalSaveButton"
          onClick={() => {
            dispatch(deleteActionAction(selectedScenarioId.data, selectedActionId.data));
          }}
          className="btn btn-danger p-2 mt-3"
          type="button"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteActionForm;
