import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deployScenarioAction, freezeScenarioAction, getScenarioStatusAction, reDeployScenarioAction, resumeScenarioAction, unDeployScenarioAction } from "../../../redux/actions/scenarioActions";
import { RootStore } from "../../../redux/store/Store";
import SendEventForm from "../detail/components/forms/SendEventForm";
import GenaralModal from "../detail/components/modals/GenaralModal";
import $ from 'jquery';


const Operations = () => {
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();

  var selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  var deployScenarioResponse: any = useSelector((state: RootStore) => state.deployScenarioReducer);
  var unDeployScenarioResponse: any = useSelector((state: RootStore) => state.unDeployScenarioReducer);
  var reDeployScenarioResponse: any = useSelector((state: RootStore) => state.reDeployScenarioReducer);
  var freezeDeployScenarioResponse: any = useSelector((state: RootStore) => state.freezeScenarioReducer);
  var resumeDeployScenarioResponse: any = useSelector((state: RootStore) => state.resumeScenarioReducer);

  const [scenarioId, setscenarioId] = useState<string>("")

  useEffect(() => {
    if(id){
      setscenarioId(id);
    }else{
      if(selectedScenarioId?.data){
        setscenarioId(selectedScenarioId.data)
      }
    }
    
  }, [id,selectedScenarioId.data])


  useEffect(() => {
    if (deployScenarioResponse?.data?.status === "success" && scenarioId) {
      dispatch(getScenarioStatusAction(scenarioId));
    }
  }, [deployScenarioResponse.data,scenarioId]);

  useEffect(() => {
    if (unDeployScenarioResponse?.data?.status === "success" && scenarioId) {
      dispatch(getScenarioStatusAction(scenarioId));
    }
  }, [unDeployScenarioResponse.data,scenarioId]);

  useEffect(() => {
    if (reDeployScenarioResponse?.data?.status === "success" && scenarioId) {
      dispatch(getScenarioStatusAction(scenarioId));
    }
  }, [reDeployScenarioResponse.data,scenarioId]);

  useEffect(() => {
    if (resumeDeployScenarioResponse?.data?.status === "success" && scenarioId) {
      dispatch(getScenarioStatusAction(scenarioId));
    }
  }, [resumeDeployScenarioResponse.data,scenarioId]);

  useEffect(() => {
    if (freezeDeployScenarioResponse?.data?.status === "success" && scenarioId) {
      dispatch(getScenarioStatusAction(scenarioId));
    }
  }, [freezeDeployScenarioResponse.data,scenarioId]);

  const renderOperationsButton = () => {
    return (
      <>
        <button
          className="btn btn-outline-white dropdown-toggle pt-2 pb-2 pl-2 pr-2 ml-1"
          type="button"
          id="ScenarioOperationDropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Operations
        </button>
        <div className="dropdown-menu" aria-labelledby="ScenarioOperationDropdown">
          <button
            className="dropdown-item"
            type="button"
            onClick={() => {
              $("#SendEventModal").modal("show");
            }}
          >
            Send Event
          </button>
          <button
            className="dropdown-item"
            type="button"
            onClick={() => {
              dispatch(reDeployScenarioAction(scenarioId));
            }}
          >
            Re Deploy
          </button>
          <button
            onClick={() => {
              dispatch(deployScenarioAction(scenarioId));
            }}
            className="dropdown-item"
            type="button"
          >
            Deploy
          </button>
          <button
            className="dropdown-item"
            type="button"
            onClick={() => {
              dispatch(unDeployScenarioAction(scenarioId));
            }}
          >
            Undeploy
          </button>
          <button
            className="dropdown-item"
            type="button"
            onClick={() => {
              dispatch(freezeScenarioAction(scenarioId));
            }}
          >
            Freeze
          </button>
          <button
            className="dropdown-item"
            type="button"
            onClick={() => {
              dispatch(resumeScenarioAction(scenarioId));
            }}
          >
            Resume
          </button>
        </div>
      </>
    );
  };

  const renderModals = () =>{
    return(
      <>
      <GenaralModal modalId="SendEventModal" modalName="Send Event" formComp={SendEventForm} />
      </>
    )
  }

  return (
    <>
      {renderOperationsButton()}
      {renderModals()}
    </>
  );
};

export default Operations;
