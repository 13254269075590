
import * as  initialStates from "../store/InitialStates";
import { GET_ACCOUNT } from "../types/types";

export const getAccountReducer = (state = initialStates.getAccountInitialState, action: any) => {
    switch (action.type) {
      case GET_ACCOUNT:
        return {
          account: action.payload,
        };
      default:
        return state;
    }
  };