
export const JSSDKExampleCode = `<script src="https://client-sdk.s3.us-west-1.amazonaws.com/herogi.min.js?190120220924"></script>
                                    
<script>
var trackOptions = [];
trackOptions.push(["trackClick", {}]);
trackOptions.push(["trackFormSubmit", {}]);
trackOptions.push(["trackPageLoad", {}]);

herogi.setConf(trackOptions);

herogi.init("appid","appsecret", true);

herogi.identify({}, null,null, function (res, d) {
console.log(res);
console.log(d);
});
</script>`

export const RestCurlExample = `curl --location --request POST 'https://stream.herogi.com/v1/identify'
--header 'Content-Type: application/json' \
--header 'Authorization: Basic YOURAUTHTOKEN' \
--data-raw '{
        "mobileNo" : "+11111111111",
        "email": "mail@example.com",
        "firstname": "John",
        "lastname" : "Wick",
        "externalUserId" : "johnwhick123",
        "country" : "US",
        "city": "Newyork",
        "language" : "EN",
        "timezone" : "Europe/Moscow"
}'`