import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import $ from 'jquery';


import * as Yup from "yup";
import { putTemplatePreviewDataAction } from "../../../../../redux/actions/ActionActions";
import { RootStore } from "../../../../../redux/store/Store";

function RtePreviewForm(props: any) {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const returnedTemplateApi = useSelector(
    (state: RootStore) => state.putTemplateStringForPreviewReducer.data
  );

  const sendedTemplateString = useSelector(
    (state: RootStore) => state.sendTemplateStringForPreviewReducer.data
  );

  const eventMap = useSelector((state: any) => state.sendEventMapDataReducer);
  const sessionMap = useSelector((state: any) => state.sendSessionDataReducer);

  const [eParamsInitial, seteParamsInitial] = useState<{ [key: string]: any }>(
    []
  );
  const [sessionInitial, setSessionInitial] = useState<{ [key: string]: any }>(
    []
  );
  const [returnedTemplateData, setreturnedTemplateData] = useState<any>(null);
  const [stringTemplateData, setstringTemplateData] = useState<string>("");
  useEffect(() => {
    if (eventMap.data) {
      var eParamsInitialTemp: any = [];
      Object.values(eventMap.data).map((event: any) => {
        Object.values(event.params).map((p: any) =>
          eParamsInitialTemp.push({ name: p.propertyName, value: "" })
        );
      });
      seteParamsInitial([...eParamsInitialTemp]);
    }
  }, [eventMap]);

  useEffect(() => {
    if (sessionMap.data) {
      var sessionInitialTemp: any = [];
      Object.values(sessionMap.data).map((s: any) => {
        sessionInitialTemp.push({ name: s.propertyName, value: "" });
      });
      setSessionInitial([...sessionInitialTemp]);
    }
  }, [sessionMap]);

  useEffect(() => {
    if (returnedTemplateApi) {
      setreturnedTemplateData(returnedTemplateApi);
    }
  }, [returnedTemplateApi]);

  useEffect(() => {
    if (sendedTemplateString) {
      setstringTemplateData(sendedTemplateString);
    }
  }, [sendedTemplateString]);

  useEffect(() => {
    if (returnedTemplateData) {
      if (returnedTemplateData.status == "OK") {
        $("#PreviewEventSessionModal").hide();
      }
    }
  }, [returnedTemplateData]);

  const validationSchema = Yup.object().shape({
    eventParams: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        value: Yup.string().required("Event parameter value is required"),
      })
    ),
    session: Yup.array().of(
      Yup.object().shape({
        /*  name: Yup.string()
                  .required('Name is required'), */
        value: Yup.string().required("session value is required"),
      })
    ),
  });

  function onSubmit(fields: any) {}
  const seePreviewHandler = (values: any) => {
    var sendObj: any = { ...values };
    if (stringTemplateData) {
      sendObj["template"] = stringTemplateData;
      alert(JSON.stringify(sendObj));
    } else {
      alert("Template data is empty or null. Please add some data!");
    }

    dispatch(putTemplatePreviewDataAction(sendObj, id));
  };

  return (
    <Formik
      initialValues={{ eventParams: eParamsInitial, session: sessionInitial }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ errors, values, touched }) => (
        <Form>
          <div className="card m-3">
            <div className="alert alert-dark p-1 rounded-0" role="alert">
              Please fill event parameters and session values to see template
            </div>
            <div className="card-body border-bottom">
              <div className="form-group">
                <label>Event Parameters</label>

                {values.eventParams ? (
                  <FieldArray name="eventParams">
                    {() =>
                      values.eventParams.map((p: any, i: any) => {
                        const paramErrors: any =
                          (errors.eventParams?.length &&
                            errors.eventParams[i]) ||
                          {};
                        const paramTouched: any =
                          (touched.eventParams?.length &&
                            touched.eventParams[i]) ||
                          {};
                        return (
                          <div>
                            <hr />

                            <div
                              key={i}
                              className="list-group list-group-flush"
                            >
                              <div className="list-group-item">
                                <div className="form-row">
                                  <div className="form-group col-6">
                                    <label>Name</label>

                                    <Field
                                      editable="none"
                                      value={p.name}
                                      type="text"
                                      className={
                                        "form-control" +
                                        (paramErrors.name && paramTouched.name
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                  </div>
                                  <div className="form-group col-6">
                                    <label>Value</label>
                                    <Field
                                      name={`eventParams.${i}.value`}
                                      type="text"
                                      className={
                                        "form-control" +
                                        (paramErrors.value && paramTouched.value
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name={`eventParams.${i}.value`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    }
                  </FieldArray>
                ) : (
                  "There is no event param"
                )}

                <hr />

                <label>Session Values</label>

                {values.session ? (
                  <FieldArray name="session">
                    {() =>
                      values.session.map((s: any, i: any) => {
                        const sessionErrors: any =
                          (errors.session?.length && errors.session[i]) || {};
                        const sessionTouched: any =
                          (touched.session?.length && touched.session[i]) || {};
                        return (
                          <div>
                            <hr />

                            <div
                              key={i}
                              className="list-group list-group-flush"
                            >
                              <div className="list-group-item">
                                <div className="form-row">
                                  <div className="form-group col-6">
                                    <label>Name</label>

                                    <Field
                                      editable="none"
                                      value={s.name}
                                      type="text"
                                      className="form-control "
                                    />
                                  </div>
                                  <div className="form-group col-6">
                                    <label>Value</label>
                                    <Field
                                      name={`session.${i}.value`}
                                      type="text"
                                      className={
                                        "form-control " +
                                        (sessionErrors.value &&
                                        sessionTouched.value
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name={`session.${i}.value`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    }
                  </FieldArray>
                ) : (
                  "There is no session"
                )}

                <div className="modal-footer mb-0 p-2">
                  <button
                    type="submit"
                    className="btn btn-primary mr-1 p-2"
                    onClick={() => {
                      seePreviewHandler(values);
                    }}
                  >
                    {/*  {previewSucceed ? FontAwesomeIcon icon={faSpinner}  : "See Preview" } */}
                    See Preview
                  </button>
                </div>
              </div>
            </div>
          </div>

          {stringTemplateData
            ? JSON.stringify(stringTemplateData)
            : "stringTemplateData"}

          {/*  {sessionMapData ? JSON.stringify(sessionMapData) :"sessionMapData"} */}
        </Form>
      )}
    </Formik>
  );
}

export default RtePreviewForm;
