import { useEffect, useRef, useState } from "react";
import { Network } from "vis-network/standalone/esm/vis-network";
import { useParams } from "react-router";
import Cookies from "universal-cookie";
import { IStateInner } from "../../types/stateTypes";
import { ITransition } from "../../types/transitionTypes";


export interface ItransitionStatePropTypes{
  transitionsDataProp:ITransition[] | null;
  statesDataProp:IStateInner[] | null;
}

const VisNetwork = (props:ItransitionStatePropTypes) => {
  const cookies = new Cookies();
  const { id } = useParams<{ id: string }>();

  const transitionsDataProp = props.transitionsDataProp;
  const statesDataProp = props.statesDataProp;

  const [statesData, setstatesData] = useState<IStateInner[] | null>([]);
  const [transitionsData, settransitionsData] = useState<ITransition[] | null>([]);
  const [stateNodes, setstateNodes] = useState<any>([]);
  const [transitionEdges, settransitionEdges] = useState<any>([]);
  const [positions, setpositions] = useState<[{x:string,y:string}] | null>(null);

  const [visNetworkOptions, setvisNetworkOptions] = useState<{}>({
    interaction: {
      hover: true,
      selectable: true,
      navigationButtons: true,
    },
    autoResize: true,
    height: "600px",
    physics: {
      enabled: false,
      hierarchicalRepulsion: {
        avoidOverlap: 1,
      },
      repulsion: {
        nodeDistance: 400,
      },
    },
  });

  useEffect(() => {
    setpositions(cookies.get("scenario_" + id.toString()));

    var _startNode: any = {
      id: -1,
      label: "start",
      color: "#e1e1e1",
      shape: "dot",
      font: { size: 13, bold: true },
      size: 20,
    };

    var _endNode: any = {
      id: -2,
      label: "end",
      font: { size: 13, bold: true },
      color: "#333333",
      shape: "dot",
      size: 20,
    };

    if(positions){
      if (positions[-1] != null) {
        _startNode["x"] = positions[-1].x;
        _startNode["y"] = positions[-1].y;
      }
  
      if (positions[-2] != null) {
        _endNode["x"] = positions[-2].x;
        _endNode["y"] = positions[-2].y;
      }
    }
    stateNodes.push(_startNode, _endNode);
  }, []);

  useEffect(() => {
    if (transitionsDataProp) {
      settransitionsData(transitionsDataProp);
    }else{
      console.log("transitionDataProp data is empty or null!");
    }
  }, [transitionsDataProp]);

  useEffect(() => {
    if (statesDataProp) {
      setstatesData(statesDataProp);
    }else{
      console.log("statesDataProp data is empty or null!");
    }
  }, [statesDataProp]);

  useEffect(() => {
    if (statesData && transitionsData) {
      setStates();
    }
  }, [statesData, transitionsData]);

  useEffect(() => {
    if (transitionsData) {
      setTransitions();
    }
  }, [transitionsData]);

  const setStates = () => {
    statesData?.forEach((state: any) => {
      var actions = state.actionArr.map((o: any) => o.name).join(", ");

      var node: any = {
        id: state.id,
        color: "#34495e",
        font: {
          multi: true,
          size: 16,
          color: "#ffffff",
        },

        shadow: {
          enabled: false,
          color: "rgba(0,0,0,0.5)",
          size: 10,
          x: 5,
          y: 5,
        },

        label: state.name,
        subLabel: "",
        shape: "box",
        value: 1,
        title: "Actions = [" + actions + "]",
        widthConstraint: { minimum: 15, maximum: 500 },
        heightConstraint: { minimum: 2 },
        margin: 10,
        data: {
          orgData: state,
          elType: "state",
        },
      };

      if (positions) {
        if (positions[state.id] != null) {
          node["x"] = positions[state.id].x;
          node["y"] = positions[state.id].y;
        }
      }
      stateNodes.push(node);
    });

    var _startNode = stateNodes.find((el: { id: number }) =>
      el.id == -1 ? el : null
    );
    var _endNode = stateNodes.find((el: { id: number }) =>
      el.id == -2 ? el : null
    );

    if (positions) {
      if (positions[-1] != null) {
        _startNode["x"] = positions[-1].x;
        _startNode["y"] = positions[-1].y;
      }

      if (positions[-2] != null) {
        _endNode["x"] = positions[-2].x;
        _endNode["y"] = positions[-2].y;
      }
    }
  };

  const setTransitions = () => {
    transitionsData?.forEach(
      (transition: {
        id: number;
        validation: string;
        event: { name: string };
        fromState: { id: number };
        toState: { id: number };
      }) => {
        var from = -1;
        var to = -2;

        if (transition.fromState != null) {
          from = transition.fromState.id;
        }

        if (transition.toState != null) {
          to = transition.toState.id;
        }

        var edge: any = {
          id: transition.id,
          title: transition.validation,
          from: from,
          to: to,
          arrows: { to: { enabled: true, scaleFactor: 0.4 } },
          width: 4,
          font: { align: "bottom", size: 16 },
          smooth: {
            enabled: false,
          },
          selfReferenceSize: 30,
          selectionWidth: 1,
          data: {
            orgData: transition,
            elType: "transition",
          },
        };

        if (transition.event != null) {
          edge["label"] = transition.event.name;
          edge["color"] = {
            color: "#3498db",
            highlight: "#3498db",
            hover: "#3498db",
          };
        } else {
          edge["color"] = {
            color: "#1abc9c",
            highlight: "#1abc9c",
            hover: "#1abc9c",
          };
        }

        if (transition.validation != null) {
          edge["title"] = transition.validation;
          edge["dashes"] = [10];
        }

        transitionEdges.push(edge);
      }
    );
  };

  var data = {
    nodes: stateNodes,
    edges: transitionEdges,
  };

  const visJsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const network =
      visJsRef.current &&
      new Network(visJsRef.current, data, visNetworkOptions);

    //hover node and edge set cursor as pointer then set default
    network?.on("hoverNode", function () {
      visJsRef.current!.style.cursor = "pointer";
    });

    network?.on("hoverEdge", function () {
      visJsRef.current!.style.cursor = "pointer";
    });

    network?.on("blurEdge", function () {
      visJsRef.current!.style.cursor = "default";
    });
    network?.on("blurNode", function () {
      visJsRef.current!.style.cursor = "default";
    });

    var nodesTemp = data.nodes;

    const saveToCookieBlock = () => {
      var nodePositions: any = {};
      nodesTemp?.forEach((item: { id: number }) => {
        var positions = network?.getPositions(item.id);
        if (positions) {
          nodePositions[item.id] = positions[item.id];
        }
      });
      cookies.set("scenario_" + id.toString(), nodePositions, { path: "/" });
    };

    network?.on("dragEnd", function () {
      saveToCookieBlock();
    });
  }, [visJsRef, data, visNetworkOptions]);


  return (
    <div>
      <div className="card shadow-sm mb-1">
        <div className="card-body p-3">
          <div className="layoutflow">
            <div className="row">
              <div id="visualization-card" className="col-sm-12">
                <div ref={visJsRef} />
              </div>

              {/*  {renderDrawerMenu()} */}
            </div>
            <div>
              
            </div>
          </div>
        </div>
      </div>

    </div>

  );
};

export default VisNetwork;
