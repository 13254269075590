import { ErrorHandler } from "../../components/common/hooksAndFuncts/ApiErrorHandle";
import { getUserHighlight } from "../../services/ReportingService";
import { GET_AUDIENCE_REPORTING_FAIL, GET_AUDIENCE_REPORTING_LOADING, GET_AUDIENCE_REPORTING_SUCCESS } from "../types/types";


export const getAudienceReporting = () => async (dispatch: any) => {
    try {
      dispatch({
        type: GET_AUDIENCE_REPORTING_LOADING,
      });
  
      getUserHighlight()
      .then((response) => {
          dispatch({
            type: GET_AUDIENCE_REPORTING_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
            ErrorHandler(error.response);
            dispatch({
                type: GET_AUDIENCE_REPORTING_FAIL,
                payload: error.response.data,
            });
        });
    } catch (error) {
      console.log(error, "error");
    }
  };