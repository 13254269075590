import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { faAngleRight, faPlus, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RuleComponent from "./components/RuleComponent";
import { Expression, FilterDef, FilterUtils, getSegmentDetail, IExpression, IFilter, IMetaData, IRule, ISegment, MetaData, saveSegment, segmentInit } from "../../../services/SegmentService";
import { useParams } from "react-router";
import { icon } from "@fortawesome/fontawesome-svg-core";
import ErrorMessageComp from "../../../components/common/ErrorMessageComp";
import { apiErrorHandle } from "../../../components/common/hooksAndFuncts/ApiErrorHandle";
import { getRandomInt } from "../../../constants/utils/Helper";

const SegmentDetailPage = () => {

  const [segmentData, setSegmentData] = useState<ISegment>(null!);

  const segmentId = useParams<{ segmentId: string }>().segmentId;

  const [metaData, setMetaData] = useState<IMetaData[]>([]);
  const [filterData, setFilterData] = useState<object[]>([]);

  const [filters, setFilters] = useState<Array<IFilter>>([]);


  useEffect(() => {

    segmentInit().then(_meta => {

      setMetaData(_meta);

      if (segmentId != null) {
        //TODO Init with actual segment 
        console.log("Existing segment with id: " + segmentId);
  
        getSegmentDetail(segmentId)
          .then((response) => {
            console.log(response);
            const _s = response.data.data;
  
            const _filters = _s.filters.map(f => {
              const rules = f.rules.map(r => {
                const _ruleD = r.ruleDetail;
                console.log(r);

                const filterDef = _meta.filter(m => m.source == _ruleD.source)[0].filterDef.filter(f => f.key == _ruleD.segmentKey)[0];
  
                const rule: IRule = {
                  ruleId: r.id,
                  deleted: false,
                  tempRuleId: null!,
                  data: {
                    segmentMeta : _meta[0], //TODO later group by source and type
                    filterDef: filterDef,
                    expressionKey: _ruleD.where[0].expression.left,
                    operand: _ruleD.where[0].expression.op,
                    value: _ruleD.where[0].expression.right[0]
                  }
                };
  
                return rule;
              });
  
              const filter: IFilter = {
                 filterId: f.id,
                 deleted: false,
                 tempFilterId: null!,
                 rules: rules,
                 isValid: true,  
              }
  
              return filter;
            });
            
            const _segment: ISegment = {
              name: _s.name,
              desc: _s.desc
            }
  
            console.log(_filters);

            setFilters(_filters);
            setSegmentData(_segment);
          }).catch(error => {
            console.log(error);
            apiErrorHandle(error, "segmentDetailApi");
          });
  
      } else {
        //Init with empty filter and rule
        console.log("Empty segment")
        const newFilters = [...filters];
  
        const f: IFilter = { filterId: null!, tempFilterId: getRandomInt(100000), deleted: false, rules: [] , isValid: true};
        f.rules.push({ ruleId: null!, deleted: false, data: null!, tempRuleId: getRandomInt(100000) });
  
        newFilters.push(f);
        setFilters(newFilters);
  
      }

    });

  }, []);

  const saveSegmentClick = (e) => {
    
    let anyErr = 0
    //Validate filters
    for(let i = 0; i < filters.length; i++) {
      let f = filters[i];
      let err = 0
      
      if(!f.deleted) {

        for(let j = 0; j < f.rules.length; j++) {
           let r = f.rules[j];
           
           if(!r.deleted && (r.data == null || r.data.segmentMeta == null || r.data.value == null || r.data.value.trim() == "" || r.data.filterDef == null || r.data.op)) {
             ++err;
             break;
           }
        }
      }

      if(err > 0) {
        f.isValid = false;
        anyErr += err;
      } else {
        f.isValid = true
      }
    }

    setFilters([...filters]);

    if(anyErr == 0) {
      let sId = segmentId == null ? segmentId : +segmentId;
      let result = saveSegment(sId, segmentData.name, segmentData.desc, filters);
      result.then((response) => { 
        window.location.href = "/segment?notify=success";
      }).catch((error) => {
        console.log(error);
        apiErrorHandle(error, "segmentSaveApi");
      });

    } else {
      alert("some errors");
    }

  }

  const addNewFilter = () => {

    const newFilters = [...filters];

    const f: IFilter = { filterId: null!, deleted: false, tempFilterId: getRandomInt(100000), rules: [], isValid: true};
    f.rules.push({ ruleId: null!, data: null!, deleted: false, tempRuleId: getRandomInt(100000) });

    newFilters.push(f);
    setFilters(newFilters);

  };

  const removeFilter = (filter: IFilter) => {

    const existingFilter: boolean = filter.filterId != null;

    const newFilters = [...filters];
    let filterCheck;

    if (existingFilter) {
      filterCheck = newFilters.filter(f => f.filterId == filter.filterId)
    } else {
      filterCheck = newFilters.filter(f => f.tempFilterId == filter.tempFilterId)
    }

    if (filterCheck.length > 0) {
      filterCheck[0].deleted = true;
    }

    setFilters(newFilters);

  }

  const ruleChange = (rule: IRule, data) => {
    rule.data = data;
    setFilters([...filters]);
  }

  const addNewRule = (filter, state) => {

    const newFilters = [...filters];

    let filterCheck: IFilter[];

    if (filter.filterId != null) {
      filterCheck = newFilters.filter(f => f.filterId == filter.filterId)
    } else {
      filterCheck = newFilters.filter(f => f.tempFilterId == filter.tempFilterId)
    }

    if (filterCheck.length > 0) {
      let rules = filterCheck[0].rules;
      rules.push({ ruleId: null!, deleted: false, data: null!, tempRuleId: getRandomInt(100000) });
    }

    setFilters(newFilters);

  };

  const removeRule = (filter: IFilter, rule: IRule) => {

    const existingFilter: boolean = filter.filterId != null;

    const newFilters = [...filters];
    let filterCheck;

    if (filter.filterId != null) {
      filterCheck = newFilters.filter(f => f.filterId == filter.filterId)
    } else {
      filterCheck = newFilters.filter(f => f.tempFilterId == filter.tempFilterId)
    }

    if (filterCheck.length > 0) {

      let rules = filterCheck[0].rules.map(r => {
        if (rule.ruleId != null && rule.ruleId == r.ruleId) {
          r.deleted = true;
          return r;
        }
        else if (rule.tempRuleId != null && rule.tempRuleId == r.tempRuleId) {
          r.deleted = true;
          return r;
        }
        else
          return r;
      });

      filterCheck[0].rules = rules;

      for (let i = 0; i < newFilters.length; i++) {
        if (existingFilter) {
          if (newFilters[i].filterId == filterCheck[0].filterId) {
            newFilters[i] = filterCheck[0];
            break;
          }
        } else {
          if (newFilters[i].tempFilterId == filterCheck[0].tempFilterId) {
            newFilters[i] = filterCheck[0];
            break;
          }
        }
      }
    }

    setFilters(newFilters);

  }

  const handleSubmit = (event) => {
    event.preventDefault();
  }

  const lastRuleIndex = (f: IFilter) => {
    const notDeleted = f.rules.filter(r => !r.deleted);
    if (notDeleted.length > 0) {
      return notDeleted[notDeleted.length - 1];
    }

    return null;

  }

  const renderRuleComponent = (index: number, rule: IRule, f: IFilter) => {

    let total = f.rules.length;
    const lastRule: IRule = lastRuleIndex(f)!;


    if (lastRule == null) {

      return rule.deleted == false ? <div key={rule.ruleId == null ? rule.tempRuleId : rule.ruleId}>
        <RuleComponent addRule={addNewRule} removeRule={removeRule} ruleChange={ruleChange} rule={rule} filter={f} meta={metaData}
          isLast={true}
        />
      </div> : "";
    } else {

      if (FilterUtils.compareRule(lastRule, rule)) {
        return rule.deleted == false ? <div key={rule.ruleId == null ? rule.tempRuleId : rule.ruleId}>
          <RuleComponent addRule={addNewRule} removeRule={removeRule} ruleChange={ruleChange} rule={rule} filter={f} meta={metaData}
            isLast={true}
          />
        </div> : "";
      } else {

        return (
          rule.deleted == false ? <div key={rule.ruleId == null ? rule.tempRuleId : rule.ruleId}>
            <RuleComponent addRule={addNewRule} removeRule={removeRule} ruleChange={ruleChange} rule={rule} filter={f} meta={metaData}
              isLast={false}
            />
            <div className="row rule-row">
              <div className="sep-left"></div>
              <div className="sep-text">OR</div>
              <div className="sep-right"></div>
            </div>
          </div>
            : ""
        )
      }
    }
  }

  const renderBreadCrumps = () => {
    return (
      <div className="card shadow-sm ">
        <div className="card-body pb-3 pt-3">
          <div className="d-flex align-items-center justify-content-between px-0">
            <div className="d-flex align-items-center">
              <div>
                <strong>Segment </strong>
                <small>
                  <FontAwesomeIcon icon={faAngleRight} />
                </small>
                <strong>
                  {" "}
                  {segmentId ? "Edit " : "Create New One"} {segmentData ? `${segmentData?.name}` : ""}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSegmentMeta = () => {
    return <div className="card shadow-sm mt-3 mb-3">
      <div className="form-group card-body">
        <label htmlFor="campaignNameLabel">Segment Name</label>

        <input
          style={{ width: "35%" }}
          className="form-control"
          id="segmentName"
          name="segmentName"
          placeholder="E.g. Active users"
          onChange={e => {
            
            setSegmentData(sd => {
              let newSegment = {... sd};
              newSegment.name = e.target.value;
              return newSegment;
            });
            }
          }
          value={segmentData != null ? segmentData.name : ""}
        />

        <label className="mt-3" htmlFor="campaignNameLabel">Segment Description</label>

        <textarea
          style={{ width: "35%" }}
          className="form-control"
          id="segmentDesc"
          name="segmentDesc"
          placeholder="Description"
          onChange={e => {
            
            setSegmentData(sd => {
              let newSegment = {... sd};
              newSegment.desc = e.target.value;
              return newSegment;
            });
            }
          }
          value={segmentData != null ? segmentData.desc : ""}
        />

        {
          //<ErrorMessageComp name="Error" /> 
        }
      </div>
    </div>
  }

  return (
    <div className="dashboard-main">
      {renderBreadCrumps()}
      <form onSubmit={handleSubmit}>
        {renderSegmentMeta()}
        <div className="filters">
        {filters.map((f, i) => {
          return f.deleted == false ? <div className="filter-item" key={f.filterId == null ? f.tempFilterId : f.filterId}>
            <div className={"card shadow-sm"}>
              <div className="form-group card-body">
                {
                  f.rules.map((r, i) => {
                    return renderRuleComponent(i, r, f)
                  })
                }
              </div>
              <div className="filter-bottom border-top">
                <div style={{float: "left"}}>
                <button onClick={e => addNewFilter()} className="btn btn-dark btn-sm" type="button">
                  <FontAwesomeIcon icon={faPlus} />
                  <span style={{ width: "40px" }}>AND</span>
                </button>
                <button style={{ marginLeft: "5px" }} className="btn btn-link"
                  onClick={e => removeFilter(f)} type="button">Delete</button>
                  </div>
                  { !f.isValid ?
                    <div style={{float:"left", marginLeft: "20px", marginTop:"10px" }} className="error-message">*Filter data is not valid or complete</div> 
                    : ""
                  }
              </div>
            </div>
            <div className="pipe">
              <span className="badge badge-secondary">AND</span>
            </div>
          </div>
            : ""
        })
        }
        </div>
        <button style={{
          marginTop: "40px"
        }}
        onClick={ e =>  saveSegmentClick(e)}
          className="btn btn-primary">Save changes</button>
      </form>

    </div>
  );
}

export default SegmentDetailPage