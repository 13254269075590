import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addValidationAction } from "../../../../../redux/actions/ValidationActions";
import { RootStore } from "../../../../../redux/store/Store";
import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";
import $ from 'jquery';



const AddNewValidatonForm = () => {
  const dispatch = useDispatch();

  const selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  const validationError: any = useSelector((state: RootStore) => state.putValidationReducer);

  const [validationEData, setvalidationEData] = useState<any>(null);
  const [validationOptionState, setvalidationOptionState] = useState<any>(null);
  const [leftButtonState, setleftButtonState] = useState(false);
  const [rightButtonState, setrightButtonState] = useState(false);
  const [operandValue, setoperandValue] = useState("");
  const [validationNameValue, setvalidationNameValue] = useState("");
  const [descriptionValue, setdescriptionValue] = useState("");

  const [leftEditorValue, setleftEditorValue] = useState("");
  const [rightEditorValue, setrightEditorValue] = useState("");
  const [editorValue, seteditorValue] = useState("Create a validation string by using left/right and operand");
  const [validationNameError, setvalidationNameError] = useState("");

  const validationOperand: any = useSelector((state: RootStore) => state.getValidationOperandReducer);

  useEffect(() => {
    if (validationError.data) {
      if (validationError.data) {
        setvalidationEData(validationError.data.message);
      }
    }
    if (validationError?.data?.status === "success") {
      handleResetForm();
    }
  }, [validationError]);

  useEffect(() => {
    if (validationOperand.data) {
      setvalidationOptionState(validationOperand.data?.data);
    }
  }, [validationOperand.data]);

  useEffect(() => {
    if (validationOptionState) {
      setValidationOptions();
    }
  }, [validationOptionState]);

  useEffect(() => {
    if (leftEditorValue !== "" || rightEditorValue !== "" || operandValue !== "") {
      seteditorValue(leftEditorValue + " " + operandValue + " " + rightEditorValue);
    }
  }, [leftEditorValue, rightEditorValue, operandValue]);

  useEffect(() => {
    formValidationControl();
  }, [validationNameValue, operandValue, descriptionValue]);

  useEffect(() => {
    $("#AddNewValidationModalCloseIconButton").click(function () {
      handleResetForm();
    });
  }, []);

  const validationOnSubmitHandler = () => {
    var bodyObj = {
      name: validationNameValue,
      left: leftEditorValue,
      operand: operandValue,
      right: rightEditorValue,
      description: descriptionValue,
    };
    dispatch(addValidationAction(bodyObj, selectedScenarioId.data));
  };

  const setValidationOptions = () => {
    return (
      <>
        <option value="" disabled selected>
          Operand
        </option>
        <optgroup label="Numeric">{validationOptionState ? Object.values(validationOptionState.numeric).map((item: any) => <option value={item.name}>{item.name}</option>) : "null"}</optgroup>
        <optgroup label="String">{validationOptionState ? Object.values(validationOptionState.string).map((item: any) => <option value={item.name}>{item.name}</option>) : null}</optgroup>
      </>
    );
  };

  const leftOnChangeHandler = (e: any) => {
    setleftEditorValue(e.target.value);
  };

  const rightOnChangeHandler = (e: any) => {
    setrightEditorValue(e.target.value);
  };

  const operandOnChange = (e: any) => {
    setoperandValue(e.target.value);
  };

  const validationNameHandleChange = (e: any) => {
    setvalidationNameValue(e.target.value);
    setvalidationEData("");
    if (e.target.value === "") {
      setvalidationNameError("Validation name is required");
    }else{
      setvalidationNameError("");
    }
  };

  const descriptionHandleChange = (e: any) => {
    setdescriptionValue(e.target.value);
  };

  const formValidationControl = (): boolean => {
    if (validationNameValue === "" || operandValue === "") {
      return true;
    } else {
      return false;
    }
  };

  const handleResetForm = () => {
    setleftEditorValue("");
    setrightEditorValue("");
    setoperandValue("");
    seteditorValue("Create a validation string by using left/right and operand");
    setvalidationNameValue("");
    setdescriptionValue("");
    setvalidationEData("");
  };

  const renderAddNewValidationForm = () => {
    return (
      <>
        <div className="form-group">
          <label className="form-label" htmlFor="validationName">
            Validation Name
          </label>
          <input
            className="form-control"
            name="validationName"
            type="text"
            value={validationNameValue}
            onChange={(e) => {
              validationNameHandleChange(e);
            }}
          />
          <ErrorMessageComp name={validationNameError} />
          {validationEData ? <ErrorMessageComp name={validationEData} /> : null}
        </div>

        <div className="row">
          <div className="col-sm">
            <button
              className="form-control  btn-outline-white  mt-3 w-100"
              data-toggle="modal"
              data-target="#AddNewValidationLeftModal"
              onClick={() => {
                setleftButtonState(true);
              }}
            >
              Left{" "}
            </button>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <select
                className="form-control  mt-3 w-100"
                name="sessionType"
                onChange={(e) => {
                  operandOnChange(e);
                }}
              >
                {setValidationOptions()}
              </select>
            </div>
          </div>
          <div className="col-sm">
            <button
              className="form-control  btn-outline-white  mt-3 w-100"
              data-toggle="modal"
              data-target="#AddNewValidationRightModal"
              onClick={() => {
                setrightButtonState(true);
              }}
            >
              Right{" "}
            </button>
          </div>
        </div>

        <div className="validation-preview">
          <span>{editorValue}</span>
        </div>

        <div className="mt-3">
          <label className="form-label" htmlFor="description">
            Description
          </label>
          <textarea
            className="form-control"
            name="description"
            value={descriptionValue}
            rows={3}
            onChange={(e) => {
              descriptionHandleChange(e);
            }}
          ></textarea>
        </div>

        <div className="modal-footer mt-3">
          <button
            id="addNewValidationCloseButton"
            className="btn btn-primary p-2 mt-3"
            type="button"
            data-dismiss="modal"
            onClick={() => {
              handleResetForm();
              seteditorValue("Create a validation string by using left/right and operand");
            }}
          >
            Close
          </button>

          <button
            id="addNewValidationCreateButton"
            className="btn btn-success p-2 mt-3"
            disabled={formValidationControl() || !(leftEditorValue !== "" && rightEditorValue !== "")}
            onClick={() => {
              validationOnSubmitHandler();
            }}
          >
            Create
          </button>
        </div>
      </>
    );
  };

  const renderAddNewValidationLeftModal = () => {
    return (
      <>
        <label>
          {" "}
          <strong>left</strong>
        </label>
        <input className="form-control" type="text" defaultValue={leftEditorValue} onChange={(e) => leftOnChangeHandler(e)} />

        <div className="modal-footer mt-3">
          <button
            id="renderAddNewValidationLeftModalButton"
            className="btn btn-primary p-2 mt-3"
            type="button"
            onClick={() => {
              setleftButtonState(false);
            }}
          >
            Close
          </button>
          <button
            id="renderAddNewValidationLeftModalSaveButton"
            onClick={() => {
              setleftButtonState(false);
            }}
            className="btn btn-success p-2 mt-3"
            type="submit"
          >
            Save
          </button>
        </div>
      </>
    );
  };

  const renderAddNewValidationRightModal = () => {
    return (
      <>
        <label>
          {" "}
          <strong>right</strong>
        </label>
        <input className="form-control" type="text" defaultValue={rightEditorValue} onChange={(e) => rightOnChangeHandler(e)} />

        <div className="modal-footer mt-3">
          <button
            id="renderAddNewValidationRightModalButton"
            className="btn btn-primary p-2 mt-3"
            type="button"
            onClick={() => {
              setrightButtonState(false);
            }}
          >
            Close
          </button>
          <button
            id="renderAddNewValidationRightModalSaveButton"
            onClick={() => {
              setrightButtonState(false);
            }}
            className="btn btn-success p-2 mt-3"
            type="submit"
          >
            Save
          </button>

          <div className="mt-3"></div>
        </div>
      </>
    );
  };

  const renderFormWithLogic = () => {
    return <>{leftButtonState ? renderAddNewValidationLeftModal() : rightButtonState ? renderAddNewValidationRightModal() : renderAddNewValidationForm()}</>;
  };

  return <>{renderFormWithLogic()}</>;
};

export default AddNewValidatonForm;
