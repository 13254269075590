import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faBook, faCog, faCreditCard, faSearch, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { logoutURL } from "../../../constants/Urls";
import logo from "../../../assets/images/landing/herogi-logo.svg";
import axios from "axios";
import alertify from "alertifyjs";
import { useEffect, useState } from "react";
import { Account } from "../../../services/AccountService";
import { useSelector } from "react-redux";
import { RootStore } from "../../../redux/store/Store";


const Navbar = () => {
  let history = useHistory();

  const [account, setAccount] = useState<Account>(null!);

  const accountReducer: any = useSelector((state: RootStore) => state.getAccountReducer);

  useEffect(() => { 
    if (accountReducer) {
      setAccount(accountReducer.account);
    }
  }, [accountReducer.account]);

  const logoutApi = (e) => {
    e.preventDefault();
    axios
      .get(logoutURL)
      .then((response) => {
        history.push("/login");
        localStorage.removeItem("loginData");
      })
      .catch((error) => {
        if (error.response.status && error.response.status >= 400 && error.response.status <= 599) {
          var message = error.response.status + " " + error.response.statusText;
          alertify.notify(message, "fail", 5, function () {
            history.push("/login");
          });
          console.log(error, "logoutApiError");
        }
      });
  };

  const renderUserDetails = () => {
    return (
      <div className="dropdown-user-details">
        <div className="dropdown-user-details-name">{account?.companyName ? account.companyName : "N/A"}</div>
        <div className="dropdown-user-details-email">{account?.email ? account.email : "N/A"}</div>
      </div>
    );
  };

  return (
    <div>
      <div className="custom-navbar">
        <nav className="topnav navbar navbar-expand shadow-sm justify-content-between justify-content-sm-start navbar-light bg-white" id="sidenavAccordion">
          <Link to="/">
            <a style={{ marginLeft: "25px" }} href="/">
              <img src={logo} alt="logo" className="" />
              <span style={{marginLeft: "10px"}} className="badge badge-light text-body">BETA</span>
            </a>
          </Link>

          <ul className="navbar-nav align-items-center ml-auto">
            <li className="nav-item dropdown no-caret d-none d-sm-block mr-3">
              <a className="link dropdown-toggle" id="navbarDropdownDocs" href="javascript:void(0);" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div className="font-weight-500">{account?.companyName ? account.companyName : "N/A"}</div>

                <div className="dropdown-arrow">
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownUserImage">
                <h6 className="dropdown-header d-flex align-items-center">
                  <div className="dropdown-user-details">
                    <div className="dropdown-user-details-name">{renderUserDetails()}</div>
                  </div>
                </h6>
                <div className="dropdown-divider"></div>
                <Link className="link" to="/settings">
                  <a className="dropdown-item">
                    <div className="dropdown-item-icon">
                      <FontAwesomeIcon icon={faCog} />
                    </div>
                    Accounts
                  </a>
                </Link>

                <Link className="link" to="/settings/billing">
                  <a className="dropdown-item">
                    <div className="dropdown-item-icon">
                      <FontAwesomeIcon icon={faCreditCard} />
                    </div>
                    Billing
                  </a>
                </Link>

                <a onClick={logoutApi} className="dropdown-item" href="">
                  <div className="dropdown-item-icon">
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </div>
                  Logout
                </a>
              </div>
            </li>

            <li className="nav-item dropdown no-caret d-none d-sm-block mr-3">
              <a className="link dropdown-toggle" id="navbarDropdownDocs" href="javascript:void(0);" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div className="font-weight-500">Support</div>

                <div className="dropdown-arrow">
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-right py-0 mr-sm-n15 mr-lg-0 o-hidden animated--fade-in-up" aria-labelledby="navbarDropdownDocs">
                <a className="dropdown-item py-3" href="http://learn.herogi.com/" target="_blank" rel="noreferrer">
                  <div className="icon-stack bg-primary-soft text-primary mr-4">
                    <FontAwesomeIcon icon={faBook} />
                  </div>
                  <div>
                    <div className="medium text-500">Documentation</div>
                    Usage instructions and reference
                  </div>
                </a>
                <div className="dropdown-divider m-0"></div>

                <div className="dropdown-divider m-0"></div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
