import { useEffect, useState } from "react";
import { ErrorHandler } from "../../../components/common/hooksAndFuncts/ApiErrorHandle";
import { getEventsListView } from "../../../services/ReportingService";
import { EventFilterTable } from "../streams/EventFilterTable";
import { EventLog } from "../streams/EventLog";
import { LogModal, ResultModal } from "../streams/Modals";

const EventStreamBox = ({title, subtitle, scenarios, events, startDate, endDate}) => {

    const [loading, setLoading] = useState(true);
    const [eventStreams, setEventStreams] = useState<Array<EventLog>>([]);
    const [selectedEventStream, setSelectedEventStream] = useState<EventLog | null>(null);
    const [sizeOfEventStream, setSizeOfEventStream] = useState(0);

    const eventStreamColumns = [
        {
          dataField: "sessionId",
          text: "Session Id",
        },
        {
          dataField: "scenarioName",
          text: "Scenario Name",
        },
        {
          dataField: "eventName",
          text: "Event Name",
        },
        {
          dataField: "entryTime",
          text: "Entry Time",
        },
    
        {
          dataField: "result",
          text: "Execution Details",
        }
      ];

      useEffect(() => {
        submitQuery(1);
    }, [scenarios, events, startDate, endDate]);

      const submitQuery = (currentPage) => {

        setLoading(true);

        getEventsListView(scenarios, events, true, true, startDate, endDate, 1, 10)
            .then((res) => {
                let logs = res.data?.hits?.map((item) => new EventLog(item));
                setEventStreams(logs);
                setSizeOfEventStream(logs.length);
                setLoading(false);
            })
            .catch((err) => {
                ErrorHandler(err.response)
                setLoading(false);
            });
    }

    const prepareData = () => {
      
        return eventStreams.map((item, index) => {
            let logs = item.getLogs()
            let logHtml = (
                <a style={{ textDecoration: "none", fontSize: "1.2rem" }} 
                        data-toggle="modal"
                        data-target="#EventLogModal" 
                        onClick={(e) => {
                           
                            setSelectedEventStream(item);
                            
                        }}
                    >
                        { logs[0] != 0 ?  <span className="badge badge-info rounded-circle">{logs[0]}</span> : null }
                        { logs[1] != 0 ?  <span className="badge badge-warning rounded-circle">{logs[1]}</span> : null }
                        { logs[2] != 0 ?  <span className="badge badge-danger rounded-circle">{logs[2]}</span> : null }
                    </a>
            )
            return {
                sessionId: item.session_id,
                scenarioName: <a href={"/scenario/"+ item.scenario_id.toString()} target="_blank">{item.scenario_name}</a>,
                eventName: item.event_name,
                entryTime: item.entry_time,
                result: 
                    <a  href="#" data-toggle="modal"
                        data-target="#EventResultModal" 
                        onClick={(e) => {
                            setSelectedEventStream(item);
                    
                        }}>
                        <span>show</span>   
                    </a>
            }
        })
    }

    const renderEventStreamTable = () => {
        return (
          <div className="mt-4">
            <div className="card shadow-sm mb-1">
              <div className="card-body">
                <div>
                    <EventFilterTable 
                        pageSize={10}
                        eventStream = {prepareData()}
                        eventStreamColumns={eventStreamColumns}
                        sizeOfEventStream = {sizeOfEventStream}
                        submitQuery = {submitQuery}
                        disablePage = {true}
                     />
                </div>
              </div>
            </div>
          </div>
        );
    }


    return (
        <>
            <LogModal eventLog={selectedEventStream} />
            <ResultModal eventLog={selectedEventStream} />
            {loading
                ? <div>Loading </div>
                : <div className="card">
                    <div className="card-body">
                        <h5 className="card-title font-weight-bolder">{title}</h5>
                        <div className="mb-5 pb-3" style={{borderBottom: "1px solid #b4b4b4"}}>
                            <div className="text-gray-600">{subtitle}</div>
                        </div>
                        <div>
                            {renderEventStreamTable()}
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default EventStreamBox;