import * as EventActionTypes from "../types/types";
import * as InitialStates from "../store/InitialStates";
import { Dispatch } from "redux";
import axios from "axios";

export const putNewEventReducer = (state:any = InitialStates.scenarioListInitialState, action: any) => {
  switch (action.type) {
    case EventActionTypes.ADD_NEW_EVENT_FAIL:
      return {
        data: action.payload,
      };
    case EventActionTypes.ADD_NEW_EVENT_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const postEditEventReducer = (state:any = InitialStates.editEventInitialState, action: any) => {
  switch (action.type) {
    case EventActionTypes.EDIT_EVENT_FAIL:
      return {
        data: action.payload,
      };
    case EventActionTypes.EDIT_EVENT_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const deleteEventReducer = (state:any = InitialStates.deleteEventDataInitialState, action: any) => {
  switch (action.type) {
    case EventActionTypes.DELETE_EVENT_FAIL:
      return {
        data: action.payload,
      };
    case EventActionTypes.DELETE_EVENT_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const putEventParamReducer = (state:any = InitialStates.addEventParamDataInitialState, action: any) => {
  switch (action.type) {
    case EventActionTypes.ADD_EVENT_PARAM_FAIL:
      return {
        data: action.payload,
      };
    case EventActionTypes.ADD_EVENT_PARAM_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const postEventParamReducer = (state:any = InitialStates.editEventParamInitialState, action: any) => {
  switch (action.type) {
    case EventActionTypes.EDIT_EVENT_PARAM_FAIL:
      return {
        data: action.payload,
      };
    case EventActionTypes.EDIT_EVENT_PARAM_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const deleteEventParamReducer = (state:any = InitialStates.deleteEventDataInitialState, action: any) => {
  switch (action.type) {
    case EventActionTypes.DELETE_EVENT_PARAM_FAIL:
      return {
        data: action.payload,
      };
    case EventActionTypes.DELETE_EVENT_PARAM_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const postEventSessionIdentifierReducer = (state:any = InitialStates.editEventSessionIdentifierInitialState, action: any) => {
  switch (action.type) {
    case EventActionTypes.EDIT_EVENT_SESSION_IDENTIFIER_FAIL:
      return {
        data: action.payload,
      };
    case EventActionTypes.EDIT_EVENT_SESSION_IDENTIFIER_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const eventSendReducer = (state: any = InitialStates.eventSendInitialState, action: any) => {
  switch (action.type) {
    case EventActionTypes.SEND_EVENT_FAIL:
      return {
        data: action.payload,
      };
    case EventActionTypes.SEND_EVENT_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendEventMapDataReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case EventActionTypes.SEND_EVENTMAP_DATA_FAIL:
      return {
        data: action.payload,
      };
    case EventActionTypes.SEND_EVENTMAP_DATA_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendEventDataReducer = (state:any = InitialStates.sendEventDataInitialState, action: any) => {
  switch (action.type) {
    case EventActionTypes.SEND_EVENT_DATA_FAIL:
      return {
        data: action.payload,
      };
    case EventActionTypes.SEND_EVENT_DATA_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendEventParamsReducer = (state:any = InitialStates.sendEventParamsInitialState, action: any) => {
  switch (action.type) {
    case EventActionTypes.SEND_EVENT_PARAMS_FAIL:
      return {
        data: action.payload,
      };
    case EventActionTypes.SEND_EVENT_PARAMS_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendEventIdReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case EventActionTypes.SEND_EVENT_ID_FAIL:
      return {
        data: action.payload,
      };
    case EventActionTypes.SEND_EVENT_ID_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendEventParamIdReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case EventActionTypes.SEND_EVENT_PARAM_ID_FAIL:
      return {
        data: action.payload,
      };
    case EventActionTypes.SEND_EVENT_PARAM_ID_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendEventSessionIdReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case EventActionTypes.SEND_SELECTED_EVENT_SESSION_ID_FAIL:
      return {
        data: action.payload,
      };
    case EventActionTypes.SEND_SELECTED_EVENT_SESSION_ID_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};


export const getUniqueEventParamReducer = (state = InitialStates.getUniqueEventParamsDataInitialState, action: any) => {
  switch (action.type) {
    case EventActionTypes.GET_UNIQUE_EVENT_PARAMS_FAIL:
      return {
        data: action.payload,
      };
    case EventActionTypes.GET_UNIQUE_EVENT_PARAMS_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};
