export type OptionsType = {
    value: string | number ;
    key: string  ;
  };
  
  export const generateEventOptions =[
    { key: "REGULAR", value: "REGULAR" },
    { key: "TIMER", value: "TIMER" },
  ]

  export const generateArrayOfYears = () => {
    var min = new Date().getFullYear();
    var max = min + 14;
    var years: OptionsType[] = [];
  
    for (var i = max; i >= min; i--) {
      years.push({ key: i.toString(), value: i });
    }
    return years.reverse();
  };
  
  export const generateArrayOfMonths = [
    { key: "January", value: "01" },
    { key: "February", value: "02" },
    { key: "March", value: "03" },
    { key: "April", value: "04" },
    { key: "May", value: "05" },
    { key: "June", value: "06" },
    { key: "July", value: "07" },
    { key: "August", value: "08" },
    { key: "September", value: "09" },
    { key: "October", value: "10" },
    { key: "November", value: "11" },
    { key: "December", value: "12" },
  ];
  
  export const generateArrayOfDays = () => {
    var dates: OptionsType[] = [];
  
    for (let i = 31; i >= 1; i--) {
      if (String(i).length === 1) {
        var ifVal = "0" + i.toString().replace(/"/g, "");
        dates.push({ key: ifVal, value: ifVal  });
      } else {
        var elseVal = i.toString().replace(/"/g, "");
        dates.push({ key: elseVal, value: elseVal });
      }
    }
 
    return dates.reverse();
  };
  
  export const generateArrayOfHours = () => {
    var hours: OptionsType[] = [];
  
    for (let i = 24; i >= 0; i--) {
      if (String(i).length === 1) {
        var ifVal = "0" + i.toString().replace(/"/g, "");
        hours.push({ key: ifVal, value: ifVal });
      } else {
        var elseVal = i.toString().replace(/"/g, "");
        hours.push({ key: elseVal, value: elseVal });
      }
    }
  
    return hours.reverse();
  };
  
  export const generateArrayOfMinutes = () => {
    var minutes: OptionsType[] = [];
  
    for (let i = 59; i >= 0; i--) {
      if (String(i).length === 1) {
        var ifVal = "0" + i.toString().replace(/"/g, "");
        minutes.push({ key: ifVal, value: ifVal });
      } else {
        var elseVal = i.toString().replace(/"/g, "");
        minutes.push({ key: elseVal, value: elseVal });
      }
    }
    return minutes.reverse();
  };


  export const parameterTypeOptions = [
    { key: "STRING", value: "STRING" },
    { key: "NUMERIC", value: "NUMERIC" },
  ];

  export const aggregationTypeOptions = [
    { key: "None", value: "" },
    { key: "FIXED", value: "FIXED" },
    { key: "SLIDING", value: "SLIDING" },
  ];

  export const aggregationDurationOptions = [
    { key: "Not Specified", value: "" },
    { key: "MINUTE", value: "MINUTE" },
    { key: "HOUR", value: "HOUR" },
    { key: "DAY", value: "DAY" },
    { key: "WEEK", value: "WEEK" },
    { key: "MONTH", value: "MONTH" },
    { key: "YEAR", value: "YEAR" },
  ];

  export const radioOptions = [
    {
      key: "Use default session identifier",
      value: "Use default session identifier",
    },
    {
      key: "Override identifier",
      value: "Override identifier",
    },
  ];

  export const sessionTypeOptions = [
    { key: "STRING", value: "STRING" },
    { key: "NUMERIC", value: "NUMERIC" },
  ];


  
  