import moment from "moment";
import { useEffect, useState } from "react";
import { number } from "yargs";
import { ESHistogramQuery, ESQueryPropAggregation } from "../../services/ESQueries";
import DataBox from "./components/DataBox";
import EventStreamBox from "./components/EventStreamBox";
import GraphBox from "./components/GraphBox";
import GraphBoxWithPeriod from "./components/GraphBoxWithPeriod";
import PieChart from "./components/PieChart";
import ProgressBar from "./components/ProgressBar";


const WebReportPage = () => {


    // Get the current date
    let endDate = moment();
    // Subtract 30 days from the current date
    let startDate = endDate.clone().subtract(30, 'days');


    const scenarios = ["MonitoringScenario"];

    const activeUsers = new ESHistogramQuery(["PageLoad"], scenarios, "1d", startDate, endDate, true);
    const pageViews = new ESHistogramQuery(["PageLoad"], scenarios, "1d", startDate, endDate, false);
    const frequentlyVisitedPages = new ESQueryPropAggregation(["PageLoad"], scenarios, "event_data.url", startDate, endDate);
    const referrerPages = new ESQueryPropAggregation(["PageLoad"], scenarios, "event_data.platform_data.referrer", startDate, endDate);
    const os = new ESQueryPropAggregation(["PageLoad"], scenarios, "event_data.platform_data.os", startDate, endDate);
    const browser = new ESQueryPropAggregation(["PageLoad"], scenarios, "event_data.platform_data.browser", startDate, endDate);

    const renderContentWithProgress = (graphData) => {
        return (<div style={{ height: "250px", overflow: "scroll" }}>
            <ProgressBar items={graphData} />
        </div>)
    }

    const renderContentWithPie = (graphData) => {

        const _chartJSData = {
            datasets: [{
                data: graphData.map((item) => item.value),
            }],
            labels: graphData.map((item) => item.label)
        };


        return (
            <div className="d-flex justify-content-center">
                <div style={{ height: "250px", width: "250px" }}>
                    <PieChart graphData={_chartJSData} />
                </div>
            </div>
        )
    }

    //const periodActiveUsers = new ESHistogramQueryWithPeriods(["PageLoad"], scenarios, "1d", thisWeek.startDate, thisWeek.endDate, lastWeek.startDate, lastWeek.endDate, true);


    return (
        <div className="dashboard-main">
            <div>
                <h2>Starter Web KPIs</h2>
                <div className="row mt-4">
                    <div className="col-md-6">
                        <GraphBox esQuery={activeUsers}
                            title="Daily Active Users"
                            subtitle={"unique active users since " + moment(startDate).format("MMM DD")}
                            label="Active Users"
                        />

                    </div>
                    <div className="col-md-6">
                        <GraphBox esQuery={pageViews}
                            title="Page View Events"
                            subtitle={"page views since " + moment(startDate).format("MMM DD")}
                            label="Page View"
                        />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6">
                        <DataBox esQuery={referrerPages}
                            title="Where are users coming from?"
                            subtitle={"Last 30 Days"}
                            renderContent={renderContentWithProgress}
                        />
                    </div>
                    <div className="col-md-6">
                        <DataBox esQuery={frequentlyVisitedPages}
                            title="Frequently Visited Pages"
                            subtitle={"Last 30 Days"}
                            renderContent={renderContentWithProgress}
                        />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6">
                        <DataBox esQuery={os}
                            title="Breakdown of users by device family"
                            subtitle={"Last 30 Days"}
                            renderContent={renderContentWithPie}
                        />
                    </div>
                    <div className="col-md-6">
                        <DataBox esQuery={browser}
                            title="Breakdown of users by browser"
                            subtitle={"Last 30 Days"}
                            renderContent={renderContentWithPie}
                        />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <EventStreamBox title="Realtime Event Streams" 
                            subtitle={"Last 10 Events"}
                            scenarios={scenarios[0]}
                            events={"PageLoad"}
                            startDate={startDate.format("YYYY-MM-DD")}
                            endDate={endDate.format("YYYY-MM-DD")}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default WebReportPage;