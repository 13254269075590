import { useEffect, useState } from "react";
import { BarElement, CategoryScale, Chart, ChartOptions, Legend, LinearScale, LineController, PointElement, registerables, TimeScale, Title, Tooltip } from "chart.js";
import { Line } from "react-chartjs-2";
import 'chartjs-adapter-moment';
import { getElasticEvents } from "../../services/ReportingService";



export const LineOptions = () => {

    const colors = [
        '#FF549E',
        '#FF876E',
        '#FFC256',
        '#F9F871',
        '#00CFFF',
        '#FF6DC0',
        '#DA79F9',
        '#512B00'
    ];

    const getLineOptions = (color: string) => {
        return {
            backgroundColor: "#F0FAFF",
                    borderColor: color,
                    pointBackgroundColor: color, // Background color of the points
                    pointRadius: 0,
                    borderWidth: 2,
        };
    };

    const BlueLineStyle = getLineOptions("#1e61f0");
    const BlueLineDottedStyle = {
        ...getLineOptions("#1e61f0"),
        borderDash: [5, 5]
    }

    const allLines = [BlueLineStyle, ...colors.map(c => {
            return getLineOptions(c)
        }
    )];

    return {
        primary : BlueLineStyle,
        primaryDotted: BlueLineDottedStyle,
        all: allLines
    };
}

const ElasticLineChart = ({graphData, xUnit }) => {


    // Register the necessary components and scales
    Chart.register(CategoryScale, LinearScale, PointElement, LineController, Title, Legend, Tooltip, TimeScale, BarElement);
    Chart.register(...registerables);

    const xUnitStep = xUnit ? xUnit : 7;

    const allData = graphData.datasets.flatMap(dataset => dataset.data);
    const globalMax = allData.length > 0 ? Math.max(...allData) : 10;
    const stepSize = globalMax / 4;

    const lineOption = {
        fill: true,
        lineTension: 0.1,
        backgroundColor: "#F0FAFF",
        borderColor: "#6C94BC",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#6C94BC",
        pointBackgroundColor: "#6C94BC",
        pointBorderWidth: 0.5,
        pointHoverRadius: 2,
        pointHoverBackgroundColor: "#F0FAFF",
        pointHoverBorderColor: "#6C94BC",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        borderWidth: 3
    };

    const options: ChartOptions<'line'> = {
        elements: {
            point: {
                radius: 2
            }
        },
        scales: {
            y: {
                id: 'y-axis-1',
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    beginAtZero: true,
                    precision: 0,
                    stepSize : stepSize,
                    min: 0, // Force the y-axis to start at 0
                    max: globalMax, // Use the calculated maximum value
                    callback: function(value) {
                        return Number(value.toFixed(2)); // Ensure the value is properly formatted
                    }
                },
                grid: {
                    display: true,
                    drawBorder: false,
                    borderDash: [1, 1],
                    color: '#c2c4ca',
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                },
            },
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    stepSize: xUnitStep,
                    displayFormats: {
                        day: 'MMM DD'
                    },
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Days'
                },
                grid: {
                    display: false, // Remove vertical grid lines
                },
                id: 'x-axis-1',
            }
        },
        plugins: {
            filler: {
                propagate: true // Propagate values to start and end of the chart
            },
            legend: {
                display: true,
                position: 'bottom', // Move legend to the bottom
                labels: {
                    usePointStyle: true // Use point style (circle) for legend
                  }
            }
        }
    };

    return (
        <Line data={graphData} options={options} />
    );
};

export default ElasticLineChart;