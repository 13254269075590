
export class Account {
    customerId: number;
    appName: string;
    companyName: string;
    email: string;
    enabledFeatures: EnabledFeatures;

    constructor(json: any) {
        this.customerId = json.customerId;
        this.appName = json.appName;
        this.companyName = json.companyName;
        this.email = json.email;
        this.enabledFeatures = new EnabledFeatures(json.enabledFeatures);
    }
}

export class EnabledFeatures {
    campaignLimits: number;
    segmentLimits: number;
    eventFilters: boolean;
    eventProcessing: boolean;
    cdp: boolean;
    customAttributes: boolean;
    actionTypes: string[];
    triggerEvents: string[];

    constructor(json: any) {
        this.campaignLimits = json.campaignLimits;
        this.segmentLimits = json.segmentLimits;
        this.eventFilters = json.eventFilters;
        this.eventProcessing = json.eventProcessing;
        this.cdp = json.cdp;
        this.customAttributes = json.customAttributes;
        this.actionTypes = json.actionTypes;
        this.triggerEvents = json.triggerEvents;
    }
}
