import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { apiErrorHandle } from "../../../components/common/hooksAndFuncts/ApiErrorHandle";
import axios from "axios";
import ErrorMessageComp from "../../../components/common/ErrorMessageComp";


const Password = () => {
  const postChangePasswordUrl = "/customer/password";

  const [passwordAlert, setpasswordAlert] = useState("");

  const newPassword1Ref = useRef<HTMLInputElement>(null);
  const newPassword2Ref = useRef<HTMLInputElement>(null);
  const oldPasswordRef = useRef<HTMLInputElement>(null);

  const postChangePassword = (): void => {
    const bodyObject = {
      newPassword1: newPassword1Ref.current!.value,
      newPassword2: newPassword2Ref.current!.value,
      oldPassword: oldPasswordRef.current!.value,
    };

    axios
      .post(postChangePasswordUrl, bodyObject)
      .then((response) => {
        if (response.data.status === "success") {
          setpasswordAlert("Updated Successfuly");
        }
      })
      .catch((error) => {
        if (error.response) {
          setpasswordAlert(error.response.data.message);
        }
        apiErrorHandle(error, "postChangePasswordApiError");
      });
  };

  const passwordSubmitHandler = (event: React.FormEvent): void => {
    event.preventDefault();
    try {
      postChangePassword();
      newPassword1Ref.current!.value = "";
      newPassword2Ref.current!.value = "";
      oldPasswordRef.current!.value = "";
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="dashboard-main">
        <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
          <div className="container-fluid">
            <div className="page-header-content">
              <div className="row align-items-center justify-content-between pt-3">
                <div className="col-auto mb-3">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i data-feather="user"></i>
                    </div>
                    Account Settings - Password Reset
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-fluid mt-4">
          <nav className="nav nav-borders">
          <Link className="link" to="/settings">
              <span className="nav-link ml-0">Profile</span>
            </Link>

            <Link className="link" to="/settings/password">
              <a className="nav-link active">Password Reset</a>
            </Link>

            <Link className="link" to="/settings/billing">
              <a className="nav-link">Billing</a>
            </Link>
          </nav>
          <hr className="mt-0 mb-4" />
          <div className="card mb-4">
            <div className="card-header">Change Password</div>
            <div className="card-body">
              <form>
                <div className="form-group">
                  <label className="small mb-1" htmlFor="currentPassword">
                    Current Password
                  </label>
                  <input
                    ref={oldPasswordRef}
                    className="form-control"
                    id="currentPassword"
                    type="password"
                    placeholder="Enter current password"
                  />
                </div>
                <div className="form-group">
                  <label className="small mb-1" htmlFor="newPassword">
                    New Password
                  </label>
                  <input
                    ref={newPassword1Ref}
                    className="form-control"
                    id="newPassword"
                    type="password"
                    placeholder="Enter new password"
                  />
                </div>
                <div className="form-group">
                  <label className="small mb-1" htmlFor="confirmPassword">
                    Confirm Password
                  </label>
                  <input
                    ref={newPassword2Ref}
                    className="form-control"
                    id="confirmPassword"
                    type="password"
                    placeholder="Confirm new password"
                  />
                </div>

                <div className="form-group">
                  {passwordAlert ? (
                    <ErrorMessageComp name={passwordAlert} />
                  ) : null}
                </div>

                <button
                  onClick={passwordSubmitHandler}
                  className="btn btn-primary"
                  type="button"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Password;
