import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import LineChart from "../../components/common/charts/LineChart";
import MultiLineChart from "../../components/common/charts/MultiLineChart";
import { getCampaingAction } from "../../redux/actions/campaignActions";
import { RootStore } from "../../redux/store/Store";
import { getPendingSessionCount, getScenarioStates, ReportingQueries } from "../../services/ReportingService";
import CampaignOperations from "./comps/CampaignOperations";
import CampaignStatus from "./comps/CampaignStatus";

const CampaignViewPage = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const campaignId = useParams<{ campaignId: string }>().campaignId;

  const campaignR: any = useSelector((state: RootStore) => state.getCampaignReducer);

  const [campaignData, setcampaignData] = useState<any>(null);
  const [campaignStatus, setCampaignStatus] = useState("");
  const [campaignGraphData, setCampaignGraphData] = useState<any>({ datasets: [], labels: [] });
  const [campaignTotalState, setCampaignTotalState] = useState<number>(0);

  useEffect(() => {
    dispatch(getCampaingAction(campaignId));
  }, []);

  useEffect(() => {
    document.title = "Campaign Detail | Herogi Growth Panel"
  }, []);

  useEffect(() => {
    if (campaignR) {
      setcampaignData(campaignR?.data?.data?.campaign);
      setCampaignStatus(campaignR?.data?.data?.campaign?.status?.status);
    }
  }, [campaignR]);

  useEffect(() => {

    if (campaignData) {

      getScenarioStates(campaignData.scenarioId)
        .then((res) => {
          let mapped = mapToGraphData(res?.data?.dates_with_holes?.buckets);
          setCampaignGraphData(mapped);

        });

      getPendingSessionCount(campaignData.scenarioId)
        .then((res) => {
          console.log(res);
          setCampaignTotalState(res?.data?.total ? res?.data?.total : 0);
        });
    }

  }, [campaignData]);

  const mapToGraphData = (elasticData: any[]) => {

    let graphData = {};
    graphData["labels"] = [];
    graphData["datasets"] = [];

    let labels: any[] = [];
    let runStateData: any[] = [];
    let otherStateData: any[] = [];

    if (elasticData != null) {

      elasticData.forEach(e => {
        let label = e["key_as_string"];
        let multiData = e["state_agg"]["buckets"];

        let runStateDocCount = 0;
        let otherStateDocCount = 0;

        multiData.forEach(d => {

          if (d["key"] == "_runState") {
            runStateDocCount = d["doc_count"];
          } else {
            otherStateDocCount = d["doc_count"];
          }
        });

        runStateData.push(runStateDocCount);
        otherStateData.push(otherStateDocCount);

        labels.push(label);

      });
    }

    graphData["labels"] = labels;
    graphData["datasets"] = [
      { "id": 1, "label": "Campaign Received", data: runStateData }
    ]

    return graphData;

  }

  const renderHeader = () => {
    return (
      <div className="card shadow-sm mb-1">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between px-0">
            <div className="d-flex align-items-center">
              <h3>
                {campaignData ? campaignData?.name : "Campaign Name"}{" "}
                {campaignStatus ? CampaignStatus(campaignStatus) : null}
              </h3>
            </div>

            <div className="ms-4 small">
              <span
                className="btn btn-light p-2 mr-2 "
                onClick={() => {
                  history.push(`/campaign/edit/${campaignId}`);
                }}
              >
                EDIT{" "}
              </span>
              <span className="dropdown">
                {/* TODO operation component */}
                <CampaignOperations />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCampaignViewDetails = () => {
    return (
      <div className="card mb-4 mt-4 shadow-sm">
        <div className="card-header">
          <div className="row ">
            <div style={{ color: "#000" }} className="col-auto mr-auto">
              Campaign Details
            </div>
            <div className="col-auto"></div>
          </div>
        </div>
        <div className="card-body">
          <h5 className="card-title">
            {campaignData ? (
              <div>
                <div className="row">
                  <div className="col-auto mr-auto">
                    <h4>{campaignData.name}</h4>
                  </div>
                  <div className="col-auto">
                    {campaignData?.status?.status == "DEPLOYED" ? (
                      <div
                        style={{
                          color: "#27AE60",
                        }}
                      >
                        {campaignData?.status?.status}{" "}
                      </div>
                    ) : (
                      <div style={{ color: "#C0392B" }}>{campaignData?.status?.status}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto mr-auto">
                    <label>Last Update:</label>
                    {new Date(campaignData.updateDate).toLocaleString()}
                  </div>
                </div>

                <div className="row">
                  <div className="col md-6">
                    <label style={{ color: "#ea7e22" }}>Description</label>
                    <div>{campaignData.description}</div>
                  </div>
                  <div className="col md-6"></div>
                </div>
              </div>
            ) : null}
          </h5>
        </div>
      </div>
    );
  };

  const renderDataPanel = () => {

    return (
      <div className="card mb-4 pb-1 shadow-sm">
        <div className="row">
          <div className="col-md-9">
            <h3 style={{ textAlign: "center" }} className="mt-3 ml-3">Performance Over Time</h3>
            <MultiLineChart graphData={campaignGraphData.datasets} labelData={campaignGraphData.labels} />
          </div>
          <div className="col-md-3 campaign-pending">
            <div className="row" style={{ height: "100%;" }}>
              <div className="col-xs-1 border"></div>
              <div className="col">
                <h3>Scheduled Users</h3>
                <div className="data-text">
                  {campaignTotalState}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }

  return (
    <div className="dashboard-main">
      {renderHeader()} {renderCampaignViewDetails()}
      {renderDataPanel()}
    </div>
  );
};

export default CampaignViewPage;
