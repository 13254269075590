
import GenaralModal from "../../scenario/detail/components/modals/GenaralModal";
import { EventLog } from "./EventLog";

export const LogModal = (props: { eventLog: EventLog | null }) => {

    const { eventLog } = props;

    return (
        <GenaralModal modalId="EventLogModal" modalName="Log Messages" size="lg" formComp={() => {

            return (
                eventLog?.log_messages.map((log, index) => {
                    return (
                        <div className="m-2">
                            <span className={log.level == 'INFO' ? 'text-info' + " mr-2" : (log.level == 'WARN' ? 'text-warning' : 'text-danger') + " mr-2"}>
                                <b>[{log.level}]</b>
                            </span>
                            -
                            <span className="ml-2 mr-2"><b>{log.message}</b></span>
                            -
                            <span className="ml-2 mr-2">{log.detail}</span>
                        </div>
                    )
                })
            )
        }} />
    );
}

export const ResultModal = (props: { eventLog: EventLog | null }) => {

    const { eventLog } = props;

    const renderActions = () => {

        if(eventLog?.result && eventLog?.result.actions.length > 0) {
             
            return (
                <>
                    <label><b>Actions: </b></label>
                    {eventLog.result.actions.map((action, index) => {
                        return (
                            <div>
                                <h5>
                                    {index + 1}. {action.name}
                                    <small className="ml-1 badge badge-dark">{action.template}</small>
                                </h5>
                            </div>
                        )}
                    )}
                </>
            )
        }
        else {
            return (<label><b>Actions: </b>N/A</label>)
        }
    }

    const renderEventDetails = () => {
        
        return (
            <>
                <div>
                    <label><b>Event Name: </b>{eventLog?.event_name}</label>
                </div>
                <div>
                    <label><b>Event Data: </b></label>
                    <div className="bg-gray-100 p-2 fw-200" style={{maxHeight: "150px", overflow: "scroll"}}>
                        <pre>{JSON.stringify(eventLog?.event_data, null, 2)}</pre>
                    </div>
                </div>
            </>
        )
    }

    return (
        <GenaralModal modalId="EventResultModal" modalName="Execution Details" size="lg" formComp={() => {

            if(eventLog && eventLog.result !== undefined)  {

                return <div>
                        {eventLog.result.validation.map((validation, index) => { 
                            return (
                                <>
                                    <div>
                                        <label><b>Validation[{index + 1}]: </b>{validation.raw}</label>
                                    </div>
                                    <div>
                                        <label><b>Validation[{index + 1}] Result: </b>{validation.value}</label>
                                    </div>
                                </>
                            )}
                        )}
                        <div>
                            {eventLog.result.transition ?
                                <label><b>Transition: </b>{ eventLog.result.transition.toString() }</label>
                                :
                                <label><b>Transition: </b>N/A</label>
                            }
                        </div>
                        <div>
                            {eventLog.result.from ?
                                <label><b>From State: </b>{ eventLog.result.from }</label>
                                :
                                <label><b>From State: </b>N/A</label>
                            }
                        </div>
                        <div>
                            {eventLog.result.to ?
                                <label><b>To State: </b>{ eventLog.result.to }</label>
                                :
                                <label><b>To State: </b>N/A</label>
                            }
                        </div>
                        <div>
                            {renderActions()}
                            {renderEventDetails()}
                        </div>
                    </div>
            } else {
                return renderEventDetails()
            }
        }} />
    );
}