import * as transitionActionTypes from "../types/types";
import { apiErrorHandle } from "../../components/common/hooksAndFuncts/ApiErrorHandle";
import axios from "axios";

export const putTransitionAction = (bodyObj: object, scenarioId: string,resetForm:any) => async (dispatch: any) => {
  try {
    dispatch({
      type: transitionActionTypes.ADD_TRANSITION_LOADING,
    });

    await axios
      .put(`/scenario/${scenarioId}/transition`, bodyObj)
      .then((response) => {
        resetForm();
        dispatch({
          type: transitionActionTypes.ADD_TRANSITION_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "putTransitionActionApiError");
        dispatch({
          type: transitionActionTypes.ADD_TRANSITION_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const postTransitionAction = (bodyObj: object, scenarioId: string, transitionId: string,resetForm:any,editedObj:any) => async (dispatch: any) => {
  try {
    dispatch({
      type: transitionActionTypes.EDIT_TRANSITION_LOADING,
    });

    await axios
      .post(`/scenario/${scenarioId}/transition/${transitionId}`, bodyObj)
      .then((response) => {
        dispatch({
          type: transitionActionTypes.EDIT_TRANSITION_SUCCESS,
          payload: {response:response,transitionId:transitionId,editedObj:editedObj},
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "postTransitionActionApiError");
        dispatch({
          type: transitionActionTypes.EDIT_TRANSITION_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const deleteTransitionAction = (scenarioId: string, transitionId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: transitionActionTypes.DELETE_TRANSITION_LOADING,
    });

    await axios
      .delete(`/scenario/${scenarioId}/transition/${transitionId}`)
      .then((response) => {
        dispatch({
          type: transitionActionTypes.DELETE_TRANSITION_SUCCESS,
          payload: { response: response, transitionId: transitionId },
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "deleteTransitionActionApiError");
        dispatch({
          type: transitionActionTypes.DELETE_TRANSITION_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const sendSelectedTransitionDataAction = (selectedTransition: any) => (dispatch: any) => {
  try {
    dispatch({
      type: transitionActionTypes.SEND_TRANSITION_DATA_LOADING,
    });
    dispatch({
      type: transitionActionTypes.SEND_TRANSITION_DATA_SUCCESS,
      payload: selectedTransition,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: transitionActionTypes.SEND_TRANSITION_DATA_FAIL,
      payload: error,
    });
  }
};


export const sendSelectedTransitionIdAction = (selectedTransitionId: string) => (dispatch: any) => {
  try {
    dispatch({
      type: transitionActionTypes.SEND_TRANSITION_ID_LOADING,
    });
    dispatch({
      type: transitionActionTypes.SEND_TRANSITION_ID_SUCCESS,
      payload: selectedTransitionId,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: transitionActionTypes.SEND_TRANSITION_ID_FAIL,
      payload: error,
    });
  }
};
