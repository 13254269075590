import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { RootStore } from "../../redux/store/Store"
import { JSSDKExampleCode, RestCurlExample } from "./Constant"

export const AudienceAttributesPage = () => {

    const account = useSelector((state: RootStore) => state.getAccountReducer)

    useEffect(() => {
        document.title = "Audience | Herogi Growth Panel"
    }, [account]);


    const renderDoc = () => {

        return (
            <div className="card mt-3">
                <div className="card-body">
                    <h2 className="card-title">Collect User data</h2>
                    <p className="card-text">Collect user data by adding the following code to your website.</p>
                    <div className="card-text text-sm">
                        <h4>Javascript SDK</h4>
                        <div><pre className="border rounded m-0 p-3">
                            <code>
                                {JSSDKExampleCode}
                            </code>
                        </pre>
                        </div>
                    </div>
                    <div className="card-text text-sm mt-4">
                        <h4>Rest Example</h4>
                        <div><pre className="border rounded m-0 p-3">
                            <code>
                                {RestCurlExample}
                            </code>
                        </pre>
                        </div>
                    </div>
                    <div className="card-text mt-5">
                        Looking for more details visit our helpcenter <a href="http://learn.herogi.com/devguide/identify-api/" target="_blank">here.</a>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="dashboard-main">
            <nav className="nav nav-borders">
                <Link className="link" to="/audience">
                    <span className="nav-link ml-0">Audience</span>
                </Link>

                <Link className="link" to="/audience/attributes">
                    <a className="nav-link active">Tracking Data</a>
                </Link>
            </nav>
            <hr className="mt-0 mb-4"></hr>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-8">
                            <div>
                                <h2 className="card-title">Available Attributes</h2>
                                <p className="card-text">The following attributes are collected by default for all users. You can add additional attributes to collect.</p>
                                    <div className="ml-4">
                                        <button className="btn btn-light  disabled mr-3" type="button">hgid</button>
                                        <button className="btn btn-light  disabled mr-3" type="button">externalUserId</button>
                                        <button className="btn btn-light  disabled mr-3" type="button">email</button>
                                        <button className="btn btn-light  disabled mr-3" type="button">firstname</button>
                                        <button className="btn btn-light  disabled mr-3" type="button">lastname</button>
                                    </div>
                                    <div className="ml-4 mt-3">
                                        <button className="btn btn-light  disabled mr-3" type="button">gender</button>
                                        <button className="btn btn-light  disabled mr-3" type="button">language</button>
                                        <button className="btn btn-light  disabled mr-3" type="button">country</button>
                                        <button className="btn btn-light  disabled mr-3" type="button">city</button>
                                        <button className="btn btn-light  disabled mr-3" type="button">currentLocation</button>
                                    </div>
                                    <div className="ml-4 mt-3">
                                        <button className="btn btn-light  disabled mr-3" type="button">timezone</button>
                                        <button className="btn btn-light  disabled mr-3" type="button">facebookId</button>
                                        <button className="btn btn-light  disabled mr-3" type="button">twitterId</button>
                                        <button className="btn btn-light  disabled mr-3" type="button">lineId</button>
                                        <button className="btn btn-light  disabled mr-3" type="button">emailOptin</button>
                                    </div>
                                    <div className="ml-4 mt-3">
                                        <button className="btn btn-light  disabled mr-3" type="button">smsOptin</button>
                                        <button className="btn btn-light  disabled mr-3" type="button">pushOptin</button>
                                        <button className="btn btn-light  disabled mr-3" type="button">webPushOptin</button>
                                        <button className="btn btn-light  disabled mr-3" type="button">locationOptin</button>
                                        <button className="btn btn-light  disabled mr-3" type="button">device</button>
                                    </div>
                            </div>
                        </div>
                        <div className="col-md-4 border-left" style={{borderColor:"gray;"}}>
                            <div style={{marginTop:"80px", textAlign:"center"}}>
                                <button className="btn btn-large btn-dark" data-toggle="modal" data-target="#UsageExceedModal">Add Custom Attributes</button>
                                <div className="mt-3 text-sm text-center">
                                    Custom Attributes allow you to attach extra data on users
                                </div>
                                <div className="mt-3 text-sm text-center">
                                    You can use this data to target users in campaigns by complex segmenting rules
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {renderDoc()}
        </div>   
    )
}
