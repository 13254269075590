import { useEffect, useState  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleScenarioAction, sendSelectedScenarioDataAction } from "../../../redux/actions/scenarioActions";
import { RootStore } from "../../../redux/store/Store";
import { faArrowLeft, faChevronDown, faClock, faEye, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useParams } from "react-router";
import { sendActionMapDataAction } from "../../../redux/actions/ActionActions";
import { getStatesAction, sendSelectedStateIdAction, sendStatesDataAction } from "../../../redux/actions/StateActions";
import { ISingleScenario } from "../types/scenarioTypes";
import { IAddState, IDeleteState, IEditState, IStateInner, IStates } from "../types/stateTypes";
import { sendEventMapDataAction } from "../../../redux/actions/EventActions";
import { sendSelectedTransitionDataAction, sendSelectedTransitionIdAction } from "../../../redux/actions/TransitionActions";
import { IAddTransition, IDeleteTransition, IEditTransition, ITransition } from "../types/transitionTypes";
import { Helmet } from "react-helmet";
import { sortBy } from "lodash";
import { ADD_NEW_STATE, ADD_NEW_TRANSITION, STATES_HEADER, TRANSITIONS_HEADER } from "../../../constants/languageConstants";
import Operations from "../commonComps/Operations";
import EditScenarioForm from "../detail/components/forms/EditScenarioForm";
import DeleteScenarioForm from "../detail/components/forms/DeleteScenarioForm";
import GenaralModal from "../detail/components/modals/GenaralModal";
import EditStateForm from "./components/forms/EditStateForm";
import DeleteStateForm from "./components/forms/DeleteStateForm";
import AddNewStateForm from "./components/forms/AddNewStateForm";
import AddNewTransitionForm from "./components/forms/AddNewTransitionForm";
import EditTransitionForm from "./components/forms/EditTransitionForm";
import DeleteTransitionForm from "./components/forms/DeleteTransitionForm";
import VisNetwork from "./components/VisNetwork";
import { sendValidationMapDataAction } from "../../../redux/actions/ValidationActions";


const ScenarioDesignPage = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  const history = useHistory();

  const singleScenario: ISingleScenario = useSelector((state: RootStore) => state.singleScenarioReducer.data);
  const states: IStates = useSelector((state: RootStore) => state.getStatesReducer.data);
  const addState: IAddState = useSelector((state: RootStore) => state.putStateReducer.data);
  const editState: IEditState = useSelector((state: RootStore) => state.postStateReducer.data);
  const deleteState: IDeleteState = useSelector((state: RootStore) => state.deleteStateReducer.data);
  const addTransition: IAddTransition = useSelector((state: RootStore) => state.putTransitionReducer.data);
  const editTransition: IEditTransition = useSelector((state: RootStore) => state.postTransitionReducer.data);
  const deleteTransition: IDeleteTransition = useSelector((state: RootStore) => state.deleteTransitionReducer.data);

  const [visualizeToggle, setvisualizeToggle] = useState<boolean>(false);
  const [selectedScenario, setSelectedScenario] = useState<ISingleScenario | null>(null);
  const [statesData, setstatesData] = useState<IStateInner[] | null>(null);
  const [transitionsData, settransitionsData] = useState<ITransition[] | null>(null);
  


  useEffect(() => {
    dispatch(getSingleScenarioAction(id));
    dispatch(getStatesAction(id));
  }, [id]);

  useEffect(() => {
    if (states) {
      setstatesData(states?.data?.data);
      dispatch(sendStatesDataAction(states));
    }
  }, [states]);

  useEffect(() => {
    if (statesData) {
      dispatch(sendStatesDataAction(statesData));
    }
  }, [statesData]);

  useEffect(() => {
    if (singleScenario) {
      setSelectedScenario(singleScenario);
      settransitionsData(singleScenario.scenario.transitions);
      dispatch(sendValidationMapDataAction(singleScenario.scenario.validationMap))
      dispatch(sendActionMapDataAction(singleScenario.scenario.actionMap));
      dispatch(sendEventMapDataAction(singleScenario.scenario.eventMap));
    }
  }, [singleScenario]);

  useEffect(() => {
    if (addState && statesData) {
      if (addState?.statusText === "OK") {

        const newStates: IStateInner[] = [...statesData];
        newStates.push(addState.data.data);
        setstatesData(newStates);
        $("#AddNewStateModal").modal("hide");
        addState.statusText = "";
      }
    }
  }, [addState]);

  useEffect(() => {
    if (editState && statesData) {
      if (editState?.response?.statusText === "OK") {

        
        dispatch(getStatesAction(id));
        dispatch(getSingleScenarioAction(id));
        $("#EditStateModal").modal("hide");
        editState.response.statusText = "";
      }
    }
  }, [editState]);

  //instead of get scenario again deleted from transition data as deleted state data
  useEffect(() => {
    if (deleteState && statesData) {
      if (deleteState.response?.statusText === "OK") {

        const newStates: IStateInner[] = [...statesData];
        const deletedStateId = deleteState.stateId;
        const deletedStatesArray: IStateInner[] = newStates.filter((ns: IStateInner) => ns.id !== parseInt(deletedStateId));
        if (transitionsData) {
          const newTransitions: ITransition[] = [...transitionsData];
          const deletedTransitionArray: ITransition[] = newTransitions
            .filter((nt: ITransition) => nt?.fromState?.id !== parseInt(deletedStateId))
            .filter((nt: ITransition) => nt?.toState?.id !== parseInt(deletedStateId));
          settransitionsData([...deletedTransitionArray]);
        }
        setstatesData([...deletedStatesArray]);

        $("#DeleteStateModal").modal("hide");
        deleteState.response.statusText = "";
      }
    }
  }, [deleteState]);

  useEffect(() => {
    if (addTransition && transitionsData) {
      if (addTransition?.statusText === "OK") {

        const newTransitions: ITransition[] = [...transitionsData];
        newTransitions.push(addTransition.data.data);
        settransitionsData(newTransitions);
        $("#AddNewTransitionModal").modal("hide");
        addTransition.statusText = "";
      }
    }
  }, [addTransition]);

  useEffect(() => {
    if (editTransition && transitionsData) {
      if (editTransition?.response?.statusText === "OK") {

        
        const newTransitions: ITransition[] = [...transitionsData];
        const editedTransitionId = editTransition.transitionId;
        const deletedTransitionArray: ITransition[] = newTransitions.filter((nt: ITransition) => nt.id !== parseInt(editedTransitionId));
        deletedTransitionArray.push(editTransition.editedObj);
        settransitionsData([...deletedTransitionArray]);
        $("#EditTransitionModal").modal("hide");
        editTransition.response.statusText = "";
        window.location.reload();

      }
    }
  }, [editTransition]);

  useEffect(() => {
    if (deleteTransition && transitionsData) {
      if (deleteTransition?.response?.statusText === "OK") {

        const newTransitions: ITransition[] = [...transitionsData];
        const deletedTransitionId = deleteTransition.transitionId;
        const deletedTransitionArray: ITransition[] = newTransitions.filter((nt: ITransition) => nt.id !== parseInt(deletedTransitionId));
        settransitionsData([...deletedTransitionArray]);
        $("#DeleteTransitionModal").modal("hide");
        deleteTransition.response.statusText = "";
      }
    }
  }, [deleteTransition]);

  useEffect(() => {
    setVisualizeButtonStyle();
  }, [visualizeToggle]);

  const setVisualizeButtonStyle = () => {
    if (visualizeToggle) {
      $("#visualize-button").addClass("active");
    } else {
      $("#visualize-button").removeClass("active");
    }
  };

  const renderStateList = () => {
    return (
      <div className="col-md-3 state-wrapper">
        <h4>
          {STATES_HEADER}
          <small>
            {statesData ? <span>({statesData.length})</span> : <span>{"(0)"}</span>}
            {statesData === null ? <FontAwesomeIcon icon={faSpinner} style={{ float: "right" }} className="fa-spin-custom" /> : null}
          </small>
          <div className="mt-2">
            <button
              className="add-item-btn btn btn-success"
              data-toggle="modal"
              data-target="#AddNewStateModal"
              onClick={() => {
                dispatch(sendStatesDataAction(statesData));
              }}
            >
              Add New State
            </button>
          </div>

          <div className="state-list">
            {statesData && statesData.length > 0 ? (
              sortBy(statesData, "name").map((s: IStateInner) => (
                <div className="state-item">
                  <h4>
                    {s.name}
                    {}
                    <div className="edit dropdown">
                      <a href="#" target="_self" className="main-link" data-toggle="dropdown" aria-expanded="false">
                        <FontAwesomeIcon icon={faChevronDown} />
                      </a>
                      <ul className="dropdown-menu dropdown-menu-right">
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            target="_self"
                            data-toggle="modal"
                            data-target="#EditStateModal"
                            onClick={() => {
                              dispatch(sendSelectedStateIdAction(s.id.toString()));
                              dispatch(sendStatesDataAction(s));
                            }}
                          >
                            Edit
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            target="_self"
                            data-toggle="modal"
                            data-target="#DeleteStateModal"
                            onClick={() => {
                              dispatch(sendSelectedStateIdAction(s.id.toString()));
                            }}
                          >
                            Delete
                          </a>
                        </li>
                      </ul>
                    </div>
                  </h4>

                  <div className="state-body">
                    <div className="action">
                      <span className="tlabel">Actions({s?.actionArr.length})</span>
                      <span className="detail">{(s?.actionArr).map((ai: { name: string }) => ai.name + " ")}</span>
                    </div>
                    <div className="meta">
                      <span className="tlabel">{"Description:"}</span>
                      <span className="detail">{s?.description}</span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>{"There is no state created"}</p>
            )}
          </div>
        </h4>
      </div>
    );
  };

  const renderTransitionList = () => {
    return (
      <div className="col-md-9 transition-wrapper">
        <h4>{TRANSITIONS_HEADER}</h4>
        <button className="add-item-btn btn btn-flatwet" data-toggle="modal" data-target="#AddNewTransitionModal">
          {ADD_NEW_TRANSITION}
        </button>
        <div className="transition-list">
          {transitionsData && transitionsData.length == 0 ? <p>There is no transtion created</p> : null}
          {transitionsData && transitionsData.length > 0 ? (
            <div>
              {transitionsData && transitionsData.length > 0
                ? transitionsData.map((t: ITransition) => (
                    <div className="transition-item">
                      <div className="edit dropdown">
                        <a href="#" target="_self" className="main-link " data-toggle="dropdown">
                          <FontAwesomeIcon icon={faChevronDown} />
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right">
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              target="_self"
                              data-toggle="modal"
                              data-target="#EditTransitionModal"
                              onClick={() => {
                                dispatch(sendSelectedTransitionDataAction(t));
                              }}
                            >
                              Edit
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              target="_self"
                              data-toggle="modal"
                              data-target="#DeleteTransitionModal"
                              onClick={() => {
                                dispatch(sendSelectedTransitionIdAction(t.id.toString()));
                              }}
                            >
                              Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <div className={`state to-state ${t?.fromState?.name ? "" : "no-state"}`}>
                          {<div className="name">{t?.fromState ? t.fromState?.name : "Start"}</div>}
                          {t.fromState ? <div className="actions">Actions:{t.fromState?.actionArr?.length}</div> : null}
                        </div>
                        <div className={`transition-link ${t?.event ? "" : "transition-link-all"}`}>
                          {<div className="event ">{t?.event ? t.event?.name : "Any"}</div>}
                          <div className="arrow">
                            <div className="line"></div>
                            <div className="head"></div>
                          </div>
                          <br />

                          {t?.validation ? <div className="validation ">{t?.validation}</div> : null}
                        </div>

                        <div className={`state to-state ${t?.toState?.name ? "" : "no-state"}`}>
                          {<div className="name">{t?.toState ? t?.toState?.name : "End"}</div>}
                          {t.toState ? <div className="actions">Actions:{t?.toState?.actionArr?.length}</div> : null}
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const renderScenarioDesigner = () => {
    return (
      <div className="card shadow-sm mb-1 p-3">
        <div className="card-body p-3">
          <div className="scenario-designer">
            <div className="row">
              {renderStateList()}
              {renderTransitionList()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPreviewLayout = () => {
    return (
      <div className="card shadow-sm mb-1">
        <div className="card-body p-3">
          <h5 className="card-title">
            {selectedScenario ? (
              <div>
                <div className="row">
                  <div className="col-auto mr-auto">
                    <h4>{selectedScenario.scenario.name}</h4>
                  </div>
                  <div className="col-auto">
                    {selectedScenario.status.status === "DEPLOYED" ? (
                      <div
                        style={{
                          color: "#27AE60",
                        }}
                      >
                        {selectedScenario.status.status}{" "}
                      </div>
                    ) : (
                      <div style={{ color: "#C0392B" }}>{selectedScenario.status.status}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto mr-auto">
                    <label>Last Update:</label>
                    {new Date(selectedScenario.scenario.creationDate).toLocaleString()}
                  </div>
                  <div className="col-auto">
                    {selectedScenario.lastOperationTime == null ? (
                      "N/A"
                    ) : (
                      <div>
                        <label>Deployment Date:</label>
                        <FontAwesomeIcon icon={faClock} />
                        {new Date(selectedScenario.lastOperationTime).toLocaleString()}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col md-6">
                    <label style={{ color: "#ea7e22" }}>Description</label>
                    <div>{selectedScenario.scenario.description}</div>
                  </div>
                  <div className="col md-6"></div>
                </div>
              </div>
            ) : null}
          </h5>
        </div>
      </div>
    );
  };

  const renderActionButtons = () => {
    return (
      <div className="card shadow-sm mb-1">
        <div className="card-body p-3">
          <div className="row">
            <div className="col-auto mr-auto">
              <span>
                <button
                  onClick={() => {
                    history.goBack();
                  }}
                  className="btn btn-outline-white pt-2 pb-2 pl-2 pr-2 ml-1"
                  type="button"
                >
                  <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 2 }} />
                  {"Back"}
                </button>

                <button
                  className="btn btn-outline-white dropdown-toggle pt-2 pb-2 pl-2 pr-2 ml-1"
                  type="button"
                  id="ScenarioManageDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Manage
                </button>
                <div className="dropdown-menu" aria-labelledby="ScenarioManageDropdown">
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => {
                      $("#EditScenarioModal").modal("show");
                      dispatch(sendSelectedScenarioDataAction(selectedScenario));
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => {
                      $("#DeleteScenarioModal").modal("show");
                    }}
                  >
                    Delete
                  </button>
                  <div className="dropdown-divider"></div>
                  <h3 className="dropdown-header font-weight-light">Add Scenario Items</h3>
                  <button className="dropdown-item" data-target="#AddNewStateModal" data-toggle="modal" type="button">
                    State
                  </button>
                  <button className="dropdown-item" data-target="#AddNewTransitionModal" data-toggle="modal" type="button">
                    Transition
                  </button>
                </div>
                <span>
                  <Operations />
                </span>
              </span>
            </div>
            <div className="col-auto">
              <button
                id="visualize-button"
                onClick={() => {
                  setvisualizeToggle(!visualizeToggle);
                }}
                className="btn btn-outline-white pt-2 pb-2 pl-2 pr-2 ml-1"
                type="button"
              >
                {"Visualize"}
                <FontAwesomeIcon icon={faEye} style={{ marginLeft: 2 }} />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderModals = () => {
    return (
      <>
        <GenaralModal modalId="EditScenarioModal" modalName="Edit Scenario" formComp={EditScenarioForm} />
        <GenaralModal modalId="DeleteScenarioModal" modalName="Delete Scenario" formComp={DeleteScenarioForm} />
        <GenaralModal modalId="AddNewStateModal" modalName="New State" formComp={AddNewStateForm} />
        <GenaralModal modalId="EditStateModal" modalName="Edit State" formComp={EditStateForm} />
        <GenaralModal modalId="DeleteStateModal" modalName="Delete State" size="lg" formComp={DeleteStateForm} />
        <GenaralModal modalId="AddNewTransitionModal" modalName="New Transition" formComp={AddNewTransitionForm} />
        <GenaralModal modalId="EditTransitionModal" modalName="Edit Transition" formComp={EditTransitionForm} />
        <GenaralModal modalId="DeleteTransitionModal" modalName="Delete Transition" size="lg" formComp={DeleteTransitionForm} />

      </>
    );
  };



  return (
    <div className="dashboard-main">
      <Helmet>
        <title>Herogi | Scenario Design</title>
      </Helmet>

      {renderModals()}
      {renderActionButtons()}
      {renderPreviewLayout()}
    
      {visualizeToggle ? <VisNetwork statesDataProp = {statesData} transitionsDataProp = {transitionsData} />  :renderScenarioDesigner() }
      <br />
     
    </div>
  );
};

export default ScenarioDesignPage;
