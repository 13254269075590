import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import logo from "../../assets/images/landing/herogi-logo.svg";

const GenericErrorPage = () => {
  
  const parts = window.location.href.split("/");
  var statuscode = parts[parts.length - 1];

  var description = "";

  if (statuscode === "403") {
    description = "This requested URL is forbidden.";
  } else if (statuscode === "404") {
    description = "This requested URL was not found on this server.";
  } else if (statuscode === "408") {
    description = "Request Time-Out";
  } else if (statuscode === "410") {
    description = "Gone";
  } else if (statuscode === "500") {
    description = "Internal Server Error";
  } else if (statuscode === "502") {
    description = "Bad Gateway";
  } else if (statuscode === "503") {
    description = "Service Temporarily Unavailable";
  } else if (statuscode === "504") {
    description = "Gateway Time-Out";
  } else {
    description = "Something Wrong";
  }

  return (
    <div className="bg-white">
      <div id="layoutError">
        <div id="layoutError_content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="text-center mt-4">
                  <img className="img-fluid p-4 w-75" src={logo} alt="" />
                  <p className="lead">{description}</p>
                  <Link className="text-arrow-icon" to="/">
                    <FontAwesomeIcon icon={faArrowLeft} />
                    <i className="ml-0 mr-1" data-feather="arrow-left"></i>
                    Return to Home Page
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericErrorPage;
