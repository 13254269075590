import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { RootStore } from "../../../../../redux/store/Store";
import { putScenarioAction } from "../../../../../redux/actions/scenarioActions";
import { useEffect } from "react";
import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";
import * as Yup from "yup";
import $ from 'jquery';



const AddNewScenarioForm = () => {
  const dispatch = useDispatch();

  var addNewScenarioError: any = useSelector((state: RootStore) => state.putNewScenarioReducer);

  useEffect(() => {
    $("#AddNewScenarioModalCloseIconButton").click(function () {
      $("#AddNewScenarioCloseButton")[0].click();
    });
  }, []);
  

  const handleScenarioSubmit = (bodyObject: any, formObj: any) => {
    dispatch(putScenarioAction(bodyObject, formObj));
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Scenario name is required").matches(/^\w+$/, "Scenario name should be alphanumeric"),
  });

  const formik = useFormik({
    onSubmit: () => {},
    initialValues: { name: "", description: "" },
    validationSchema: validationSchema,
    validateOnChange: true,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <div>
          <div>
            <label className="form-label" htmlFor="name">
              Scenario Name
            </label>
          </div>
          <input className="form-control" id="name" name="name" type="text" onChange={formik.handleChange} value={formik.values.name} />

          {addNewScenarioError.data !== undefined ? (
            <ErrorMessageComp name={addNewScenarioError.data.message} />
          ) : formik.errors.name  ? (
            <ErrorMessageComp name={formik.errors.name} />
          ) : null}
        </div>

        <div>
          <div className="mt-3">
            <label className="form-label" htmlFor="description">
              Description
            </label>
          </div>
          <textarea className="form-control" id="description" name="description" rows={3} onChange={formik.handleChange}  value={formik.values.description} />
          {formik.errors.description  ? <ErrorMessageComp name={formik.errors.description} /> : null}
        </div>

        <div>
          <div className="modal-footer">
            <button
              id="AddNewScenarioCloseButton"
              className="btn btn-primary p-2 mt-3"
              type="button"
              data-dismiss="modal"
              onClick={() => {
                formik.resetForm();
                if (addNewScenarioError.data) {
                  addNewScenarioError.data.message = "";
                }
              }}
            >
              Close
            </button>
            <button
              id="AddNewScenarioCreateButton"
              onClick={() => {
                handleScenarioSubmit(formik.values, formik);
              }}
              className="btn btn-success p-2 mt-3"
              type="submit"
              disabled={!(formik.isValid && formik.dirty)}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddNewScenarioForm;
