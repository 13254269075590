import { useEffect, useRef, useState } from "react";
import AceEditor from "react-ace";
import { useDispatch, useSelector } from "react-redux";
import { getEventUniqueEventParamAction } from "../../../redux/actions/EventActions";
import { useParams } from "react-router";
import { RootStore } from "../../../redux/store/Store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExpand,
  faEye,
  faRandom,
  faTerminal,
} from "@fortawesome/free-solid-svg-icons";
import dompurify from "dompurify";
import RtePreviewForm from "../design/components/forms/RtePreviewForm";
import StackModal from "../detail/components/modals/StackModal";
import { sendTemplateStringForPreview } from "../../../redux/actions/ActionActions";

import "ace-builds/src-noconflict/mode-ftl";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-min-noconflict/ext-language_tools";
import $ from 'jquery';



const RichTextEditorAction = (props: any) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  //set data from editor example
  const editorValueProp = props.editorValueProp;
  const editorsetValueProp = props.seteditorValueProp;
  /*   const [editorValue, seteditorValue] = props.functions; */
  const [editorValue, seteditorValue] = useState("");
  const [orgEditorValue, setorgEditorValue] = useState("");

  const aceEditorRef = useRef<any>();
  const [aceEditor, setaceEditor] = useState<any>(null);
  const [cursorPosition, setcursorPosition] = useState<any>(null);
  const [uniqueEventParamsData, setuniqueEventParamsData] = useState<[] | null>(
    null
  );
  const [sessionData, setsessionData] = useState<[] | null>(null);
  const [modalExpandToggle, setmodalExpandToggle] = useState(false);

  const [mathOperators, setmathOperators] = useState([
    { name: "Additive", value: "${### + ###}" },
    { name: "Subtraction", value: "${### - ###}" },
    { name: "Multiplication", value: "${### * ###}" },
    { name: "Division", value: "${### / ###}" },
    { name: "Modulo", value: "${### % ###}" },
    { name: "Abs", value: "${ x?abs }" },
    { name: "Round", value: "${ x?round }" },
    { name: "Floor", value: "${ x?floor }" },
    { name: "Ceiling", value: "${ x?ceiling }" },
  ]);

  const [booleanOperators, setbooleanOperators] = useState([
    { name: "Equals", value: "${### == ###}" },
    { name: "Not equals", value: "${### != ###}" },
    { name: "Less than", value: "${### < ###}" },
    { name: "Less than or equal to", value: "${### <= ###}" },
    { name: "Greater than", value: "${### > ###}" },
    { name: "Greater than or equal to", value: "${### >= ###}" },
    { name: "Boolean and", value: "${### && ###}" },
    { name: "Boolean or", value: "${### || ###}" },
  ]);

  const [functionsOperators, setfunctionsOperators] = useState([
    { name: "If", value: "<#if condition>...</#if>" },
    { name: "If/Else", value: "<#if condition>...<#else>...</#if>" },
    {
      name: "If/ElseIf",
      value: "<#if condition>...<#elseif condition2>...<#else>...</#if>",
    },
    { name: "List", value: "<#list sequence as item>${item}</#list>" },
  ]);

  const uniqueEventParams: any = useSelector(
    (state: RootStore) => state.getUniqueEventParamReducer
  );
  const session: any = useSelector(
    (state: RootStore) => state.sendSessionDataReducer
  );

  useEffect(() => {
    $("#PreviewEventSessionModalCloseIconButton").click(function () {
      $("#PreviewEventSessionModal").modal("hide");
    });
  }, []);

  useEffect(() => {
    $(
      "#EditActionParamModalCloseIconButton,#renderEditEditorActionParamModalContentCloseButton,#AddNewActionParamModalCloseIconButton,#renderAddButtonModalContentCloseButton"
    ).click(function () {
      editorsetValueProp(orgEditorValue);
      $("#EditActionParamModalD,#AddNewActionParamModalD")
        .removeClass("modal-dialog modal-fullscreen")
        .addClass("modal-dialog");
    });
  }, [orgEditorValue]);

  useEffect(() => {
    if (editorValueProp) {
      seteditorValue(editorValueProp);
    }
  }, [editorValueProp]);

  useEffect(() => {
    if (editorValueProp) {
      setorgEditorValue(editorValueProp);
    }
  }, []);

  useEffect(() => {
    if (aceEditorRef.current) {
      setaceEditor(aceEditorRef.current.editor);
      setcursorPosition(aceEditorRef.current.editor.getCursorPosition());
      if (editorValueProp) {
        aceEditorRef.current.editor.session.setValue(editorValueProp);
      }
      aceEditorRef.current.editor.resize();
      aceEditorRef.current.editor.session.setUseWrapMode(true);
    }
  }, [aceEditorRef]);

  useEffect(() => {
    dispatch(getEventUniqueEventParamAction(id.toString()));
  }, []);

  useEffect(() => {
    if (uniqueEventParams) {
      setuniqueEventParamsData(uniqueEventParams.data);
    }
  }, [uniqueEventParams]);

  useEffect(() => {
    if (session) {
      setsessionData(session.data);
    }
  }, [session]);

  const onChangeHandler = (e: any) => {
    editorsetValueProp(e);
  };

  const insertAtPosition = (p: string) => {
    if (aceEditorRef && cursorPosition) {
      aceEditor.session.insert(
        aceEditorRef.current.editor.getCursorPosition(),
        p
      );
    }
  };

  const addEventParam = (p: any) => {
    aceEditor.focus();
    var text: string = "${" + "event." + p.propertyName + ".value()" + "}";
    insertAtPosition(text);
  };

  const addSessionParam = (s: any) => {
    var text: string = "${" + "session." + s.propertyName + ".value()" + "}";
    insertAtPosition(text);
  };

  const addKeyValue = (m: any) => {
    var text: string = m.value;
    insertAtPosition(text);
  };

  const addValidation = (v: any) => {
    var text = "validation." + v.value;
    insertAtPosition(text);
  };

  const addCondition = (c: any) => {
    var text = c.value;
    insertAtPosition(text);
  };

  useEffect(() => {
    if (modalExpandToggle) {
      $("#EditActionParamModalD,#AddNewActionParamModalD")
        .removeClass("modal-dialog")
        .addClass("modal-dialog modal-fullscreen");

      if (aceEditorRef.current.editor) {
        /*   height="228.8px" */

        aceEditorRef.current.editor.container.style.minHeight = "600px";
        aceEditorRef.current.editor.resize();
        aceEditorRef.current.editor.session.setUseWrapMode(true);
      }
    } else {
      $("#EditActionParamModalD,#AddNewActionParamModalD")
        .removeClass("modal-dialog modal-fullscreen")
        .addClass("modal-dialog");

      $("#richbox-wrapper-id").removeClass("richbox-wrapper");
      $("#richbox-id").removeClass("richbox-fullscreen").addClass("richbox");
      $("#preview-id").removeClass("preview");

      if (aceEditorRef.current?.editor) {
        aceEditorRef.current.editor.container.style.minHeight = `228.8px`;
        setInterval(function () {
          if (aceEditorRef.current?.editor) {
            aceEditorRef.current.editor.resize();
            aceEditorRef.current.editor.session.setUseWrapMode(true);
          }
        }, 600);
      }
    }
  }, [modalExpandToggle]);

  const expandHandler = () => {
    setmodalExpandToggle(!modalExpandToggle);
  };

  const renderRichbox = () => {
    return (
      <div className="richbox">
        {!modalExpandToggle ? (
          <div className="alert alert-dark p-1 rounded-0" role="alert">
            Please click{" "}
            <FontAwesomeIcon
              style={{ marginLeft: "8px", marginRight: "8px" }}
              icon={faExpand}
            />{" "}
            button to see preview
          </div>
        ) : null}
        <div className="nav-bar">
          <div className="btn-group">
            <div className="btn-group">
              <button
                className="btn btn-default dropdown-toggle"
                data-toggle="dropdown"
              >
                E <span className="caret"></span>
              </button>
              <ul className="dropdown-menu">
                {uniqueEventParamsData
                  ? Object.values(uniqueEventParamsData).map((p: any) => (
                      <li
                        className="rte-li"
                        onClick={() => {
                          addEventParam(p);
                        }}
                      >
                        <a>{p.propertyName}</a>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
            <div className="btn-group">
              <button
                className="btn btn-default dropdown-toggle last-child"
                data-toggle="dropdown"
              >
                S <span className="caret"></span>
              </button>
              <ul className="dropdown-menu ">
                {sessionData
                  ? Object.values(sessionData).map((p: any) => (
                      <li
                        className="rte-li"
                        onClick={() => {
                          addSessionParam(p);
                        }}
                      >
                        <a>{p.propertyName}</a>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
          </div>
          <div className="btn-group">
            <div className="btn-group">
              <button className="btn btn-default " data-toggle="dropdown">
                Math
              </button>
              <ul className="dropdown-menu">
                {mathOperators
                  ? mathOperators.map((m: any) => (
                      <li
                        className="rte-li"
                        onClick={() => {
                          addKeyValue(m);
                        }}
                      >
                        <a>{m.name}</a>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
            <div className="btn-group">
              <button className="btn btn-default " data-toggle="dropdown">
                <FontAwesomeIcon icon={faRandom} />
              </button>
              <ul className="dropdown-menu">
                {booleanOperators
                  ? booleanOperators.map((b: any) => (
                      <li
                        className="rte-li"
                        onClick={() => {
                          addValidation(b);
                        }}
                      >
                        <a>{b.name}</a>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
            <div className="btn-group">
              <button
                className="btn btn-default  last-child"
                data-toggle="dropdown"
              >
                <FontAwesomeIcon icon={faTerminal} />
              </button>
              <ul className="dropdown-menu">
                {functionsOperators
                  ? functionsOperators.map((f: any) => (
                      <li
                        className="rte-li"
                        onClick={() => {
                          addCondition(f);
                        }}
                      >
                        <a>{f.name}</a>
                      </li>
                    ))
                  : null}
              </ul>
            </div>

            <div className="btn-group">
              <button
                className="btn btn-default last-child"
                onClick={() => {
                  expandHandler();
                }}
              >
                <FontAwesomeIcon icon={faExpand} />
              </button>
            </div>

            {modalExpandToggle ? (
              <div className="btn-group">
                <button
                  className="btn btn-default last-child"
                  onClick={() => {
                    dispatch(sendTemplateStringForPreview(editorValueProp));
                    $("#PreviewEventSessionModal").modal("show");
                    $("#PreviewEventSessionModal").modal({ backdrop: true });
                  }}
                >
                  <FontAwesomeIcon icon={faEye} />
                </button>
              </div>
            ) : null}
          </div>
          <AceEditor
            className="ace-rte"
            ref={aceEditorRef}
            mode="ftl"
            theme="chrome"
            name="ace-rte"
            editorProps={{ $blockScrolling: Infinity }}
            enableBasicAutocompletion={true}
            enableLiveAutocompletion={false}
            enableSnippets={true}
            onChange={(e) => onChangeHandler(e)}
            width="100%"
            height="228.8px"
          />
        </div>
        <br />
      </div>
    );
  };

  // Render editor
  return (
    <div>
      {renderRichbox()}

      <StackModal
        modalId="PreviewEventSessionModal"
        modalName="Preview Event Session Modal"
        size="xl"
        formComp={RtePreviewForm}
      />
    </div>
  );
};

export default RichTextEditorAction;
