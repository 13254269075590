import * as SegmentActionTypes from "../types/types";

export const getSegmentListReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case SegmentActionTypes.GET_SEGMENT_LIST_FAIL:
      return {
        data: action.payload,
      };
    case SegmentActionTypes.GET_SEGMENT_LIST_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const getSegmentReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case SegmentActionTypes.GET_SEGMENT_FAIL:
      return {
        data: action.payload,
      };
    case SegmentActionTypes.GET_SEGMENT_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendSegmentDataReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case SegmentActionTypes.SEND_SEGMENT_DATA_FAIL:
      return {
        data: action.payload,
      };
    case SegmentActionTypes.SEND_SEGMENT_DATA_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendSegmentIdReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case SegmentActionTypes.SEND_SEGMENT_ID_FAIL:
      return {
        data: action.payload,
      };
    case SegmentActionTypes.SEND_SEGMENT_ID_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const putSegmentReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case SegmentActionTypes.ADD_SEGMENT_FAIL:
      return {
        data: action.payload,
      };
    case SegmentActionTypes.ADD_SEGMENT_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const postSegmentReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case SegmentActionTypes.EDIT_SEGMENT_FAIL:
      return {
        data: action.payload,
      };
    case SegmentActionTypes.EDIT_SEGMENT_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};


export const deleteSegmentReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case SegmentActionTypes.DELETE_SEGMENT_FAIL:
      return {
        data: action.payload,
      };
    case SegmentActionTypes.DELETE_SEGMENT_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};