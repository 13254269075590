import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteTransitionAction } from "../../../../../redux/actions/TransitionActions";
import { RootStore } from "../../../../../redux/store/Store";
import $ from 'jquery';


const DeleteTransitionForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const selectedTransitionId: string = useSelector((state: RootStore) => state.sendTransitionIdReducer.data);

  return (
    <div>
      <p>Are you sure to delete transition? This operation is not returnable and all transition data will be deleted permanently.</p>

      <div className="modal-footer">
        <button id="DeleteTransitionModalCloseButton" className="btn btn-primary p-2 mt-3" type="button" data-dismiss="modal" onClick={()=>{$("#visualize-toggle-button").click()}}>
          Cancel
        </button>
        <button
          id="DeleteTranstionModalSaveButton"
          onClick={() => {
            dispatch(deleteTransitionAction(id, selectedTransitionId));
          }}
          className="btn btn-danger p-2 mt-3"
          type="button"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteTransitionForm;
