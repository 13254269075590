import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { RootStore } from "../../../../../redux/store/Store";
import { postStateAction } from "../../../../../redux/actions/StateActions";
import { IActionMap, IStateError, IStateInner } from "../../../types/stateTypes";
import Select, { components } from "react-select";
import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";
import $ from 'jquery';


const EditStateForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const actionMap: IActionMap = useSelector((state: RootStore) => state.sendActionMapReducer.data);
  const editStateError: IStateError = useSelector((state: RootStore) => state.postStateReducer.data);
  const editState: IStateInner = useSelector((state: RootStore) => state.sendStatesDataReducer.data);
  const editStateId: string = useSelector((state: RootStore) => state.sendStateIdReducer.data);

  const [stateName, setstateName] = useState<string>("");
  const [stateDescription, setstateDescription] = useState<string>("");
  const [editStateEData, setEditStateEData] = useState<string | null>(null);
  const [actionMapOptions, setactionMapOptions] = useState<any>([]);
  const [initialActions, setinitialActions] = useState<any>([]);
  const [valueActions, setvalueActions] = useState<any>([]);

  useEffect(() => {
    if (editState) {
      setstateName("");
      setstateDescription("");
      setstateName(editState?.name);
      setstateDescription(editState?.description);
    }
  }, [editState]);

  useEffect(() => {
    if (editState?.actionArr) {
      const newInitialActions: any = [];
      setinitialActions([]);
      setvalueActions([]);
      Object.values(editState?.actionArr).map((es: any) => {
        newInitialActions.push({ value: es.id, label: es.name, template: es.template.name });
        setinitialActions([...newInitialActions]);
        setvalueActions([...newInitialActions]);
      });
    }
  }, [editState?.actionArr]);

  useEffect(() => {
    if (actionMap) {
      const newActionMapOptions: any = [];
      Object.values(actionMap).map((ai: any) => {
        newActionMapOptions.push({ value: ai.id, label: ai.name, template: ai.template.name });
      });
      setactionMapOptions([...newActionMapOptions]);
    }
  }, [actionMap]);

  useEffect(() => {
    if (editStateError) {
      if (editStateError.message) {
        setEditStateEData(editStateError.message);
      }
    }
  }, [editStateError]);

  useEffect(() => {
    $("#EditStateModalCloseIconButton").click(function () {
      $("#editStateCloseButton")[0].click();
    });
  }, []);

  const handleSubmitState = () => {
    const bodyObj: any = {
      actions: [],
      description: "",
      name: "",
    };
    valueActions.map((ia: any) => {
      bodyObj.actions.push(ia.value);
    });
    bodyObj.name = stateName;
    bodyObj.description = stateDescription;

    dispatch(postStateAction(bodyObj, id, editStateId));
  };

  const handleStateNameOnChange = (e: any) => {
    if (e.target.value === "") {
      setEditStateEData("State name is required");
    } else {
      setEditStateEData("");
    }
    setstateName(e.target.value);
  };
  const handleStateDescriptionOnChange = (e: any) => {
    setstateDescription(e.target.value);
  };

  const handleStateMultiSelect = (e: any) => {
    setvalueActions(e);
  };

  const CustomOption = (innerProps: any) => (
    <components.Option {...innerProps}>
      <div>{innerProps.data.label}</div>
      <small>{innerProps.data.template}</small>
    </components.Option>
  );

  return (
    <div>
      <div>
        <div>
          <label className="form-label" htmlFor="eventName">
            State Name
          </label>
        </div>
        <input className="form-control" id="name" name="name" type="text" onChange={(e) => handleStateNameOnChange(e)} value={stateName} />

        {editStateEData ? <ErrorMessageComp name={editStateEData} /> : null}
      </div>

      <div>
        <div className="mt-3">
          <label className="form-label" htmlFor="eventType">
            Actions
          </label>
        </div>

        <Select
          value={valueActions.map((ia: any) => ia)}
          isMulti={true}
          name="actions"
          onChange={handleStateMultiSelect}
          options={actionMapOptions}
          components={{ Option: CustomOption }}
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </div>

      <div>
        <div className="mt-3">
          <label className="form-label" htmlFor="description">
            Description
          </label>
        </div>
        <textarea className="form-control" id="description" name="description" rows={3} onChange={(e) => handleStateDescriptionOnChange(e)} value={stateDescription} />
      </div>

      <div>
        <div className="modal-footer">
          <button
            id="editStateCloseButton"
            className="btn btn-primary p-2 mt-3"
            type="button"
            data-dismiss="modal"
            onClick={() => {
              setEditStateEData("");
              if (editState) {
                setstateName(editState?.name);
                setstateDescription(editState?.description);
              }
              setvalueActions([...initialActions]);
              $("#visualize-toggle-button").click()
            }}
          >
            Close
          </button>
          <button
            id="editStateCreateButton"
            onClick={() => {
              handleSubmitState();
            }}
            className="btn btn-success p-2 mt-3"
            type="submit"
            disabled={editStateEData ? true : false}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditStateForm;
