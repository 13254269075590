import React from "react";
import Select from "react-select";
import "../segment.css";
import { useEffect, useRef, useState } from "react";
import { IMetaData, IFilterDef, FilterDef, IExpression, IFilter, IRule, FilterUtils } from "../../../../services/SegmentService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross, faPlus, faTachometerAlt, faThumbsDown, faXmark, faXmarkSquare } from "@fortawesome/free-solid-svg-icons";
import { boolean } from "yup";



export default class RuleComponent extends React.Component<{addRule: any, removeRule: any, ruleChange: any, rule: IRule, filter: IFilter, meta: IMetaData[], isLast: boolean}, {segmentMeta : IMetaData, filterDef: IFilterDef, expressionKey: string, expression: IExpression, operand: string, value: string}> {
        
    constructor(props: any) {
        super(props);

        console.log(this.props.rule);

        if(this.props.rule.data != null) {

            this.state = {
                segmentMeta : this.props.rule.data.segmentMeta!,
                filterDef: this.props.rule.data.filterDef!,
                expressionKey: this.props.rule.data.expressionKey!,
                expression: this.props.rule.data.filterDef.expressions.filter(e => e.key == this.props.rule.data.expressionKey)[0]!,
                operand: this.props.rule.data.operand!,
                value: this.props.rule.data.value!
            }
        } else {

            this.state = {
                segmentMeta : null!,
                filterDef: null!,
                expressionKey: null!,
                expression: null!,
                operand: null!,
                value: null!
            }
        }
    }

    ruleIdExist = () => this.props.rule.ruleId != null
    
    addNewRule = () => {
        //this.props.addRule(this.state);    
    }
    
    change = (event: any) => {
        console.log(event);
        const selectedKey = this.props.meta.find(m => m.source == event.source);
        const selectedFilterDef = selectedKey?.filterDef.find(f => f.key == event.value);

        let expression;
        let expressionKey;
        if(selectedFilterDef?.key != "customAttributes") {
            expression = selectedFilterDef?.expressions[0]!;
            expressionKey = expression.key;
        }
        this.setState({segmentMeta: selectedKey!, filterDef: selectedFilterDef!, expression: expression, expressionKey: expressionKey, operand: null!, value: null!});
    }

    changeRuleData = (key, value) => {
        this.setState(state => {
             state[key] =  value;
             return state;
        },() => {
            this.props.ruleChange(this.props.rule, this.state);
        });
    }
    
    chooseFilterDef = (event: any) => {
        const selectedFilterDef = this.state.segmentMeta.filterDef.find(fd => fd.key == event.target.value)!;
        //console.log(selectedFilterDef);
        this.setState({filterDef: selectedFilterDef});
    }
 
    toSegmentOption = () => {
        let options = this.props.meta.map((m: IMetaData) => {
            const label = m.readableName;
            if(m.source == "customer") { 
                
                const subOptions = m.filterDef.map(f => {
                    return {source : m.source, type : m.type, label : f.readableName, value: f.key} 
                });
                
                return { label : label, options : subOptions};
            } else {
                return { label : label, value : m.source};
            }
        });
        
        return options;
    }

    renderReadOnlyFilter = () => {

        let subFilter;

        if(this.props.rule.data.expressionKey != "value") {
           subFilter = this.props.rule.data.filterDef.expressions.filter(e => e.key == this.props.rule.data.expressionKey)[0].readableName;     
        } else {
           subFilter = "";     
        }

        return (
            <div className="segment-readonly">
                <div className="main font-weight-bold">
                    <span>User Attributes</span>
                    <span>/</span>
                    <span>{this.props.rule.data.filterDef.readableName}</span>
                 </div>
                 { subFilter != "" ?          
                        <div className="font-weight-bold">
                            <span>{subFilter}</span>
                        </div>
                    : ""
                    
                 } 
            </div>
        );

        return "Selected Filter:" + this.props.rule.data.filterDef.readableName + " / " + subFilter;
 
    }

    render() {
        
        let filterDefDetail;
        
        if (this.state.filterDef != null && this.state.filterDef.key == "customAttributes") {
            filterDefDetail = <div></div>
            let subFilterDef;
            
            if(this.ruleIdExist() == false) {
            
              subFilterDef =  <Select className="col-sm-4" onChange ={e => {this.setState(state => ({...state, expression : this.state.filterDef.expressions.find(_e => _e.key == e?.value)!}));
                    }} options={this.state.filterDef.expressions.map(e => ({"label" : e.readableName, "value" : e.key}))} 
                />
            
            } else {
               
                subFilterDef =  <Select className="col-sm-4" onChange ={e => {this.setState(state => ({...state, expression : this.state.filterDef.expressions.find(_e => _e.key == e?.value)!}));
                    }} 
                    options={this.state.filterDef.expressions.map(e => ({"label" : e.readableName, "value" : e.key}))}
                    value={{"label" : this.props.rule.data.expressionKey, "value" : this.props.rule.data.expressionKey}} 
                /> 
                    
            }

            let filterDefOperand;
            let filterDefOption; 
            if(this.state.expression != null) {

                if(this.ruleIdExist() == false) {
                    filterDefOperand =  <Select className="col-sm-3" onChange ={e => this.changeRuleData("operand", e?.value)} options={this.state.expression.opList.map(o => ({"label" : o, "value" : o}))} 
                                            placeholder = "Operand"
                                        />
                } else {
                    filterDefOperand =  <Select className="col-sm-3" onChange ={e => this.changeRuleData("operand", e?.value)} options={this.state.expression.opList.map(o => ({"label" : o, "value" : o}))} 
                                            placeholder = "Operand"
                                            value={{label : this.props.rule.data.operand, value : this.props.rule.data.operand}}
                                        />    
                }

                filterDefOption = <input className="col-sm-4 form-control" onChange={e => this.changeRuleData("value", e.target.value)} type="text" value={this.state.value}></input>
                                
            }
                    
            filterDefDetail = 
            <div className="col-sm-8">
                <div className="row">
                    {subFilterDef}
                    {filterDefOperand}
                    {filterDefOption}
                </div>
            </div>
        } 
        
        else if(this.state.filterDef != null && this.state.filterDef.key != "customAttributes") {
            let ex = this.state.filterDef.expressions[0];
            let filterDefOperand;
            if(this.ruleIdExist() == false) {
                filterDefOperand = <Select className="col-sm-3" onChange ={e => this.changeRuleData("operand", e?.value)} options={ex.opList.map(o => ({"label" : o, "value" : o}))} 
                      placeholder = "Operand"
                />

            } else {
                filterDefOperand = <Select className="col-sm-3" onChange ={e => this.changeRuleData("operand", e?.value)} options={ex.opList.map(o => ({"label" : o, "value" : o}))} 
                      value={{label : this.props.rule.data.operand, value : this.props.rule.data.operand}}  placeholder = "Operand"
                />
            }
                    
            let filterDefOption: JSX.Element;
                        
            if(ex.options.length > 0) {
                
                if(this.ruleIdExist() == false) {
                filterDefOption =   
                    <Select className="col-sm-4" onChange ={e => this.changeRuleData("value", e?.value)} options={ex.options.map(o => ({"label" : o.readableName, "value" : o.key}))} 
                        placeholder = "Choose a value"
                    />         
                    
                } else {

                    let selectedVal = ex.options.filter(o => o.key == this.props.rule.data.value).map(o => {
                        return {
                            label : o.readableName,
                            value : o.key
                        }
                    })[0]!;

                    filterDefOption = 
                    <Select className="col-sm-4" onChange ={e => this.changeRuleData("value", e?.value)} 
                        options={ex.options.map(o => ({"label" : o.readableName, "value" : o.key}))} 
                       value={selectedVal} placeholder = "Choose a value"
                    /> 
                }
            } else {
                filterDefOption = <input className="col-sm-4 form-control" onChange={e => this.changeRuleData("value", e.target.value)} type="text" value={this.state.value}></input>                        
            }
                                
            filterDefDetail = 
                <div className="col-sm-8">
                    <div className="row">
                        {filterDefOperand}
                        {filterDefOption}
                    </div>
                </div>
        }
                                
        return (
                <div className="row">
                    <div className="col-sm-10">
                        <div className="row">
                            <div className="col-sm-3">
                            {   this.ruleIdExist() == false ? 
                                <Select
                                onChange = {this.change} options={this.toSegmentOption()}
                                        placeholder = "Choose a filter"
                                /> : this.renderReadOnlyFilter()
                            
                            }
                            </div>
                            {filterDefDetail}
                        </div>
                    </div>
                    <div className="col-sm-2" style={{textAlign:"right"}}>
                        
                           
                        {this.props.isLast ? 
                            <button onClick={(e) => this.props.addRule(this.props.filter, this.state)} className="btn btn-green btn-sm" type="button">
                                <FontAwesomeIcon icon={faPlus} />
                                <span style={{width:"40px"}}>OR</span>
                            </button>  : "" }   
                            
                        { FilterUtils.nonEmptyRules(this.props.filter).length > 1 ? 

                            <button style={{marginLeft:"10px"}} onClick={e => this.props.removeRule(this.props.filter ,this.props.rule)} className="btn-delete btn" type="button">
                                <FontAwesomeIcon icon={faXmark} size="1x" />
                            </button>
                           : ""
                        }
                    </div>                    
                </div>
                );
    }
}