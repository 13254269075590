import * as InitialStates from "../store/InitialStates";
import * as ValidationActionTypes from "../types/types";

export const getValidationOperandReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case ValidationActionTypes.GET_VALIDATION_OPERAND_FAIL:
      return {
        data: action.payload,
      };
    case ValidationActionTypes.GET_VALIDATION_OPERAND_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const putValidationReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case ValidationActionTypes.ADD_VALIDATION_FAIL:
      return {
        data: action.payload,
      };
    case ValidationActionTypes.ADD_VALIDATION_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const postValidationReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case ValidationActionTypes.EDIT_VALIDATION_FAIL:
      return {
        data: action.payload,
      };
    case ValidationActionTypes.EDIT_VALIDATION_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const deleteValidationReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case ValidationActionTypes.DELETE_VALIDATION_FAIL:
      return {
        data: action.payload,
      };
    case ValidationActionTypes.DELETE_VALIDATION_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendSelectedValidationDataReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case ValidationActionTypes.SEND_VALIDATION_DATA_FAIL:
      return {
        data: action.payload,
      };
    case ValidationActionTypes.SEND_VALIDATION_DATA_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendSelectedValidationIdReducer = (state: any = "", action: any) => {
  switch (action.type) {
    case ValidationActionTypes.SEND_VALIDATION_ID_FAIL:
      return {
        data: action.payload,
      };
    case ValidationActionTypes.SEND_VALIDATION_ID_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};


export const sendValidationMapDataReducer = (state: any = [], action: any) => {
  switch (action.type) {
    case ValidationActionTypes.SEND_VALIDATION_MAP_DATA_FAIL:
      return {
        data: action.payload,
      };
    case ValidationActionTypes.SEND_VALIDATION_MAP_DATA_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};
