export const GET_SCENARIO_LIST_LOADING = "GET_SCENARIO_LIST_LOADING";
export const GET_SCENARIO_LIST_SUCCESS = "GET_SCENARIO_LIST_SUCCESS";
export const GET_SCENARIO_LIST_FAIL = "GET_SCENARIO_LIST_FAIL";

export const GET_SINGLE_SCENARIO_LIST_LOADING = "GET_SINGLE_SCENARIO_LIST_LOADING";
export const GET_SINGLE_SCENARIO_LIST_SUCCESS = "GET_SINGLE_SCENARIO_LIST_SUCCESS";
export const GET_SINGLE_SCENARIO_LIST_FAIL = "GET_SINGLE_SCENARIO_LIST_FAIL";

export const ADD_NEW_SCENARIO_LOADING = "ADD_NEW_SCENARIO_LOADING";
export const ADD_NEW_SCENARIO_SUCCESS = "ADD_NEW_SCENARIO_SUCCESS";
export const ADD_NEW_SCENARIO_FAIL = "ADD_NEW_SCENARIO_FAIL";

export const EDIT_SCENARIO_LOADING = "EDIT_SCENARIO_LOADING";
export const EDIT_SCENARIO_SUCCESS = "EDIT_SCENARIO_SUCCESS";
export const EDIT_SCENARIO_FAIL = "EDIT_SCENARIO_FAIL";

export const DELETE_SCENARIO_LOADING = "DELETE_SCENARIO_LOADING";
export const DELETE_SCENARIO_SUCCESS = "DELETE_SCENARIO_SUCCESS";
export const DELETE_SCENARIO_FAIL = "DELETE_SCENARIO_FAIL";

export const SEND_SCENARIO_LOADING = "SEND_SCENARIO_LOADING";
export const SEND_SCENARIO_SUCCESS = "SEND_SCENARIO_SUCCESS";
export const SEND_SCENARIO_FAIL = "SEND_SCENARIO_FAIL";

export const ADD_NEW_EVENT_LOADING = "ADD_NEW_EVENT_LOADING";
export const ADD_NEW_EVENT_FAIL = "ADD_NEW_EVENT_FAIL";
export const ADD_NEW_EVENT_SUCCESS = "ADD_NEW_EVENT_SUCCESS";

export const EDIT_EVENT_LOADING = "EDIT_EVENT_LOADING";
export const EDIT_EVENT_FAIL = "EDIT_EVENT_FAIL";
export const EDIT_EVENT_SUCCESS = "EDIT_EVENT_SUCCESS";

export const DELETE_EVENT_LOADING = "DELETE_EVENT_LOADING";
export const DELETE_EVENT_FAIL = "DELETE_EVENT_FAIL";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";

export const ADD_EVENT_PARAM_LOADING = "ADD_EVENT_PARAM_LOADING";
export const ADD_EVENT_PARAM_FAIL = "ADD_EVENT_PARAM_FAIL";
export const ADD_EVENT_PARAM_SUCCESS = "ADD_EVENT_PARAM_SUCCESS";

export const EDIT_EVENT_PARAM_LOADING = "EDIT_EVENT_PARAM_LOADING";
export const EDIT_EVENT_PARAM_FAIL = "EDIT_EVENT_PARAM_FAIL";
export const EDIT_EVENT_PARAM_SUCCESS = "EDIT_EVENT_PARAM_SUCCESS";

export const DELETE_EVENT_PARAM_LOADING = "DELETE_EVENT_PARAM_LOADING";
export const DELETE_EVENT_PARAM_FAIL = "DELETE_EVENT_PARAM_FAIL";
export const DELETE_EVENT_PARAM_SUCCESS = "DELETE_EVENT_PARAM_SUCCESS";

export const EDIT_EVENT_SESSION_IDENTIFIER_LOADING = "EDIT_EVENT_SESSION_IDENTIFIER_LOADING";
export const EDIT_EVENT_SESSION_IDENTIFIER_FAIL = "EDIT_EVENT_SESSION_IDENTIFIER_FAIL";
export const EDIT_EVENT_SESSION_IDENTIFIER_SUCCESS = "EDIT_EVENT_SESSION_IDENTIFIER_SUCCESS";

export const SEND_EVENT_PARAMS_LOADING = "SEND_EVENT_PARAMS_LOADING";
export const SEND_EVENT_PARAMS_FAIL = "SEND_EVENT_PARAMS_FAIL";
export const SEND_EVENT_PARAMS_SUCCESS = "SEND_EVENT_PARAMS_SUCCESS";

export const SEND_EVENT_DATA_LOADING = "SEND_EVENT_DATA_LOADING";
export const SEND_EVENT_DATA_FAIL = "SEND_EVENT_DATA_FAIL";
export const SEND_EVENT_DATA_SUCCESS = "SEND_EVENT_DATA_SUCCESS";

export const SEND_EVENTMAP_DATA_LOADING = "SEND_EVENTMAP_DATA_LOADING";
export const SEND_EVENTMAP_DATA_FAIL = "SEND_EVENTMAP_DATA_FAIL";
export const SEND_EVENTMAP_DATA_SUCCESS = "SEND_EVENTMAP_DATA_SUCCESS";

export const SEND_SELECTED_EVENT_DATA_LOADING = "SEND_SELECTED_EVENT_DATA_LOADING";
export const SEND_SELECTED_EVENT_DATA_FAIL = "SEND_SELECTED_EVENT_DATA_FAIL";
export const SEND_SELECTED_EVENT_DATA_SUCCESS = "SEND_EVENTMAP_DATA_SUCCESS";

export const GET_EVENTS = "GET_EVENTS";
export const GET_SELECTED_EVENT = "GET_SELECTED_EVENT";

export const SEND_SCENARIO_ID_LOADING = "SEND_SCENARIO_ID_LOADING";
export const SEND_SCENARIO_ID_SUCCESS = "SEND_SCENARIO_ID_SUCCESS";
export const SEND_SCENARIO_ID_FAIL = "SEND_SCENARIO_ID_FAIL";

export const SEND_SCENARIO_NAME_LIST_LOADING = "SEND_SCENARIO_NAME_LIST_LOADING";
export const SEND_SCENARIO_NAME_LIST_SUCCESS = "SEND_SCENARIO_NAME_LIST_SUCCESS";
export const SEND_SCENARIO_NAME_LIST_FAIL = "SEND_SCENARIO_LIST_FAIL";

export const SEND_EVENT_ID_LOADING = "SEND_EVENT_ID_LOADING";
export const SEND_EVENT_ID_SUCCESS = "SEND_EVENT_ID_SUCCESS";
export const SEND_EVENT_ID_FAIL = "SEND_EVENT_ID_FAIL";

export const SEND_EVENT_PARAM_ID_LOADING = "SEND_EVENT_PARAM_ID_LOADING";
export const SEND_EVENT_PARAM_ID_SUCCESS = "SEND_EVENT_PARAM_ID_SUCCESS";
export const SEND_EVENT_PARAM_ID_FAIL = "SEND_EVENT_PARAM_ID_FAIL";

export const SEND_EVENT_LOADING = "SEND_EVENT_LOADING";
export const SEND_EVENT_SUCCESS = "SEND_EVENT_SUCCESS";
export const SEND_EVENT_FAIL = "SEND_EVENT_FAIL";

export const RE_DEPLOY_LOADING = "RE_DEPLOY_LOADING";
export const RE_DEPLOY_SUCCESS = "RE_DEPLOY_SUCCESS";
export const RE_DEPLOY_FAIL = "RE_DEPLOY_FAIL";

export const DEPLOY_LOADING = "DEPLOY_LOADING";
export const DEPLOY_SUCCESS = "DEPLOY_SUCCESS";
export const DEPLOY_FAIL = "DEPLOY_FAIL";

export const UN_DEPLOY_LOADING = "UN_DEPLOY_LOADING";
export const UN_DEPLOY_SUCCESS = "UN_DEPLOY_SUCCESS";
export const UN_DEPLOY_FAIL = "UN_DEPLOY_FAIL";

export const FREEZE_LOADING = "FREEZE_LOADING";
export const FREEZE_SUCCESS = "FREEZE_SUCCESS";
export const FREEZE_FAIL = "FREEZE_FAIL";

export const RESUME_LOADING = "RESUME_LOADING";
export const RESUME_SUCCESS = "RESUME_SUCCESS";
export const RESUME_FAIL = "RESUME_FAIL";

export const ADD_SESSION_LOADING = "ADD_SESSION_LOADING";
export const ADD_SESSION_SUCCESS = "ADD_SESSION_SUCCESS";
export const ADD_SESSION_FAIL = "ADD_SESSION_FAIL";

export const EDIT_SESSION_LOADING = "EDIT_SESSION_LOADING";
export const EDIT_SESSION_SUCCESS = "EDIT_SESSION_SUCCESS";
export const EDIT_SESSION_FAIL = "EDIT_SESSION_FAIL";

export const DELETE_SESSION_LOADING = "DELETE_SESSION_LOADING";
export const DELETE_SESSION_SUCCESS = "DELETE_SESSION_SUCCESS";
export const DELETE_SESSION_FAIL = "DELETE_SESSION_FAIL";

export const SEND_SELECTED_SESSION_DATA_LOADING = "SEND_SELECTED_SESSION_DATA_LOADING";
export const SEND_SELECTED_SESSION_DATA_FAIL = "SEND_SELECTED_SESSION_DATA_FAIL";
export const SEND_SELECTED_SESSION_DATA_SUCCESS = "SEND_EVENTMAP_DATA_SUCCESS";

export const SEND_SELECTED_SESSION_ID_LOADING = "SEND_SELECTED_SESSION_ID_LOADING";
export const SEND_SELECTED_SESSION_ID_FAIL = "SEND_SELECTED_SESSION_ID_FAIL";
export const SEND_SELECTED_SESSION_ID_SUCCESS = "SEND_SELECTED_SESSION_ID_SUCCESS";

export const SEND_SELECTED_EVENT_SESSION_ID_LOADING = "SEND_SELECTED_EVENT_SESSION_ID_LOADING";
export const SEND_SELECTED_EVENT_SESSION_ID_FAIL = "SEND_SELECTED_EVENT_SESSION_ID_FAIL";
export const SEND_SELECTED_EVENT_SESSION_ID_SUCCESS = "SEND_SELECTED_EVENT_SESSION_ID_SUCCESS";

export const DEPLOY_SCENARIO_LOADING = "DEPLOY_SCENARIO_LOADING";
export const DEPLOY_SCENARIO_FAIL = "DEPLOY_SCENARIO_FAIL";
export const DEPLOY_SCENARIO_SUCCESS = "DEPLOY_SCENARIO_SUCCESS";

export const RE_DEPLOY_SCENARIO_LOADING = "RE_DEPLOY_SCENARIO_LOADING";
export const RE_DEPLOY_SCENARIO_FAIL = "RE_DEPLOY_SCENARIO_FAIL";
export const RE_DEPLOY_SCENARIO_SUCCESS = "RE_DEPLOY_SCENARIO_SUCCESS";

export const UN_DEPLOY_SCENARIO_LOADING = "UN_DEPLOY_SCENARIO_LOADING";
export const UN_DEPLOY_SCENARIO_FAIL = "UN_DEPLOY_SCENARIO_FAIL";
export const UN_DEPLOY_SCENARIO_SUCCESS = "UN_DEPLOY_SCENARIO_SUCCESS";

export const FREEZE_SCENARIO_LOADING = "FREEZE_SCENARIO_LOADING";
export const FREEZE_SCENARIO_FAIL = "FREEZE_SCENARIO_FAIL";
export const FREEZE_SCENARIO_SUCCESS = "FREEZE_SCENARIO_SUCCESS";

export const RESUME_SCENARIO_LOADING = "RESUME_SCENARIO_LOADING";
export const RESUME_SCENARIO_FAIL = "RESUME_SCENARIO_FAIL";
export const RESUME_SCENARIO_SUCCESS = "RESUME_SCENARIO_SUCCESS";

export const DEPLOY_CAMPAIGN_LOADING = "DEPLOY_CAMPAIGN_LOADING";
export const DEPLOY_CAMPAIGN_FAIL = "DEPLOY_CAMPAIGN_FAIL";
export const DEPLOY_CAMPAIGN_SUCCESS = "DEPLOY_CAMPAIGN_SUCCESS";

export const RE_DEPLOY_CAMPAIGN_LOADING = "RE_DEPLOY_CAMPAIGN_LOADING";
export const RE_DEPLOY_CAMPAIGN_FAIL = "RE_DEPLOY_CAMPAIGN_FAIL";
export const RE_DEPLOY_CAMPAIGN_SUCCESS = "RE_DEPLOY_CAMPAIGN_SUCCESS";

export const UN_DEPLOY_CAMPAIGN_LOADING = "UN_DEPLOY_CAMPAIGN_LOADING";
export const UN_DEPLOY_CAMPAIGN_FAIL = "UN_DEPLOY_CAMPAIGN_FAIL";
export const UN_DEPLOY_CAMPAIGN_SUCCESS = "UN_DEPLOY_CAMPAIGN_SUCCESS";

export const FREEZE_CAMPAIGN_LOADING = "FREEZE_CAMPAIGN_LOADING";
export const FREEZE_CAMPAIGN_FAIL = "FREEZE_CAMPAIGN_FAIL";
export const FREEZE_CAMPAIGN_SUCCESS = "FREEZE_CAMPAIGN_SUCCESS";

export const RESUME_CAMPAIGN_LOADING = "RESUME_CAMPAIGN_LOADING";
export const RESUME_CAMPAIGN_FAIL = "RESUME_CAMPAIGN_FAIL";
export const RESUME_CAMPAIGN_SUCCESS = "RESUME_CAMPAIGN_SUCCESS";

export const GET_SCENARIO_STATUS_LOADING = "GET_SCENARIO_STATUS_LOADING";
export const GET_SCENARIO_STATUS_FAIL = "GET_SCENARIO_STATUS_FAIL";
export const GET_SCENARIO_STATUS_SUCCESS = "GET_SCENARIO_STATUS_SUCCESS";

export const GET_TEMPLATE_LOADING = "GET_TEMPLATE_LOADING";
export const GET_TEMPLATE_FAIL = "GET_TEMPLATE_FAIL";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";

export const GET_INTEGRATION_VENDORS_LOADING = "GET_INTEGRATION_VENDORS_LOADING";
export const GET_INTEGRATION_VENDORS_FAIL = "GET_INTEGRATION_VENDORS_FAIL";
export const GET_INTEGRATION_VENDORS_SUCCESS = "GET_INTEGRATION_VENDORS_SUCCESS";

export const SEND_ACTION_DATA_LOADING = "SEND_ACTION_DATA_LOADING";
export const SEND_ACTION_DATA_FAIL = "SEND_ACTION_DATA_FAIL";
export const SEND_ACTION_DATA_SUCCESS = "SEND_ACTION_DATA_SUCCESS";

export const SEND_ACTION_ID_LOADING = "SEND_ACTION_ID_LOADING";
export const SEND_ACTION_ID_FAIL = "SEND_ACTION_ID_FAIL";
export const SEND_ACTION_ID_SUCCESS = "SEND_ACTION_ID_SUCCESS";

export const SEND_VALIDATION_DATA_LOADING = "SEND_VALIDATION_DATA_LOADING";
export const SEND_VALIDATION_DATA_FAIL = "SEND_VALIDATION_DATA_FAIL";
export const SEND_VALIDATION_DATA_SUCCESS = "SEND_VALIDATION_DATA_SUCCESS";

export const SEND_VALIDATION_ID_LOADING = "SEND_VALIDATION_ID_LOADING";
export const SEND_VALIDATION_ID_FAIL = "SEND_VALIDATION_ID_FAIL";
export const SEND_VALIDATION_ID_SUCCESS = "SEND_VALIDATION_ID_SUCCESS";

export const GET_VALIDATION_OPERAND_LOADING = "GET_VALIDATION_OPERAND_LOADING";
export const GET_VALIDATION_OPERAND_FAIL = "GET_VALIDATION_OPERAND_FAIL";
export const GET_VALIDATION_OPERAND_SUCCESS = "GET_VALIDATION_OPERAND_SUCCESS";

export const ADD_VALIDATION_LOADING = "ADD_VALIDATION_LOADING";
export const ADD_VALIDATION_FAIL = "ADD_VALIDATION_FAIL";
export const ADD_VALIDATION_SUCCESS = "ADD_VALIDATION_SUCCESS";

export const EDIT_VALIDATION_LOADING = "EDIT_VALIDATION_LOADING";
export const EDIT_VALIDATION_FAIL = "EDIT_VALIDATION_FAIL";
export const EDIT_VALIDATION_SUCCESS = "EDIT_VALIDATION_SUCCESS";

export const DELETE_VALIDATION_LOADING = "DELETE_VALIDATION_LOADING";
export const DELETE_VALIDATION_FAIL = "DELETE_VALIDATION_FAIL";
export const DELETE_VALIDATION_SUCCESS = "DELETE_VALIDATION_SUCCESS";

export const ADD_ACTION_LOADING = "ADD_ACTION_LOADING";
export const ADD_ACTION_FAIL = "ADD_ACTION_FAIL";
export const ADD_ACTION_SUCCESS = "ADD_ACTION_SUCCESS";

export const EDIT_ACTION_LOADING = "EDIT_ACTION_LOADING";
export const EDIT_ACTION_FAIL = "EDIT_ACTION_FAIL";
export const EDIT_ACTION_SUCCESS = "EDIT_ACTION_SUCCESS";

export const DELETE_ACTION_LOADING = "DELETE_ACTION_LOADING";
export const DELETE_ACTION_FAIL = "DELETE_ACTION_FAIL";
export const DELETE_ACTION_SUCCESS = "DELETE_ACTION_SUCCESS";

export const ADD_ACTION_PARAM_LOADING = "ADD_ACTION_PARAM_LOADING";
export const ADD_ACTION_PARAM_FAIL = "ADD_ACTION_PARAM_FAIL";
export const ADD_ACTION_PARAM_SUCCESS = "ADD_ACTION_PARAM_SUCCESS";

export const EDIT_ACTION_PARAM_LOADING = "EDIT_ACTION_PARAM_LOADING";
export const EDIT_ACTION_PARAM_FAIL = "EDIT_ACTION_PARAM_FAIL";
export const EDIT_ACTION_PARAM_SUCCESS = "EDIT_ACTION_PARAM_SUCCESS";

export const DELETE_ACTION_PARAM_LOADING = "DELETE_ACTION_PARAM_LOADING";
export const DELETE_ACTION_PARAM_FAIL = "DELETE_ACTION_PARAM_FAIL";
export const DELETE_ACTION_PARAM_SUCCESS = "DELETE_ACTION_PARAM_SUCCESS";

export const RETURN_ACTION_SET_LOADING = "RETURN_ACTION_SET_LOADING";
export const RETURN_ACTION_SET_FAIL = "RETURN_ACTION_SET_FAIL";
export const RETURN_ACTION_SET_SUCCESS = "RETURN_ACTION_SET_SUCCESS";

export const SEND_ACTION_PARAM_ID_LOADING = "SEND_ACTION_PARAM_ID_LOADING";
export const SEND_ACTION_PARAM_ID_FAIL = "SEND_ACTION_PARAM_ID_FAIL";
export const SEND_ACTION_PARAM_ID_SUCCESS = "SEND_ACTION_PARAM_ID_SUCCESS";

export const ACTION_RETURNS_LOADING = "ACTION_RETURNS_LOADING";
export const ACTION_RETURNS_FAIL = "ACTION_RETURNS_FAIL";
export const ACTION_RETURNS_SUCCESS = "ACTION_RETURNS_SUCCESS";

export const SEND_ACTION_PARAM_DATA_LOADING = "SEND_ACTION_PARAM_DATA_LOADING";
export const SEND_ACTION_PARAM_DATA_FAIL = "SEND_ACTION_PARAM_DATA_FAIL";
export const SEND_ACTION_PARAM_DATA_SUCCESS = "SEND_ACTION_PARAM_DATA_SUCCESS";

export const SEND_ACTION_PARAMS_DATA_LOADING = "SEND_ACTION_PARAMS_DATA_LOADING";
export const SEND_ACTION_PARAMS_DATA_FAIL = "SEND_ACTION_PARAMS_DATA_FAIL";
export const SEND_ACTION_PARAMS_DATA_SUCCESS = "SEND_ACTION_PARAMS_DATA_SUCCESS";

export const SEND_ACTION_TEMPLATE_PARAMS_DATA_LOADING = "SEND_ACTION_TEMPLATE_PARAMS_DATA_LOADING";
export const SEND_ACTION_TEMPLATE_PARAMS_DATA_FAIL = "SEND_ACTION_TEMPLATE_PARAMS_DATA_FAIL";
export const SEND_ACTION_TEMPLATE_PARAMS_DATA_SUCCESS = "SEND_ACTION_TEMPLATE_PARAMS_DATA_SUCCESS";

export const GET_INTEGRATIONS_LOADING = "GET_INTEGRATIONS_LOADING";
export const GET_INTEGRATIONS_FAIL = "GET_INTEGRATIONS_FAIL";
export const GET_INTEGRATIONS_SUCCESS = "GET_INTEGRATIONS_SUCCESS";

export const ADD_INTEGRATION_LOADING = "ADD_INTEGRATION_LOADING";
export const ADD_INTEGRATION_FAIL = "ADD_INTEGRATION_FAIL";
export const ADD_INTEGRATION_SUCCESS = "ADD_INTEGRATION_SUCCESS";

export const EDIT_INTEGRATION_LOADING = "EDIT_INTEGRATION_LOADING";
export const EDIT_INTEGRATION_FAIL = "EDIT_INTEGRATION_FAIL";
export const EDIT_INTEGRATION_SUCCESS = "EDIT_INTEGRATION_SUCCESS";

export const DELETE_INTEGRATION_LOADING = "DELETE_INTEGRATION_LOADING";
export const DELETE_INTEGRATION_FAIL = "DELETE_INTEGRATION_FAIL";
export const DELETE_INTEGRATION_SUCCESS = "DELETE_INTEGRATION_SUCCESS";

export const ADD_STATE_LOADING = "ADD_STATE_LOADING";
export const ADD_STATE_FAIL = "ADD_STATE_FAIL";
export const ADD_STATE_SUCCESS = "ADD_STATE_SUCCESS";

export const EDIT_STATE_LOADING = "EDIT_STATE_LOADING";
export const EDIT_STATE_FAIL = "EDIT_STATE_FAIL";
export const EDIT_STATE_SUCCESS = "EDIT_STATE_SUCCESS";

export const DELETE_STATE_LOADING = "DELETE_STATE_LOADING";
export const DELETE_STATE_FAIL = "DELETE_STATE_FAIL";
export const DELETE_STATE_SUCCESS = "DELETE_STATE_SUCCESS";

export const ADD_TRANSITION_LOADING = "ADD_TRANSITION_LOADING";
export const ADD_TRANSITION_FAIL = "ADD_TRANSITION_FAIL";
export const ADD_TRANSITION_SUCCESS = "ADD_TRANSITION_SUCCESS";

export const EDIT_TRANSITION_LOADING = "EDIT_TRANSITION_LOADING";
export const EDIT_TRANSITION_FAIL = "EDIT_TRANSITION_FAIL";
export const EDIT_TRANSITION_SUCCESS = "EDIT_TRANSITION_SUCCESS";

export const DELETE_TRANSITION_LOADING = "DELETE_TRANSITION_LOADING";
export const DELETE_TRANSITION_FAIL = "DELETE_TRANSITION_FAIL";
export const DELETE_TRANSITION_SUCCESS = "DELETE_TRANSITION_SUCCESS";

export const SEND_ACTION_MAP_LOADING = "SEND_ACTION_MAP_LOADING";
export const SEND_ACTION_MAP_FAIL = "SEND_ACTION_MAP_FAIL";
export const SEND_ACTION_MAP_SUCCESS = "SEND_ACTION_MAP_SUCCESS";

export const SEND_STATE_ID_LOADING = "SEND_STATE_ID_LOADING";
export const SEND_STATE_ID_FAIL = "SEND_STATE_ID_FAIL";
export const SEND_STATE_ID_SUCCESS = "SEND_STATE_ID_SUCCESS";

export const GET_STATES_LOADING = "GET_STATES_LOADING";
export const GET_STATES_FAIL = "GET_STATES_FAIL";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";

export const SEND_STATES_DATA_LOADING = "SEND_STATES_DATA_LOADING";
export const SEND_STATES_DATA_FAIL = "SEND_STATES_DATA_FAIL";
export const SEND_STATES_DATA_SUCCESS = "SEND_STATES_DATA_SUCCESS";

export const SEND_TRANSITION_ID_LOADING = "SEND_TRANSITION_ID_LOADING";
export const SEND_TRANSITION_ID_FAIL = "SEND_TRANSITION_ID_FAIL";
export const SEND_TRANSITION_ID_SUCCESS = "SEND_TRANSITION_ID_SUCCESS";

export const GET_STATE_LOADING = "GET_STATE_LOADING";
export const GET_STATE_FAIL = "GET_STATE_FAIL";
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS";

export const SEND_TRANSITION_DATA_LOADING = "SEND_TRANSITION_DATA_LOADING";
export const SEND_TRANSITION_DATA_FAIL = "SEND_TRANSITION_DATA_FAIL";
export const SEND_TRANSITION_DATA_SUCCESS = "SEND_TRANSITION_DATA_SUCCESS";

export const GET_UNIQUE_EVENT_PARAMS_LOADING = "GET_UNIQUE_EVENT_PARAMS_LOADING";
export const GET_UNIQUE_EVENT_PARAMS_FAIL = "GET_UNIQUE_EVENT_PARAMS_FAIL";
export const GET_UNIQUE_EVENT_PARAMS_SUCCESS = "GET_UNIQUE_EVENT_PARAMS_SUCCESS";

export const SEND_SESSION_DATA_LOADING = "SEND_SESSION_DATA_LOADING";
export const SEND_SESSION_DATA_FAIL = "SEND_SESSION_DATA_FAIL";
export const SEND_SESSION_DATA_SUCCESS = "SEND_SESSION_DATA_SUCCESS";

export const SEND_TEMPLATE_STRING_LOADING = "SEND_TEMPLATE_STRING_LOADING";
export const SEND_TEMPLATE_STRING_FAIL = "SEND_TEMPLATE_STRING_FAIL";
export const SEND_TEMPLATE_STRING_SUCCESS = "SEND_TEMPLATE_STRING_SUCCESS";

export const PUT_TEMPLATE_PREVIEW_DATA_LOADING = "PUT_TEMPLATE_PREVIEW_DATA_LOADING";
export const PUT_TEMPLATE_PREVIEW_DATA_FAIL = "PUT_TEMPLATE_PREVIEW_DATA_FAIL";
export const PUT_TEMPLATE_PREVIEW_DATA_SUCCESS = "PUT_TEMPLATE_PREVIEW_DATA_SUCCESS";

export const SEND_VALIDATION_MAP_DATA_LOADING = "SEND_VALIDATION_MAP_DATA_LOADING";
export const SEND_VALIDATION_MAP_DATA_FAIL = "SEND_VALIDATION_MAP_DATA_FAIL";
export const SEND_VALIDATION_MAP_DATA_SUCCESS = "SEND_VALIDATION_MAP_DATA_SUCCESS";

//campaign

export const GET_CAMPAIGN_LIST_LOADING = "GET_CAMPAIGN_LIST_LOADING";
export const GET_CAMPAIGN_LIST_SUCCESS = "GET_CAMPAIGN_LIST_SUCCESS";
export const GET_CAMPAIGN_LIST_FAIL = "GET_CAMPAIGN_LIST_FAIL";

export const ADD_CAMPAIGN_LOADING = "ADD_CAMPAIGN_LOADING";
export const ADD_CAMPAIGN_SUCCESS = "ADD_CAMPAIGN_SUCCESS";
export const ADD_CAMPAIGN_FAIL = "ADD_CAMPAIGN_FAIL";

export const EDIT_CAMPAIGN_LOADING = "EDIT_CAMPAIGN_LOADING";
export const EDIT_CAMPAIGN_SUCCESS = "EDIT_CAMPAIGN_SUCCESS";
export const EDIT_CAMPAIGN_FAIL = "EDIT_CAMPAIGN_FAIL";

export const GET_CAMPAIGN_LOADING = "GET_CAMPAIGN_LOADING";
export const GET_CAMPAIGN_FAIL = "GET_CAMPAIGN_FAIL";
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS";

export const GET_CAMPAIGN_INIT_LOADING = "GET_CAMPAIGN_INIT_LOADING";
export const GET_CAMPAIGN_INIT_FAIL = "GET_CAMPAIGN_INIT_FAIL";
export const GET_CAMPAIGN_INIT_SUCCESS = "GET_CAMPAIGN_INIT_SUCCESS";

//segment
export const GET_SEGMENT_LIST_LOADING = "GET_SEGMENT_LIST_LOADING";
export const GET_SEGMENT_LIST_SUCCESS = "GET_SEGMENT_LIST_SUCCESS";
export const GET_SEGMENT_LIST_FAIL = "GET_SEGMENT_LIST_FAIL";

export const GET_SEGMENT_LOADING = "GET_SEGMENT_LOADING";
export const GET_SEGMENT_SUCCESS = "GET_SEGMENT_SUCCESS";
export const GET_SEGMENT_FAIL = "GET_SEGMENT_FAIL";


export const SEND_SEGMENT_DATA_LOADING = "SEND_SEGMENT_DATA_LOADING";
export const SEND_SEGMENT_DATA_FAIL = "SEND_SEGMENT_DATA_FAIL";
export const SEND_SEGMENT_DATA_SUCCESS = "SEND_SEGMENT_DATA_SUCCESS";

export const SEND_SEGMENT_ID_LOADING = "SEND_SEGMENT_ID_LOADING";
export const SEND_SEGMENT_ID_FAIL = "SEND_SEGMENT_ID_FAIL";
export const SEND_SEGMENT_ID_SUCCESS = "SEND_SEGMENT_DATA_SUCCESS";

export const DELETE_SEGMENT_LOADING = "DELETE_SEGMENT_LOADING";
export const DELETE_SEGMENT_FAIL = "DELETE_SEGMENT_FAIL";
export const DELETE_SEGMENT_SUCCESS = "DELETE_SEGMENT_SUCCESS";

export const EDIT_SEGMENT_LOADING = "EDIT_SEGMENT_LOADING";
export const EDIT_SEGMENT_FAIL = "EDIT_SEGMENT_FAIL";
export const EDIT_SEGMENT_SUCCESS = "EDIT_SEGMENT_SUCCESS";

export const ADD_SEGMENT_LOADING = "ADD_SEGMENT_LOADING";
export const ADD_SEGMENT_FAIL = "ADD_SEGMENT_FAIL";
export const ADD_SEGMENT_SUCCESS = "ADD_SEGMENT_SUCCESS";

export const GET_TEMPLATE_WITH_ID_LOADING = "GET_TEMPLATE_WITH_ID_LOADING";
export const GET_TEMPLATE_WITH_ID_FAIL = "GET_TEMPLATE_WITH_ID_FAIL";
export const GET_TEMPLATE_WITH_ID_SUCCESS = "GET_TEMPLATE_WITH_ID_SUCCESS";

export const SEND_CAMPAING_EVENT_TEMPLATE_PARAM_LOADING = "SEND_CAMPAING_EVENT_TEMPLATE_PARAM_LOADING";
export const SEND_CAMPAING_EVENT_TEMPLATE_PARAM_FAIL = "SEND_CAMPAING_EVENT_TEMPLATE_PARAM_FAIL";
export const SEND_CAMPAING_EVENT_TEMPLATE_PARAM_SUCCESS = "SEND_CAMPAING_EVENT_TEMPLATE_PARAM_SUCCESS";

export const GET_ACCOUNT = "GET_ACCOUNT";

export const GET_AUDIENCE_REPORTING_LOADING = "GET_AUDIENCE_REPORTING_LOADING";
export const GET_AUDIENCE_REPORTING_FAIL = "GET_AUDIENCE_REPORTING_FAIL";
export const GET_AUDIENCE_REPORTING_SUCCESS = "GET_AUDIENCE_REPORTING_SUCCESS";









