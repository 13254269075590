import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { faAngleRight, faCheck, faCircle, faCloudUploadAlt, faInfoCircle, faQuestion, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectedScenarioBgColor, tableDeployedBgColor, tableUndeployedBgColor } from "../../../constants/utils/constantUtils";
import { RootStore } from "../../../redux/store/Store";
import { useDispatch, useSelector } from "react-redux";
import { LOADING, TABLE_DEPLOYMENT, TABLE_LAST_UPDATE, TABLE_SCENARIO_NAME, TABLE_STATUS } from "../../../constants/languageConstants";
import { getScenarioList, sendSelectScenarioIdAction, sendSelectScenarioList } from "../../../redux/actions/scenarioActions";
import { cloneDeep, sortBy } from "lodash";
import { linkViewUrl } from "../../../constants/Urls";
import Preview from "./components/Preview";
import Operations from "../commonComps/Operations";
import GenaralModal from "../detail/components/modals/GenaralModal";
import AddNewScenarioForm from "../detail/components/forms/AddScenarioForm";
import { Account } from "../../../services/AccountService";
import  cepPreviewImg from "../../../assets/images/cep_preview.gif";


const ScenarioListPreviewPage = () => {
  const dispatch = useDispatch();

  const scenarioList: any = useSelector((state: RootStore) => state.scenariListReducer);
  const addNewScenario: any = useSelector((state: RootStore) => state.putNewScenarioReducer);
  const scenarioStatus: any = useSelector((state: RootStore) => state.getScenarioStatusReducer);

  const [selectedScenarioId, setselectedScenarioId] = useState(null);
  const [selectedScenarioName, setselectedScenarioName] = useState("");
  const [currentPage, setcurrentPage] = useState<number>(1);
  const [scenarioPerPage, setscenarioPerPage] = useState<number>(10);
  const [selectedCellId, setselectedCellId] = useState<null | number>(null);
  const [scenarioListData, setscenarioListData] = useState<any | null>(null);
  const [scenarioMap, setscenarioMap] = useState<any | null>({});
  const [complexScenarioEnabled, setcomplexScenarioEnabled] = useState<boolean>(false);

  const accountReducer: any = useSelector((state: RootStore) => state.getAccountReducer)

  useEffect(() => {
    document.title = "Complex Scenarios | Herogi Growth Panel"
    if (accountReducer) {
      console.log(accountReducer.account);
      setcomplexScenarioEnabled(accountReducer.account?.enabledFeatures.eventProcessing);
    }
  }, [accountReducer]);

  useEffect(() => {
    dispatch(getScenarioList());
  }, []);

  useEffect(() => {
    if (scenarioList.data) {
      setscenarioListData(sortBy(scenarioList.data, "scenario.name"));
      const newScenarioMap = { ...scenarioMap };
      scenarioList.data.map((scenario: any) => (newScenarioMap[scenario.id] = scenario));
      setscenarioMap(newScenarioMap);
    }
  }, [scenarioList.data]);

  useEffect(() => {
    if (complexScenarioEnabled) {
      disableCheckButtons();
      checkSelectedTbody();
    }
  });

  useEffect(() => {
    if (addNewScenario.data?.statusText === "OK") {
      dispatch(getScenarioList());
      $("#AddNewScenarioModal").modal("hide");
    }
  }, [addNewScenario.data]);

  useEffect(() => {
    var scenarioNames: any = [];
    scenarioNames.push(selectedScenarioName);
    dispatch(sendSelectScenarioList(scenarioNames));
    dispatch(getScenarioList());
  }, [selectedScenarioName]);

  useEffect(() => {
    if (scenarioStatus.data?.status === "success") {
      if (scenarioMap) {
        var newScenarioList = cloneDeep(scenarioListData);
        if (scenarioStatus.data.data[0].id && newScenarioList) {
          var gettedScenario: any = newScenarioList.find((obj: any) => obj.id == scenarioStatus.data.data[0].id);
          var filteredNewScenarioList = newScenarioList.filter((obj: any) => obj.id != scenarioStatus.data.data[0].id);

          if (gettedScenario.status?.status) {
            gettedScenario.status.status = scenarioStatus.data.data[0].status;
            filteredNewScenarioList.push(gettedScenario);
            setscenarioListData(sortBy(filteredNewScenarioList, "scenario.name"));
            scenarioStatus.data.status = "";

          }
        }
      }
    }
  }, [scenarioStatus.data]);

  //pagination calculations
  const indexOfLastScenarios = currentPage * scenarioPerPage;
  const indexOfFirstScenarios = indexOfLastScenarios - scenarioPerPage;
  var currentScenarios: any;
  if (scenarioListData) {
    currentScenarios = scenarioList.data ? scenarioListData.slice(indexOfFirstScenarios, indexOfLastScenarios) : [];
  }

  const pageNumbers: any = [];

  if (scenarioListData) {
    if (scenarioListData) {
      for (let i = 1; i <= Math.ceil(Object.values(scenarioListData).length / scenarioPerPage); i++) {
        pageNumbers.push(i);
      }
    }
  }

  const checkSelectedTbody = () => {
    var tbodys = document.getElementsByClassName("scenariolist-tbody");
    for (var i = 0, len = tbodys.length; i < len; i++) {
      var element: HTMLInputElement = tbodys[i] as HTMLInputElement;

      if (element.getAttribute("id") === selectedCellId?.toString()) {
        element.style.backgroundColor = selectedScenarioBgColor;
        var el: HTMLTableDataCellElement = element.querySelector("#td0") as HTMLTableDataCellElement;
        el.innerText = "✓";
      } else {
        element.style.backgroundColor = "inherit";
        var el: HTMLTableDataCellElement = element.querySelector("#td0") as HTMLTableDataCellElement;
        el.innerText = " ";
      }
    }
  };

  const disableCheckButtons = () => {
    if (scenarioListData) {
      var scenarioViewButton: HTMLButtonElement = document.getElementById("ScenarioViewButton") as HTMLButtonElement;
      var scenarioOperationButton: HTMLButtonElement = document.getElementById("ScenarioOperationDropdown") as HTMLButtonElement;
      if (selectedScenarioId) {
        scenarioViewButton!.disabled = false;
        scenarioOperationButton!.disabled = false;
      } else {
        scenarioViewButton!.disabled = true;
        scenarioOperationButton!.disabled = true;
      }
    }
  };

  const renderPageNumber = () => {
    return (
      <ul className="pagination">
        <li className="page-item">
          <button
            className="custom-pagination-button"
            type="button"
            onClick={() => {
              if (currentPage > 1) {
                setcurrentPage(currentPage - 1);
              }
            }}
          >
            Prev
          </button>
        </li>

        <li className="page-item">
          <button
            className="custom-pagination-button"
            type="button"
            onClick={() => {
              setcurrentPage(currentPage);
            }}
          >
            {currentPage} / {Object.values(pageNumbers).length}
          </button>
        </li>
        <li className="page-item">
          <button
            className="custom-pagination-button"
            type="button"
            onClick={() => {
              if (currentPage < Object.values(pageNumbers).length) {
                setcurrentPage(currentPage + 1);
              }
            }}
          >
            Next
          </button>
        </li>
      </ul>
    );
  };

  const renderScenarioActionButtons = () => {
    return (
      <div className="d-flex justify-content-between align-items-sm-center flex-column flex-sm-row mb-1 shadow-sm p-3 rounded">
        <div className="col col lg-2">
          <button className="btn btn-primary pt-2 pb-2 pl-2 pr-2" data-toggle="modal" data-target="#AddNewScenarioModal" type="button">
            Create New
          </button>
          <Link style={{ textDecoration: "none" }} to={linkViewUrl + selectedScenarioId}>
            <button id="ScenarioViewButton" className="btn btn-outline-white pt-2 pb-2 pl-2 pr-2 ml-1">
              {" "}
              View
            </button>
          </Link>
          <Operations />
        </div>
        <div className="col col lg-2">{/* right side element */}</div>
      </div>
    );
  };

  const renderBreadCrums = () => {
    return (
      <p className="mt-3">
        <strong>Scenario </strong>
        <small>
          <FontAwesomeIcon icon={faAngleRight} />
        </small>
        <strong> {selectedScenarioName}</strong>
      </p>
    );
  };

  const renderScenarioStatus = (cs: any) => {
    if (cs.status.status == "DEPLOYED") {
      return (
        <>
          <FontAwesomeIcon icon={faCloudUploadAlt} color={tableDeployedBgColor} /> {cs.status.status}{" "}
        </>
      );
    } else if (cs.status.status == "UNDEPLOYED") {
      return (
        <>
          <FontAwesomeIcon icon={faCircle} color={tableUndeployedBgColor} /> {cs.status.status}
        </>
      );
    } else if (cs.status.status == "INITIATED") {
      return (
        <>
          <FontAwesomeIcon icon={faSyncAlt} className="fa-spin" /> {cs.status.status}
        </>
      );
    } else {
      return (
        <>
          <FontAwesomeIcon icon={faQuestion} /> {cs.status.status}
        </>
      );
    }
  };

  const renderScenarioListTable = () => {
    return (
      <div className="card shadow-sm mb-1">
        <div className="card-body">
          <div className="row align-items-center justify-content-between">
            {renderPageNumber()}
            <table className="table table-bordered  table-hover">
              <thead className="bg-light">
                <tr className="scenario-table-tr">
                  <th className="text-center" scope="col">
                    #
                  </th>
                  <th scope="col">{TABLE_SCENARIO_NAME}</th>
                  <th scope="col">{TABLE_LAST_UPDATE}</th>
                  <th scope="col">{TABLE_DEPLOYMENT}</th>
                  <th scope="col">{TABLE_STATUS}</th>
                </tr>
              </thead>
              {currentScenarios
                ? currentScenarios.map((cs: any) => (
                  <>
                    <tbody
                      className="scenariolist-tbody"
                      id={cs.scenario.name + cs.scenario.id}
                      onClick={() => {
                        setselectedCellId(cs.scenario.name + cs.scenario.id);
                        setselectedScenarioId(cs.scenario.id);
                        setselectedScenarioName(cs.scenario.name);
                        dispatch(sendSelectScenarioIdAction(cs.scenario.id));
                      }}
                    >
                      <td className="text-center" id="td0"></td>
                      <td>{cs.scenario.name}</td>
                      <td>
                        <div className="row ">
                          <div className="col-6 col-sm-5 ">{new Date(cs.scenario.updateDate).toLocaleDateString()}</div>
                          <div className="col-6 col-sm-5 ">{new Date(cs.scenario.updateDate).toLocaleTimeString()}</div>
                        </div>
                      </td>
                      <td>
                        {cs.lastOperationTime == null ? (
                          "N/A"
                        ) : (
                          <div className="row ">
                            <div className="col-6 col-sm-5 ">{new Date(cs.lastOperationTime).toLocaleDateString()}</div>
                            <div className="col-6 col-sm-5 ">{new Date(cs.lastOperationTime).toLocaleTimeString()}</div>
                          </div>
                        )}
                      </td>
                      <td>{renderScenarioStatus(cs)}</td>
                    </tbody>
                  </>
                ))
                : null}
            </table>
          </div>
        </div>
      </div>
    );
  };

  const renderModals = () => {
    return (
      <>
        <GenaralModal modalId="AddNewScenarioModal" modalName="New Scenario" formComp={AddNewScenarioForm} />
      </>
    );
  };

  if (complexScenarioEnabled) {

    if (scenarioList.data) {
      return (
        <>
          <div className="dashboard-main">
            {renderScenarioActionButtons()}
            {renderScenarioListTable()}
            {renderBreadCrums()}
            {scenarioListData ? <Preview scenarioList={scenarioListData} /> : null}

            {renderModals()}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="dashboard-main">
            <div className="container text-center">
              <h2>{LOADING}</h2>
              <div className="spinner-border text-primary" role="status"></div>
            </div>
          </div>
        </>
      );
    }
  } else {
    return (
      <div className="dashboard-main">
        <div className="card">
          <div className="card-body">
            <h2>Complex Scenarios Not Active</h2>
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6">
                <p className="">Herogi behind the scene runs very complex event processing engine to enable users to define 
                  complex scenarios with custom validation rules, custom events connects multiple states to run any actions on right moment.
                </p>
                <button className="btn btn-dark btn-lg mt-3" type="button" data-toggle="modal" data-target="#UsageExceedModal">Enable Now</button>
              </div>
              <div className="col-md-6">
                <img src={cepPreviewImg} className="img-fluid" />
              </div>
            </div>
            <div className="row mt-2" >
              <div className="col-md-12 text-small">
                <FontAwesomeIcon icon={faInfoCircle} /> 
                <span className="ml-2">Learn more about complex scenarios <a href="http://learn.herogi.com/userguide/events/" target="_blank">here.</a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ScenarioListPreviewPage;
