import * as SegmentActionTypes from "../types/types";
import { apiErrorHandle } from "../../components/common/hooksAndFuncts/ApiErrorHandle";
import axios from "axios";



export const getSegmentListAction = () => async (dispatch: any) => {
  try {
    dispatch({
      type: SegmentActionTypes.GET_SEGMENT_LIST_LOADING,
    });

    axios
      .get("/segment", {
        headers: {
          "Content-Type": "application/json",
        },
      })

      .then((response) => {
        dispatch({
          type: SegmentActionTypes.GET_SEGMENT_LIST_SUCCESS,
          payload: response.data, 
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "getSegmentListApiError");
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const getSegmentAction = (segmentId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: SegmentActionTypes.GET_CAMPAIGN_LOADING,
    });

    axios
      .get(`/segment/${segmentId}`, {})
      .then((response) => {
        dispatch({
          type: SegmentActionTypes.GET_CAMPAIGN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "getSegmentApiError");
      });
  } catch (error) {
    console.log(error, "error");
  }
};



export const sendSegmentDataAction = (segmentData: any) => (dispatch: any) => {
    try {
      dispatch({
        type: SegmentActionTypes.SEND_SEGMENT_DATA_LOADING,
      });
      dispatch({
        type: SegmentActionTypes.SEND_SEGMENT_DATA_SUCCESS,
        payload: segmentData,
      });
    } catch (error) {
      console.log(error, "error");
      dispatch({
        type: SegmentActionTypes.SEND_SEGMENT_DATA_FAIL,
        payload: error,
      });
    }
  };


  export const sendSegmentIdAction = (segmentId: string) => (dispatch: any) => {
    try {
      dispatch({
        type: SegmentActionTypes.SEND_SEGMENT_ID_LOADING,
      });
      dispatch({
        type: SegmentActionTypes.SEND_SEGMENT_ID_SUCCESS,
        payload: segmentId,
      });
    } catch (error) {
      console.log(error, "error");
      dispatch({
        type: SegmentActionTypes.SEND_SEGMENT_ID_FAIL,
        payload: error,
      });
    }
  };



  export const putSegmentAction = (bodyObj: object) => async (dispatch: any) => {
    try {
      dispatch({
        type: SegmentActionTypes.ADD_SEGMENT_LOADING,
      });
  
      await axios
        .put(`/segment`, bodyObj)
        .then((response) => {
          dispatch({
            type: SegmentActionTypes.ADD_SEGMENT_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          apiErrorHandle(error, "putSegmentActionApiError");
          dispatch({
            type: SegmentActionTypes.ADD_SEGMENT_FAIL,
            payload: error.response.data,
          });
        });
    } catch (error) {
      console.log(error, "error");
    }
  };
  
  export const postSegmentAction = (bodyObj: object, scenarioId: string, segmentId: string, newObj: object) => async (dispatch: any) => {
    try {
      dispatch({
        type: SegmentActionTypes.EDIT_SEGMENT_LOADING,
      });
  
      await axios
        .post(`/segment/${segmentId}`, bodyObj)
        .then((response) => {
          dispatch({
            type: SegmentActionTypes.EDIT_SEGMENT_SUCCESS,
            payload: { newObj, response: response.data },
          });
        })
        .catch((error) => {
          apiErrorHandle(error, "postSegmentActionApiError");
          dispatch({
            type: SegmentActionTypes.EDIT_SEGMENT_FAIL,
            payload: error.response.data,
          });
        });
    } catch (error) {
      console.log(error, "error");
    }
  };


  export const deleteSegmentAction = (segmentId:string) => async (dispatch: any) => {
    try {
      dispatch({
        type: SegmentActionTypes.DELETE_SEGMENT_LOADING,
      });
  
      await axios
        .delete(`/segment/${segmentId}`)
        .then((response) => {
          dispatch({
            type: SegmentActionTypes.DELETE_SEGMENT_SUCCESS,
            payload: response,
          });
        })
        .catch((error) => {
          apiErrorHandle(error, "deleteSegmentApiError");
          dispatch({
            type: SegmentActionTypes.DELETE_SEGMENT_FAIL,
            payload: error.response.data,
          });
        });
    } catch (error) {
      console.log(error, "error");
    }
  };