import { sortBy } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { putActionParamAction } from "../../../../../redux/actions/ActionActions";
import { RootStore } from "../../../../../redux/store/Store";
import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";
import RichTextEditor from "../../../commonComps/RichTextEditorAction";
import $ from 'jquery';


interface IparamData {
  key: string;
  dataType: string;
  isRecurring: Boolean;
  description: string;
  id: number;
}

const AddNewActionParamForm = () => {
  const dispatch = useDispatch();

  const paramHeaders = ["Parameter Name", "Type", "Recurring", "Description"];

  const [addButtonState, setaddButtonState] = useState(false);
  const [parameterData, setparameterData] = useState<IparamData | null>(null);
  const [availableActionParameters, setavailableActionParameters] = useState<any>(null);
  const [editorValue, seteditorValue] = useState("");
  const [fieldValue, setfieldValue] = useState("");
  const [isDisable, setisDisable] = useState(false);
  const [actionParamErrorData, setactionParamErrorData] = useState("");

  const selectedActionId: any = useSelector((state: RootStore) => state.sendActionIdReducer);
  const selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  const selectedActionParams: any = useSelector((state: RootStore) => state.sendActionParamsDataReducer);
  const selectedActionTemplateParams: any = useSelector((state: RootStore) => state.sendActionTemplateParamsDataReducer);
  const actionParamError: any = useSelector((state: RootStore) => state.putActionParamReducer);

  useEffect(() => {
    if (actionParamError.data) {
      if (actionParamError.data.message) {
        setactionParamErrorData(actionParamError.data.message);
        setaddButtonState(true);
      }
    }
  }, [actionParamError]);

  useEffect(() => {
    if (selectedActionParams.data && selectedActionTemplateParams.data) {
      filterActionParams();
    }
  }, [selectedActionParams, selectedActionTemplateParams]);

  useEffect(() => {
    $("#AddNewActionParamModalCloseIconButton").click(function () {
      setaddButtonState(false);
      handleResetForm();
    });
  }, []);

  const filterActionParams = () => {
    if (selectedActionParams.data && selectedActionTemplateParams.data) {
      const tp = selectedActionTemplateParams.data;
      const sp = selectedActionParams.data;
      var availableParams: any = [];

      tp.forEach((t: any) => {
        if (sp[t.key] == null || t.isRecurring) {
          availableParams.push(t);
        }
      });
      setavailableActionParameters(availableParams);
    }
  };

  const handleSubmitActionParam = () => {
    const sendObj: any = {};
    if (parameterData) {
      sendObj["templateParamId"] = parameterData.id;
      sendObj["value"] = editorValue;
      if (parameterData.isRecurring) {
        sendObj["field"] = fieldValue;
      }
      if(editorValue !==""){
        dispatch(putActionParamAction(sendObj, selectedScenarioId.data, selectedActionId.data));
      }else{
        alert("Please dont leave empty editor value!")
      }
    } else {
      alert("parameter data is null or empty!");
    }
  };

  const returnNoActionError = () => {
    return (
      <span>
        <p>There is no action parameter</p>
      </span>
    );
  };
  const editorHandleChange = (e: any) => {
    seteditorValue(e.target.value);
  };

  const editorFieldChange = (e: any) => {
    setactionParamErrorData("");
    setfieldValue(e.target.value);

    //TODO WHEN RICH BOX IS ADDED DO ALL VALIDATION

    /*  if(parameterData?.isRecurring ){
      if (e.target.value === "") {
        setisDisable(true);
      } else {
        setisDisable(false);
      }
    } */
  };

  const handleResetForm = () => {
    setactionParamErrorData("");
    seteditorValue("");
  };

  const renderAddButtonModalContent = () => {
    return (
      <>
        {parameterData ? (
          <div>
            {" "}
            <div>
              <h4>
                {parameterData.key}
                <small>({parameterData.dataType})</small>
                <small> {parameterData.isRecurring === false ? "Non-Requrring" : "Recurring"}</small>
              </h4>
            </div>
            <h6 style={{ color: "orange" }}>Description</h6>
            <h6>{parameterData.description}</h6>
          </div>
        ) : (
          <p>parameter data error</p>
        )}

        {parameterData?.isRecurring === true ? (
          <div className="form-group">
            <label>
              {" "}
              <strong>Field</strong>
            </label>
            <input
              className="form-control"
              type="text"
              onChange={(e) => {
                editorFieldChange(e);
              }}
            />

            {actionParamErrorData ? <ErrorMessageComp name={actionParamErrorData} /> : null}
          </div>
        ) : null}

        <div className=" form-group mt-3">
          <label>
            <strong>Value</strong>
          </label>
         {/*  <input
            className="form-control"
            type="text"
            onChange={(e) => {
              editorHandleChange(e);
            }}
          /> */}

        <RichTextEditor editorValueProp = {editorValue}  seteditorValueProp = {seteditorValue} functions ={[editorValue,seteditorValue]} />


        </div>

        <div className="modal-footer mt-3">
          <button
            id="renderAddButtonModalContentCloseButton"
            className="btn btn-primary p-2 mt-3"
            type="button"
            onClick={() => {
              setaddButtonState(false);
              handleResetForm();
            }}
          >
            Close
          </button>
          <button
            disabled={isDisable}
            id="renderAddButtonModalContentCreateButton"
            onClick={() => {
              handleSubmitActionParam();
              setaddButtonState(false);
            }}
            className="btn btn-success p-2 mt-3"
            type="submit"
          >
            Save
          </button>
        </div>
      </>
    );
  };

  const renderAddNewActionFormTable = () => {
    return (
      <div>
        {availableActionParameters && Object.values(availableActionParameters).length > 0 ? (
          <table style={{ fontSize: "14px" }} className="table vertical-align-middle">
            <thead>
              <tr>
                {paramHeaders && paramHeaders.length > 0 ? paramHeaders.map((headers) => <th scope="col">{headers}</th>) : null}
                <th scope="col">Option</th>
              </tr>
            </thead>

            {availableActionParameters && Object.values(availableActionParameters).length > 0
              ? sortBy(Object.values(availableActionParameters), "key").map((ap: any) => (
                  <tbody>
                    <tr>
                      <td>{ap.key}</td>
                      <td>{ap.dataType}</td>
                      <td>{JSON.stringify(ap.isRecurring)}</td>
                      <td>{ap.description}</td>

                      <td>
                        {" "}
                        <button
                          className="btn btn-primary p-1"
                          onClick={() => {
                            setaddButtonState(true);
                            setparameterData(ap);
                          }}
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ))
              : returnNoActionError()}
          </table>
        ) : (
          returnNoActionError()
        )}
      </div>
    );
  };

  return <>{addButtonState ? renderAddButtonModalContent() : renderAddNewActionFormTable()}</>;
};

export default AddNewActionParamForm;
