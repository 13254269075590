export const eventListByScenarioQuery = {
    "from": 0,
    "size": 1,
    "query": {
      "bool": {
        "must": [
          {
            "match_all": {}
          }
        ],
        "filter": [
          {
            "range": {
              "entry_time": {
                "gte": "###StartDate###",
                "lte": "###EndDate###"
              }
            }
          }
        ]
      }
    },
    "sort": [
      {
        "timestamp": {
          "order": "desc"
        }
      }
    ]
  }  