import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import {
  BANNER,
  FOOTER_BLOG,
  FOOTER_CONTACT_US,
  FOOTER_DOCS,
  FOOTER_HOME,
  FOOTER_PRICING,
  FOOTER_SIGN_UP,
  LOGIN,
  LOGIN_EMAIL_EMPTY_ERROR,
  LOGIN_EMAIL_ERROR,
  LOGIN_PASSWORD_ERROR,
  SIGN_IN,
} from "../../constants/languageConstants";
import { apiErrorHandle } from "../../components/common/hooksAndFuncts/ApiErrorHandle";
import { loginApiUrl } from "../../constants/Urls";
import axios from "axios";
import ErrorMessageComp from "../../components/common/ErrorMessageComp";
import logosmall from "../../assets/images/landing/logo_small_contact.png";
import * as Yup from "yup";
import { Account } from "../../services/AccountService";
import { useDispatch } from "react-redux";
import { GET_ACCOUNT } from "../../redux/types/types";


const LoginPage = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const [postError, setPostError] = useState<any>(null);

  useEffect(() => {
    document.title = "Login | Customer Growth Panel"
  }, []);

  const loginApi = (postObject: object) => {
    axios
      .post(loginApiUrl, postObject)
      .then((response) => {
        const companyData = {
          email: response.data.data?.email,
          companyName: response.data.data?.companyName,
        };
        
        history.push({
          pathname: "/",
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "loginApiError");

        if (error.response.status && error.response.status >= 402 && error.response.status <= 599) {
          setPostError(error.response.status + " " + error.response.statusText);
        } else {
          setPostError(error.response.data.message);
        }
      });
  };

  const handleRegisterSubmit = (loginPostObject: object) => {
    loginApi(loginPostObject);
  };

  const renderLoginFooter = () => {
    return (
      <>
        <div>
          <footer>
            <div className="col-sm-12 text-center mt-5">
              <span>{BANNER} </span>
              <span>/</span>
              <span>
                <a href="https://herogi.com"> {FOOTER_HOME} </a>
              </span>
              <span>/</span>
              <span>
                <a href="https://herogi.com/pricing"> {FOOTER_PRICING} </a>
              </span>
              <span>/</span>
              <span>
                <a href="https://learn.herogi.com"> {FOOTER_DOCS} </a>
              </span>
              <span>/</span>
              <span>
                <a href="https://blog.herogi.com/"> {FOOTER_BLOG} </a>
              </span>
              <span>/</span>
              <span>
                <a href="https://l1.herogi.com/contact"> {FOOTER_CONTACT_US} </a>
              </span>
              <span>/</span>
              <span>
                <Link to="/register"> {FOOTER_SIGN_UP}</Link>
              </span>
            </div>
          </footer>
        </div>
      </>
    );
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(LOGIN_EMAIL_EMPTY_ERROR).email(LOGIN_EMAIL_ERROR),
    password: Yup.string().required(LOGIN_PASSWORD_ERROR),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: () => {},
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnMount: true,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4">
            <div className="card shadow-sm border-1 rounded-lg mt-5">
              <div className="card-header text-center ">
                <img style={{ width: "45%" }} src={logosmall} alt="logosmall" />
              </div>

              <div className="card-body">
                <div className="panel-heading">
                  <h4 className="panel-title">{SIGN_IN}</h4>
                </div>
                <div className="panel-body">
                  <div>{postError ? <ErrorMessageComp name={postError} /> : null}</div>

                  <div className="form-group">
                    {formik.errors.email && formik.touched.email && <ErrorMessageComp name={formik.errors.email} />}
                    <input
                      className="form-control"
                      id="email"
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      placeholder="Email Address"
                      autoComplete="email"
                    />
                  </div>

                  <div className="form-group">
                    {formik.errors.password && formik.touched.password && <ErrorMessageComp name={formik.errors.password} />}
                    <input
                      className="form-control"
                      id="password"
                      name="password"
                      type="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      placeholder="Password"
                      autoComplete="password"
                    />
                  </div>

                  <div className="form-group">
                    <button
                      onClick={() => {
                        handleRegisterSubmit(formik.values);
                        setPostError("");
                      }}
                      className="btn btn-primary btn-lg btn-block p-3 "
                      type="submit"
                      disabled={!formik.isValid}
                    >
                      {LOGIN}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {renderLoginFooter()}
    </form>
  );
};

export default LoginPage;
