import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { authenticateUrl } from "../../constants/Urls";
import axios from "axios";
import Navbar from "../../pages/dashboard/components/Navbar";
import Sidebar from "../../pages/dashboard/components/Sidebar";
import { GET_ACCOUNT } from "../../redux/types/types";
import { useDispatch } from "react-redux";
import { Account } from "../../services/AccountService";


const PrivateRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
}> = (props) => {
  const [condition, setcondition] = useState<any>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    isAuthenticated();
  }, []);

  const isAuthenticated = () => {
    axios
      .get(authenticateUrl)
      .then((response) => {
        if (response.status === 200) {
          setcondition(true);

          const account = new Account(response.data.data)
          console.log(account);

          dispatch({
            type: GET_ACCOUNT,
            payload: account
          });

        } else {
          setcondition(false);
        }
      })
      .catch((error) => {
        if (error.response.status && error.response.status >= 400 && error.response.status <= 599) {
          setcondition(false);
          console.log(error, "authenticateApiError");
        }
      });
  };

  const renderComponent = () => {
    return condition !== null && condition === true ? (
      <div>
        <>
          <Navbar />
          <Sidebar />
        </>
        <Route path={props.path} exact={props.exact} component={props.component} />
      </div>
    ) : condition === false ? (
      <Redirect to="/login" />
    ) : null;
  };

  return <>{renderComponent()}</>;
};
export default PrivateRoute;
