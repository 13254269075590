import axios from "axios";
import { apiErrorHandle, ErrorHandler } from "../../components/common/hooksAndFuncts/ApiErrorHandle";
import * as CampaignActionTypes from "../types/types";

export const addCampaignAction = (bodyObj: any, formObj: any, setMessageComposerState: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: CampaignActionTypes.ADD_CAMPAIGN_LOADING,
    });
    await axios
      .put(`/campaign`, bodyObj)
      .then((response) => {
        dispatch({
          type: CampaignActionTypes.ADD_CAMPAIGN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "addCampaignApiError");
        dispatch({
          type: CampaignActionTypes.ADD_CAMPAIGN_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const editCampaignAction = (bodyObj: any, campaignId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: CampaignActionTypes.EDIT_CAMPAIGN_LOADING,
    });
    await axios
      .post(`/campaign/${campaignId}`, bodyObj)
      .then((response) => {
        dispatch({
          type: CampaignActionTypes.EDIT_CAMPAIGN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "EditCampaignApiError");
        dispatch({
          type: CampaignActionTypes.EDIT_CAMPAIGN_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const getCampaignListAction = () => async (dispatch: any) => {
  try {
    dispatch({
      type: CampaignActionTypes.GET_CAMPAIGN_LIST_LOADING,
    });

    axios
      .get("/campaign", {
        headers: {
          "Content-Type": "application/json",
        },
      })

      .then((response) => {
        dispatch({
          type: CampaignActionTypes.GET_CAMPAIGN_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "getCampaignListApiError");
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const getCampaingAction = (campaignId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: CampaignActionTypes.GET_CAMPAIGN_LOADING,
    });

    axios
      .get(`/campaign/${campaignId}`, {})
      .then((response) => {
        dispatch({
          type: CampaignActionTypes.GET_CAMPAIGN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "getCampaignApiError");
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const getCampaignInitAction = () => async (dispatch: any) => {
  try {
    dispatch({
      type: CampaignActionTypes.GET_CAMPAIGN_INIT_LOADING,
    });

    axios
      .get("/campaign/new", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => {
        dispatch({
          type: CampaignActionTypes.GET_CAMPAIGN_INIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "getCampaignInıtApiError");
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const deployCampaignAction = (campaignId: string | number) => async (dispatch: any) => {
  var data = {
    command: "deploy",
  };
  try {
    dispatch({
      type: CampaignActionTypes.DEPLOY_CAMPAIGN_LOADING,
    });
    await axios
      .post(`/campaign/${campaignId}/op`, data)
      .then((response) => {
        /* TODO status check could make here as response (because need scenarioId) */
        alert("Campaign operation succesfully completed");
        dispatch({
          type: CampaignActionTypes.DEPLOY_CAMPAIGN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        //alert(JSON.stringify(error.response.data.message));
        dispatch({
          type: CampaignActionTypes.DEPLOY_CAMPAIGN_FAIL,
          payload: error.response.data,
        });
        ErrorHandler(error.response);
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const reDeployCampaignAction = (campaignId: string) => async (dispatch: any) => {
  var data = {
    command: "redeploy",
  };
  try {
    dispatch({
      type: CampaignActionTypes.RE_DEPLOY_CAMPAIGN_LOADING,
    });
    await axios
      .post(`/campaign/${campaignId}/op`, data)
      .then((response) => {
        alert("Campaign operation succesfully completed");
        dispatch({
          type: CampaignActionTypes.RE_DEPLOY_CAMPAIGN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        /*  apiErrorHandle(error, "reDeployCampaignApiError"); */
        alert(JSON.stringify(error.response.data.message));

        dispatch({
          type: CampaignActionTypes.RE_DEPLOY_CAMPAIGN_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const unDeployCampaignAction = (campaignId: string) => async (dispatch: any) => {
  var data = {
    command: "undeploy",
  };
  try {
    dispatch({
      type: CampaignActionTypes.UN_DEPLOY_CAMPAIGN_LOADING,
    });
    await axios
      .post(`/campaign/${campaignId}/op`, data)
      .then((response) => {
        alert("Campaign operation succesfully completed");
        dispatch({
          type: CampaignActionTypes.UN_DEPLOY_CAMPAIGN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        /*   apiErrorHandle(error, "unDeployCampaignApiError"); */
        alert(JSON.stringify(error.response.data.message));

        dispatch({
          type: CampaignActionTypes.UN_DEPLOY_CAMPAIGN_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const freezeCampaignAction = (campaignId: string) => async (dispatch: any) => {
  var data = {
    command: "freeze",
  };
  try {
    dispatch({
      type: CampaignActionTypes.FREEZE_CAMPAIGN_LOADING,
    });
    await axios
      .post(`/campaign/${campaignId}/op`, data)
      .then((response) => {
        alert("Campaign operation succesfully completed");
        dispatch({
          type: CampaignActionTypes.FREEZE_CAMPAIGN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        /*    apiErrorHandle(error, "freezeCampaignApiError"); */
        alert(JSON.stringify(error.response.data.message));

        dispatch({
          type: CampaignActionTypes.FREEZE_CAMPAIGN_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const resumeCampaignAction = (campaignId: string) => async (dispatch: any) => {
  var data = {
    command: "resume",
  };
  try {
    dispatch({
      type: CampaignActionTypes.RESUME_CAMPAIGN_LOADING,
    });
    await axios
      .post(`/campaign/${campaignId}/op`, data)
      .then((response) => {
        alert("Campaign operation succesfully completed");
        dispatch({
          type: CampaignActionTypes.RESUME_CAMPAIGN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        /*   apiErrorHandle(error, "resumeCampaignApiError"); */
        alert(JSON.stringify(error.response.data.message));

        dispatch({
          type: CampaignActionTypes.RESUME_CAMPAIGN_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};


export const sendCampaignEventTemplateParamAction = (eventTemplateParam:any) => (dispatch: any) => {
  try {
    dispatch({
      type: CampaignActionTypes.SEND_CAMPAING_EVENT_TEMPLATE_PARAM_LOADING,
    });
      dispatch({
          type: CampaignActionTypes.SEND_CAMPAING_EVENT_TEMPLATE_PARAM_SUCCESS,
          payload: eventTemplateParam,
        });
  } catch (error) {
    console.log(error,"error");
    dispatch({
      type: CampaignActionTypes.SEND_CAMPAING_EVENT_TEMPLATE_PARAM_FAIL,
      payload: error,
    });
  }
};
