import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StackModal = (props: any) => {
  const { modalId, modalName,size, formComp } = props;
  const modalSizeClassName = size ==="" ? "modal-dialog" : "modal-dialog modal-"+size

  return (
    <>
      <div className="modal"  id={modalId} tabIndex={-1} role="dialog" aria-labelledby={modalId + "Label"} aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div id={modalId+"D"}   className={modalSizeClassName} role="document">
          <div  className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={modalId + "Label"}>
                {modalName}
              </h5>
              <button id={modalId + "CloseIconButton"} className="close" type="button"  aria-label="Close" onClick={() => {}}>
                <span aria-hidden="true">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </button>
            </div>
            <div className="modal-body">{formComp()}</div>
            <div className="modal-footer" hidden></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StackModal;
