import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../redux/store/Store";
import { useParams } from "react-router";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  sendEventMapDataAction,
  sendSelectedEventDataAction,
  sendSelectedEventIdAction,
  sendSelectedEventParamAction,
  sendSelectedEventParamIdAction,
  sendSelectedEventSessionIdRefAction,
} from "../../../../../redux/actions/EventActions";
import { EventItemParamsType, EventItemType, IDeleteEvent, IDeleteEventParam, IEditEvent, IEditEventParam, IEditEventSession, INewEvent, INewParam } from "../../../types/eventTypes";
import { ISingleScenario } from "../../../types/scenarioTypes";
import { sendSelectScenarioIdAction } from "../../../../../redux/actions/scenarioActions";
import { sortBy } from "lodash";
import AddNewEventForm from "../forms/AddNewEventForm";
import AddNewEventParamForm from "../forms/AddNewEventParamForm";
import EditEventManageForm from "../forms/EditEventManageForm";
import EditEventParamForm from "../forms/EditEventParamForm";
import EditSessionIdentifierManageForm from "../forms/EditSessionIdentifierManageForm";
import GenaralModal from "../modals/GenaralModal";
import DeleteEventManageForm from "../forms/DeleteEventManageForm";
import DeleteEventParamForm from "../forms/DeleteEventParamForm";
//import $ from 'jquery';
//import 'bootstrap';



const EventTab = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  const singleScenario:ISingleScenario = useSelector((state: RootStore) => state.singleScenarioReducer.data);
  const newEvent: INewEvent = useSelector((state: RootStore) => state.newEventReducer.data);
  const editEvent: IEditEvent = useSelector((state: RootStore) => state.postEditEventReducer.data);
  const deleteEvent: IDeleteEvent = useSelector((state: RootStore) => state.deleteEventReducer.data);
  const newParam: INewParam = useSelector((state: RootStore) => state.putEventParamReducer.data);
  const editEventParam: IEditEventParam = useSelector((state: RootStore) => state.postEventParamReducer.data);
  const deleteEventParam: IDeleteEventParam = useSelector((state: RootStore) => state.deleteEventParamReducer.data);
  const editEventSession: IEditEventSession = useSelector((state: RootStore) => state.postEventSessionIdentifierReducer.data);

  const [eventMapData, setEventMapData] = useState<{} | null>(null);
  const [selectedScenarioData, setSelectedScenarioData] = useState<ISingleScenario | null>(null);
  const [selectedEventData, setSelectedEventData] = useState<EventItemType | null>(null);
  const [selectedEventParamsData, setSelectedEventParamsData] = useState<EventItemParamsType | null>(null);
  const [selectedEventSessionIdRef, setSelectedEventSessionIdRef] = useState<string | null>(null);
  const [selectedEventId, setSelectedEventId] = useState<string>("");
  const [selectedEventName, setSelectedEventName] = useState<string>("");
  const [selectedEventParamId, setSelectedEventParamId] = useState<string>("");

  useEffect(() => {
    if (id) {
      dispatch(sendSelectScenarioIdAction(id));
    }
  }, []);

  useEffect(() => {
    if(singleScenario){
      setSelectedScenarioData(singleScenario);
    }
  }, [singleScenario]);

  useEffect(() => {
    setEventMap();
    setSelectedEvent();
    setSelectedEventParams();
  }, [selectedScenarioData, selectedEventName]);

  const setEventMap = () => {
    if (selectedScenarioData) {
      setEventMapData(selectedScenarioData.scenario.eventMap);
      dispatch(sendEventMapDataAction(selectedScenarioData.scenario.eventMap));
    }
  };

  const setSelectedEvent = () => {
    if (selectedScenarioData) {
      if (selectedEventName) {
        setSelectedEventData(selectedScenarioData.scenario.eventMap[selectedEventName]);
        setSelectedEventSessionIdRef(selectedScenarioData.scenario.eventMap[selectedEventName].sessionIdRef);
        dispatch(sendSelectedEventSessionIdRefAction(selectedScenarioData.scenario.eventMap[selectedEventName].sessionIdRef));
        dispatch(sendSelectedEventDataAction(selectedScenarioData.scenario.eventMap[selectedEventName]));
      }
    }
  };

  const setSelectedEventParams = () => {
    if (selectedScenarioData) {
      if (selectedEventName) {
        setSelectedEventParamsData(selectedScenarioData.scenario.eventMap[selectedEventName].params);
        dispatch(sendSelectedEventParamAction(selectedScenarioData.scenario.eventMap[selectedEventName].params));
      }
    }
  };

  useEffect(() => {
    if (selectedScenarioData) {
      if (newEvent?.status === "success") {
          const newSingleScenarioObj = { ...selectedScenarioData };
          newSingleScenarioObj.scenario.eventMap[newEvent.data.name] = newEvent.data;
          setSelectedScenarioData(newSingleScenarioObj);
          setSelectedEventId((newEvent.data.id).toString());
          setSelectedEventName(newEvent.data.name);
          $("#AddNewEventModal").modal("hide");

          $(function () {
            $("#eventList").children().removeClass("active");
            $("#eventList")
              .children("#" + newEvent.data.name)
              .addClass("active");
          });
      }
    }
  }, [newEvent]);

  useEffect(() => {
    if (selectedScenarioData) {
        if (editEvent?.response?.status === "success") {
          const singleScenarioObj = { ...selectedScenarioData };
          delete singleScenarioObj.scenario.eventMap[selectedEventName];
          const newSingleScenarioObj = { ...singleScenarioObj };
          newSingleScenarioObj.scenario.eventMap[editEvent.bodyObject?.name] = editEvent.bodyObject;
          setSelectedEventName(editEvent.bodyObject?.name);
          setSelectedScenarioData(newSingleScenarioObj);
          $("#EditEventManageModal").modal("hide");
          $(function () {
            $("#eventList").children().removeClass("active");
            $("#eventList")
              .children("#" + editEvent.bodyObject?.name)
              .addClass("active");
          });
      }
    }
  }, [editEvent]);

  useEffect(() => {
    if (selectedScenarioData) {
        if (deleteEvent?.response?.statusText === "OK") {
          const singleScenarioObj = { ...selectedScenarioData };
          delete singleScenarioObj.scenario.eventMap[selectedEventName];
          setSelectedScenarioData(singleScenarioObj);
          setSelectedEventName("");
          setSelectedEventId("");
          setSelectedEventData(null);
          $("#DeleteEventManageModal").modal("hide");
          $("#eventList").children().removeClass("active");
        }
    }
  }, [deleteEvent]);

  useEffect(() => {
    if (selectedScenarioData) {
        if (newParam?.status === "success") {
          const singleScenarioObj = { ...selectedScenarioData };
          singleScenarioObj.scenario.eventMap[selectedEventName].params[newParam.data.propertyName] = newParam.data;
          setSelectedScenarioData(singleScenarioObj);
          $("#AddNewEventParamModal").modal("hide");
        }
      }
  }, [newParam]);

  useEffect(() => {
    if (selectedScenarioData) {
        if (editEventParam?.response?.status ==="success") {
          const singleScenarioObj = { ...selectedScenarioData };
          delete singleScenarioObj.scenario.eventMap[selectedEventName].params[selectedEventParamId];
          const newSingleScenarioObj = { ...singleScenarioObj };
          newSingleScenarioObj.scenario.eventMap[selectedEventName].params[editEventParam.bodyObj.propertyName] = editEventParam.bodyObj;
          setSelectedScenarioData(newSingleScenarioObj);
          editEventParam.response.status = "";
          $("#EditEventParamModal").modal("hide");
        }
    }
  }, [editEventParam, selectedEventParamId]);

  useEffect(() => {
    if (selectedScenarioData) {
        if (deleteEventParam?.status === "success") {
          const singleScenarioObj = { ...selectedScenarioData };
          delete singleScenarioObj.scenario.eventMap[selectedEventName].params[selectedEventParamId];
          setSelectedScenarioData(singleScenarioObj);
          deleteEventParam.status = "";
          $("#DeleteEventParameterModal").modal("hide");
        }
    }
  }, [deleteEventParam, selectedEventParamId]);

  useEffect(() => {
    if (selectedScenarioData) {
        if (editEventSession?.response?.status === "success") {
          const singleScenarioObj = { ...selectedScenarioData };
          singleScenarioObj.scenario.eventMap[selectedEventName].sessionIdRef = editEventSession.bodyObj.sessionDef;
          setSelectedScenarioData(singleScenarioObj);
          $("#SessionEventManageModal").modal("hide");
        }
    }
  }, [editEventSession]);

  const renderEventManageButton = () => {
    return (
      <>
        <button className="btn btn-outline-primary dropdown-toggle pt-2 pb-2 pl-2 pr-2 ml-1" type="button" id="EventManageDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Manage
        </button>

        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="EventManageDropdown">
          <button
            className="dropdown-item"
            type="button"
            data-toggle="modal"
            data-target="#EditEventManageModal"
            onClick={() => {
              dispatch(sendSelectScenarioIdAction(id));
              dispatch(sendSelectedEventIdAction(selectedEventId));
            }}
          >
            Edit
          </button>

          <button
            className="dropdown-item"
            type="button"
            data-toggle="modal"
            data-target="#SessionEventManageModal"
            onClick={() => {
              dispatch(sendSelectScenarioIdAction(id));
              dispatch(sendSelectedEventIdAction(selectedEventId));
            }}
          >
            Select Session Identifier
          </button>

          <button
            className="dropdown-item"
            type="button"
            data-toggle="modal"
            data-target="#DeleteEventManageModal"
            onClick={() => {
              dispatch(sendSelectScenarioIdAction(id));
              dispatch(sendSelectedEventIdAction(selectedEventId));
            }}
          >
            Delete
          </button>
        </div>
      </>
    );
  };

  const renderEventTimer = (timerFormat: string | null) => {
    var timerFormatDesc: string = "";

    if (timerFormat !== null) {
      var year = timerFormat.substring(0, 4);
      var month = timerFormat.substring(4, 6);
      var day = timerFormat.substring(6, 8);
      var hour = timerFormat.substring(8, 10);
      var minute = timerFormat.substring(10, 12);

      if (year === "****") {
        timerFormatDesc += "Every Year /";
      } else {
        timerFormatDesc += year + " /";
      }

      if (month === "**") {
        timerFormatDesc += "Every Month /";
      } else {
        timerFormatDesc += " " + month + " /";
      }

      if (day === "**") {
        timerFormatDesc += "Every Day on ";
      } else {
        timerFormatDesc += " " + day + " on";
      }

      if (hour === "**") {
        timerFormatDesc += "Every Hour :";
      } else {
        timerFormatDesc += " " + hour + " :";
      }

      if (minute === "**") {
        timerFormatDesc += "Every Minute ";
      } else {
        timerFormatDesc += " " + minute;
      }
    }

    if (timerFormat !== null) {
      return (
        <div>
          <strong> {"Timer Format: "}</strong>
          {timerFormatDesc}
        </div>
      );
    } else {
      return null;
    }
  };

  const renderEventParams = () => {
    return (
      <>
        {selectedEventParamsData
          ? sortBy(Object.values(selectedEventParamsData), "propertyName").map((item: any) => (
              <tr>
                <th scope="row">
                  <span> {item.propertyName}</span>
                  {selectedEventSessionIdRef !== null && selectedEventSessionIdRef === item.propertyName ? (
                    <span
                      style={{
                        fontSize: "10px",
                        marginLeft: "2px",
                      }}
                      className="badge badge-info"
                    >
                      session
                    </span>
                  ) : null}
                </th>
                <td>{item.propertyType}</td>
                <td>{item.aggInfo ? item.aggInfo.type : "N/A"}</td>
                <td>{item.aggInfo ? (item.aggInfo.unit ? item.aggInfo.unit + " " + item.aggInfo.durationType : "-") : "-"}</td>
                <td>{item.aggInfo ? (item.aggInfo.baseTime ? item.aggInfo.baseTime : "-") : "-"}</td>
                <td>{item.description ? item.description : "-"}</td>

                <td style={{ width: "100px" }}>
                  <>
                    <span
                      className="span-button"
                      data-toggle="modal"
                      data-target="#EditEventParamModal"
                      onClick={() => {
                        setSelectedEventParamId(item.propertyName);
                        dispatch(sendSelectedEventIdAction(selectedEventId));
                        dispatch(sendSelectedEventParamIdAction(item.propertyName));
                      }}
                    >
                      Edit
                    </span>
                  </>
                  <>
                    <span
                      className="span-button"
                      data-toggle="modal"
                      data-target="#DeleteEventParameterModal"
                      onClick={() => {
                        setSelectedEventParamId(item.propertyName);
                        dispatch(sendSelectedEventIdAction(selectedEventId));
                        dispatch(sendSelectedEventParamIdAction(item.propertyName));
                      }}
                    >
                      Delete
                    </span>
                  </>
                </td>
              </tr>
            ))
          : null}
      </>
    );
  };

  const renderModals = () => {
    return (
      <>
        <GenaralModal modalId="AddNewEventModal" modalName="New Event" formComp={AddNewEventForm} />
        <GenaralModal modalId="EditEventManageModal" modalName="Edit Event" formComp={EditEventManageForm} />
        <GenaralModal modalId="SessionEventManageModal" modalName="Select Session Identifier" formComp={EditSessionIdentifierManageForm} />
        <GenaralModal modalId="DeleteEventManageModal" modalName="Delete Event" size="lg" formComp={DeleteEventManageForm} />
        <GenaralModal modalId="AddNewEventParamModal" modalName="New Event Parameter" formComp={AddNewEventParamForm} />
        <GenaralModal modalId="EditEventParamModal" modalName="Edit Event Parameter" formComp={EditEventParamForm} />
        <GenaralModal modalId="DeleteEventParameterModal" modalName="Delete Event Parameter" size="lg" formComp={DeleteEventParamForm} />
      </>
    );
  };

  const renderEventTab = () => {
    return (
      <h5 className="card-title">
        <div className="row">
          <div className="col-2 border-right ">
            <div className="list-group list-group-flush ">
              <div className="list-group-item p-1 ">
                <span>
                  <strong>Event List </strong>
                  <small>({eventMapData ? +Object.values(eventMapData).length : 0})</small>
                </span>
                <div>
                  <button className="btn btn-success p-1 mt-2 mb-2" data-toggle="modal" data-target="#AddNewEventModal" onClick={() => {}}>
                    Add New Event
                  </button>
                </div>
              </div>
            </div>

            <div className="list-group list-group-flush " id="eventList" role="tablist">
              {eventMapData
                ? Object.values(eventMapData).length !== 0
                  ? sortBy(Object.values(eventMapData), "name").map((item: any) => (
                      <a
                        className="list-group-item list-group-item-action p-1"
                        id={item.name}
                        data-toggle="list"
                        href={"#" + item.name}
                        role="tab"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          dispatch(sendSelectedEventIdAction(selectedEventId));
                          setSelectedEventId(item.id);
                          setSelectedEventName(item.name);
                        }}
                      >
                        {item.name}
                      </a>
                    ))
                  : " There is no event created"
                : " There is no event created"}
            </div>
          </div>

          <div className="tab-content col-10">
            {id ? (
              selectedEventName ? (
                selectedEventData ? (
                  <div className="tab-pane active" id="home" role="tabpanel">
                    <div className="list-group list-group-flush ">
                      <div className="list-group-item border-0 p-1 ">
                        <div className="row">
                          <div className="col-auto mr-auto">
                            <span>{selectedEventData.name} </span>
                          </div>
                          <div className="col-auto">{renderEventManageButton()}</div>
                        </div>
                      </div>
                      <div className="list-group-item border-0 p-1">
                        <span>
                          <strong> {"Type: "}</strong>
                          {selectedEventData.type}
                        </span>
                      </div>

                      <div className="list-group-item border-0 p-1">
                        <span>{renderEventTimer(selectedEventData.timerFormat)}</span>
                      </div>

                      <div className="list-group-item border-0 p-1">
                        <span style={{ color: "#EF9222" }}>
                          <strong> {"Description: "}</strong>
                        </span>
                        <span>{selectedEventData.description ? selectedEventData.description : "-"}</span>
                      </div>
                    </div>

                    {(selectedEventData.type as string).toUpperCase() !== "TIMER" ? (
                      <div className="card mb-4 mt-3 shadow-sm">
                        <div className="row p-2">
                          <div className="col-auto mr-auto">
                            <strong>Event Parameters</strong>
                            <small> ({selectedEventParamsData ? Object.values(selectedEventParamsData).length : null})</small>
                          </div>
                          <div className="col-auto">
                            <span
                              className="span-button"
                              data-toggle="modal"
                              data-target="#AddNewEventParamModal"
                              onClick={() => {
                                dispatch(sendSelectedEventIdAction(selectedEventId));
                              }}
                            >
                              <FontAwesomeIcon icon={faPlus} className="mr-2" />
                              Add New
                            </span>
                          </div>
                        </div>
                        <table className="table table-hover ">
                          <thead className="bg-light">
                            <tr>
                              <th scope="col">Parameter</th>
                              <th scope="col">Type</th>
                              <th scope="col">Aggregation</th>
                              <th scope="col">Agg. Duration</th>
                              <th scope="col">Agg. Base Time</th>
                              <th scope="col">Description</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>

                          <tbody>{renderEventParams()}</tbody>
                        </table>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <strong>There is no selected scenarioId</strong>
                )
              ) : (
                <strong>select an event</strong>
              )
            ) : (
              <strong>select an event</strong>
            )}
          </div>
        </div>
      </h5>
    );
  };

  return (
    <div>
      {renderModals()}
      {renderEventTab()}
    </div>
  );
};

export default EventTab;
