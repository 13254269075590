import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../redux/store/Store";

const ViewActionParamForm = () => {

 
  const [actionParamData, setactionParamData] = useState<any>(null);
  const [editorValue, seteditorValue] = useState("");
  const [selectectedParamIndex, setSelectectedParamIndex] = useState<number>(0);
 
  const selectedActionParam: any = useSelector((state: RootStore) => state.sendActionParamDataReducer);

  useEffect(() => {
    if (selectedActionParam.data) {
      setactionParamData(selectedActionParam.data);
      if (selectedActionParam.data[selectectedParamIndex]?.field) {
      }
      seteditorValue(selectedActionParam.data[selectectedParamIndex].value);
    }
  }, [selectedActionParam.data, selectectedParamIndex]);

  const renderEditEditorActionParamModalContent = () => {
    return (
      <>
        <div>
          <span>
            {actionParamData ? actionParamData[0]?.key : ""}{" "}
            <small>
              ({actionParamData ? actionParamData[0]?.dataType : ""}) {actionParamData ? (actionParamData[0]?.isRecurring ? "Recurring" : "Non-Requrring") : ""}
            </small>
          </span>
          <div>
            <small style={{ color: "orange" }}>Description</small>
          </div>
        </div>
        <div>
          <small>{actionParamData ? actionParamData[0]?.description : ""}</small>
        </div>

        <div className="mt-3">
          <label>
            <strong>Value</strong>
          </label>
        </div>
        <div style={{maxHeight:"300px",overflow:"auto",paddingBottom:"10px"}}>
        {editorValue}
        </div>
      </>
    );
  };

  return (
    <div>
      {renderEditEditorActionParamModalContent()}
    </div>
  );
};

export default ViewActionParamForm;
