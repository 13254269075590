import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../redux/store/Store";
import { postActionActionReturnsAction } from "../../../../../redux/actions/ActionActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";


const AddActionReturnsLinkForm = () => {
  const dispatch = useDispatch();

  var selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  var selectedAction: any = useSelector((state: RootStore) => state.sendActionDataReducer);
  var selectedActionId: any = useSelector((state: RootStore) => state.sendActionIdReducer);
  var eventMap: any = useSelector((state: RootStore) => state.sendEventMapDataReducer);

  const [eventListData, seteventListData] = useState<any>(null);
  const [returningEventData, setreturningEventData] = useState<any>(null);

  useEffect(() => {
    if (selectedAction.data) {
      if (selectedAction.data.returningEvent) {
        setreturningEventData(selectedAction.data.returningEvent);
      }
    }
  }, [selectedActionId.data, selectedAction.data]);

  useEffect(() => {
    if (eventMap.data) {
      seteventListData(eventMap.data);
    }
  }, [eventMap.data]);

  const handleSubmitReturning = (bodyObj: any, formObj: any) => {
    const sendArray: any = [];

    if (bodyObj.httpResponseEvent) {
      const innerObj = { name: bodyObj.httpResponseEvent, template: "HttpResponseEvent" };
      sendArray.push(innerObj);
    }
    if (bodyObj.genericErrorEvent) {
      const innerObj = { name: bodyObj.genericErrorEvent, template: "GenericErrorEvent" };
      sendArray.push(innerObj);
    }
    dispatch(postActionActionReturnsAction(sendArray, formObj, selectedScenarioId.data, selectedActionId.data));
  };

  const setEventListOptionsGEE = () => {
    return (
      <>
        <option value="" selected label="Not Set" />
        {eventListData ? Object.values(eventListData).map((item: any) => <option value={item.name} label={item.name} />) : null}
      </>
    );
  };

  const setEventListOptionsHRE = () => {
    return (
      <>
        <option value="" selected label="Not Set" />
        {eventListData ? Object.values(eventListData).map((item: any) => <option value={item.name} label={item.name} />) : null}
      </>
    );
  };

  const validationSchema = Yup.object().shape({});

  const formik = useFormik({
    initialValues: {
      httpResponseEvent: returningEventData ? returningEventData[0]?.name : "",
      genericErrorEvent: returningEventData ? returningEventData[1]?.name : "",
    },
    onSubmit: () => {},
    validationSchema: validationSchema,
    enableReinitialize: true,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-group">
        <label className="form-label" htmlFor="genericErrorEvent">
          HttpResponseEvent
        </label>
        <a style={{ float: "right" }} target="_blank" href="http://learn.herogi.com/integrations/http#HttpResponseEvent">
          <FontAwesomeIcon icon={faQuestionCircle} className="fa fa-question-circle" />
        </a>
        <select className="form-control" id="httpResponseEvent" name="httpResponseEvent" value={formik.values.httpResponseEvent} onChange={formik.handleChange}>
          {setEventListOptionsHRE()}
        </select>
      </div>

      <div className="form-group">
        <label className="form-label" htmlFor="httpResponseEvent">
          GenericErrorEvent
        </label>
        <a style={{ float: "right" }} target="_blank" href="http://learn.herogi.com/integrations/http#GenericErrorEvent">
          <FontAwesomeIcon icon={faQuestionCircle} className="fa fa-question-circle" />
        </a>
        <select className="form-control" id="genericErrorEvent" name="genericErrorEvent" value={formik.values.genericErrorEvent} onChange={formik.handleChange}>
          {setEventListOptionsGEE()}
        </select>
      </div>

      <div>
        <div className="modal-footer">
          <button
            id="AddNewActionReturningEventParamModalCloseButton"
            className="btn btn-primary p-2 mt-3"
            type="button"
            data-dismiss="modal"
            onClick={() => {
              formik.resetForm();
            }}
          >
            Close
          </button>
          <button
            id="AddNewActionReturningEventParamModalCreateSaveButton"
            onClick={() => {
              handleSubmitReturning(formik.values, formik);
            }}
            className="btn btn-success p-2 mt-3"
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddActionReturnsLinkForm;
