import * as IntegrationActionTypes from "../types/types";

export const getIntegrationsReducer = (state = [], action: any) => {
  switch (action.type) {
    case IntegrationActionTypes.GET_INTEGRATIONS_FAIL:
      return {
        data: action.payload,
      };
    case IntegrationActionTypes.GET_INTEGRATIONS_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const putIntegrationReducer = (state = {}, action: any) => {
  switch (action.type) {
    case IntegrationActionTypes.ADD_INTEGRATION_FAIL:
      return {
        data: action.payload,
      };
    case IntegrationActionTypes.ADD_INTEGRATION_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const postIntegrationReducer = (state = {}, action: any) => {
  switch (action.type) {
    case IntegrationActionTypes.EDIT_INTEGRATION_FAIL:
      return {
        data: action.payload,
      };
    case IntegrationActionTypes.EDIT_INTEGRATION_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const deleteIntegrationReducer = (state = {}, action: any) => {
  switch (action.type) {
    case IntegrationActionTypes.DELETE_INTEGRATION_FAIL:
      return {
        data: action.payload,
      };
    case IntegrationActionTypes.DELETE_INTEGRATION_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};
