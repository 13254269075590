import { combineReducers } from "redux"
import { getAccountReducer } from "./AccountReducers";
import { deleteActionParamReducer, deleteActionReducer, getIntegrationVendorsReducer, getTemplateReducer, getTemplateWithIdReducer, postActionParamReducer, postActionReducer, postActionReturnsReducer, putActionParamReducer, putActionReducer, putTemplateStringForPreviewReducer, sendActionDataReducer, sendActionIdReducer, sendActionMapDataReducer, sendActionParamDataReducer, sendActionParamIdReducer, sendActionParamsDataReducer, sendActionTemplateParamsDataReducer, sendTemplateStringForPreviewReducer } from "./ActionReducers";
import { addCampaignReducer, deployCampaignReducer, editCampaignReducer, freezeCampaignReducer, getCampaignInitReducer, getCampaignListReducer, getCampaignReducer, reDeployCampaignReducer, resumeCampaignReducer, sendCampaignEventTemplateParamsReducer, unDeployCampaignReducer } from "./campaignReducers";
import { sendEventIdReducer, deleteEventParamReducer, deleteEventReducer, postEditEventReducer, postEventParamReducer, postEventSessionIdentifierReducer, putEventParamReducer, putNewEventReducer, sendEventDataReducer, sendEventParamsReducer, sendEventParamIdReducer, eventSendReducer, sendEventSessionIdReducer, sendEventMapDataReducer, getUniqueEventParamReducer } from "./EventReducers";
import { deleteIntegrationReducer, getIntegrationsReducer, postIntegrationReducer, putIntegrationReducer } from "./IntegrationReducers";
import { deleteScenarioReducer, deployScenarioReducer, freezeScenarioReducer, getScenarioListReducer, getScenarioStatusReducer, getSingleScenarioReducer, postScenarioReducer, putNewScenarioReducer, reDeployScenarioReducer, resumeScenarioReducer, sendScenarioDataReducer, sendScenarioIdReducer, sendScenarioNameListReducer, unDeployScenarioReducer } from "./scenarioReducers";
import { deleteSegmentReducer, getSegmentListReducer, getSegmentReducer, postSegmentReducer, putSegmentReducer, sendSegmentDataReducer, sendSegmentIdReducer } from "./SegmentReducers";
import { deleteSessionReducer, postSessionReducer, putSessionReducer, sendSelectedSessionDataReducer, sendSelectedSessionIdReducer, sendSessionDataReducer } from "./sessionReducers";
import { deleteStateReducer, getStateByIdReducer, getStatesReducer, postStateReducer, putStateReducer, sendStateIdReducer, sendStatesDataReducer } from "./StateReducers";
import { deleteTransitionReducer, postTransitionReducer, putTransitionReducer, sendTransitionDataReducer, sendTransitionIdReducer } from "./TransitionReducers";
import { deleteValidationReducer, getValidationOperandReducer, postValidationReducer, putValidationReducer, sendSelectedValidationDataReducer, sendSelectedValidationIdReducer, sendValidationMapDataReducer } from "./ValidationReducers";
import { getAuidenceReportingReducer } from "./ReportingReducers";

const RootReducer = combineReducers({
  scenariListReducer: getScenarioListReducer,
  singleScenarioReducer: getSingleScenarioReducer,
  newEventReducer: putNewEventReducer,
  postEditEventReducer: postEditEventReducer,
  sendEventDataReducer: sendEventDataReducer,
  sendEventParamsReducer: sendEventParamsReducer,
  deleteEventReducer: deleteEventReducer,
  putEventParamReducer: putEventParamReducer,
  postEventParamReducer: postEventParamReducer,
  deleteEventParamReducer: deleteEventParamReducer,
  postEventSessionIdentifierReducer: postEventSessionIdentifierReducer,
  sendScenarioIdReducer: sendScenarioIdReducer,
  sendEventIdReducer: sendEventIdReducer,
  sendEventParamIdReducer: sendEventParamIdReducer,
  sendScenarioDataReducer: sendScenarioDataReducer,
  putNewScenarioReducer: putNewScenarioReducer,
  postScenarioReducer: postScenarioReducer,
  deleteScenarioReducer: deleteScenarioReducer,
  sendScenarioNameListReducer: sendScenarioNameListReducer,
  eventSendReducer: eventSendReducer,
  putSessionReducer: putSessionReducer,
  postSessionReducer: postSessionReducer,
  deleteSessionReducer: deleteSessionReducer,
  sendSelectedSessionDataReducer: sendSelectedSessionDataReducer,
  sendSelectedSessionIdReducer: sendSelectedSessionIdReducer,
  sendEventSessionIdReducer: sendEventSessionIdReducer,
  getScenarioStatusReducer: getScenarioStatusReducer,
  deployScenarioReducer: deployScenarioReducer,
  reDeployScenarioReducer: reDeployScenarioReducer,
  unDeployScenarioReducer: unDeployScenarioReducer,
  freezeScenarioReducer: freezeScenarioReducer,
  resumeScenarioReducer: resumeScenarioReducer,
  getTemplateReducer: getTemplateReducer,
  getIntegrationVendorsReducer: getIntegrationVendorsReducer,
  sendActionDataReducer: sendActionDataReducer,
  sendActionIdReducer: sendActionIdReducer,
  sendSelectedValidationDataReducer: sendSelectedValidationDataReducer,
  sendSelectedValidationIdReducer: sendSelectedValidationIdReducer,
  getValidationOperandReducer: getValidationOperandReducer,
  putValidationReducer: putValidationReducer,
  postValidationReducer: postValidationReducer,
  deleteValidationReducer: deleteValidationReducer,
  putActionReducer: putActionReducer,
  postActionReducer: postActionReducer,
  deleteActionReducer: deleteActionReducer,
  sendEventMapDataReducer: sendEventMapDataReducer,
  sendActionParamIdReducer: sendActionParamIdReducer,
  postActionReturnsReducer: postActionReturnsReducer,
  putActionParamReducer: putActionParamReducer,
  deleteActionParamReducer: deleteActionParamReducer,
  sendActionParamDataReducer: sendActionParamDataReducer,
  postActionParamReducer: postActionParamReducer,
  sendActionParamsDataReducer: sendActionParamsDataReducer,
  sendActionTemplateParamsDataReducer: sendActionTemplateParamsDataReducer,
  getIntegrationsReducer: getIntegrationsReducer,
  putIntegrationReducer: putIntegrationReducer,
  postIntegrationReducer: postIntegrationReducer,
  deleteIntegrationReducer: deleteIntegrationReducer,
  putStateReducer: putStateReducer,
  postStateReducer: postStateReducer,
  deleteStateReducer: deleteStateReducer,
  sendActionMapReducer: sendActionMapDataReducer,
  sendStateIdReducer: sendStateIdReducer,
  getStatesReducer: getStatesReducer,
  sendStatesDataReducer: sendStatesDataReducer,
  putTransitionReducer: putTransitionReducer,
  postTransitionReducer: postTransitionReducer,
  deleteTransitionReducer: deleteTransitionReducer,
  sendTransitionIdReducer: sendTransitionIdReducer,
  getStateByIdReducer: getStateByIdReducer,
  sendTransitionDataReducer: sendTransitionDataReducer,
  getUniqueEventParamReducer: getUniqueEventParamReducer,
  sendSessionDataReducer: sendSessionDataReducer,
  sendTemplateStringForPreviewReducer: sendTemplateStringForPreviewReducer,
  putTemplateStringForPreviewReducer: putTemplateStringForPreviewReducer,
  sendValidationMapDataReducer: sendValidationMapDataReducer,
  sendSegmentDataReducer: sendSegmentDataReducer,
  putSegmentReducer: putSegmentReducer,
  postSegmentReducer: postSegmentReducer,
  deleteSegmentReducer: deleteSegmentReducer,
  sendSegmentIdReducer: sendSegmentIdReducer,
  getCampaignListReducer,
  addCampaignReducer,
  editCampaignReducer,
  getCampaignReducer,
  getCampaignInitReducer,
  deployCampaignReducer,
  reDeployCampaignReducer,
  unDeployCampaignReducer,
  freezeCampaignReducer,
  resumeCampaignReducer,
  getTemplateWithIdReducer,
  sendCampaignEventTemplateParamsReducer,
  getSegmentListReducer,
  getSegmentReducer,
  getAccountReducer,
  getAuidenceReportingReducer
});

export default RootReducer