import * as EventActionTypes from "../types/types";
import { apiErrorHandle } from "../../components/common/hooksAndFuncts/ApiErrorHandle";
import axios from "axios";


export const eventTimerFormatHandler = (
   bodyObject:any
  ) => {
    var timerFormat: string | null = "";

  
    if (bodyObject.everyYear == "") {
      timerFormat += "*".repeat(4);
    } else {
      timerFormat +=bodyObject.everyYear;
    }
  
    if (bodyObject.everyMonth == "") {
      timerFormat += "*".repeat(2);
    } else {
      timerFormat += bodyObject.everyMonth;
    }
  
    if (bodyObject.everyDay == "") {
      timerFormat += "*".repeat(2);
    } else {
      timerFormat += bodyObject.everyDay;
    }


    if(bodyObject.everyHour){
      if (bodyObject.everyHour == "") {
        timerFormat += "*".repeat(2);
      } else {
        timerFormat += bodyObject.everyHour;
      }
    }

    if(bodyObject.everyMinute){
      if (bodyObject.everyMinute == "") {
        timerFormat += "*".repeat(2);
      } else {
        timerFormat +=bodyObject.everyMinute;
      }
    }

    alert(JSON.stringify(timerFormat))

    if(bodyObject.eventType){
      if ((bodyObject.eventType as string).toUpperCase()  === "REGULAR") {
        timerFormat = null;
      }
    }
  
    return timerFormat;
  };


export const putNewEventAction = (bodyObject:any,scenarioId:string,formObj:any) => async (dispatch: any) => {

    var timerFormat = eventTimerFormatHandler(
        bodyObject
      );
    try {
      dispatch({
        type: EventActionTypes.ADD_NEW_EVENT_LOADING,
      });
      await axios
        .put(`/scenario/${scenarioId}/event`,
        {
        description:bodyObject.description,
        name:bodyObject.eventName,
        timerFormat:timerFormat,
        type:bodyObject.eventType    
        })
        .then((response) => {
          formObj.resetForm();
          dispatch({
            type: EventActionTypes.ADD_NEW_EVENT_SUCCESS,
            payload: response.data,
          });
        }).catch((error) =>{
          apiErrorHandle(error, "postNewEventApiError");
          dispatch({
            type: EventActionTypes.ADD_NEW_EVENT_FAIL,
            payload: error.response.data,
          });
        })
    } catch (error) {
      console.log(error,"error");
     
    }
  };


  export const postEditEventAction = (bodyObject:any,scenarioId:string,eventId:string) => async (dispatch: any) => {

   
    try {
      dispatch({
        type: EventActionTypes.EDIT_EVENT_LOADING,
      });
      await axios
        .post(`/scenario/${scenarioId}/event/${eventId}`,
        bodyObject)
        .then((response) => {
          dispatch({
            type: EventActionTypes.EDIT_EVENT_SUCCESS,
            payload: {bodyObject,response:response.data},
          });
        }).catch((error) =>{
          apiErrorHandle(error, "postEditEventApiError");
          dispatch({
            type: EventActionTypes.EDIT_EVENT_FAIL,
            payload: error.response.data,
          });
        })
    } catch (error) {
      console.log(error,"error");
     
    }
  };

  export const deleteEventAction = (scenarioId:string,eventId:string) => async (dispatch: any) => {
    try {
      dispatch({
        type: EventActionTypes.DELETE_EVENT_LOADING,
      });
      await axios
        .delete(`/scenario/${scenarioId}/event/${eventId}`,
        )
        .then((response) => {
          dispatch({
            type: EventActionTypes.DELETE_EVENT_SUCCESS,
            payload: {response:response},
          });
        }).catch((error) =>{
          apiErrorHandle(error, "deleteEventApiError");
          dispatch({
            type: EventActionTypes.DELETE_EVENT_FAIL,
            payload: error.data,
          });
        })
    } catch (error) {
      console.log(error,"error");
    
    }
  };

  export const putEventParamAction = (bodyObject:any,scenarioId:string,eventId:string,formObj:any) => async (dispatch: any) => {

    try {
      dispatch({
        type: EventActionTypes.ADD_EVENT_PARAM_LOADING,
      });
      await axios
        .put(`/scenario/${scenarioId}/event/${eventId}/param`,
            bodyObject)
        .then((response) => {
          formObj.resetForm();
          dispatch({
            type: EventActionTypes.ADD_EVENT_PARAM_SUCCESS,
            payload: response.data,
          });
        }).catch((error) =>{
          apiErrorHandle(error, "putEventParamApiError");
          dispatch({
            type: EventActionTypes.ADD_EVENT_PARAM_FAIL,
            payload: error.response.data,
          });
        })
    } catch (error) {
      console.log(error,"error");
     
    }
  };

  export const postEventParamAction = (bodyObj:any,scenarioId:string,eventId:string,propertyName:string) => async (dispatch: any) => {
    try {
      dispatch({
        type: EventActionTypes.EDIT_EVENT_PARAM_LOADING,
      });
      await axios
        .post(`/scenario/${scenarioId}/event/${eventId}/param/${propertyName}`,bodyObj
        )
        .then((response) => {
          dispatch({
            type: EventActionTypes.EDIT_EVENT_PARAM_SUCCESS,
            payload: {bodyObj,response:response.data},
          });
        }).catch((error) =>{
          apiErrorHandle(error, "postEventParamApiError");
          dispatch({
            type: EventActionTypes.EDIT_EVENT_PARAM_FAIL,
            payload: error.response.data,
          });
        })
    } catch (error) {
      console.log(error,"error");
     
    }
  };

  export const deleteEventParamAction = (scenarioId:string,eventId:string,propertyName:string) => async (dispatch: any) => {
    try {
      dispatch({
        type: EventActionTypes.DELETE_EVENT_PARAM_LOADING,
      });
      await axios
        .delete(`/scenario/${scenarioId}/event/${eventId}/param/${propertyName}`,
        )
        .then((response) => {
          dispatch({
            type: EventActionTypes.DELETE_EVENT_PARAM_SUCCESS,
            payload: response.data,
          });
        }).catch((error) =>{
          apiErrorHandle(error, "deleteEventParamApiError");
        })
    } catch (error) {
      console.log(error,"error");
     
    }
  };

  export const postEditEventSessionIdentiferAction = (bodyObj:any,scenarioId:string,eventId:string,formObj:any) => async (dispatch: any) => {
    try {
      dispatch({
        type: EventActionTypes.EDIT_EVENT_SESSION_IDENTIFIER_LOADING
      });
      await axios
        .post(`/scenario/${scenarioId}/event/${eventId}/session`,bodyObj
        )
        .then((response) => {
          formObj.resetForm()
          dispatch({
            type: EventActionTypes.EDIT_EVENT_SESSION_IDENTIFIER_SUCCESS,
            payload: {bodyObj,response:response.data},
          });
        }).catch((error) =>{
          apiErrorHandle(error, "postEditEventSessionIdentiferApiError");
          dispatch({
            type: EventActionTypes.EDIT_EVENT_SESSION_IDENTIFIER_FAIL,
            payload: error.data,
          });
        })
    } catch (error) {
      console.log(error,"error");
     
    }
  };





  export const eventSendAction = (bodyObj:any) => async (dispatch: any) => {
   
    try {
      dispatch({
        type: EventActionTypes.SEND_EVENT_LOADING,
      });
      await axios
        .post(`/event/send`,
        bodyObj)
        .then((response) => {
          dispatch({
            type: EventActionTypes.SEND_EVENT_SUCCESS,
            payload: response.data,
          });
        }).catch((error) =>{
          apiErrorHandle(error, "eventSendApiError");
          dispatch({
            type: EventActionTypes.SEND_EVENT_FAIL,
            payload: error.response.data,
          });
        })
    } catch (error) {
      console.log(error,"error");
     
    }
  };


  
  export const sendEventMapDataAction = (eventMapData:object) => (dispatch: any) => {
    try {
      dispatch({
        type: EventActionTypes.SEND_EVENTMAP_DATA_LOADING,
      });
        dispatch({
            type: EventActionTypes.SEND_EVENTMAP_DATA_SUCCESS,
            payload: eventMapData,
          });
    } catch (error) {
      console.log(error,"error");
      dispatch({
        type: EventActionTypes.SEND_EVENTMAP_DATA_FAIL,
        payload: error,
      });
    }
  };

  export const sendEventParamIdAction = (eventParamId:string) => (dispatch: any) => {
    try {
      dispatch({
        type: EventActionTypes.SEND_EVENT_PARAM_ID_LOADING,
      });
        dispatch({
            type: EventActionTypes.SEND_EVENT_PARAM_ID_SUCCESS,
            payload: eventParamId,
          });
    } catch (error) {
      console.log(error,"error");
      dispatch({
        type: EventActionTypes.SEND_EVENT_PARAM_ID_FAIL,
        payload: error,
      });
    }
  };




  export const sendSelectedEventDataAction = (selectedEventData:object) => (dispatch: any) => {
    try {
      dispatch({
        type: EventActionTypes.SEND_EVENT_DATA_LOADING,
      });
        dispatch({
            type: EventActionTypes.SEND_EVENT_DATA_SUCCESS,
            payload: selectedEventData,
          });
    } catch (error) {
      console.log(error,"error");
      dispatch({
        type: EventActionTypes.SEND_EVENT_DATA_FAIL,
        payload: error,
      });
    }
  };


  export const sendSelectedEventParamAction = (selectedEventParamsData:object) => (dispatch: any) => {
    try {
      dispatch({
        type: EventActionTypes.SEND_EVENT_PARAMS_LOADING,
      });
        dispatch({
            type: EventActionTypes.SEND_EVENT_PARAMS_SUCCESS,
            payload: selectedEventParamsData,
          });
    } catch (error) {
      console.log(error,"error");
      dispatch({
        type: EventActionTypes.SEND_EVENT_PARAMS_FAIL,
        payload: error,
      });
    }
  };


  export const sendSelectedEventIdAction = (selectedEventId:string) => (dispatch: any) => {
    try {
      dispatch({
        type: EventActionTypes.SEND_EVENT_ID_LOADING,
      });
        dispatch({
            type: EventActionTypes.SEND_EVENT_ID_SUCCESS,
            payload: selectedEventId,
          });
    } catch (error) {
      console.log(error,"error");
      dispatch({
        type: EventActionTypes.SEND_EVENT_ID_FAIL,
        payload: error,
      });
    }
  };

  // typescript v4.0 changed error type any to unknown so added error type to those lines 


  export const sendSelectedEventSessionIdRefAction = (selectedSessionIdRef:string) => (dispatch: any) => {
    try {
      dispatch({
        type: EventActionTypes.SEND_SELECTED_EVENT_SESSION_ID_LOADING,
      });
        dispatch({
            type: EventActionTypes.SEND_SELECTED_EVENT_SESSION_ID_SUCCESS,
            payload: selectedSessionIdRef,
          });
    } catch (error) {
      console.log(error,"error");
        dispatch({
          type: EventActionTypes.SEND_SELECTED_EVENT_SESSION_ID_FAIL,
          payload: error,
        });
      
    }
  };



  export const sendSelectedEventParamIdAction = (selectedEventParamId:string) => (dispatch: any) => {
    try {
      dispatch({
        type: EventActionTypes.SEND_EVENT_PARAM_ID_LOADING,
      });
        dispatch({
            type: EventActionTypes.SEND_EVENT_PARAM_ID_SUCCESS,
            payload: selectedEventParamId,
          });
    } catch (error) {
      console.log(error,"error");
      dispatch({
        type: EventActionTypes.SEND_EVENT_PARAM_ID_FAIL,
        payload: error,
      });
    }
  };


  export const getEventUniqueEventParamAction = (scenarioId:string) => async (dispatch: any) => {
    try {
      dispatch({
        type: EventActionTypes.GET_UNIQUE_EVENT_PARAMS_LOADING,
      });
  
      await axios
        .get(`/scenario/${scenarioId}/eventparam`)
        .then((response) => {
          dispatch({
            type: EventActionTypes.GET_UNIQUE_EVENT_PARAMS_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          apiErrorHandle(error, "getUniqueEventParamsError");
        });
    } catch (error) {
      console.log(error, "error");
      dispatch({
        type: EventActionTypes.GET_UNIQUE_EVENT_PARAMS_FAIL,
        payload: error,
      });
    }
  };






