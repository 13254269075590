import { BarElement, CategoryScale, Chart, ChartOptions, Legend, LinearScale, LineController, PointElement, registerables, TimeScale, Title, Tooltip } from "chart.js";
import moment from "moment";
import { Line } from "react-chartjs-2";
import 'chartjs-adapter-moment';


const MultiLineChart = (props: any) => {

    // Register the necessary components and scales
    Chart.register(CategoryScale, LinearScale, PointElement, LineController, Title, Legend, Tooltip, TimeScale, BarElement);
    Chart.register(...registerables);


    const xUnitStep = props.xUnit ? props.xUnit : 7;

    const graphData: any = {
        labels: [],
        datasets: [],
    }

    const lineOption = {
        fill: true,
        lineTension: 0.1,
        backgroundColor: "#F0FAFF",
        borderColor: "#6C94BC",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#6C94BC",
        pointBackgroundColor: "#F0FAFF",
        pointBorderWidth: 0.5,
        pointHoverRadius: 2,
        pointHoverBackgroundColor: "#F0FAFF",
        pointHoverBorderColor: "#6C94BC",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        borderWidth: 3
    };

    const colorPending = {
        backgroundColor: "#B68947",
        borderColor: "#7E5716",
        pointBorderColor: "#7E5716",
        pointBackgroundColor: "#B68947",
        pointHoverBackgroundColor: "#B68947",
        pointHoverBorderColor: "#7E5716",
    };

    const options: ChartOptions<'line'> = {
        elements: {
            point: {
                radius: 2
            }
        },
        scales: {
            y: {
                id: 'y-axis-1',
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    beginAtZero: true,
                    precision: 0
                }
            },
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    stepSize: xUnitStep,
                    displayFormats: {
                        day: 'MMM DD'
                    }
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Days'
                },

                id: 'x-axis-1',
            }
        }
    };

    if (props.graphData) {
        props.graphData.forEach(d => {
            let newD = { ...lineOption };
            newD["data"] = [...d["data"]];
            newD["label"] = d["label"];
            if (d["label"] == "Pending") {
                newD = { ...newD, ...colorPending };
            }
            graphData.datasets.push(newD);
        })
        //graphData["datasets"] = [...props.graphData];
    }

    if (props.labelData) {
        graphData.labels = props.labelData;
    }

    return (
        <Line data={graphData} options={options} />
    )
}

export default MultiLineChart;