import * as stateActionTypes from "../types/types";
import { apiErrorHandle } from "../../components/common/hooksAndFuncts/ApiErrorHandle";
import axios from "axios";
import { faWindowRestore } from "@fortawesome/free-solid-svg-icons";

export const getStatesAction = (scenarioId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: stateActionTypes.GET_STATES_LOADING,
    });

    await axios
      .get(`/scenario/${scenarioId}/state`)
      .then((response) => {
        dispatch({
          type: stateActionTypes.GET_STATES_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "getStatesActionApiError");
        dispatch({
          type: stateActionTypes.GET_STATES_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const putStateAction = (bodyObj: object, scenarioId: string, formObj: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: stateActionTypes.ADD_STATE_LOADING,
    });

    await axios
      .put(`/scenario/${scenarioId}/state`, bodyObj)
      .then((response) => {
        /* window.location.reload(); */
        formObj.resetForm();
        dispatch({
          type: stateActionTypes.ADD_STATE_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "putActionActionApiError");
        dispatch({
          type: stateActionTypes.ADD_STATE_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const postStateAction = (bodyObj: object, scenarioId: string, stateId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: stateActionTypes.EDIT_STATE_LOADING,
    });

    await axios
      .post(`/scenario/${scenarioId}/state/${stateId}`, bodyObj)
      .then((response) => {
        dispatch({
          type: stateActionTypes.EDIT_STATE_SUCCESS,
          payload: { response: response, stateId: stateId },
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "postStateActionApiError");
        dispatch({
          type: stateActionTypes.EDIT_STATE_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const deleteStateAction = (scenarioId: string, stateId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: stateActionTypes.DELETE_STATE_LOADING,
    });

    await axios
      .delete(`/scenario/${scenarioId}/state/${stateId}`)
      .then((response) => {
       /*  window.location.reload(); */

        dispatch({
          type: stateActionTypes.DELETE_STATE_SUCCESS,
          payload: { response: response, stateId: stateId },
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "deleteStateActionApiError");
        dispatch({
          type: stateActionTypes.DELETE_STATE_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const getStateByIdAction = (stateId: string, scenarioId: string) => async (dispatch: any) => {
  const params = {
    query: stateId,
  };
  try {
    dispatch({
      type: stateActionTypes.GET_STATE_LOADING,
    });

    await axios
      .get(`/scenario/${scenarioId}/state`, { params })
      .then((response) => {
        dispatch({
          type: stateActionTypes.GET_STATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "getStateByIdActionApiError");
        dispatch({
          type: stateActionTypes.GET_STATE_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const sendStatesDataAction = (statesData:any) => (dispatch: any) => {
  try {
    dispatch({
      type: stateActionTypes.SEND_STATES_DATA_LOADING,
    });
    dispatch({
      type: stateActionTypes.SEND_STATES_DATA_SUCCESS,
      payload: statesData,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: stateActionTypes.SEND_STATES_DATA_FAIL,
      payload: error,
    });
  }
};

export const sendSelectedStateIdAction = (selectedStateId: string) => (dispatch: any) => {
  try {
    dispatch({
      type: stateActionTypes.SEND_STATE_ID_LOADING,
    });
    dispatch({
      type: stateActionTypes.SEND_STATE_ID_SUCCESS,
      payload: selectedStateId,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: stateActionTypes.SEND_STATE_ID_FAIL,
      payload: error,
    });
  }
};
