import { useEffect, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

export const EventFilterTable = (props) => {

    const pageSize = props.pageSize;
    const eventStream = props.eventStream;
    const sizeOfEventStream = props.sizeOfEventStream;
    const submitQuery = props.submitQuery;
    const eventStreamColumns = props.eventStreamColumns;
    const disablePage = props.disablePage ? props.disablePage : false;

    const tableRef = useRef<any>(null);

    const [options, setOptions] = useState<any>(
        {
            paginationSize: 5,
            pageStartIndex: 1,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: false,
            nextPageTitle: "First page",
            prePageTitle: "Pre page",
            firstPageTitle: "Next page",
            lastPageTitle: "Last page",
            showTotal: true,
            totalSize: sizeOfEventStream,
            onPageChange : (p: number) => {
                submitQuery(p);
            },
            disablePageTitle: true,
            sizePerPage: pageSize
          }
      );
      
      useEffect(() => {

        if(disablePage){

          setOptions(prevOptions => ({
            ...prevOptions,
            hidePageListOnlyOnePage: true,
            showTotal: false,
        })); 
        }

       
      }, [disablePage]);

       useEffect(() => {
        setOptions(prevOptions => ({
            ...prevOptions,
            totalSize : sizeOfEventStream
        }));
    }, [sizeOfEventStream]);

      return (
        <BootstrapTable
                    ref={tableRef}
                    keyField="id"
                    bordered = {false}
                    striped = {false}
                    data={eventStream}
                    remote={ { pagination: true, filter: false, sort: false } }
                    onTableChange = {(type, newState) => {
                      }}
                    columns={eventStreamColumns}
                    /*  selectRow={selectRow} */
                    pagination={paginationFactory(options)}
                  />
      );

}