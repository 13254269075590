import { useDispatch, useSelector } from "react-redux";
import { deleteEventParamAction } from "../../../../../redux/actions/EventActions";
import { RootStore } from "../../../../../redux/store/Store";

const DeleteEventParamForm = () => {
  const dispatch = useDispatch();

  const selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  const selectedEventId: any = useSelector((state: RootStore) => state.sendEventIdReducer);
  const selectedEventParamId: any = useSelector((state: RootStore) => state.sendEventParamIdReducer);
  const eventSessionId: any = useSelector((state: RootStore) => state.sendEventSessionIdReducer);

  return (
    <div>
      <p>Are you sure to delete event parameter? This operation is not returnable and parameter data will be deleted permanently.</p>

      <div className="modal-footer">
        <button id="DeleteEventParamModalCloseButton" className="btn btn-light mt-3" type="button" data-dismiss="modal" onClick={() => {}}>
          Cancel
        </button>
        <button
          id="DeleteEventParamModalDeleteButton"
          className="btn btn-danger mt-3"
          type="button"
          onClick={() => {
            if (eventSessionId.data == selectedEventParamId.data) {
              alert("Event parameter is used as session id reference can not be deleted. Use different event parameter or default session and then try again.");
            } else {
              dispatch(deleteEventParamAction(selectedScenarioId.data, selectedEventId.data, selectedEventParamId.data));
            }
          }}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteEventParamForm;
