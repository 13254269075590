import { Account } from "../../services/AccountService";

export const defaultApiState = {
    data: null,
    loading: false,
    error: null,
  };

export const addScenarioInitialState = {};
export const editScenarioInitialState = {};
export const deleteScenarioInitialState = {};
export const scenarioIdInitialState = "";
export const scenarioListInitialState = [];
export const singleScenarioInitialState = {};
export const eventInitialState = {};
export const eventParamInitialState = {};
export const editEventInitialState = {};
export const sendEventDataInitialState = {};
export const deleteEventDataInitialState = {};
export const addEventParamDataInitialState = {};
export const deleteEventParamInitialState = {};
export const editEventParamInitialState = {};
export const sendEventParamsInitialState = {};
export const editEventSessionIdentifierInitialState = {};
export const sendEventInitialState = {};
export const reDeployInitialState = {};
export const deployInitialState = {};
export const unDeployInitialState = {};
export const freezeInitialState = {};
export const resumeInitialState = {};
export const eventSendInitialState = {};
export const putSessionInitialState = {};
export const postSessionInitialState = {};
export const deleteSessionInitialState = {};
export const sendSelectedSessionDataInitialState = {};
export const getUniqueEventParamsDataInitialState = [];
export const sendSessionDataInitialState = {};

//campaign
export const addCampaignInitialState = {};
export const editCampaignInitialState = {};
export const getCampaignListInitialState = {};


//account
export const getAccountInitialState = new Account({
    id: -1,
    name: "N/A",
    email: "N/A",
    enabledFeatures: {
        campaignLimits: 1,
        segmentLimits: 1,
        eventProcessing: false,
        cdp: true,
        customAttributes: false,
        triggerEvents: ["SDK"],
        actionTypes: ["_ALL"]
    },
});

//Reporting
export const getAudienceMetricsInitialState = defaultApiState;



