import * as ActionActionTypes from "../types/types";
import { apiErrorHandle } from "../../components/common/hooksAndFuncts/ApiErrorHandle";
import axios from "axios";


export const getTemplateActions = () => async (dispatch: any) => {
  try {
    dispatch({
      type: ActionActionTypes.GET_TEMPLATE_LOADING,
    });

    await axios
      .get("/action/template")
      .then((response) => {
        dispatch({
          type: ActionActionTypes.GET_TEMPLATE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "getTemplateApiError");
        dispatch({
          type: ActionActionTypes.GET_TEMPLATE_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const getTemplateActionsWithId = (id:string) => async (dispatch: any) => {
  try {
    dispatch({
      type: ActionActionTypes.GET_TEMPLATE_WITH_ID_LOADING,
    });

    await axios
      .get(`/action/template/${id}`)
      .then((response) => {
        dispatch({
          type: ActionActionTypes.GET_TEMPLATE_WITH_ID_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "getTemplateWithIdApiError");
        dispatch({
          type: ActionActionTypes.GET_TEMPLATE_WITH_ID_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const getRawActionTemplateById = (id:string) => {

  return axios
      .get("/action/templateraw/" + id, {
        headers: {
          "Content-Type": "application/json",
        },
      });
};

export const getIntegrationVendorsAction = (vendorId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: ActionActionTypes.GET_INTEGRATION_VENDORS_LOADING,
    });

    await axios
      .get(`/integration/vendor/${vendorId}`)
      .then((response) => {
        dispatch({
          type: ActionActionTypes.GET_INTEGRATION_VENDORS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "getIntegrationVendorsApiError");
        dispatch({
          type: ActionActionTypes.GET_INTEGRATION_VENDORS_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const putActionAction = (bodyObj: object, scenarioId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: ActionActionTypes.ADD_ACTION_LOADING,
    });

    await axios
      .put(`/scenario/${scenarioId}/action`, bodyObj)
      .then((response) => {
        dispatch({
          type: ActionActionTypes.ADD_ACTION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "putActionActionApiError");
        dispatch({
          type: ActionActionTypes.ADD_ACTION_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const postActionAction = (bodyObj: object, scenarioId: string, actionId: string, newObj: object) => async (dispatch: any) => {
  try {
    dispatch({
      type: ActionActionTypes.EDIT_ACTION_LOADING,
    });

    await axios
      .post(`/scenario/${scenarioId}/action/${actionId}`, bodyObj)
      .then((response) => {
        dispatch({
          type: ActionActionTypes.EDIT_ACTION_SUCCESS,
          payload: { newObj, response: response.data },
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "postActionActionApiError");
        dispatch({
          type: ActionActionTypes.EDIT_ACTION_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const deleteActionAction = (scenarioId: string, actionId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: ActionActionTypes.DELETE_ACTION_LOADING,
    });

    await axios
      .delete(`/scenario/${scenarioId}/action/${actionId}`)
      .then((response) => {
        dispatch({
          type: ActionActionTypes.DELETE_ACTION_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "deleteActionActionApiError");
        dispatch({
          type: ActionActionTypes.DELETE_ACTION_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const postActionActionReturnsAction = (bodyObj: object, formObj: any, scenarioId: string, actionId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: ActionActionTypes.ACTION_RETURNS_LOADING,
    });

    await axios
      .post(`/scenario/${scenarioId}/action/${actionId}/event`, bodyObj)
      .then((response) => {
        formObj.resetForm();
        dispatch({
          type: ActionActionTypes.ACTION_RETURNS_SUCCESS,
          payload: { bodyObj, response: response },
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "postActionActionApiError");
        dispatch({
          type: ActionActionTypes.ACTION_RETURNS_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const putActionParamAction = (bodyObj: object, scenarioId: string, actionId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: ActionActionTypes.ADD_ACTION_PARAM_LOADING,
    });

    await axios
      .put(`/scenario/${scenarioId}/action/${actionId}/param`, bodyObj)
      .then((response) => {
        dispatch({
          type: ActionActionTypes.ADD_ACTION_PARAM_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "postActionActionApiError");
        dispatch({
          type: ActionActionTypes.ADD_ACTION_PARAM_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const postActionParamAction = (bodyObj: any, scenarioId: string, actionId: string, actionParamId: string, actionParamField?: string) => async (dispatch: any) => {
  const params = {
    field: actionParamField,
  };

  try {
    dispatch({
      type: ActionActionTypes.EDIT_ACTION_PARAM_LOADING,
    });

    await axios
      .post(`/scenario/${scenarioId}/action/${actionId}/param/${actionParamId}`, bodyObj, { params })
      .then((response) => {
        dispatch({
          type: ActionActionTypes.EDIT_ACTION_PARAM_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "postActionActionApiError");
        dispatch({
          type: ActionActionTypes.EDIT_ACTION_PARAM_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const deleteActionParamAction = (deleteObj: object, scenarioId: string, actionId: string, paramId: string, actionParamField?: string) => async (dispatch: any) => {
  const params = {
    field: actionParamField,
  };

  try {
    dispatch({
      type: ActionActionTypes.DELETE_ACTION_PARAM_LOADING,
    });

    await axios
      .delete(`/scenario/${scenarioId}/action/${actionId}/param/${paramId}`, { params })
      .then((response) => {
        dispatch({
          type: ActionActionTypes.DELETE_ACTION_PARAM_SUCCESS,
          payload: { deleteObj, response: response },
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "deleteActionActionApiError");
        dispatch({
          type: ActionActionTypes.DELETE_ACTION_PARAM_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const sendActionMapDataAction = (actionMap: object) => (dispatch: any) => {
  try {
    dispatch({
      type: ActionActionTypes.SEND_ACTION_MAP_LOADING,
    });
    dispatch({
      type: ActionActionTypes.SEND_ACTION_MAP_SUCCESS,
      payload: actionMap,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: ActionActionTypes.SEND_ACTION_MAP_FAIL,
      payload: error,
    });
  }
};

export const sendSelectedActionDataAction = (selectedAction: object) => (dispatch: any) => {
  try {
    dispatch({
      type: ActionActionTypes.SEND_ACTION_DATA_LOADING,
    });
    dispatch({
      type: ActionActionTypes.SEND_ACTION_DATA_SUCCESS,
      payload: selectedAction,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: ActionActionTypes.SEND_ACTION_DATA_FAIL,
      payload: error,
    });
  }
};

export const sendSelectedActionIdAction = (selectedActionId: string) => (dispatch: any) => {
  try {
    dispatch({
      type: ActionActionTypes.SEND_ACTION_ID_LOADING,
    });
    dispatch({
      type: ActionActionTypes.SEND_ACTION_ID_SUCCESS,
      payload: selectedActionId,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: ActionActionTypes.SEND_ACTION_ID_FAIL,
      payload: error,
    });
  }
};

export const sendSelectedActionParamDataAction = (selectedActionParamData: any) => (dispatch: any) => {
  try {
    dispatch({
      type: ActionActionTypes.SEND_ACTION_PARAM_DATA_LOADING,
    });
    dispatch({
      type: ActionActionTypes.SEND_ACTION_PARAM_DATA_SUCCESS,
      payload: selectedActionParamData,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: ActionActionTypes.SEND_ACTION_PARAM_DATA_FAIL,
      payload: error,
    });
  }
};

export const sendSelectedActionParamsDataAction = (selectedActionParamsData: any) => (dispatch: any) => {
  try {
    dispatch({
      type: ActionActionTypes.SEND_ACTION_PARAMS_DATA_LOADING,
    });
    dispatch({
      type: ActionActionTypes.SEND_ACTION_PARAMS_DATA_SUCCESS,
      payload: selectedActionParamsData,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: ActionActionTypes.SEND_ACTION_PARAMS_DATA_FAIL,
      payload: error,
    });
  }
};

export const sendSelectedActionParamIdAction = (selectedActionParamId: string) => (dispatch: any) => {
  try {
    dispatch({
      type: ActionActionTypes.SEND_ACTION_PARAM_ID_LOADING,
    });
    dispatch({
      type: ActionActionTypes.SEND_ACTION_PARAM_ID_SUCCESS,
      payload: selectedActionParamId,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: ActionActionTypes.SEND_ACTION_PARAM_ID_FAIL,
      payload: error,
    });
  }
};

export const sendSelectedActionTemplateParamsDataAction = (actionTemplateParamsData: any) => (dispatch: any) => {
  try {
    dispatch({
      type: ActionActionTypes.SEND_ACTION_TEMPLATE_PARAMS_DATA_LOADING,
    });
    dispatch({
      type: ActionActionTypes.SEND_ACTION_TEMPLATE_PARAMS_DATA_SUCCESS,
      payload: actionTemplateParamsData,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: ActionActionTypes.SEND_ACTION_TEMPLATE_PARAMS_DATA_FAIL,
      payload: error,
    });
  }
};


export const sendTemplateStringForPreview = (template: any) => (dispatch: any) => {
  try {
    dispatch({
      type: ActionActionTypes.SEND_TEMPLATE_STRING_LOADING,
    });
    dispatch({
      type: ActionActionTypes.SEND_TEMPLATE_STRING_SUCCESS,
      payload: template,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: ActionActionTypes.SEND_TEMPLATE_STRING_FAIL,
      payload: error,
    });
  }
};


export const putTemplatePreviewDataAction = (bodyObj: object, scenarioId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: ActionActionTypes.PUT_TEMPLATE_PREVIEW_DATA_LOADING,
    });

    await axios
      .put(`/scenario/${scenarioId}/action`, bodyObj)
      .then((response) => {
        dispatch({
          type: ActionActionTypes.PUT_TEMPLATE_PREVIEW_DATA_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "putTemplatePreviewDataApiError");
        dispatch({
          type: ActionActionTypes.PUT_TEMPLATE_PREVIEW_DATA_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};
