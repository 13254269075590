import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../redux/store/Store";
import { eventSendAction } from "../../../../../redux/actions/EventActions";
import { FieldArray, Form, Formik, getIn } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";


const SendEventForm = () => {
  const dispatch = useDispatch();
  const debug = false;
  const scenarioList: any = useSelector((state: RootStore) => state.sendScenarioNameListReducer);
  const eventSendError: any = useSelector((state: RootStore) => state.eventSendReducer);

  const [eventSendEData, seteventSendEData] = useState<any>(null);

  useEffect(() => {
    if (eventSendError.data) {
      if (eventSendError.data.message) {
        seteventSendEData(eventSendError.data.message);
      }
    }
  }, [eventSendError]);

  const handleEventSendSubmit = (bodyObject: any) => {
    dispatch(eventSendAction(bodyObject));
  };

  return (
    <Formik
      initialValues={{
        eventName: "",
        sessionId: "",
        scenarioList: scenarioList.data,
        params: [
          {
            name: "",
            value: "",
          },
        ],
      }}
      onSubmit={() => {}}
      enableReinitialize
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div>
            <label className="form-label" htmlFor="sessionId">
              Session Id
            </label>
            <input className="form-control" name="sessionId" value={values.sessionId} onChange={handleChange} onBlur={handleBlur} />
          </div>

          <div className="mt-2">
            <label className="form-label" htmlFor="eventName">
              Event Name
            </label>
            <input className="form-control" name="eventName" value={values.eventName} onChange={handleChange} onBlur={handleBlur} />
          </div>

          <label className="form-label mt-2" htmlFor="eventName">
            Event Parameters
          </label>

          <FieldArray name="params">
            {({ push, remove }) => (
              <div>
                {values.params.map((p: any, index) => {
                  const name = `params[${index}].name`;
                  const value = `params[${index}].value`;

                  return (
                    <div className="mt-2" key={p.id}>
                      <div className="row">
                        <div className="col">
                          <input className="form-control" name={name} placeholder="parameter" value={p.name} onChange={handleChange} onBlur={handleBlur} />
                        </div>
                        <div className="col">
                          {" "}
                          <input className="form-control" name={value} placeholder="value" value={p.value} onChange={handleChange} onBlur={handleBlur} />
                        </div>

                        <button className="btn btn-light p-2 " type="button" onClick={() => remove(index)}>
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </div>
                    </div>
                  );
                })}
                <button className="btn btn-light mt-2 p-2 " type="button" onClick={() => push({ name: "", value: "" })}>
                  Add
                </button>
              </div>
            )}
          </FieldArray>

          <div>
            <div className="modal-footer mt-2">
              <button
                id="SendEventModalCloseButton"
                className="btn btn-primary p-2 mt-3"
                type="button"
                data-dismiss="modal"
                onClick={() => {
                  resetForm();
                }}
              >
                Close
              </button>
              <button
                id="SendEventModalSaveButton"
                onClick={() => {
                  handleEventSendSubmit(values);
                }}
                className="btn btn-success p-2 mt-3"
                type="submit"
              >
                Create
              </button>
            </div>
          </div>

          {eventSendEData ? <ErrorMessageComp name={eventSendEData} /> : null}

          {debug && (
            <>
              <pre style={{ textAlign: "left" }}>
                <strong>Values</strong>
                <br />
                {JSON.stringify(values, null, 2)}
              </pre>
              <pre style={{ textAlign: "left" }}>
                <strong>Errors</strong>
                <br />
                {JSON.stringify(errors, null, 2)}
              </pre>
              {JSON.stringify(scenarioList.data, null, 2)}
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SendEventForm;
