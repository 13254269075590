import { faCircle, faCloudUploadAlt, faQuestion, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CampaignStatus = (cs: string ) => {
    if (cs == "DEPLOYED") {
      return (
        <span className="deployed-scenario">
          <FontAwesomeIcon icon={faCloudUploadAlt} />{cs}{" "}
        </span>
      );
    } else if (cs == "UNDEPLOYED") {
      return (
        <span className="undeployed-scenario">
          <FontAwesomeIcon icon={faCircle} />  { cs}
        </span>
      );
    } else if (cs == "INITIATED") {
      return (
        <span>
          <FontAwesomeIcon icon={faSyncAlt} className="fa-spin" />{" "}
          {cs}
        </span>
      );
    } else {
      return (
        <span>
          <FontAwesomeIcon icon={faQuestion} />   { cs}
        </span>
      );
    }
  };

export default CampaignStatus
