import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import "./App.css";
import "../pages/landing/landing.css"
import NotFoundPage from "../pages/common/NotFoundPage";
import DashboardPage from "../pages/dashboard/DashboardPage";
import LoginPage from "../pages/login/LoginPage";
import PrivateRoute from "./customRoutes/PrivateRoute";
import PublicRoute from "./customRoutes/PublicRoute";
import RegisterPage from "../pages/register/RegisterPage";
import Integration from "../pages/integration/Integration";
import AccountSettings from "../pages/account/components/AccountSettings";
import Password from "../pages/account/components/Password";
import Billing from "../pages/account/components/Billing";
import ScenarioListPreviewPage from "../pages/scenario/listpreview/ScenarioListPreviewPage";
import ScenarioDetailPage from "../pages/scenario/detail/ScenarioDetailPage";
import GenericErrorPage from "../pages/common/GenericErrorPage";
import ScenarioDesignPage from "../pages/scenario/design/ScenarioDesignPage";
import SegmentsPage from "../pages/cdp/segment/SegmentPage";
import SegmentOperation from "../pages/cdp/segment/components/SegmentOperation";
import CampaignPage from "../pages/campaign/CampaignPage";
import CampaignViewPage from "../pages/campaign/CampaignViewPage";
import CampaignAddEditPage from "../pages/campaign/CampaignAddEditPage";
import SegmentAddEditPage from "../pages/cdp/segment/SegmentAddEditPage.jsx";
import SegmentTestPage from "../pages/cdp/segment/SegmentDetailPage";
import SegmentDetailPage from "../pages/cdp/segment/SegmentDetailPage";
import SegmentPage from "../pages/cdp/segment/SegmentPage";
import GenaralModal from "../pages/scenario/detail/components/modals/GenaralModal";
import { useHistory } from "react-router-dom";
import { AudiencePage } from "../pages/cdp/AudiencePage";
import { EventData } from "../pages/analytics/streams/EventData";
import { faCircleExclamation, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AudienceAttributesPage } from "../pages/cdp/AudienceAttributesPage";
import WebReportPage from "../pages/analytics/WebReportPage";
import MessagingReportPage from "../pages/analytics/MessagingReportPage";


function App() {

  const history = useHistory();

  return (
    <div>
      <GenaralModal modalId="UsageExceedModal" modalName="Need More Features? 🚀🚀🚀" size="" formComp={() => {

        return (
          <div>
            <p>Great to see that you're utilizing Herogi at full pace!</p>
            <p>Just a small step needed and you're good to go.
              Your current package doesn't allow this operation, please visit your billing dashboard to upgrade.</p>
            <div className="modal-footer">
              <button
                className="btn btn-light mt-2"
                type="button"
                data-dismiss="modal">
                Close
              </button>
              <button className="btn btn-secondary mt-2" onClick={() => {

                $("#UsageExceedModal").modal("hide");
                history.push("/settings/billing");
                window.location.reload();
                //<Redirect to="/dashboard/settings/billing" />
              }}>
                Upgrade
              </button>
            </div>
          </div>
        )
      }} />

      <GenaralModal modalId="GenericErrorModal" modalName="Opps!!" size="" formComp={() => {

        return (
          <div>
            <p style={{ display: "flex" }}>
              <span className="d-inline-block">
                <FontAwesomeIcon icon={faCircleExclamation} className="fa-spin mr-2" />
              </span>
              <span className="ml-2">
                Something went wrong and someone from the team will look at this soon!
              </span>
            </p>
            <div className="modal-footer">
              <button
                className="btn btn-light mt-2"
                type="button"
                data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        )
      }} />

      <Router>
        <Switch>
          
          <PublicRoute exact path="/login" component={LoginPage} />
          <PublicRoute exact path="/register" component={RegisterPage} />

          <PrivateRoute exact path="/" component={DashboardPage} />
          <PrivateRoute exact path={`/campaign`} component={CampaignPage} />
          <PrivateRoute exact path={`/campaign/view/:campaignId`} component={CampaignViewPage} />
          <PrivateRoute exact path={`/campaign/create`} component={CampaignAddEditPage} />
          <PrivateRoute exact path={`/campaign/edit/:campaignId`} component={CampaignAddEditPage} />

          <PrivateRoute exact path="/settings" component={AccountSettings} />
          <PrivateRoute exact path="/settings/billing" component={Billing} />
          <PrivateRoute exact path="/settings/password" component={Password} />
          <PrivateRoute exact path="/integration" component={Integration} />
          <PrivateRoute exact path="/scenario" component={ScenarioListPreviewPage} />
          <PrivateRoute exact path="/scenario/:id" component={ScenarioDetailPage} />
          <PrivateRoute exact path="/scenario/:id/design" component={ScenarioDesignPage} />

          <PrivateRoute exact path="/audience" component={AudiencePage} />
          <PrivateRoute exact path="/audience/attributes" component={AudienceAttributesPage} />
          <PrivateRoute exact path="/segment" component={SegmentPage} />
          <PrivateRoute exact path={`/segment/create`} component={SegmentDetailPage} />
          <PrivateRoute exact path={`/segment/edit/:segmentId`} component={SegmentDetailPage} />

          <PrivateRoute exact path={`/analytics/web-report`} component={WebReportPage} />
          <PrivateRoute exact path={`/analytics/messaging-report`} component={MessagingReportPage} />
          <PrivateRoute exact path="/analytics/event-streams" component={EventData} />

          <Route path="/generic-error-page/:statuscode/" children={GenericErrorPage} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
