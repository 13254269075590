import axios from "axios";
import { apiErrorHandle } from "../../components/common/hooksAndFuncts/ApiErrorHandle";
import * as SessionActionTypes from "../types/types";

export const putSessionAction = (bodyObject: any, scenarioId: string, formObj: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SessionActionTypes.ADD_SESSION_LOADING,
    });
    await axios
      .put(`/scenario/${scenarioId}/sessiondef`, bodyObject)
      .then((response) => {
        formObj.resetForm();
        dispatch({
          type: SessionActionTypes.ADD_SESSION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "putSessionApiError");
        dispatch({
          type: SessionActionTypes.ADD_SESSION_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const postSessionAction = (bodyObject: any, scenarioId: string, formObj: any) => async (dispatch: any) => {
  var sessionId = bodyObject?.id;
  try {
    dispatch({
      type: SessionActionTypes.EDIT_SESSION_LOADING,
    });
    await axios
      .post(`/scenario/${scenarioId}/sessiondef/${sessionId}`, bodyObject)
      .then((response) => {
        formObj.resetForm();
        dispatch({
          type: SessionActionTypes.EDIT_SESSION_SUCCESS,
          payload: { bodyObject, response: response.data },
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "postSessionApiError");
        dispatch({
          type: SessionActionTypes.EDIT_SESSION_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const deleteSessionAction = (scenarioId: string, sessionId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: SessionActionTypes.DELETE_SESSION_LOADING,
    });
    await axios
      .delete(`/scenario/${scenarioId}/sessiondef/${sessionId}`)
      .then((response) => {
        dispatch({
          type: SessionActionTypes.DELETE_SESSION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "deleteSessionApiError");
        dispatch({
          type: SessionActionTypes.DELETE_SESSION_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const sendSelectedSessionIdAction = (selectedSessionId: string) => (dispatch: any) => {
  try {
    dispatch({
      type: SessionActionTypes.SEND_SELECTED_SESSION_ID_LOADING,
    });
    dispatch({
      type: SessionActionTypes.SEND_SELECTED_SESSION_ID_SUCCESS,
      payload: selectedSessionId,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: SessionActionTypes.SEND_SELECTED_SESSION_ID_FAIL,
      payload: error,
    });
  }
};

export const sendSelectedSessionDataAction = (selectedSessionData: object) => (dispatch: any) => {
  try {
    dispatch({
      type: SessionActionTypes.SEND_SELECTED_SESSION_DATA_LOADING,
    });
    dispatch({
      type: SessionActionTypes.SEND_SELECTED_SESSION_DATA_SUCCESS,
      payload: selectedSessionData,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: SessionActionTypes.SEND_SELECTED_SESSION_DATA_FAIL,
      payload: error,
    });
  }
};

export const sendSessionDataAction = (sessionData:object) => (dispatch: any) => {
  try {
    dispatch({
      type: SessionActionTypes.SEND_SESSION_DATA_LOADING,
    });
    dispatch({
      type: SessionActionTypes.SEND_SESSION_DATA_SUCCESS,
      payload: sessionData,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: SessionActionTypes.SEND_SESSION_DATA_FAIL,
      payload: error,
    });
  }
};

