import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteActionParamAction } from "../../../../../redux/actions/ActionActions";
import { RootStore } from "../../../../../redux/store/Store";


const DeleteActionParamForm = () => {
  const dispatch = useDispatch();

  const [deleteButtonState, setdeleteButtonState] = useState(false);
  const [actionParamData, setactionParamData] = useState<any>(null);
  const [selectectedParamIndex, setSelectectedParamIndex] = useState<any>(null);
  const [actionParamErrorData, setactionParamErrorData] = useState<any>(null);

  const selectedActionId: any = useSelector((state: RootStore) => state.sendActionIdReducer);
  const selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  const selectedActionParamId: any = useSelector((state: RootStore) => state.sendActionParamIdReducer);
  const selectedActionParam: any = useSelector((state: RootStore) => state.sendActionParamDataReducer);
  const actionParamError: any = useSelector((state: RootStore) => state.deleteActionParamReducer);

  useEffect(() => {
    if (actionParamError.data) {
      if (actionParamError.data.message) {
        setactionParamErrorData(actionParamError);
      }
    }
  }, [actionParamError]);

  useEffect(() => {
    if (selectedActionParam.data) {
      setactionParamData(selectedActionParam.data);
    }
  }, [selectedActionParam.data]);

  const handleDeleteActionParam = () => {
    if (actionParamData[selectectedParamIndex].isRecurring) {
      const actionParamField = actionParamData[selectectedParamIndex].field;
      dispatch(deleteActionParamAction(actionParamData, selectedScenarioId.data, selectedActionId.data, selectedActionParamId.data, actionParamField));
    } else {
      dispatch(deleteActionParamAction(actionParamData, selectedScenarioId.data, selectedActionId.data, selectedActionParamId.data));
    }
  };

  const renderDeleteActionParamModalContent = () => {
    return (
      <>
        <div>
          <span>
            {actionParamData ? actionParamData[0].key : ""}{" "}
            <small>
              ({actionParamData ? actionParamData[0].dataType : ""}) {actionParamData ? (actionParamData[0].isRecurring ? "Recurring" : "Non-Requrring") : ""}
            </small>
          </span>
          <div>
            <small style={{ color: "orange" }}>Description</small>
          </div>
        </div>
        <div>
          <small>{actionParamData ? actionParamData[0].description : ""}</small>
        </div>

        <table className="table mt-3">
          <thead>
            <tr>
              {actionParamData ? actionParamData[0]?.isRecurring ? <th>Field</th> : null : null}
              <th>Value</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {actionParamData
              ? actionParamData.map((ap: any, index: number) => (
                  <tr>
                    {ap?.isRecurring ? <td>{ap?.field}</td> : null}
                    <td>
                      <span>{ap?.value}</span>
                    </td>
                    <td style={{ width: "100px" }}>
                      <button
                        id="renderEditActionParamModalContentButton"
                        className="btn btn-danger pt-1 pb-1 pl-2 pr-2"
                        type="button"
                        onClick={() => {
                          setdeleteButtonState(true);
                          setSelectectedParamIndex(index);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </>
    );
  };

  const renderDeleteInfoActionParamModalContent = () => {
    return (
      <>
        <p>
          Are you sure to delete action parameter?
          <br />
          This operation is not returnable and parameter data will be deleted permanently.
        </p>

        <div className="modal-footer">
          <button
            id="renderDeleteActionParamModalContentCancelButton"
            className="btn btn-primary p-2 mt-3"
            type="button"
            onClick={() => {
              setdeleteButtonState(false);
            }}
          >
            Close
          </button>
          <button
            id="renderDeleteActionParamModalContentDeleteButton"
            onClick={() => {
              setdeleteButtonState(false);
              handleDeleteActionParam();
            }}
            className="btn btn-danger p-2 mt-3"
            type="submit"
          >
            Delete
          </button>
        </div>
      </>
    );
  };

  return <div>{deleteButtonState ? renderDeleteInfoActionParamModalContent() : renderDeleteActionParamModalContent()}</div>;
};

export default DeleteActionParamForm;
