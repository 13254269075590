import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootStore } from "../../../../../redux/store/Store";
import { postTransitionAction } from "../../../../../redux/actions/TransitionActions";
import { IStateInner } from "../../../types/stateTypes";
import { ITransition, ITransitionError, TransitionSelectType } from "../../../types/transitionTypes";
import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";
import Select from "react-select";
import RteTransitionVal from "../../../commonComps/RteTransitionVal";
import $ from 'jquery';


const EditTranstionForm = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const states: IStateInner[] = useSelector((state: RootStore) => state.sendStatesDataReducer.data);
  const eventMap: {} = useSelector((state: RootStore) => state.sendEventMapDataReducer.data);
  const transitionError: ITransitionError = useSelector((state: RootStore) => state.postTransitionReducer.data);
  const selectedTransition: ITransition = useSelector((state: RootStore) => state.sendTransitionDataReducer.data);

  const [fromState, setfromState] = useState<TransitionSelectType | null>(null);
  const [toState, settoState] = useState<TransitionSelectType | null>(null);
  const [event, setevent] = useState<TransitionSelectType | null>(null);

  const [validationValueToggle, setvalidationValueToggle] = useState<boolean>(false);
  const [validationRichBoxValue, setvalidationRichBoxValue] = useState<string>("");
  const [orgValidationRichBoxValue, setorgValidationRichBoxValue] = useState<string>("");

  const [transitionEData, settransitionEData] = useState<string>("");

  const [transitionId, settransitionId] = useState<string>("");

  const [statesData, setstatesData] = useState<IStateInner[] | null>(null);
  const [eventMapData, setEventMapData] = useState<{} | null>(null);
  const [stateOptionsData, setStateOptionsData] = useState<[] | { value: number; label: string }[]>([]);
  const [eventMapOptionData, setEventMapOptionData] = useState<any>([]);

  useEffect(() => {
    if (selectedTransition) {
      setvalidationRichBoxValue(selectedTransition?.validation);
      setorgValidationRichBoxValue(selectedTransition?.validation);

      if (selectedTransition?.id) {
        settransitionId(selectedTransition?.id?.toString());
      } else {
        alert(("TransitionId empty or null!"))
      }

      if (selectedTransition?.fromState) {
        setfromState({ value: selectedTransition?.fromState?.id, label: selectedTransition?.fromState?.name });
      }
      else{
        setfromState(null);
      }
      if (selectedTransition?.toState) {
        settoState({ value: selectedTransition?.toState?.id, label: selectedTransition?.toState?.name });
      }else{
        settoState(null);
      }
      if (selectedTransition?.event) {
        setevent({ value: selectedTransition?.event?.id, label: selectedTransition?.event?.name });
      }
    }
  }, [selectedTransition]);

  useEffect(() => {
    if (states) {
      setstatesData(states);
    }
  }, [states]);

  useEffect(() => {
    if (eventMap) {
      setEventMapData(eventMap);
    }
  }, [eventMap]);

  useEffect(() => {
    if (statesData) {
      setStateOptions();
    }
  }, [statesData]);

  useEffect(() => {
    if (eventMapData) {
      setEventMapOptions();
    }
  }, [eventMapData]);

  useEffect(() => {
    if (transitionError) {
      settransitionEData(transitionError?.message);
    }
  }, [transitionError]);
  useEffect(() => {
    $("#EditTransitionModalCloseIconButton").click(function () {
      resetForm();
      setvalidationValueToggle(false);
      setvalidationRichBoxValue(orgValidationRichBoxValue);
    });
    
  }, [orgValidationRichBoxValue]);

  const setStateOptions = () => {
    if (statesData !== null) {
      const newStatesData: { value: number; label: string }[] = [];
      if (Object.values(statesData).length === 0) {
        setStateOptionsData([]);
      } else {
        Object.values(statesData).map((si: IStateInner) => {
          newStatesData.push({ value: si.id, label: si.name });
          setStateOptionsData([...newStatesData]);
        });
      }
    }
  };

  const setEventMapOptions = () => {
    if (eventMapData) {
      const newEventMapData: { value: number; label: string }[] = [];
      Object.values(eventMapData).map((ei: any) => {
        newEventMapData.push({ value: ei.id, label: ei.name });
        setEventMapOptionData([...newEventMapData]);
      });
    }
  };

  const handleSubmitState = () => {
    const bodyObj: { validation: string; event?: number; fromState?: number; toState?: number } = {
      validation: validationRichBoxValue ? validationRichBoxValue : "",
    };
    var editedObj: { id?: number; validation?: string; event?: number; fromState?: IStateInner; toState?: IStateInner } = {};

    if (event) {
      bodyObj["event"] = event?.value;
    }
    if (fromState) {
      bodyObj["fromState"] = fromState?.value;
    }
    if (toState) {
      bodyObj["toState"] = toState?.value;
    }

    if (selectedTransition && eventMapData && statesData) {

      editedObj.id = selectedTransition?.id;
      editedObj.validation = validationRichBoxValue ? validationRichBoxValue : "";
      editedObj.event = Object.values(eventMapData).find((ei: any) => ei.id === event?.value) as number;
      if (fromState) {
        editedObj.fromState = statesData.find((si: IStateInner) => si.id === fromState?.value);
      }
      if (toState) {
        editedObj.toState = statesData.find((si: IStateInner) => si.id === toState?.value);
      }
    }

    dispatch(postTransitionAction(bodyObj, id, transitionId, resetForm, editedObj));
  };

  const validationRichBoxValueChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setvalidationRichBoxValue(e.target.value);
  };

  const fromStateHandleChange = (value: TransitionSelectType) => {
    setfromState(value);
  };

  const toStateHandleChange = (value: TransitionSelectType) => {
    settoState(value);
  };

  const eventHandleChange = (value: TransitionSelectType) => {
    setevent(value);
  };

  const resetForm = () => {
    if (selectedTransition) {
      if (selectedTransition?.fromState) {
        setfromState({ value: selectedTransition?.fromState?.id, label: selectedTransition?.fromState?.name });
      } else {
        setfromState(null);
      }
      if (selectedTransition?.toState) {
        settoState({ value: selectedTransition?.toState?.id, label: selectedTransition?.toState?.name });
      } else {
        settoState(null);
      }
      if (selectedTransition?.event) {
        setevent({ value: selectedTransition?.event?.id, label: selectedTransition?.event?.name });
      } else {
        setevent(null);
      }
    }
    setvalidationRichBoxValue(selectedTransition?.validation);
    settransitionEData("");
  };

  const renderTransitionValitionValue = () => {
    return (
      <div>
        <div>

        <RteTransitionVal editorValueProp={validationRichBoxValue} seteditorValueProp={setvalidationRichBoxValue}/>

          {/* <input
            className="form-control"
            name="validation"
            onChange={(e) => {
              validationRichBoxValueChangeHandler(e);
            }}
            value={validationRichBoxValue}
          /> */}
        </div>

        <div>
          <div className="modal-footer mt-3">
            <button
              id="validationValueCloseButton"
              className="btn btn-primary p-2 mt-3"
              type="button"
              onClick={() => {
                setvalidationValueToggle(false);
                $("#AddNewTransitionModalLabel").text("New Transition");
              }}
            >
              Close
            </button>
            <button
              id="validationValueSaveButton"
              onClick={() => {
                setvalidationValueToggle(false);
                $("#AddNewTransitionModalLabel").text("New Transition");
              }}
              className="btn btn-success p-2 mt-3"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div>
        <div>
          <div className="mt-3">
            <label className="form-label" htmlFor="fromState">
              From State
            </label>
          </div>

          <Select isClearable options={stateOptionsData} onChange={(value) => fromStateHandleChange(value)} value={fromState} placeholder="Start" />
        </div>

        <div>
          <div className="mt-3">
            <label className="form-label" htmlFor="toState">
              To State
            </label>
          </div>

          <Select isClearable options={stateOptionsData} onChange={(value) => toStateHandleChange(value)} value={toState} placeholder="End" />
        </div>

        <div>
          <div className="mt-3">
            <label className="form-label" htmlFor="event">
              Event
            </label>
          </div>

          <Select isClearable options={eventMapOptionData} onChange={(value) => eventHandleChange(value)} value={event} placeholder="Any" />
        </div>

        <div className="mt-3">
          <label className="form-label" htmlFor="validation-string">
            Validation String
          </label>
        </div>
        <div className="form-inline">
          <input
            style={{ width: "90%" }}
            className="form-control"
            id="validation"
            name="validation"
            onChange={(e) => {
              validationRichBoxValueChangeHandler(e);
            }}
            value={validationRichBoxValue}
          />
          <button
            className="btn btn-flatwet p-2"
            onClick={() => {
              setvalidationValueToggle(true);
              $("#AddNewTransitionModalLabel").text("Validation Value");
            }}
          >
            Set
          </button>
        </div>

        <div className="mt-3">{transitionEData ? <ErrorMessageComp name={transitionEData} /> : null}</div>

        <div>
          <div className="modal-footer mt-3">
            <button
              id="editTransitionCloseButton"
              className="btn btn-primary p-2 mt-3"
              type="button"
              data-dismiss="modal"
              onClick={() => {
                resetForm();
                $("#visualize-toggle-button").click()
              }}
            >
              Close
            </button>
            <button
              id="editTransitionSaveButton"
              onClick={() => {
                handleSubmitState();
              }}
              className="btn btn-success p-2 mt-3"
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {validationValueToggle ? renderTransitionValitionValue() : renderForm()}

    </>
  );
};

export default EditTranstionForm;
