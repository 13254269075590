import axios from "axios";
import { cloneDeep } from "lodash";
import moment from "moment";
import { eventListByScenarioQuery } from "./ElasticQuery";
import { ErrorHandler } from "../components/common/hooksAndFuncts/ApiErrorHandle";

export const ReportingQueries = {

  eventMonthlyQuery: {
    "query": {
      "bool": {
        "must": [
          {
            "match_all": {}
          }
        ],
        "filter": [
          {
            "range": {
              "entry_time": {
                "gte": "###StartDate###",
                "lt": "###EndDate###"
              }
            }
          }
        ]
      }
    },
    "aggs": {
      "dates_with_holes": {
        "date_histogram": {
          "field": "entry_time",
          "calendar_interval": "day",
          "min_doc_count": 0,
          "extended_bounds": {
            "min": "###StartDate###",
            "max": "###EndDate###"
          }
        }
      }
    }
  },

  stateDailyQuery: {
    "query": {
      "bool": {
        "must": [
          {
            "match_all": {}
          }
        ],
        "filter": [
          {
            "range": {
              "entry_time": {
                "gte": "###StartDate###",
                "lte": "###EndDate###"
              }
            }
          }
        ]
      }
    },
    "aggs": {
      "dates_with_holes": {
        "date_histogram": {
          "field": "entry_time",
          "calendar_interval": "day",
          "min_doc_count": 0,
          "extended_bounds": {
            "min": "###StartDate###",
            "max": "###EndDate###"
          }
        },
        "aggs": {
          "state_agg": {
            "terms": {
              "field": "state_name"
            }
          }
        }
      }
    }
  },
  

  allPendingSessionQuery: {
    "size": 0,
    "query": {
      "bool": {
        "filter": [
          {
            "match": {
              "state_name": "_waitState"
            }
          }
        ]
      }
    }
  }  
}

export function generateQuery(rawQuery: any) {

  var query = cloneDeep(rawQuery);
  var now = new Date();

  var startDate = new Date(new Date().setDate(now.getDate() - 30));//new Date(now.getFullYear(), now.getMonth(), 1);
  var lastDate = new Date(new Date().setDate(now.getDate() + 1));;//new Date(now.getFullYear(), now.getMonth() + 1, 1);

  var filter =
    query["query"]["bool"]["filter"][0]["range"][
    "entry_time"
    ];

  filter["gte"] = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
  filter["lte"] = moment(lastDate).format("YYYY-MM-DD HH:mm:ss");

  var extension =
    query["aggs"]["dates_with_holes"]["date_histogram"][
    "extended_bounds"
    ];
  extension["min"] = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
  extension["max"] = moment(lastDate).format("YYYY-MM-DD HH:mm:ss");

  return JSON.stringify(query);

};


export function getElasticEvents(query) {

  let request = {};
  request["query"] = JSON.stringify(query);

  return axios.post("/elastic/app", request)
}

export function getElasticDocs(query) {

  let request = {};
  request["query"] = JSON.stringify(query);

  return axios.post("/elastic/app/hits", request)
}

export function getElasticSessions(query) {

  let request = {};
  request["query"] = query;

  return axios
    .post("/elastic/sessions")
    .catch((error) => {
      console.log("elasticAppApiError", error);
    });
}

export function getScenarioStates(scenarioId: string) {

  let request = {};
  request["query"] = generateQuery(ReportingQueries.stateDailyQuery);
  request["scenarioId"] = scenarioId;
  request["type"] = "StateHistory"

  return axios
    .post("/elastic/scenario", request)
    .catch((error) => {
      console.log("elasticAppApiError", error);
    });
}

export function getPendingSessionCount(scenarioId: string) {

  let request = {};
  request["query"] = JSON.stringify(ReportingQueries.allPendingSessionQuery);
  request["scenarioId"] = scenarioId;
  request["type"] = "StateLog"

  return axios
    .post("/elastic/scenario/hits", request)
    .catch((error) => {
      console.log("elasticAppApiError", error);
    });
}

export function getEventsListView(scenarioName, eventName, scenarioHit, eventHit, startDate, endDate, pageNumber, pageSize) {

  //fill query data with new criteria
  let query = cloneDeep(eventListByScenarioQuery);

  query['from'] = (pageNumber - 1) * pageSize;
  query['size'] = pageSize;

  var filter: Array<any> = query['query']['bool']['filter'];
  filter[0]['range']['entry_time']['gte'] = moment(startDate + " 00:00:00").format("YYYY-MM-DD HH:mm:ss");
  //$filter("date")(startDate, 'yyyy-MM-dd 00:00:00');
  filter[0]['range']['entry_time']['lte'] = moment(endDate + " 23:59:59").format("YYYY-MM-DD HH:mm:ss");;
  //$filter("date")(endDate, 'yyyy-MM-dd 23:59:59');

  if (scenarioHit)
    filter[1] = { "term": { "scenario_hit": "true" } };
  else
    filter[1] = { "term": { "scenario_hit": "false" } };

  if (eventHit)
    filter[2] = { "term": { "event_hit": "true" } }
  else
    filter[2] = { "term": { "event_hit": "false" } }

  if (scenarioName != "" && scenarioName)
    filter[3] = { "term": { "scenario_name": scenarioName } };

  if (eventName != "" && eventName) {
    if (filter.length == 1)
      filter[3] = { "term": { "event_name": eventName } };
    else
      filter[4] = { "term": { "event_name": eventName } };
  }

  
  return getElasticDocs(query)

}

export function getUserHighlight() {
  return axios.get("/userquery/highlight", {});
};