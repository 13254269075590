import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { sessionTypeOptions } from "./utils/formUtils";
import { RootStore } from "../../../../../redux/store/Store";
import { putSessionAction } from "../../../../../redux/actions/sessionActions";
import * as Yup from "yup";
import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";
import $ from 'jquery';



const AddNewSessionForm = () => {
  const dispatch = useDispatch();

  var selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  var newSessionError: any = useSelector((state: RootStore) => state.putSessionReducer);

  const [newSessionEData, setnewSessionEData] = useState<any>(null);

  useEffect(() => {
    if (newSessionError.data) {
      if (newSessionError.data.message) {
        setnewSessionEData(newSessionError.data.message);
      }
    }
    if (newSessionError?.data?.status === "success") {
      setnewSessionEData("");
    }
  }, [newSessionError.data]);

  useEffect(() => {
    $("#AddNewSessionDefinitionModalCloseIconButton").click(function () {
      $("#AddNewSessionDefinitionCloseButton").trigger("click");
    });
  }, []);

  const handleSubmitSession = (bodyObj: any, formObj: any) => {
    dispatch(putSessionAction(bodyObj, selectedScenarioId.data, formObj));
  };

  const setSessionTypeOptions = (selectedItem: any) => {
    return (
      <>
        {selectedItem ? <option value={selectedItem} label={selectedItem} /> : null}
        {sessionTypeOptions
          .filter((item) => item.key !== selectedItem)
          .map((item, index) => (
            <option key={index} value={item.value} label={item.key} />
          ))}
      </>
    );
  };

  const validationSchema = Yup.object().shape({
    propertyName: Yup.string().required("Session definition name is required"),
  });

  const formik = useFormik({
    initialValues: {
      propertyName: "",
      propertyType: "STRING",
      description: "",
    },
    onSubmit: () => {},
    validationSchema: validationSchema,
    validateOnChange:true,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <div className="form-group">
          <label className="form-label" htmlFor="sessionName">
            Session Name
          </label>
        </div>
        <input
          className="form-control"
          id="propertyName"
          name="propertyName"
          type="text"
          onChange={(e) => {
            setnewSessionEData("");
            formik.handleChange(e);
          }}
          value={formik.values.propertyName}
        />

        {newSessionEData ? <ErrorMessageComp name={newSessionEData} /> : formik.errors.propertyName ? <ErrorMessageComp name={formik.errors.propertyName} /> : null}
      </div>

      <div className="form-group mt-3">
        <label className="form-label" htmlFor="sessionType">
          Session Type
        </label>
        <select className="form-control" id="propertyType" name="propertyType" value={formik.values.propertyType} onChange={formik.handleChange} >
          {setSessionTypeOptions(formik.values.propertyType)}
        </select>
      </div>

      <div className=" form-group mt-3">
        <label className="form-label" htmlFor="description">
          Description
        </label>
        <textarea className="form-control" id="description" name="description" rows={3} onChange={formik.handleChange} value={formik.values.description} />
      </div>

      <div className="modal-footer mt-3">
        <button
          id="AddNewSessionDefinitionCloseButton"
          className="btn btn-primary p-2 mt-3"
          type="button"
          data-dismiss="modal"
          onClick={() => {
            formik.resetForm();
            setnewSessionEData("");
          }}
        >
          Close
        </button>
        <button
          id="AddNewSessionDefinitionSaveButton"
          className="btn btn-success p-2 mt-3"
          type="submit"
          disabled={!(formik.isValid && formik.dirty)}
          onClick={() => {
            handleSubmitSession(formik.values, formik);
            if (newSessionError?.data) {
              newSessionError.data.message = "";
            }
          }}
        >
          Create
        </button>
      </div>
    </form>
  );
};

export default AddNewSessionForm;
