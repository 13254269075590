import { useDispatch, useSelector } from "react-redux";
import SimpleNumberBox from "./components/SimpleNumberBox";
import { RootStore } from "../../redux/store/Store";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { getAudienceReporting } from "../../redux/actions/ReportingActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { ESHistogramQuery, ESHistogramQueryWithPropAggregation, ESQueryEventFilterAggregation, ESQueryPropAggregation } from "../../services/ESQueries";
import { getElasticEvents } from "../../services/ReportingService";
import GraphBox from "./components/GraphBox";
import ProgressBar from "./components/ProgressBar";
import DataBox from "./components/DataBox";

interface MessagingMetrics {
    send: number;
    opened: number;
    clicked: number;
    delivered: number | undefined;
}

const MessagingReportPage = () => {

    const dispatch = useDispatch();
    const audienceReportingR = useSelector((state: RootStore) => state.getAuidenceReportingReducer);

    const [emailBoxMetricLoading, setEmailBoxMetricLoading] = useState<boolean>(true);
    const [webpushBoxMetricLoading, setWebpushBoxMetricLoading] = useState<boolean>(true);
    const [emailMetrics, setEmailMetrics] = useState<MessagingMetrics | null>(null);
    const [webpushMetrics, setWebPushMetrics] = useState<MessagingMetrics | null>(null);

    const scenarios = ["MonitoringScenario"];

    const endDate = moment();
    const startDateLastY = endDate.clone().subtract(365, 'days');
    const startDate = endDate.clone().subtract(30, 'days')

    //const emailQuery = new ESQueryPropAggregation(["EmailMessageTracking"], scenarios, "event_data.tracking_type", startDateLastY, endDate);
    const { current: emailQuery } = useRef(new ESQueryPropAggregation(["EmailMessageTracking"], scenarios, "event_data.tracking_type", startDateLastY, endDate));
    const { current: webPushQuery } = useRef(new ESQueryPropAggregation(["PushMessageTracking"], scenarios, "event_data.tracking_type", startDateLastY, endDate));
    const totalEmailSend = new ESHistogramQueryWithPropAggregation(["EmailMessageTracking"], scenarios, "event_data.tracking_type", "1d", startDate, endDate, false,
        [
            {
                "values": ["send", "delivered"],
                "field" : "event_data.tracking_type",
                "operator": "terms"
            }
        ]
    );

    const totalEmailEngagement = new ESHistogramQueryWithPropAggregation(["EmailMessageTracking"], scenarios, "event_data.tracking_type", "1d", startDate, endDate, false,
        [
            {
                "values": ["clicked", "opened"],
                "field" : "event_data.tracking_type",
                "operator": "terms"
            }
        ]
    );

    const emailUnsubscribeQ = new ESHistogramQuery(["UnsubscribeTracking"], scenarios, "1d", startDate, endDate, true, 
        [
            {
                "values" : ["email"],
                "field" : "event_data.channel",
                "operator" : "terms"
            }
        ]
    );

    const emailEngagementSourceQ = new ESQueryPropAggregation(["EmailMessageTracking"], scenarios, "event_data.scenario", startDate, endDate);

    const totalPushSend = new ESHistogramQueryWithPropAggregation(["PushMessageTracking"], scenarios, "event_data.tracking_type", "1d", startDate, endDate, false,
        [
            {
                "values": ["send", "delivered"],
                "field" : "event_data.tracking_type",
                "operator": "terms"
            }
        ]
    );

    const totalPushEngagement = new ESHistogramQueryWithPropAggregation(["PushMessageTracking"], scenarios, "event_data.tracking_type", "1d", startDate, endDate, false,
        [
            {
                "values": ["clicked", "opened"],
                "field" : "event_data.tracking_type",
                "operator": "terms"
            }
        ]
    );

    const pushUnsubscribeQ = new ESHistogramQuery(["UnsubscribeTracking"], scenarios, "1d", startDate, endDate, true, 
        [
            {
                "values" : ["webpush"],
                "field" : "event_data.channel",
                "operator" : "terms"
            }
        ]
    );

    const pushEngagementSourceQ = new ESQueryPropAggregation(["PushMessageTracking"], scenarios, "event_data.scenario", startDate, endDate);

    const isLoading = () => {
        return emailBoxMetricLoading || webpushBoxMetricLoading || audienceReportingR.loading;

    }

    useEffect(() => {
        dispatch(getAudienceReporting());
    }, [dispatch]);

    useEffect(() => {
        if (audienceReportingR) {
            //console.log(audienceReportingR);
        } else {
            //console.log(audienceReportingR);
        }
    }, [audienceReportingR]);

    let _mapper = (buckets: any) => {
        let metrics: MessagingMetrics = {
            send: 0,
            opened: 0,
            clicked: 0,
            delivered: 0
        };

        buckets.forEach((item: any) => {
            switch (item.key) {
                case "send":
                    metrics.send = item.doc_count;
                    break;
                case "opened":
                    metrics.opened = item.doc_count;
                    break;
                case "clicked":
                    metrics.clicked = item.doc_count;
                    break;
                case "delivered":
                    metrics.delivered = item.doc_count;
                    break;
                default:
                    break;
            }
        });

        return metrics;
    }

    useEffect(() => {

        getElasticEvents(emailQuery.getQuery())
            .then((res) => {
                let buckets = res.data.filtered_events?.eventprop_type_terms.buckets;
                let metrics = _mapper(buckets);
                setEmailMetrics(metrics);
                setEmailBoxMetricLoading(false);
            }).catch((err) => {
                setEmailBoxMetricLoading(false);
            });

    }, []);

    useEffect(() => {

        getElasticEvents(webPushQuery.getQuery())
            .then((res) => {
               
                let buckets = res.data.filtered_events?.eventprop_type_terms.buckets;
                let metrics = _mapper(buckets);
                setWebPushMetrics(metrics);
                setWebpushBoxMetricLoading(false);
            }).catch((err) => {
                setWebpushBoxMetricLoading(false);
            });

    }, []);

    const renderContentWithProgress = (graphData) => {
        return (<div className="h-75" style={{overflow: "scroll" }}>
            <ProgressBar items={graphData} />
        </div>)
    }

    return (
        <div className="dashboard-main">
            <div>
                <h2>Multi-channel Messaging KPIs</h2>
                <div className="row mt-4">
                    <div className="col-md-3" style={{minHeight : "120px"}}>
                        {!isLoading() ?
                            <SimpleNumberBox title="Email Auidence" value={audienceReportingR.data && audienceReportingR.data.data.totalEmailExist} icon="users with email" />
                            :
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <FontAwesomeIcon style={{ color: "#b4b4b4" }} size={"3x"} icon={faChartLine} className="fa-thin" beatFade />
                            </div>
                        }
                    </div>
                    <div className="col-md-3">
                        {!isLoading() ?
                            <SimpleNumberBox title="Email Subscribed" value={audienceReportingR.data && (audienceReportingR.data.data.totalEmailOptin)} icon="optin" />
                            :
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <FontAwesomeIcon style={{ color: "#b4b4b4" }} size={"3x"} icon={faChartLine} className="fa-thin" beatFade />
                            </div>
                        }
                    </div>
                    <div className="col-md-3">
                        {!isLoading() ?
                            <SimpleNumberBox title="Total Email" value={emailMetrics?.send} icon="sent" />
                            :
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <FontAwesomeIcon style={{ color: "#b4b4b4" }} size={"3x"} icon={faChartLine} className="fa-thin" beatFade />
                            </div>
                        }

                    </div>
                    <div className="col-md-3">
                        {!isLoading() ?
                            <SimpleNumberBox title="Email Engagement" value={(emailMetrics?.clicked ?? 0) + (emailMetrics?.opened ?? 0)} icon="clicked/opened" />
                            :
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <FontAwesomeIcon style={{ color: "#b4b4b4" }} size={"3x"} icon={faChartLine} className="fa-thin" beatFade />
                            </div>
                        }
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-3">
                        {!isLoading() ?
                            <SimpleNumberBox title="Webpush Auidence" value={audienceReportingR.data && audienceReportingR.data.data.totalWebpushExist} icon="users with token" />
                            :
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <FontAwesomeIcon style={{ color: "#b4b4b4" }} size={"3x"} icon={faChartLine} className="fa-thin" beatFade />
                            </div>
                        }
                    </div>
                    <div className="col-md-3">
                        {!isLoading() ?
                            <SimpleNumberBox title="Webpush Subscribed" value={audienceReportingR.data && (audienceReportingR.data.data.totalWebpushOptin)} icon="optin" />
                            :
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <FontAwesomeIcon style={{ color: "#b4b4b4" }} size={"3x"} icon={faChartLine} className="fa-thin" beatFade />
                            </div>
                        }
                    </div>
                    <div className="col-md-3">
                        {!isLoading() ?
                            <SimpleNumberBox title="Total Webpush" value={webpushMetrics?.send} icon="sent" />
                            :
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <FontAwesomeIcon style={{ color: "#b4b4b4" }} size={"3x"} icon={faChartLine} className="fa-thin" beatFade />
                            </div>
                        }
                    </div>
                    <div className="col-md-3">
                        {!isLoading() ?
                            <SimpleNumberBox title="Webpush Engagement" value={(webpushMetrics?.clicked ?? 0) + (webpushMetrics?.opened ?? 0)} icon="clicked/opened" />
                            :
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <FontAwesomeIcon style={{ color: "#b4b4b4" }} size={"3x"} icon={faChartLine} className="fa-thin" beatFade />
                            </div>
                        }
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6">
                        <GraphBox esQuery={totalEmailSend}
                            title="Email Activity"
                            subtitle={"send/delivered emails since " + moment(startDate).format("MMM DD")}
                            label="Send / Delivered"
                        />

                    </div>
                    <div className="col-md-6">
                        <GraphBox esQuery={totalEmailEngagement}
                            title="Email Engagement"
                            subtitle={"clicked/opened email messages since " + moment(startDate).format("MMM DD")}
                            label="Clicked / Delivered"
                        />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6">
                        <GraphBox esQuery={emailUnsubscribeQ}
                            title="Email Unsubscribes"
                            subtitle={"users unsubscribed since " + moment(startDate).format("MMM DD")}
                            label="Unsubscribe"
                        />        
                    </div>
                    <div className="col-md-6">
                        <DataBox esQuery={emailEngagementSourceQ}
                            title="Where are email messages coming from?"
                            subtitle={"Last 30 Days"}
                            renderContent={renderContentWithProgress}
                        />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6">
                        <GraphBox esQuery={totalPushSend}
                            title="Webpush Activity"
                            subtitle={"send/delivered push messages since " + moment(startDate).format("MMM DD")}
                            label="Send / Delivered"
                        />
                    </div>
                    <div className="col-md-6">
                        <GraphBox esQuery={totalPushEngagement}
                            title="Webpush Engagement"
                            subtitle={"clicked/opened push messages since " + moment(startDate).format("MMM DD")}
                            label="Send / Delivered"
                        />

                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6">
                        <GraphBox esQuery={pushUnsubscribeQ}
                            title="Webpush Unsubscribes"
                            subtitle={"users unsubscribed since " + moment(startDate).format("MMM DD")}
                            label="Unsubscribe"
                        />        
                    </div>
                    <div className="col-md-6">
                        <DataBox esQuery={pushEngagementSourceQ}
                            title="Where are webpush messages coming from?"
                            subtitle={"Last 30 Days"}
                            renderContent={renderContentWithProgress}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MessagingReportPage;
