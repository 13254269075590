import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../../../redux/store/Store";
import { faCircle, faClock, faCloudUploadAlt, faCodeBranch, faQuestion, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { tableDeployedBgColor, tableUndeployedBgColor } from "../../../../constants/utils/constantUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Preview = (props:any) => {
  const scenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);

  const [selectedActionId, setselectedActionId] = useState(null);
  const [selectedValidationId, setselectedValidationId] = useState(null);
  const [selectedSessionDefId, setselectedSessionDefId] = useState(null);
  const [selectedEventId, setselectedEventId] = useState(null);
  const [selectedScenario, setSelectedScenario] = useState<null | {}>(null);

  useEffect(() => {
    if (props.scenarioList) {
      setSelectedScenario(props.scenarioList.filter((slf: any) => slf.scenario.id == scenarioId.data));
    }
  }, [props.scenarioList, scenarioId.data]);

  
  const renderEventTab = () => {
    return (
      <h5 className="card-title">
        {selectedScenario
          ? Object.values(selectedScenario).map((ss: any) => (
              <div className="row">
                <div className="col-2 border-right">
                  <div className="list-group list-group-flush ">
                    <div className="list-group-item p-1 ">
                      <span>
                        {" "}
                        {"Event List"} ({Object.values(ss.scenario.eventMap).length}){" "}
                      </span>
                    </div>
                  </div>

                  <div className="list-group list-group-flush " id="list-tab" role="tablist">
                    {Object.values(ss.scenario.eventMap).length !== 0
                      ? Object.values(ss.scenario.eventMap).map((em: any) => (
                          <a
                            className="list-group-item list-group-item-action p-1"
                            id={"list-" + em.name + "-list"}
                            data-toggle="list"
                            href={"#" + "list-" + em.name}
                            role="tab"
                            aria-controls={em.name}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setselectedEventId(em.id);
                            }}
                          >
                            {em.name}
                          </a>
                        ))
                      : " There is no event created"}
                  </div>
                </div>

                <div className="col-10 ">
                  <div>
                    {selectedEventId !== null ? (
                      Object.values(ss.scenario.eventMap).length !== 0 ? (
                        Object.values(ss.scenario.eventMap)
                          .filter((emf: any) => emf.id == selectedEventId)
                          .map((em: any) => (
                            <>
                              <div className="list-group list-group-flush ">
                                <div className="list-group-item border-0 p-1 ">
                                  <span>{em.name} </span>
                                </div>
                                <div className="list-group-item border-0 p-1">
                                  {"Type: "}
                                  {em.type}
                                </div>
                                <div className="list-group-item border-0 p-1">
                                  <span style={{ color: "#EF9222" }}>{"Description: "}</span>
                                  {em.description}
                                </div>
                              </div>

                              <div className="card  mb-4 mt-3 shadow-sm">
                                <table className="table table-hover ">
                                  <thead>
                                    <tr>
                                      <th scope="col ">
                                        Event list {"("} {Object.values(em.params).length} {")"}
                                      </th>
                                    </tr>
                                  </thead>

                                  <thead className="bg-light">
                                    <tr>
                                      <th scope="col">Parameter</th>
                                      <th scope="col">Type</th>
                                      <th scope="col">Aggregation</th>
                                      <th scope="col">Agg. Duration</th>
                                      <th scope="col">Agg. Base Time</th>
                                      <th scope="col">Description</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {Object.values(em.params).map((emp: any) => (
                                      <tr>
                                        <th scope="row">{emp.propertyName}</th>
                                        <td>{emp.propertyType}</td>
                                        <td>{emp.aggInfo ? emp.aggInfo.type : "N/A"}</td>
                                        <td>{emp.aggInfo ? (emp.aggInfo.unit ? emp.aggInfo.unit + " " + emp.aggInfo.durationType : "-") : "-"}</td>
                                        <td>{emp.aggInfo ? (emp.aggInfo.baseTime ? emp.aggInfo.baseTime : "-") : "-"}</td>
                                        <td>{emp.description ? emp.description : "-"}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          ))
                      ) : (
                        <strong>There is no event created</strong>
                      )
                    ) : (
                      <strong>Select event</strong>
                    )}
                  </div>
                </div>
              </div>
            ))
          : null}
      </h5>
    );
  };

  const renderActionTab = () => {
    return (
      <h5 className="card-title">
        {selectedScenario
          ? Object.values(selectedScenario).map((ss: any) => (
              <div className="row">
                <div className="col-2 border-right">
                  <div className="list-group list-group-flush ">
                    <div className="list-group-item p-1 ">
                      <span>
                        {" "}
                        {"Action List"}({Object.values(ss.scenario.actionMap).length})
                      </span>
                    </div>
                  </div>

                  <div className="list-group list-group-flush " id="list-tab" role="tablist">
                    {Object.values(ss.scenario.actionMap).length !== 0
                      ? Object.values(ss.scenario.actionMap).map((am: any) => (
                          <a
                            className="list-group-item list-group-item-action p-1"
                            id={"list-" + am.name + "-list"}
                            data-toggle="list"
                            href={"#" + "list-" + am.name}
                            role="tab"
                            aria-controls={am.name}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setselectedActionId(am.id);
                            }}
                          >
                            {am.name}
                          </a>
                        ))
                      : " There is no action created"}
                  </div>
                </div>
                <div className="col-10 ">
                  <div>
                    {selectedActionId !== null ? (
                      Object.values(ss.scenario.actionMap).length !== 0 ? (
                        Object.values(ss.scenario.actionMap)
                          .filter((ssf: any) => ssf.id == selectedActionId)
                          .map((am: any) => (
                            <>
                              <div className="list-group list-group-flush ">
                                <div className="list-group-item border-0 p-1 ">
                                  <span>
                                    {am.name} ({am.template.name})
                                  </span>
                                </div>

                                <div className="list-group-item border-0 p-1">
                                  <span style={{ color: "#EF9222" }}>{"Description: "}</span>
                                  {am.description}
                                </div>
                              </div>

                              <div className="card  mb-4 mt-3 shadow-sm">
                                <div className="row p-2">
                                  <div className="col-auto mr-auto">
                                    {am.template ? (
                                      am.returningEvent ? (
                                        <small>
                                          Action returns {"("}
                                          {Object.values(am.returningEvent).length}
                                          {")"}
                                        </small>
                                      ) : (
                                        <small>Action returns (0)</small>
                                      )
                                    ) : null}
                                  </div>
                                </div>

                                <table className="table table-hover ">
                                  <thead>
                                    <tr></tr>
                                  </thead>

                                  <thead className="bg-light">
                                    <tr>
                                      <th scope="col">Event</th>
                                      <th scope="col">Template</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {am.template
                                      ? am.returningEvent
                                        ? Object.values(am.returningEvent).map((re: any) => (
                                            <tr>
                                              <td>{re.name}</td>
                                              <td>{re.template}</td>
                                            </tr>
                                          ))
                                        : null
                                      : null}
                                  </tbody>
                                </table>
                              </div>

                              <div className="card  mb-4 mt-3 shadow-sm">
                                <table className="table table-hover ">
                                  <thead>
                                    <tr>
                                      <th scope="col ">
                                        {"Action Params"}({Object.values(am.params).length})
                                      </th>
                                    </tr>
                                  </thead>

                                  <thead className="bg-light">
                                    <tr>
                                      <th scope="col">Key</th>
                                      <th scope="col">Field/Value</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {Object.values(am.params).map((amf: any) => (
                                      <tr>
                                        <th scope="row">{amf[0].key}</th>
                                        <td>{amf[0].value}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          ))
                      ) : (
                        <strong>There is no action created</strong>
                      )
                    ) : (
                      <strong>Select action</strong>
                    )}
                  </div>
                </div>
              </div>
            ))
          : null}
      </h5>
    );
  };

  const renderValidationTab = () => {
    return (
      <h5 className="card-title">
        {selectedScenario
          ? Object.values(selectedScenario).map((ss: any) => (
              <div className="row">
                <div className="col-2 border-right">
                  <div className="list-group list-group-flush ">
                    <div className="list-group-item p-1 ">
                      <span>
                        {" "}
                        {"Validation List"}({Object.values(ss.scenario.validationMap).length})
                      </span>
                    </div>
                  </div>
                  <div className="list-group list-group-flush " id="list-tab" role="tablist">
                    {Object.values(ss.scenario.validationMap).length !== 0
                      ? Object.values(ss.scenario.validationMap).map((vm: any) => (
                          <a
                            className="list-group-item list-group-item-action p-1"
                            id={"list-" + vm.name + "-list"}
                            data-toggle="list"
                            href={"#" + "list-" + vm.name}
                            role="tab"
                            aria-controls={vm.name}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setselectedValidationId(vm.id);
                            }}
                          >
                            {vm.name}
                          </a>
                        ))
                      : " There is no validation created"}
                  </div>
                </div>
                <div className="col-10 ">
                  <div>
                    {selectedValidationId !== null ? (
                      Object.values(ss.scenario.validationMap).length !== 0 ? (
                        Object.values(ss.scenario.validationMap)
                          .filter((ssf: any) => ssf.id == selectedValidationId)
                          .map((vm: any) => (
                            <>
                              <div className="list-group list-group-flush ">
                                <div className="list-group-item border-0 p-1 ">
                                  <span>{vm.name} </span>
                                </div>

                                <div className="list-group-item border-0 p-1">
                                  <span style={{ color: "#EF9222" }}>{"Description: "}</span>
                                  {vm.description}
                                </div>
                              </div>

                              <div className="card  mb-4 mt-3 shadow-sm">
                                <table className="table table-hover ">
                                  <thead>
                                    <tr>
                                      <th scope="col ">Validation Detail</th>
                                    </tr>
                                  </thead>

                                  <thead className="bg-light">
                                    <tr>
                                      <th scope="col">Left</th>
                                      <th scope="col">
                                        <FontAwesomeIcon icon={faCodeBranch} />
                                        Operand
                                      </th>

                                      <th scope="col">Right</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <td>{vm.left}</td>
                                    <td>{vm.operand}</td>
                                    <td>{vm.right}</td>
                                  </tbody>
                                </table>
                              </div>
                            </>
                          ))
                      ) : (
                        <strong>There is no validation created</strong>
                      )
                    ) : (
                      <strong>Select validation</strong>
                    )}
                  </div>
                </div>
              </div>
            ))
          : null}
      </h5>
    );
  };

  const renderSessionDefTab = () => {
    return (
      <h5 className="card-title">
        {selectedScenario
          ? Object.values(selectedScenario).map((ss: any) => (
              <div className="row">
                <div className="col-2 border-right">
                  <div className="list-group list-group-flush ">
                    <div className="list-group-item p-1 ">
                      <span>
                        {" "}
                        {"Session Definition Items"}({Object.values(ss.scenario.sessionData).length})
                      </span>
                    </div>
                  </div>
                  <div className="list-group list-group-flush " id="list-tab" role="tablist">
                    {Object.values(ss.scenario.sessionData).length !== 0
                      ? Object.values(ss.scenario.sessionData).map((sd: any) => (
                          <a
                            className="list-group-item list-group-item-action p-1"
                            id={"list-" + sd.propertyName + "-list"}
                            data-toggle="list"
                            href={"#" + "list-" + sd.propertyName}
                            role="tab"
                            aria-controls={sd.propertyName}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setselectedSessionDefId(sd.id);
                            }}
                          >
                            {sd.propertyName}
                          </a>
                        ))
                      : " There is no session created"}
                  </div>
                </div>
                <div className="col-10 ">
                  <div>
                    {selectedSessionDefId !== null ? (
                      Object.values(ss.scenario.sessionData).length !== 0 ? (
                        Object.values(ss.scenario.sessionData)
                          .filter((ssf: any) => ssf.id == selectedSessionDefId)
                          .map((sd: any) => (
                            <>
                              <div className="list-group list-group-flush ">
                                <div className="list-group-item border-0 p-1 ">
                                  <span>
                                    {" "}
                                    {sd.propertyName} ({sd.propertyType}){" "}
                                  </span>
                                </div>

                                <div className="list-group-item border-0 p-1">
                                  <span style={{ color: "#EF9222" }}>{"Description: "}</span>
                                  {sd.description}
                                </div>
                              </div>
                            </>
                          ))
                      ) : (
                        <strong>There is no session created</strong>
                      )
                    ) : (
                      <strong>Select session definition</strong>
                    )}
                  </div>
                </div>
              </div>
            ))
          : null}
      </h5>
    );
  };

  const renderScenarioStatus = (cs: any) => {

  

    if (cs.status.status == "DEPLOYED") {
      return (
        <>
          <FontAwesomeIcon icon={faCloudUploadAlt} color={tableDeployedBgColor} /> {cs.status.status}{" "}
        </>
      );
    } else if (cs.status.status == "UNDEPLOYED") {
      return (
        <>
          <FontAwesomeIcon icon={faCircle} color={tableUndeployedBgColor} /> {cs.status.status}
        </>
      );
    } else if (cs.status.status == "INITIATED") {
      return (
        <>
          <FontAwesomeIcon icon={faSyncAlt} className="fa-spin" /> {cs.status.status}
        </>
      );
    } else {
      return (
        <>
          <FontAwesomeIcon icon={faQuestion} /> {cs.status.status}
        </>
      );
    }
  };

  const renderEachScenarioDetailsTabLayout = () => {
    return (
      <>
        {scenarioId.data ? (
          <div className="card ">
            <div className="card-header border-bottom">
              <ul className="nav nav-tabs card-header-tabs" id="cardTab" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" id="scenario-information-tab" href="#scenario-information" data-toggle="tab" role="tab" aria-controls="scenario-information" aria-selected="true">
                    Scenario Informations
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="event-tab" href="#event" data-toggle="tab" role="tab" aria-controls="event" aria-selected="false">
                    Event
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" id="action-tab" href="#action" data-toggle="tab" role="tab" aria-controls="action" aria-selected="false">
                    Actions
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" id="validation-tab" href="#validation" data-toggle="tab" role="tab" aria-controls="action" aria-selected="false">
                    Validations
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="session-tab" href="#session" data-toggle="tab" role="tab" aria-controls="session" aria-selected="false">
                    Session Definition
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="cardTabContent">
                <div className="tab-pane fade show active" id="scenario-information" role="tabpanel" aria-labelledby="scenario-information-tab">
                  <h5 className="card-title">
                    {selectedScenario
                      ? Object.values(selectedScenario).map((ss: any) => (
                          <div>
                            <div className="row">
                              <div className="col-auto mr-auto">
                                <h3>{ss.scenario.name}</h3>
                              </div>
                              <div className="col-auto">{renderScenarioStatus(ss)}</div>
                            </div>
                            <div className="row">
                              <div className="col-auto mr-auto">
                                <label>Last Update:</label> {new Date(ss.scenario.updateDate).toLocaleString()}
                              </div>
                              <div className="col-auto">
                                {ss.lastOperationTime == null ? (
                                  "N/A"
                                ) : (
                                  <div>
                                      <FontAwesomeIcon icon={faClock} style={{marginRight:"2"}} />
                                    <label>Deployment Date:</label> {new Date(ss.lastOperationTime).toLocaleString()}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col md-6">
                                <label style={{ color: "#ea7e22" }}>Description</label>
                                <div>{ss.scenario.description}</div>
                              </div>
                              <div className="col md-6"></div>
                            </div>
                          </div>
                        ))
                      : null}
                  </h5>
                </div>
                <div className="tab-pane fade" id="event" role="tabpanel" aria-labelledby="event-tab">
                  {renderEventTab()}
                </div>

                <div className="tab-pane fade" id="action" role="tabpanel" aria-labelledby="action-tab">
                  {renderActionTab()}
                </div>

                <div className="tab-pane fade" id="validation" role="tabpanel" aria-labelledby="validation-tab">
                  {renderValidationTab()}
                </div>

                <div className="tab-pane fade" id="session" role="tabpanel" aria-labelledby="session-tab">
                  {renderSessionDefTab()}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  return <>{renderEachScenarioDetailsTabLayout()}</>;
};

export default Preview;
