import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEventUniqueEventParamAction } from "../../../redux/actions/EventActions";
import { useParams } from "react-router";
import { RootStore } from "../../../redux/store/Store";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-ftl";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-min-noconflict/ext-language_tools";



const RteTransitionVal = (props: any) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const editorValueProp = props.editorValueProp;
  const editorsetValueProp = props.seteditorValueProp;

  const aceEditorRef = useRef<any>();
  const [aceEditor, setaceEditor] = useState<any>(null);
  const [cursorPosition, setcursorPosition] = useState<any>(null);

  const validations: any = useSelector(
    (state: RootStore) => state.sendValidationMapDataReducer.data
  );

  const [validationMapData, setvalidationMapData] = useState<any>(null);

  const [conditionData, setconditionData] = useState([
    { name: "And", value: "&&" },
    { name: "Or", value: "||" },
  ]);

  useEffect(() => {
    if (validations) {
      setvalidationMapData(validations);
    }
  }, [validations]);

  useEffect(() => {
    if (aceEditorRef.current) {
      setaceEditor(aceEditorRef.current.editor);
      setcursorPosition(aceEditorRef.current.editor.getCursorPosition());
      if (editorValueProp) {
        aceEditorRef.current.editor.session.setValue(editorValueProp);
      }
      aceEditorRef.current.editor.session.setUseWrapMode(true);
    }
  }, [aceEditorRef]);

  useEffect(() => {
    dispatch(getEventUniqueEventParamAction(id.toString()));
  }, []);

  const onChangeHandler = (e: any) => {
    editorsetValueProp(e);
  };

  const insertAtPosition = (p: string) => {
    if (aceEditorRef && cursorPosition) {
      aceEditor.session.insert(
        aceEditorRef.current.editor.getCursorPosition(),
        p
      );
    }
  };

  const addValidation = (v: any) => {
    var text = "validation." + v.name;
    insertAtPosition(text);
  };

  const addCondition = (c: any) => {
    var text = c.value;
    insertAtPosition(text);
  };

  const renderRichbox = () => {
    return (
      <div className="richbox">
        <div className="nav-bar">
          <div className="btn-group">
            <div className="btn-group">
              <button
                className="btn btn-default dropdown-toggle"
                data-toggle="dropdown"
              >
                Validations <span className="caret"></span>
              </button>
              <ul className="dropdown-menu">
                {validationMapData
                  ? Object.values(validationMapData).map((p: any) => (
                      <li
                        className="rte-li"
                        onClick={() => {
                          addValidation(p);
                        }}
                      >
                        <a>{p.name}</a>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
            <div className="btn-group">
              <button
                className="btn btn-default dropdown-toggle last-child"
                data-toggle="dropdown"
              >
                Conditions <span className="caret"></span>
              </button>
              <ul className="dropdown-menu ">
                {conditionData
                  ? Object.values(conditionData).map((p: any) => (
                      <li
                        className="rte-li"
                        onClick={() => {
                          addCondition(p);
                        }}
                      >
                        <a>{p.name}</a>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
          </div>
          <div className="btn-group"></div>
          <AceEditor
            className="ace-rte"
            ref={aceEditorRef}
            mode="ftl"
            theme="chrome"
            name="ace-rte"
            editorProps={{ $blockScrolling: Infinity }}
            enableBasicAutocompletion={true}
            enableLiveAutocompletion={false}
            enableSnippets={true}
            onChange={(e) => onChangeHandler(e)}
            width="100%"
            height="228.8px"
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderRichbox()}
      {/* {validationMapData ? JSON.stringify(validationMapData):"validationMapData"} */}
    </div>
  );
};

export default RteTransitionVal;
