import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GenaralModal = (props: any) => {
  
  const { modalId, modalName,size, formComp, addInfos } = props;
  const modalSizeClassName = size ==="" ? "modal-dialog" : "modal-dialog modal-"+size

  return (
    <>
      <div className="modal" id={modalId} tabIndex={-1} role="dialog" aria-labelledby={modalId + "Label"} aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div id={modalId+"D"}  className={modalSizeClassName} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id={modalId + "Label"}>
                {modalName}
              </h4>
              <button id={modalId + "CloseIconButton"} className="close" type="button" data-dismiss="modal" aria-label="Close" onClick={() => {}}>
                <span aria-hidden="true">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </button>
            </div>
            <div className="modal-body">{formComp(addInfos)}</div>
            <div className="modal-footer" hidden></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenaralModal;
