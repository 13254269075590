import { Chart, CategoryScale, LinearScale, PointElement, LineController, Title, Legend, Tooltip, TimeScale, BarElement, registerables, ChartOptions, PieController } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';


const PieChart = ({ graphData }) => {


    // Register the necessary components and scales

    Chart.register(CategoryScale, LinearScale, PointElement, LineController, Title, Legend, Tooltip, TimeScale, BarElement, PieController);
    Chart.register(...registerables);

    const options: ChartOptions<'doughnut'> = {
        cutout: 50,
        elements: {
            arc: {
                circular: true
            }
        },
        backgroundColor: [
            '#1E61F0',
            '#D24DD1',
            '#FF549E',
            '#FF876E',
            '#FFC256',
            '#F9F871',
            '#00CFFF',
            '#FF6DC0',
            '#DA79F9',
            '#512B00'
        ],
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = context.chart.data.labels[context.dataIndex];
                        const value = context.chart.data.datasets[0].data[context.dataIndex];
                        const total = context.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
                        const percentage = Math.round((value / total) * 100);
                        return `${label}: ${value} (${percentage}%)`;
                    }
                },
                bodyFont: {
                    weight: 'bold'
                }
            },
            datalabels: {
                display: true,
                color: '#fff',
                align: 'center',
                anchor: 'left',
                formatter: function (value, context) {
                    return context.chart.data.labels[context.dataIndex];
                },
                labels: {
                    title: {

                    },
                    value: {
                        color: 'green'
                    }
                }
            }
        }
    };

    return (
        <Doughnut data={graphData} options={options} plugins={[ChartDataLabels]} />
    );
}

export default PieChart;