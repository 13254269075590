import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortBy } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getValidationOperandAction, sendSelectedValidationDataAction, sendSelectedValidationIdAction, sendValidationMapDataAction } from "../../../../../redux/actions/ValidationActions";
import { RootStore } from "../../../../../redux/store/Store";
import { ISingleScenario } from "../../../types/scenarioTypes";
import { IDeleteValidation, IEditValidation, INewValidation, ValidationItemType } from "../../../types/validationTypes";
import AddNewValidationForm from "../forms/AddNewValidationForm";
import DeleteValidationManageForm from "../forms/DeleteValidationManageForm";
import EditValidationManageForm from "../forms/EditValidationManageForm";
import GenaralModal from "../modals/GenaralModal";
import $ from 'jquery';


const ValidationTab = () => {
  const dispatch = useDispatch();

  const singleScenario: ISingleScenario = useSelector((state: RootStore) => state.singleScenarioReducer.data);
  const newValidation: INewValidation = useSelector((state: RootStore) => state.putValidationReducer.data);
  const editValidation: IEditValidation = useSelector((state: RootStore) => state.postValidationReducer.data);
  const deleteValidation: IDeleteValidation = useSelector((state: RootStore) => state.deleteValidationReducer.data);

  const [selectedScenarioData, setSelectedScenarioData] = useState<ISingleScenario | null>(null);
  const [validationMapData, setvalidationMapData] = useState<{ [key: string]: any } | null>(null);
  const [selectedValidationData, setselectedValidationData] = useState<ValidationItemType | null>(null);
  const [selectedValidationId, setselectedValidationId] = useState<string>("");
  const [selectedValidationName, setselectedValidationName] = useState<string>("");

  useEffect(() => {
    dispatch(getValidationOperandAction());
  }, []);

  useEffect(() => {
    setSelectedScenarioData(singleScenario);
  }, [singleScenario]);

  useEffect(() => {
    setValidationMap();
    setSelectedValidation();
  }, [selectedScenarioData, selectedValidationName]);

  const setValidationMap = () => {
    if (selectedScenarioData) {
      /* dispatch(sendValidationMapDataAction(selectedScenarioData.scenario.validationMap)) */
      setvalidationMapData(selectedScenarioData.scenario.validationMap);
    }
  };

  const setSelectedValidation = () => {
    if (selectedScenarioData) {
      if (selectedValidationName) {
        setselectedValidationData(selectedScenarioData.scenario.validationMap[selectedValidationName]);
        dispatch(sendSelectedValidationDataAction(selectedScenarioData.scenario.validationMap[selectedValidationName]));
      }
    }
  };

  useEffect(() => {
    if (selectedScenarioData) {
      if (newValidation?.status === "success") {
        const newSingleScenarioObj = { ...selectedScenarioData };
        newSingleScenarioObj.scenario.validationMap[newValidation.data.name] = newValidation.data;
        setSelectedScenarioData(newSingleScenarioObj);
        setselectedValidationId(newValidation.data.id.toString());
        setselectedValidationName(newValidation.data.name);
        dispatch(sendSelectedValidationIdAction(newValidation.data.id.toString()));
        $("#AddNewValidationModal").modal("hide");
        $(function () {
          $("#validationList").children().removeClass("active");
          $("#validationList")
            .children("#" + newValidation.data.name)
            .addClass("active");
        });
      }
    }
  }, [newValidation]);

  useEffect(() => {
    if (selectedScenarioData) {
      if (editValidation?.response?.statusText === "OK") {
        const singleScenarioObj = { ...selectedScenarioData };
        delete singleScenarioObj.scenario.validationMap[selectedValidationName];
        const newSingleScenarioObj = { ...singleScenarioObj };
        newSingleScenarioObj.scenario.validationMap[editValidation.bodyObj?.name] = editValidation.bodyObj;
        setselectedValidationName(editValidation.bodyObj?.name);
        setSelectedScenarioData(newSingleScenarioObj);
        $("#EditValidationManageModal").modal("hide");
        $(function () {
          $("#validationList").children().removeClass("active");
          $("#validationList")
            .children("#" + editValidation.bodyObj?.name)
            .addClass("active");
        });
      }
    }
  }, [editValidation]);

  useEffect(() => {
    if (selectedScenarioData) {
      if (deleteValidation?.statusText === "OK") {
        const singleScenarioObj = { ...selectedScenarioData };
        delete singleScenarioObj.scenario.validationMap[selectedValidationName];
        setSelectedScenarioData(singleScenarioObj);
        setselectedValidationName("");
        setselectedValidationId("");
        setselectedValidationData(null);
        $("#DeleteValidationManageModal").modal("hide");
        $("#validationList").children().removeClass("active");
      }
    }
  }, [deleteValidation]);

  const renderValidationManageButton = () => {
    return (
      <>
        <button
          className="btn btn-outline-primary dropdown-toggle pt-2 pb-2 pl-2 pr-2 ml-1"
          type="button"
          id="ValidationManageDropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Manage
        </button>

        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="ValidationManageDropdown">
          <button className="dropdown-item" type="button" data-toggle="modal" data-target="#EditValidationManageModal">
            Edit
          </button>

          <button className="dropdown-item" type="button" data-toggle="modal" data-target="#DeleteValidationManageModal">
            Delete
          </button>
        </div>
      </>
    );
  };

  const renderValidationList = () => {
    return (
      <div className="list-group list-group-flush " id="validationList" role="tablist">
        {validationMapData
          ? validationMapData
            ? sortBy(Object.values(validationMapData), "name").map((v: any) => (
                <a
                  className="list-group-item list-group-item-action p-1"
                  data-toggle="list"
                  id={v.name}
                  href={"#" + v.name}
                  role="tab"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setselectedValidationId(v.id);
                    setselectedValidationName(v.name);
                    dispatch(sendSelectedValidationIdAction(v.id));
                  }}
                >
                  {v.name}
                </a>
              ))
            : " There is no validation created"
          : " There is no validation created"}
      </div>
    );
  };

  const renderValidationPreview = () => {
    return (
      <>
        {selectedValidationData ? (
          <>
            <div className="list-group list-group-flush ">
              <div className="list-group-item border-0 p-1 ">
                <div className="row">
                  <div className="col-auto mr-auto">
                    <span>{selectedValidationData.name} </span>
                  </div>
                  <div className="col-auto">{renderValidationManageButton()}</div>
                </div>
              </div>

              <div className="list-group-item border-0 p-1">
                <span style={{ color: "#EF9222" }}>{"Description: "}</span>
                {selectedValidationData.description ? selectedValidationData.description : "-"}
              </div>
            </div>

            <div className="card  mb-4 mt-3 shadow-sm">
              <table className="table table-hover ">
                <thead>
                  <tr>
                    <th scope="col ">Validation Detail</th>
                  </tr>
                </thead>

                <thead className="bg-light">
                  <tr>
                    <th scope="col">Left</th>
                    <th scope="col">
                      <FontAwesomeIcon icon={faCodeBranch} />
                      Operand
                    </th>

                    <th scope="col">Right</th>
                  </tr>
                </thead>

                <tbody>
                  <td>{selectedValidationData.left}</td>
                  <td>{selectedValidationData.operand}</td>
                  <td>{selectedValidationData.right}</td>
                </tbody>
              </table>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const renderModals = () => {
    return (
      <>
        <GenaralModal modalId="AddNewValidationModal" modalName="New Validation" formComp={AddNewValidationForm} />
        <GenaralModal modalId="EditValidationManageModal" modalName="Edit Validation" formComp={EditValidationManageForm} />
        <GenaralModal modalId="DeleteValidationManageModal" modalName="Delete Validation" size="lg" formComp={DeleteValidationManageForm} />
      </>
    );
  };

  const renderValidationTab = () => {
    return (
      <h5 className="card-title">
        <div className="row">
          <div className="col-2 border-right">
            <div className="list-group list-group-flush ">
              <div className="list-group-item p-1 ">
                <span>
                  <strong>Validation List </strong>
                  <small>({validationMapData ? Object.values(validationMapData).length : null})</small>
                </span>
                <button className="btn btn-success p-1 mt-2 mb-2" data-toggle="modal" data-target="#AddNewValidationModal">
                  Add New Validation{" "}
                </button>
              </div>
            </div>
            {renderValidationList()}
          </div>
          <div className="col-10 ">
            <div>{selectedValidationData ? renderValidationPreview() : <strong>Select validation</strong>}</div>
          </div>
        </div>
      </h5>
    );
  };

  return (
    <div>
      {renderValidationTab()}
      {renderModals()}
    </div>
  );
};

export default ValidationTab;
