import { FieldProps } from "formik";
import Select from "react-select";


interface Option {
  label: string;
  value: string;
}

interface CustomSelectProps extends FieldProps {
  options: any;
  isMulti?: boolean;
  className?: string;
  placeholder?: string;
  value:any
  defaultValues:any,
  components:any,
}

export const CustomSelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  components,
}: CustomSelectProps) => {

  const onChange = (option: any) => {
    if(option){
      form.setFieldValue(
        field.name,
        isMulti
          ? (option as Option[]).map((item: Option) => item.value)
          : (option as Option).value ? (option as Option).value : null
      );
    }else{
      form.setFieldValue(
        field.name, ""
      );
    }
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option:Option) => field.value.indexOf(option.value) >= 0)
        : options.find((option:Option) => option.value === field.value);
    } else {
      return isMulti ? [] : ("" as string);
    }
  };

  return (
    <Select
      isClearable
      className={className}
      name={field.name}
      value={getValue()}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      components={components}
    />
  );
};

export default CustomSelect;
