import * as transitionActionTypes from "../types/types";

export const putTransitionReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case transitionActionTypes.ADD_TRANSITION_FAIL:
      return {
        data: action.payload,
      };
    case transitionActionTypes.ADD_TRANSITION_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const postTransitionReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case transitionActionTypes.EDIT_TRANSITION_FAIL:
      return {
        data: action.payload,
      };
    case transitionActionTypes.EDIT_TRANSITION_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const deleteTransitionReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case transitionActionTypes.DELETE_TRANSITION_FAIL:
      return {
        data: action.payload,
      };
    case transitionActionTypes.DELETE_TRANSITION_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendTransitionDataReducer = (state:any = {}, action: any) => {
  switch (action.type) {
    case transitionActionTypes.SEND_TRANSITION_DATA_FAIL:
      return {
        data: action.payload,
      };
    case transitionActionTypes.SEND_TRANSITION_DATA_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};


export const sendTransitionIdReducer = (state:any = "", action: any) => {
  switch (action.type) {
    case transitionActionTypes.SEND_TRANSITION_ID_FAIL:
      return {
        data: action.payload,
      };
    case transitionActionTypes.SEND_TRANSITION_ID_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};
