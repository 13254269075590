import { faAngleRight, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { render } from "@testing-library/react";
import axios from "axios";
import { useFormik } from "formik";
import { data } from "jquery";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { array } from "yup/lib/locale";
import ErrorMessageComp from "../../components/common/ErrorMessageComp";
import SuccessMessageComp from "../../components/common/SuccessMessageComp";
import { getTemplateActions } from "../../redux/actions/ActionActions";
import {
  addCampaignAction,
  deployCampaignAction,
  editCampaignAction,
  getCampaignInitAction,
  getCampaingAction,
  sendCampaignEventTemplateParamAction,
} from "../../redux/actions/campaignActions";
import { eventTimerFormatHandler } from "../../redux/actions/EventActions";
import { RootStore } from "../../redux/store/Store";
import Integration from "../integration/Integration";
import AddNewActionForm from "../scenario/detail/components/forms/AddNewActionForm";
import CustomRadio from "../scenario/detail/components/forms/utils/CustomRadio";
import {
  generateArrayOfDays,
  generateArrayOfMonths,
  generateArrayOfYears,
} from "../scenario/detail/components/forms/utils/formUtils";
import GenaralModal from "../scenario/detail/components/modals/GenaralModal";
import AddCampaignActionForm from "./comps/AddCampaignActionForm";
import NewMessageComposer from "./comps/NewMessageComposer";

const CampaignAddEditPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const campaignId = useParams<{ campaignId: string }>().campaignId;

  const emptyCampaign = {
    id: null,
    name: "",
    desciption: "",
    rule: null,
    segment: null,
    action: {
      action: {}
    },
  }

  const campaignInitR: any = useSelector((state: RootStore) => state.getCampaignInitReducer);
  const campaignR: any = useSelector((state: RootStore) => state.getCampaignReducer);
  const campaignErrorR: any = useSelector((state: RootStore) => state.addCampaignReducer);
  const campaignErrorEditR: any = useSelector((state: RootStore) => state.editCampaignReducer);
  const deployCampaignResponse: any = useSelector((state: RootStore) => state.deployCampaignReducer);

  const [postError, setpostError] = useState<string>("");

  const [campaignInitData, setcampaignInitData] = useState<any>(null);
  const [campaignData, setcampaignData] = useState<any>(null);
  const [messageComposerShow, setmessageComposerShow] = useState(false);
  const [messageComposerState, setmessageComposerState] = useState<any>({});
  const [campaingErrorState, setcampaingErrorState] = useState<any>("");
  const [saveInitiated, setSaveInitiated] = useState<boolean>(false);
  const [deployCampaignResponseState, setdeployCampaignResponseState] = useState("");

  const [integrationListApiData, setintegrationListApiData] = useState<any>(null);
  const [eventTemplateId, seteventTemplateId] = useState<any>("");
  const [campaignAction, setCampaignAction] = useState<any>(null);
  const [campaignActionTemplate, setCampaignActionTemplate] = useState<any>(null);
  const [disableSaveDeploy, setdisableSaveDeploy] = useState(false);


  useEffect(() => {
    dispatch(getTemplateActions());
  }, []);

  useEffect(() => {
    getIntegrations("/integration");

    setcampaingErrorState("");
  }, []);

  useEffect(() => {
    document.title = "Campaign Edit | Herogi Growth Panel"
  }, []);

  const getIntegrations = (url: string) => {
    axios
      .get(url, {})
      .then((response) => {
        var integrationApiList: any = [];
        response.data?.integrationList.forEach((el: any) => {
          if (el.productName === "AWS") {
            integrationApiList.push("amazon");
          } else {
            integrationApiList.push(el.productName.toLowerCase());
          }
        });

        setintegrationListApiData(integrationApiList);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error);
        }
      });
  };

  //when createIntegrationModal closed check integrations list
  useEffect(() => {
    $("#CreateIntegrationModalCloseIconButton").click(function () {
      getIntegrations("/integration");
    });
  }, []);

  useEffect(() => {
    setpostError("");
    setcampaingErrorState("");
    setdeployCampaignResponseState("");
    return () => {
      setpostError("");
      setcampaingErrorState("");
      setdeployCampaignResponseState("");
    };
  }, []);

  useEffect(() => {
    dispatch(getCampaignInitAction());
  }, []);

  useEffect(() => {
    if (campaignInitR) {
      setcampaignInitData(campaignInitR);
    }
  }, [campaignInitR]);

  useEffect(() => {
    if (campaignId) {
      dispatch(getCampaingAction(campaignId));
    }
  }, [campaignId]);

  useEffect(() => {
    if (campaignR && campaignId) {
      setcampaingErrorState("");
      setcampaignData(campaignR?.data?.data?.campaign);

      if(campaignR?.data?.data?.campaign?.action?.action != null) {
        setCampaignAction(campaignR?.data?.data?.campaign?.action?.action);
      }

    } else {
      setcampaingErrorState("");
      setCampaignAction(null);
      setcampaignData(emptyCampaign);
    }
  }, [campaignR]);

  useEffect(() => {
    setcampaingErrorState(campaignErrorR);
  }, [campaignErrorR]);

  useEffect(() => {

    //campaignEditR still holds data as usual that's why this is set after click
    //TODO fix
    if(campaingErrorState != null && campaingErrorState != "" && saveInitiated ) {
      if(campaingErrorState.data?.status == "success") {
      
        setcampaignData(campaingErrorState.data?.data);
       
        setmessageComposerState("");
        setcampaingErrorState("");
        formik.resetForm();
        if (campaignData && campaignData.name && campaignId) {
          history.push(`/campaign/view/${campaignId}`);
        } else {
          history.push("/campaign");
        }
        
        setcampaignData(null);

      } else {
        setSaveInitiated(false);
      }
    }

  }, [campaingErrorState]);

  useEffect(() => {
    setcampaingErrorState(campaignErrorEditR);
  }, [campaignErrorEditR]);

  useEffect(() => {
    setdeployCampaignResponseState(deployCampaignResponse?.data?.data?.status);
  }, [deployCampaignResponse]);

  useEffect(() => {
    if (messageComposerState) {
      if (Object.values(messageComposerState).length > 0) {
        setdisableSaveDeploy(true);
      } else {
        setdisableSaveDeploy(false);
      }
    }
  }, [messageComposerState]);

  useEffect(() => {
    if (disableSaveDeploy) {
    }
  }, [disableSaveDeploy]);



  const initializeAction = (actionTemplate, integration) => {
    //TODO reset integration;

    let tempAction = {};

    if(integration != null) {
      //setIntegrationId(integration.id);
      tempAction["integration"] = JSON.parse(JSON.stringify(integration));
    } else {
      tempAction["integration"] = null;
    }
    
    setCampaignActionTemplate(actionTemplate);

    tempAction["template"] = JSON.parse(JSON.stringify(actionTemplate));
    tempAction["params"] = {};
    actionTemplate.templateParams.map(p => {
      const ap = JSON.parse(JSON.stringify(p));
      ap["value"] = "";
      tempAction["params"][p.key] = [ap];
    });

    setCampaignAction({...tempAction});
    //setcampaignData({...campaignData});
  }

  useEffect(() => {
    if (eventTemplateId && campaignInitData) {
      if (campaignInitData?.data?.data?.eventTemplates) {
        campaignInitData?.data?.data?.eventTemplates.forEach((el: any) => {
          if (el.id == eventTemplateId) {
            dispatch(sendCampaignEventTemplateParamAction(el.params));
          }
        });
      }
    }
  }, [eventTemplateId, campaignInitData]);

  const setEventTemplatesDataOption = () => {
    return (
      <>
        <option disabled={true} value="" selected label="Event Template" />
        {campaignInitData
          ? campaignInitData?.data?.data?.eventTemplates.map((agd: any) => <option value={agd?.id} label={agd?.name} />)
          : null}
      </>
    );
  };

  // const getActionGroupData = () => {
  //   var selectedAgdi: any = {};
  //   if (campaignInitData) {
  //     var campaignRunsType = formik.values.campaignRuns;

  //     campaignInitData?.data?.data?.actionGroupData.map((agd: any) => {
  //       {
  //         Object.values(agd?.templates).map((agdt: any) => {
  //           if (campaignRunsType === "ONETIME") {
  //             if (agdt.id == formik.values.oneTimeMailOr) {
  //               selectedAgdi = agdt;
  //             }
  //           }
  //           if (campaignRunsType === "SCHEDULED") {
  //             if (agdt.id == formik.values.scheduledMailOr) {
  //               selectedAgdi = agdt;
  //             }
  //           }
  //           if (campaignRunsType === "TRIGGER") {
  //             if (agdt.id == formik.values.afterEventMailOr) {
  //               selectedAgdi = agdt;
  //             }
  //           }
  //         });
  //       }
  //     });
  //   }

  //   return selectedAgdi;
  // };

  const getSharedParams = () => {
    var selectedSp: any = [];
    if (campaignInitData) {
      campaignInitData?.data?.data?.actionGroupData.map((agd: any) => {
        {
          Object.values(agd?.templates).map((agdt: any) => {
            //TODO check actiontemplate usage  
            if (agdt.id == campaignAction.template.id) {
              selectedSp = agd.sharedParams;
            }
          });
        }
      });
    }

    return selectedSp;
  };

  const setSegmentsOption = () => {
    return (
      <>
        <option disabled={true} value="" selected label="Choose Segment" />
        {campaignInitData?.data?.data?.segments
          ? campaignInitData?.data?.data?.segments.map((s: any) => <option value={s.id} label={s.name} />)
          : null}
      </>
    );
  };

  const copyActionParamsFromComposer = (params) => {
    params.map(p => {
      campaignAction.params[p.key] = p.params;
    });

    setCampaignAction({...campaignAction});
  }

  const everyYearOptions = () => {
    return (
      <>
        <option value="" label="Every Year" />
        {Object.values(generateArrayOfYears()).map((item, index) => (
          <option key={index} value={item.value} label={item.key} />
        ))}
      </>
    );
  };

  const everyMonthOptions = () => {
    return (
      <>
        <option value="" label="Every Month" />
        {generateArrayOfMonths.map((item, index) => (
          <option key={index} value={item.value} label={item.key} />
        ))}
      </>
    );
  };

  const everyDayOptions = () => {
    return (
      <>
        <option value="" label="Every Day" />
        {Object.values(generateArrayOfDays()).map((item, index) => (
          <option key={index} value={item.value} label={item.key} />
        ))}
      </>
    );
  };

  const renderBreadCrums = () => {
    return (
      <div className="card shadow-sm ">
        <div className="card-body pb-3 pt-3">
          <div className="d-flex align-items-center justify-content-between px-0">
            <div className="d-flex align-items-center">
              <div>
                <strong>Campaign </strong>
                <small>
                  <FontAwesomeIcon icon={faAngleRight} />
                </small>
                <strong>
                  {" "}
                  {campaignId ? "Edit " : "Create New One"} {campaignData ? `${campaignData?.name}` : ""}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleSubmitCampaign = () => {
    
    const payloadTypedParams: any = [];

    var payloadTemplateId = parseInt(campaignAction.template.id);
       
    Object.keys(campaignAction.params).forEach((key, index) => {
      let params = campaignAction.params[key];  
      let flattenParams = params.map(p => {
        let field = !p.isRecurring ? null : p.field;
        return { "templateParamId" : p.id, field : field, value : p.value };
      });
      payloadTypedParams.push(...flattenParams);
    }); 
    
    var payloadObj: any = {
      name: formik.values.campaignName,
      description: formik.values.campaignDescription,
      segmentId: parseInt(formik.values.choseSegment),
      runType: {
        runType: formik.values.campaignRuns,
        eventTemplateId: formik.values.campaignRuns === "TRIGGER" ? parseInt(formik.values.eventTemplateOr) : null,
        conditions: [], //map it after conditions
        frequency: formik.values.campaignRuns === "SCHEDULED" ? eventTimerFormatHandler(formik.values) : "****",
      },
      action: {
        name: campaignAction.template ? campaignAction.template.name : "",
        templateId: campaignAction.template.id,
        integrationId: campaignAction.integration?.id!,
        params: payloadTypedParams ? payloadTypedParams : [],
      },
    };

    if (formik.isValid) {
      if (campaignId) {
        dispatch(editCampaignAction(payloadObj, campaignId));
      } else {
        dispatch(addCampaignAction(payloadObj, formik.resetForm, setmessageComposerState));
      }
    } else {

    }
  };

  const validationSchema = Yup.object().shape({
    campaignName: Yup.string().required("Campaign name is required"),
    choseSegment: Yup.string().required("Segment required"),
    campaignRuns: Yup.string().required("Campaign runs option is required"),
    // oneTimeMailOr: Yup.string().when("campaignRuns", {
    //   is: "ONETIME",
    //   then: Yup.string().required("Action Template is required"),
    // }),
    // scheduledMailOr: Yup.string().when("campaignRuns", {
    //   is: "SCHEDULED",
    //   then: Yup.string().required("Action Template is required"),
    // }),

    // afterEventMailOr: Yup.string().when("campaignRuns", {
    //   is: "TRIGGER",
    //   then: Yup.string().required("Action Template is required"),
    // }),

    eventTemplateOr: Yup.string().when("campaignRuns", {
      is: "TRIGGER",
      then: Yup.string().required("Event Template is required"),
    }),
  });

  const initialValuesFormik = {
    campaignName: campaignData?.name,
    campaignDescription: campaignData?.description,
    choseSegment: campaignData?.segment != null ? campaignData?.segment : "",
    campaignRuns: campaignData?.rule?.type == null ? "ONETIME" : campaignData?.rule?.type,
    everyYear: campaignData?.rule?.frequency ? (campaignData?.rule?.frequency).substring(0, 4) : "****",
    everyMonth: campaignData?.rule?.frequency ? (campaignData?.rule?.frequency).substring(4, 6) : "**",
    everyDay: campaignData?.rule?.frequency ? (campaignData?.rule?.frequency).substring(6, 8) : "**",
    scheduledSendTime: "",
    scheduledCustomerTimezone: "",
    eventTemplateOr: campaignData ? campaignData?.rule?.event?.id : "",
  };

  const formik = useFormik({
    initialValues: {...initialValuesFormik},
    onSubmit: () => {},
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const renderResult = () => {
    
    if(campaingErrorState != null && campaingErrorState != "" && Object.keys(campaingErrorState).length > 0) {

      if(campaingErrorState.data?.status == "success") {
        return (
          <SuccessMessageComp name={campaingErrorState?.data.status} /> 
        );
      } else {

        if(campaingErrorState.data.data == undefined) {
          return (
            <ErrorMessageComp style={{ width: "25%", fontSize: "14px" }} name={campaingErrorState.data.message} />  
          );
        } else {
          return (
            campaingErrorState.data.data.map(e => {
              return <ErrorMessageComp style={{ width: "25%", fontSize: "14px" }} name={e.message[0]} />  
              }
              )
          );  
          }
      }
    }
  }

  return (

    <>
      <GenaralModal modalId="AddNewActionModal" modalName="New Action" size="" formComp={AddCampaignActionForm} 
      addInfos={{
        saveCallback : (data) => { 
          initializeAction(data.actionTemplate, data.integration);
          $("#AddNewActionModal").modal("hide");
         }
      }}/>
    
    <div className="dashboard-main">
      {renderBreadCrums()}
      <GenaralModal
        modalId="CreateIntegrationModal"
        modalName="Create Integration"
        size="fullscreen"
        addInfos={{ padding: 0, height: "90vh" }}
        formComp={Integration}
      />

      {messageComposerShow ? (
        <div className="card shadow-sm p-3 mt-3 ">
          <NewMessageComposer 
            composerToggle={setmessageComposerShow} 
            sharedParams={getSharedParams()}
            action={campaignAction}
            handleSubmit = {(data) => {
              copyActionParamsFromComposer(data);
              setmessageComposerShow(false);
            }}
          />
        </div>
      ) : null}

      { !messageComposerShow ?  (  
        <div className="card shadow-sm p-3 mt-3 ">
          <div className="form-group">
            <label htmlFor="campaignNameLabel">Campaign Name</label>

            <input
              style={{ width: "25%" }}
              className={formik.errors.campaignName && formik.touched.campaignName ? "form-control is-invalid" : "form-control"}
              id="campaignName"
              name="campaignName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onClick={() => {
                setpostError("");
              }}
              value={formik.values.campaignName}
              placeholder="Campaign Name"
            />
            {formik.errors.campaignName && formik.touched.campaignName && (
              <ErrorMessageComp style={{ width: "25%", fontSize: "14px" }} name={formik.errors.campaignName} />
            )}

            <label className="mt-3" htmlFor="campaignDescriptionLabel">
              Campaign Description
            </label>

            <textarea
              style={{ width: "25%" }}
              className={
                formik.errors.campaignDescription && formik.touched.campaignDescription ? "form-control is-invalid" : "form-control"
              }
              id="campaignDescription"
              name="campaignDescription"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onClick={() => {
                setpostError("");
              }}
              value={formik.values.campaignDescription}
              placeholder="Description"
            />
            {formik.errors.campaignDescription && formik.touched.campaignDescription && (
              <ErrorMessageComp style={{ width: "25%", fontSize: "14px" }} name={formik.errors.campaignDescription} />
            )}
          </div>

          <div className="form-group">
            <label htmlFor="chooseSegmentLabel">Segment Name</label>

            <select
              style={{ width: "25%" }}
              className={formik.errors.choseSegment && formik.touched.choseSegment ? "form-control is-invalid" : "form-control"}
              id="choseSegment"
              name="choseSegment"
              onChange={formik.handleChange}
              onClick={() => {
                setpostError("");
              }}
              onBlur={formik.handleBlur}
              value={formik.values.choseSegment}
            >
              {setSegmentsOption()}
            </select>

            {formik.errors.choseSegment && formik.touched.choseSegment && (
              <ErrorMessageComp style={{ width: "25%", fontSize: "14px" }} name={formik.errors.choseSegment} />
            )}
          </div>

          <label htmlFor="scenarioRunsLabel">Campaign runs </label>

          <div className="form-group">
            <div className="row mb-4">
              <div className="col">
                <CustomRadio
                  label="One Time"
                  name="campaignRuns"
                  value="ONETIME"
                  checked={formik.values.campaignRuns === "ONETIME"}
                  handleChange={formik.setFieldValue}
                />
              </div>
              {/* <div className="col">
                <CustomRadio
                  label="Scheduled"
                  name="campaignRuns"
                  value="SCHEDULED"
                  checked={formik.values.campaignRuns === "SCHEDULED"}
                  handleChange={formik.setFieldValue}
                />
              </div> */}
              <div className="col">
                <CustomRadio
                  label="After Event"
                  name="campaignRuns"
                  value="TRIGGER"
                  checked={formik.values.campaignRuns === "TRIGGER"}
                  handleChange={formik.setFieldValue}
                />
              </div>
            </div>

            {formik.errors.campaignRuns && (
              <ErrorMessageComp style={{ width: "25%", fontSize: "14px" }} name={formik.errors.choseSegment} />
            )}

            <hr />

            {formik.values.campaignRuns === "ONETIME" ? (
              <>
                <p>
                  Choose Action to Perform
                </p>

                <div className="row ">
                  <div className="col-6">
                    <div className="row mb-2">
                        <div className="col">
                          <div className="embed-box">
                            <strong>Active Action:</strong>
                            <strong className="ml-1" style={{color: "#ef9322"}}>
                              {campaignAction?.template?.name != null ? campaignAction?.template?.name : "N/A" }
                            </strong>
                          </div>
                        </div>
                      </div>    
                      <div className="row">
                        <div className="col">
                          <button className="btn btn btn-dark" data-toggle="modal" data-target="#AddNewActionModal" onClick={() => {}}>
                              Available Actions
                          </button>
                        </div>
                      </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-3"></div>
                      <div className="col-3">
                        <span
                          className="btn btn-outline-primary p-3 "
                          onClick={() => {
                            if (campaignAction !== null) {
                              setmessageComposerShow(true);
                            } else {
                              alert("Choose an action first!");
                            }
                          }}
                        >
                          {" "}
                          Message Composer
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            {/* {formik.values.campaignRuns === "SCHEDULED" ? (
              <>
                <div className="row">
                  <div className="col-3">
                    <label htmlFor="Repeat">Repeat</label>
                  </div>
                  <div className="col-2">
                    <div className="form-group">
                      <select
                        className={formik.errors.everyYear && formik.touched.everyYear ? "form-control is-invalid" : "form-control"}
                        name="everyYear"
                        onChange={formik.handleChange}
                        onClick={() => {
                          setpostError("");
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.everyYear}
                      >
                        {everyYearOptions()}
                      </select>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-group">
                      <select
                        className={
                          formik.errors.everyMonth && formik.touched.everyMonth ? "form-control is-invalid" : "form-control"
                        }
                        name="everyMonth"
                        onChange={formik.handleChange}
                        onClick={() => {
                          setpostError("");
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.everyMonth}
                      >
                        {everyMonthOptions()}
                      </select>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-group">
                      <select
                        className={
                          formik.errors.everyDay && formik.touched.everyDay
                            ? `form-control formControlError`
                            : `form-control formControl`
                        }
                        name="everyDay"
                        onChange={formik.handleChange}
                        onClick={() => {
                          setpostError("");
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.everyDay}
                      >
                        {everyDayOptions()}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-3">
                    <label htmlFor="Repeat">Send Time</label>
                  </div>
                  <div className="col-2">
                    <div className="form-group">send time dropdown</div>
                  </div>
                  <div className="col-2">
                    <div className="form-group">customer timezone</div>
                  </div>
                </div>

                <div className="row">
                  <div style={{ display: "flex" }} className="col-6">
                    {integrationListApiData?.length > 0 ? (
                      <div style={{ width: "46%" }} className="form-group">
                        <select
                          className={
                            formik.errors.scheduledMailOr && formik.touched.scheduledMailOr
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="scheduledMailOr"
                          onChange={(e) => {
                            formik.handleChange(e);
                            console.log(e.target.value[0]);
                            setactionTemplateId(e.target.value[0]);
                            setmessageComposerState({});
                          }}
                          onClick={() => {
                            setpostError("");
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.scheduledMailOr}
                        >
                          {setActionGroupDataOption(integrationListApiData)}
                        </select>

                        {formik.errors.scheduledMailOr && formik.touched.scheduledMailOr && (
                          <ErrorMessageComp style={{ width: "25%", fontSize: "14px" }} name={formik.errors.scheduledMailOr} />
                        )}
                      </div>
                    ) : (
                      <span
                        className="btn btn-outline-primary p-1 "
                        onClick={() => {
                          $("#CreateIntegrationModal").modal("show");
                        }}
                      >
                        {" "}
                        Create Integration
                      </span>
                    )}

                    {integrationListApiData?.length > 0 ? (
                      <FontAwesomeIcon
                        color="#3498db"
                        style={{ marginLeft: "4px", cursor: "pointer" }}
                        size="2x"
                        icon={faPlusCircle}
                        onClick={() => {
                          $("#CreateIntegrationModal").modal("show");
                        }}
                      />
                    ) : null}
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-3"></div>
                      <div className="col-3">
                        <span
                          className="btn btn-outline-primary p-3 mt-3"
                          onClick={() => {
                            if (formik.values.scheduledMailOr !== "") {
                              setmessageComposerShow(true);
                            }
                          }}
                        >
                          {" "}
                          Message Composer
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="sendTime">Send Time </label>

                      <span>//send time element</span>
                    </div>
                  </div>
                </div>
              </>
            ) : null} */}

            {formik.values.campaignRuns === "TRIGGER" ? (
              <>
                <div className="row">
                  <div className="col-6">
                    {/* TODO event template selection and rules */}
                    <div className="mb-2">Choose a Trigger</div>
                    <select
                      style={{ width: "50%" }}
                      className={
                        formik.errors.eventTemplateOr && formik.touched.eventTemplateOr ? "form-control is-invalid" : "form-control"
                      }
                      name="eventTemplateOr"
                      onChange={(e) => {
                        formik.handleChange(e);
                        seteventTemplateId(e.target.value);
                      }}
                      onClick={() => {
                        setpostError("");
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.eventTemplateOr}
                    >
                      {/* event template map */}
                      {setEventTemplatesDataOption()}
                    </select>

                    {/* event template selection error */}
                    {formik.errors.eventTemplateOr && formik.touched.eventTemplateOr && (
                      <ErrorMessageComp style={{ width: "25%", fontSize: "14px" }} name={formik.errors.eventTemplateOr} />
                    )}
                  </div>
                  <div className="col-6"></div>
                </div>
                <hr></hr>        
                <p>
                      Choose Action to Perform
                </p>     
                <div className="row">
                  <div className="col-6">
                  <div className="row mb-2">
                      <div className="col">
                        <div className="embed-box">
                          <strong>Active Action:</strong>
                          <strong className="ml-1" style={{color: "#ef9322"}}>
                            {campaignAction?.template?.name != null ? campaignAction?.template?.name : "N/A" }
                          </strong>
                        </div>
                      </div>
                    </div>    
                    <div className="row">
                      <div className="col">
                        <button className="btn btn btn-dark" data-toggle="modal" data-target="#AddNewActionModal" onClick={() => {}}>
                            Available Actions
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-3"></div>
                      <div className="col-3">
                        <span
                          className="btn btn-outline-primary p-3 mb-3"
                          onClick={() => {
                            if (campaignAction !== "") {
                              setmessageComposerShow(true);
                            }
                          }}
                        >
                          {" "}
                          Message Composer
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            <hr></hr> 
            <div className="row justify-content-between mt-4 ">
              <div className="col">
                <button
                  className="btn btn-success mr-2"
                  disabled={!formik.isValid || campaignAction == null}
                  onClick={() => {
                    setSaveInitiated(true);
                    handleSubmitCampaign();
                  }}
                >
                  Save
                </button>
              </div>
              <div className="col"></div>
              <div className="col">
                <button
                  className="btn btn-light"
                  onClick={() => {
                    setmessageComposerState("");
                    formik.resetForm();
                    if (campaignData && campaignData.name && campaignId) {
                      history.push(`/campaign/view/${campaignId}`);
                    } else {
                      history.push("/campaign");
                    }
                    setcampaignData(null);
                    setcampaingErrorState("");
                  }}
                >
                  Discard
                </button>
              </div>
            </div>
            <div className="mt-3">
              {renderResult()}
            </div>
            <div className="mt-3">
              {deployCampaignResponseState === "success" ? (
                <SuccessMessageComp name={"deployment :" + deployCampaignResponseState} />
              ) : null}
            </div>
          </div>
        </div>
        ) : ""
      }
      
    </div>
    </>
  );
};

export default CampaignAddEditPage;
