
interface IRadioButton {
label?:string,
name:string,
value:string,
checked?:boolean,
handleChange?:any,
error?:string,
touched?:boolean,
}
const RadioButton = ({
    label,
    name,
    value,
    checked,
    handleChange,
    error,
    touched
  }:IRadioButton) => {
    const handleChecked = () => {
      console.log("name", name, value);
  
      handleChange(name, value);
    };
    return (
      <div className="form-group">
      <div className="form-check">

          
        <div onClick={handleChecked}>
          <input  className="form-check-input"
            type="radio"
            id={name}
            name={name}
            value={value}
            checked={checked}
            onChange={handleChecked}
          />
           <label className="form-check-label" htmlFor={label}>{label} </label>
         {/*  {error && touched && <TextError>{error}</TextError>} */}
        </div>
        </div>
      </div>
    );
  };
  
  export default RadioButton;