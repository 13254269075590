import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { eventTimerFormatHandler, postEditEventAction } from "../../../../../redux/actions/EventActions";
import { RootStore } from "../../../../../redux/store/Store";
import { useFormik } from "formik";
import { generateArrayOfYears, generateArrayOfDays, generateArrayOfHours, generateArrayOfMinutes, generateArrayOfMonths, generateEventOptions } from "../forms/utils/formUtils";

import * as Yup from "yup";

import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";

const EditEventManageForm = () => {
  const dispatch = useDispatch();

  const eventId: any = useSelector((state: RootStore) => state.sendEventIdReducer);
  const scenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  const selectedEventData: any = useSelector((state: RootStore) => state.sendEventDataReducer);
  const editEventError: any = useSelector((state: RootStore) => state.postEditEventReducer);

  const [editEventFormData, setEditEventFormData] = useState<any>(null);
  const [editEventEData, seteditEventEData] = useState<any>(null);

  useEffect(() => {
    if (editEventError.data) {
      if (editEventError.data.message) {
        seteditEventEData(editEventError.data.message);
      }
    }
  }, [editEventError]);

  useEffect(() => {
    setEventFormData();
  }, [selectedEventData, eventId.data]);

  const setEventFormData = () => {
    if (eventId.data) {
      if (selectedEventData) {
        Object.values(selectedEventData)
          .filter((item: any) => item.id == eventId.data)
          .map((item: any) => setEditEventFormData(item));
      }
    }
  };

  const eventTypeOptions = (selectedItem: any) => {
    return (
      <>
        {selectedItem ? <option value={selectedItem} label={selectedItem} /> : null}
        {generateEventOptions
          .filter((item) => item.key !== selectedItem)
          .map((item, index) => (
            <option key={index} value={item.value} label={item.key} />
          ))}
      </>
    );
  };

  const everyYearOptions = () => {
    return (
      <>
        <option value="" label="Every Year" />
        {Object.values(generateArrayOfYears()).map((item, index) => (
          <option key={index} value={item.value} label={item.key} />
        ))}
      </>
    );
  };

  const everyMonthOptions = () => {
    return (
      <>
        <option value="" label="Every Month" />
        {generateArrayOfMonths.map((item, index) => (
          <option key={index} value={item.value} label={item.key} />
        ))}
      </>
    );
  };

  const everyDayOptions = () => {
    return (
      <>
        <option value="" label="Every Day" />
        {Object.values(generateArrayOfDays()).map((item, index) => (
          <option key={index} value={item.value} label={item.key} />
        ))}
      </>
    );
  };

  const everyHourOptions = () => {
    return (
      <>
        <option value="" label="Every Hour" />
        {Object.values(generateArrayOfHours()).map((item, index) => (
          <option key={index} value={item.value} label={item.key} />
        ))}
      </>
    );
  };

  const everyMinutesOptions = () => {
    return (
      <>
        <option value="" label="Every Minutes" />
        {Object.values(generateArrayOfMinutes()).map((item, index) => (
          <option key={index} value={item.value} label={item.key} />
        ))}
      </>
    );
  };

  const handleEditEventSumbit = (bodyObject: any) => {
    var timerFormat = eventTimerFormatHandler(bodyObject);

    var orgObject: any = {
      description: bodyObject.description,
      id: editEventFormData.id,
      name: bodyObject.eventName,
      params: editEventFormData.params,
      sessionIdRef: editEventFormData.sessionIdRef,
      timerFormat: timerFormat,
      type: editEventFormData.type,
    };
    dispatch(postEditEventAction(orgObject, scenarioId.data, eventId.data));
  };

  const validationSchema = Yup.object().shape({
    eventName: Yup.string().required("Event name is required"),

    customValidate: Yup.string().when("eventType", {
      is: (eventType: string) => eventType && eventType === "Regular",
      then: Yup.string().when("eventName", {
        is: (eventName: string) => eventName && eventName.length == 0,
        then: Yup.string().required("Event name is required"),
        otherwise: Yup.string(),
      }),
      otherwise: Yup.string(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      eventName: editEventFormData ? editEventFormData.name : "",
      eventType: editEventFormData ? editEventFormData.type : "REGULAR",
      everyYear: editEventFormData
        ? editEventFormData.type !== "REGULAR"
          ? editEventFormData.timerFormat
            ? (editEventFormData.timerFormat as string).substring(0, 4) === "****"
              ? ""
              : (editEventFormData.timerFormat as string).substring(0, 4)
            : ""
          : ""
        : "",
      everyMonth: editEventFormData
        ? editEventFormData.type !== "REGULAR"
          ? editEventFormData.timerFormat
            ? (editEventFormData.timerFormat as string).substring(4, 6) === "**"
              ? ""
              : (editEventFormData.timerFormat as string).substring(4, 6)
            : ""
          : ""
        : "",
      everyDay: editEventFormData
        ? editEventFormData.type !== "REGULAR"
          ? editEventFormData.timerFormat
            ? (editEventFormData.timerFormat as string).substring(6, 8) === "**"
              ? ""
              : (editEventFormData.timerFormat as string).substring(6, 8)
            : ""
          : ""
        : "",
      everyHour: editEventFormData
        ? editEventFormData.type !== "REGULAR"
          ? editEventFormData.timerFormat
            ? (editEventFormData.timerFormat as string).substring(8, 10) === "**"
              ? ""
              : (editEventFormData.timerFormat as string).substring(8, 10)
            : ""
          : ""
        : "",
      everyMinute: editEventFormData
        ? editEventFormData.type !== "REGULAR"
          ? editEventFormData.timerFormat
            ? (editEventFormData.timerFormat as string).substring(10, 12) === "**"
              ? ""
              : (editEventFormData.timerFormat as string).substring(10, 12)
            : ""
          : ""
        : "",
      description: editEventFormData ? (editEventFormData.description ? editEventFormData.description : "") : "",
    },
    onSubmit: (values) => {},
    validationSchema: validationSchema,
    enableReinitialize: true,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <div>
          <label className="form-label" htmlFor="eventName">
            Event Name
          </label>
        </div>
        <input className="form-control" id="eventName" name="eventName" type="text" onChange={formik.handleChange} value={formik.values.eventName} />

        {editEventEData ? <ErrorMessageComp name={editEventEData} /> : <ErrorMessageComp name={formik.errors.eventName} />}
      </div>

      <div>
        <div className="mt-3">
          <label className="form-label" htmlFor="eventType">
            Event Type
          </label>
        </div>
        <select className="form-control" id="eventType" name="eventType" disabled value={formik.values.eventType} onChange={formik.handleChange}>
          {eventTypeOptions(formik.values.eventType)}
        </select>
      </div>

      {formik.values.eventType === "TIMER" ? (
        <>
          <div className="mt-3">
            <label className="form-label" htmlFor="TimerFormat">
              Timer Format
            </label>
          </div>

          <div className="row ">
            <label className="col-sm form-label align-self-center">Date:</label>
            <div className="col-sm">
              <select className="form-control" id="everyYear" name="everyYear" value={formik.values.everyYear} onChange={formik.handleChange}>
                {everyYearOptions()}
              </select>
            </div>
            <div className="col-sm">
              <select className="form-control" id="everyMonth" name="everyMonth" value={formik.values.everyMonth} onChange={formik.handleChange}>
                {everyMonthOptions()}
              </select>
            </div>
            <div className="col-sm">
              <select className="form-control" id="everyDay" name="everyDay" value={formik.values.everyDay} onChange={formik.handleChange}>
                {everyDayOptions()}
              </select>
            </div>
          </div>

          <div className="row mt-3">
            <label className="col-sm form-label align-self-center">Time:</label>
            <div className="col-sm">
              <select className="form-control" id="everyHour" name="everyHour" value={formik.values.everyHour} onChange={formik.handleChange}>
                {everyHourOptions()}
              </select>
            </div>
            <div className="col-sm">
              <select className="form-control" id="everyMinute" name="everyMinute" value={formik.values.everyMinute} onChange={formik.handleChange}>
                {everyMinutesOptions()}
              </select>
            </div>
            <div className="col-sm"></div>
          </div>
        </>
      ) : null}

      <div>
        <div className="mt-3">
          <label className="form-label" htmlFor="description">
            Description
          </label>
        </div>
        <textarea className="form-control" id="description" name="description" rows={3} onChange={formik.handleChange} value={formik.values.description} />
      </div>

      <div>
        <div className="modal-footer">
          <button
            id="addNewEventCloseButton"
            className="btn btn-light mt-3"
            type="button"
            data-dismiss="modal"
            onClick={() => {
              formik.resetForm();
            }}
          >
            Close
          </button>
          <button
            id="addNewEventCreateButton"
            onClick={() => {
              handleEditEventSumbit(formik.values);
            }}
            className="btn btn-success mt-3"
            type="submit"
            disabled={!formik.isValid}
          >
            Create
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditEventManageForm;
