import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { deleteStateAction } from "../../../../../redux/actions/StateActions";
import { RootStore } from "../../../../../redux/store/Store";
import $ from 'jquery';


const DeleteStateForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const selectedStateId: string = useSelector((state: RootStore) => state.sendStateIdReducer.data);

  return (
    <div>
      <p>Are you sure to delete state? This operation is not returnable and all state data will be deleted permanently.</p>

      <div className="modal-footer">
        <button id="DeleteStateModalCloseButton" className="btn btn-primary p-2 mt-3" type="button" data-dismiss="modal" onClick={()=>{$("#visualize-toggle-button").click()}}>
          Cancel
        </button>
        <button
          id="DeleteStateModalSaveButton"
          onClick={() => {
            dispatch(deleteStateAction(id, selectedStateId));
          }}
          className="btn btn-danger p-2 mt-3"
          type="button"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteStateForm;
