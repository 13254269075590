export class LogMessage {
  level!: string;
  message!: string;
  detail!: string;
}

export class Result {
  validation!: any[];
  transition!: boolean;
  from?: string;
  to?: string;
  actions!: any[];
}

export class EventLog {
  session_id: string;
  scenario_id: number;
  scenario_name: string;
  event_name: string;
  event_data: Map<string, any>;
  scenario_hit: boolean;
  event_hit: boolean;
  result?: Result;
  log_messages: LogMessage[];
  entry_time: string;
  timestamp: number;

  constructor(json: any) {

    const logMessages = json.log_messages.map(l => {
      const logMessage = new LogMessage();
      logMessage.level = l.level.name;
      logMessage.message = l.message;
      logMessage.detail = l.detail;
      return logMessage;
    });

    if (json.result) {
      const result = new Result();
      result.validation = json.result.validation;
      result.transition = json.result.transition;
      result.from = json.result.from;
      result.to = json.result.to;
      result.actions = json.result.actions;

      this.result = result;
    }

    this.session_id = json.session_id;
    this.scenario_id = json.scenario_id;
    this.scenario_name = json.scenario_name;
    this.event_name = json.event_name;
    this.event_data = json.event_data;
    this.scenario_hit = json.scenario_hit;
    this.event_hit = json.event_hit;
    this.log_messages = logMessages;
    this.entry_time = json.entry_time;
    this.timestamp = json.timestamp;
  }

  getLogs = () => {

    let errors = 0;
    let info = 0;
    let warning = 0;

    this.log_messages.map(l => {
      if (l.level === 'INFO') {
        info += 1;
      } else if (l.level === 'WARN') {
        warning += 1;
      } else if (l.level === 'ERROR') {
        errors += 1;
      } else {
        info += 1;
      }
    });

    return [info, warning, errors];
  }

}

