import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postActionParamAction, sendSelectedActionParamDataAction } from "../../../../../redux/actions/ActionActions";
import { RootStore } from "../../../../../redux/store/Store";
import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";
import RichTextEditorAction from "../../../commonComps/RichTextEditorAction";
import SplitPane from "react-split-pane";
import $ from 'jquery';



const EditActionParamForm = () => {
  const dispatch = useDispatch();

  const [editButtonState, seteditButtonState] = useState(false);
  const [editorValue, seteditorValue] = useState("");
  const [orgEditorValue, setorgEditorValue] = useState("")
  const [actionParamData, setactionParamData] = useState<any>(null);
  const [fieldValue, setfieldValue] = useState("");
  const [selectectedParamIndex, setSelectectedParamIndex] = useState<number>(0);
  const [actionParamErrorData, setactionParamErrorData] = useState("");
  const [isDisable, setisDisable] = useState(false);

  const selectedActionId: any = useSelector((state: RootStore) => state.sendActionIdReducer);
  const selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  const selectedActionParamId: any = useSelector((state: RootStore) => state.sendActionParamIdReducer);
  const selectedActionParam: any = useSelector((state: RootStore) => state.sendActionParamDataReducer);
  const actionParamError: any = useSelector((state: RootStore) => state.postActionParamReducer);


  
  useEffect(() => {
    if (actionParamError.data) {
      if (actionParamError.data.message) {
        setactionParamErrorData(actionParamError.data.message);
      }
    }
    if (actionParamError?.data?.statusText === "OK") {
      seteditButtonState(false);
    }
  }, [actionParamError]);

  useEffect(() => {
    if (selectedActionParam.data) {
      setactionParamData(selectedActionParam.data);
      if (selectedActionParam.data[selectectedParamIndex]?.field) {
        setfieldValue(selectedActionParam.data[selectectedParamIndex].field);
      }
      seteditorValue(selectedActionParam.data[selectectedParamIndex].value);
      setorgEditorValue(selectedActionParam.data[selectectedParamIndex].value);
    }
  }, [selectedActionParam.data, selectectedParamIndex]);

  useEffect(() => {
    $("#EditActionParamModalCloseIconButton").click(function () {
      seteditButtonState(false);
    });
  }, []);

  const handleSubmitActionParam = () => {
    const sendObj: any = {};
    if (actionParamData) {
      sendObj["templateParamId"] = actionParamData[selectectedParamIndex].id;
      sendObj["value"] = editorValue;
      if (actionParamData[selectectedParamIndex].isRecurring) {
        sendObj["field"] = fieldValue;
        const actionParamField = actionParamData[selectectedParamIndex].field;
        dispatch(postActionParamAction(sendObj, selectedScenarioId.data, selectedActionId.data, selectedActionParamId.data, actionParamField));
      } else {
        if(editorValue !==""){
          dispatch(postActionParamAction(sendObj, selectedScenarioId.data, selectedActionId.data, selectedActionParamId.data));
        }else{
          alert("Please dont leave empty editor value!")
        }
      }
    } else {
      alert("actionParamData is null or empty!");
    }
  };

  const handleEditorChange = (e: any) => {
    seteditorValue(e.target.value);
  };

  const handleEditorFieldChange = (e: any) => {
    setfieldValue(e.target.value);
    setactionParamErrorData("");
    if (e.target.value === "") {
      setisDisable(true);
    } else {
      setisDisable(false);
    }
  };

  const renderEditActionParamModalContent = () => {
    return (
      <>
        <div>
          <span>
            {actionParamData ? actionParamData[0]?.key : ""}{" "}
            <small>
              ({actionParamData ? actionParamData[0]?.dataType : ""}) {actionParamData ? (actionParamData[0]?.isRecurring ? "Recurring" : "Non-Requrring") : ""}
            </small>
          </span>
          <div>
            <small style={{ color: "orange" }}>Description</small>
          </div>
        </div>
        <div>{actionParamData ? actionParamData[0]?.description ? <small>{actionParamData[0]?.description}</small> : null : null}</div>

        <table className="table mt-3">
          <thead>
            <tr>
              {actionParamData ? actionParamData[0]?.isRecurring ? <th>Field</th> : null : null}
              <th>Value</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {actionParamData
              ? actionParamData.map((ap: any, index: number) => (
                  <tr>
                    {ap?.isRecurring ? <td>{ap?.field}</td> : null}
                    <td>
                      {(ap?.value as string).length > 300 ? (ap?.value as string).substring(0, 300) + "..." : <span>{ap?.value}</span>}
                      {(ap?.value as string).length > 300 ? (
                        <div>
                          <a style={{cursor:"pointer"}}
                            className="show-more"
                            onClick={() => {
                              $("#EditActionParamModal").modal("hide");
                              setTimeout(() => {
                                $("#ViewActionParamModal").modal("show"); 
                              }, 200)
                              dispatch(sendSelectedActionParamDataAction(actionParamData));
                            }}
                          >
                            Show More
                          </a>
                        </div>
                      ) : null}
                    </td>
                    <td style={{ width: "100px" }}>
                      <button
                        id="renderEditActionParamModalContentButton"
                        className="btn btn-primary pt-1 pb-1 pl-2 pr-2"
                        type="button"
                        onClick={() => {
                          seteditButtonState(true);
                          setSelectectedParamIndex(index);
                        }}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </>
    );
  };

  const renderEditEditorActionParamModalContent = () => {
    return (
      <>
        <div>
          <span>
            {actionParamData ? actionParamData[0]?.key : ""}{" "}
            <small>
              ({actionParamData ? actionParamData[0]?.dataType : ""}) {actionParamData ? (actionParamData[0]?.isRecurring ? "Recurring" : "Non-Requrring") : ""}
            </small>
          </span>
          <div>
            <small style={{ color: "orange" }}>Description</small>
          </div>
        </div>
        <div>
          <small>{actionParamData ? actionParamData[0]?.description : ""}</small>
        </div>

        {actionParamData[0]?.isRecurring === true ? (
          <div className="form-group">
            <label className="mt-3">
              <strong>Field Name</strong>
            </label>
            <input
              id="fieldName"
              autoComplete="off"
              className="form-control"
              name="field"
              type="text"
              value={fieldValue}
              onChange={(e) => {
                handleEditorFieldChange(e);
              }}
            />

            {actionParamErrorData ? <ErrorMessageComp name={actionParamErrorData} /> : null}
          </div>
        ) : null}

        <div className="mt-3">
          <label>
            <strong>Value</strong>
          </label>
        </div>
      {/*   <input
          className="form-control"
          type="text"
          value={editorValue}
          onChange={(e) => {
            handleEditorChange(e);
          }}
        /> */}

        <RichTextEditorAction editorValueProp = {editorValue}  seteditorValueProp = {seteditorValue} functions ={[editorValue,seteditorValue]} />


  

        <div className="modal-footer mt-3">
          <button
            id="renderEditEditorActionParamModalContentCloseButton"
            className="btn btn-primary p-2 mt-3"
            type="button"
            onClick={() => {
              seteditButtonState(false);
              setactionParamErrorData("");
            }}
          >
            Close
          </button>
          <button
            id="renderEditEditorActionParamModalContentEditButton"
            onClick={() => {
              handleSubmitActionParam();
            }}
            disabled={isDisable}
            className="btn btn-success p-2 mt-3"
            type="submit"
          >
            Edit
          </button>
        </div>
      </>
    );
  };

  return <div>{editButtonState ? renderEditEditorActionParamModalContent() : renderEditActionParamModalContent()}</div>;
};

export default EditActionParamForm;
