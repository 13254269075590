import * as InitialStates from "../store/InitialStates";
import * as SessionActionTypes from "../types/types";

export const putSessionReducer = (state: any = InitialStates.putSessionInitialState, action: any) => {
  switch (action.type) {
    case SessionActionTypes.ADD_SESSION_FAIL:
      return {
        data: action.payload,
      };
    case SessionActionTypes.ADD_SESSION_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const postSessionReducer = (state: any = InitialStates.postSessionInitialState, action: any) => {
  switch (action.type) {
    case SessionActionTypes.EDIT_SESSION_FAIL:
      return {
        data: action.payload,
      };
    case SessionActionTypes.EDIT_SESSION_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const deleteSessionReducer = (state: any = InitialStates.deleteScenarioInitialState, action: any) => {
  switch (action.type) {
    case SessionActionTypes.DELETE_SESSION_FAIL:
      return {
        data: action.payload,
      };
    case SessionActionTypes.DELETE_SESSION_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendSelectedSessionIdReducer = (state: any = "", action: any) => {
  switch (action.type) {
    case SessionActionTypes.SEND_SELECTED_SESSION_ID_FAIL:
      return {
        data: action.payload,
      };
    case SessionActionTypes.SEND_SELECTED_SESSION_ID_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendSelectedSessionDataReducer = (state: any = InitialStates.sendSelectedSessionDataInitialState, action: any) => {
  switch (action.type) {
    case SessionActionTypes.SEND_SELECTED_SESSION_DATA_FAIL:
      return {
        data: action.payload,
      };
    case SessionActionTypes.SEND_SELECTED_SESSION_DATA_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export const sendSessionDataReducer = (state: any = InitialStates.sendSessionDataInitialState, action: any) => {
  switch (action.type) {
    case SessionActionTypes.SEND_SESSION_DATA_FAIL:
      return {
        data: action.payload,
      };
    case SessionActionTypes.SEND_SESSION_DATA_SUCCESS:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

