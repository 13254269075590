import { useDispatch, useSelector } from "react-redux";
import { deleteSessionAction } from "../../../../../redux/actions/sessionActions";
import { RootStore } from "../../../../../redux/store/Store";

const DeleteSessionManageForm = () => {
  const dispatch = useDispatch();

  const selectedScenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  const selectedSessionId: any = useSelector((state: RootStore) => state.sendSelectedSessionIdReducer);

  return (
    <div>
      <p>
        Are you sure to delete session definition?
        <br />
        This operation is not returnable and all session data will be deleted permanently.
      </p>

      <div className="modal-footer">
        <button id="deleteSessionDefinitionCloseButton" className="btn btn-primary p-2 mt-3" type="button" data-dismiss="modal" onClick={() => {}}>
          Close
        </button>
        <button
          id="deleteSessionDefinitionDeleteButton"
          onClick={() => {
            dispatch(deleteSessionAction(selectedScenarioId.data, selectedSessionId.data));
          }}
          className="btn btn-danger p-2 mt-3"
          type="submit"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteSessionManageForm;
