import { sortBy } from "lodash";
import { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignListAction } from "../../redux/actions/campaignActions";
import CampaignStatus from "./comps/CampaignStatus";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { RootStore } from "../../redux/store/Store";

const CampaignPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const campaignListTableDataRef = useRef<any>(null);
  const campaignListR: any = useSelector((state: RootStore) => state.getCampaignListReducer);

  const [campaingListData, setCampaingListData] = useState<any>(null);
  const [campaingListTableData, setcampaingListTableData] = useState<any>([]);

  useEffect(() => {
    dispatch(getCampaignListAction());
  }, []);

  useEffect(() => {
    document.title = "Campaign List | Herogi Growth Panel"
  }, []);

  useEffect(() => {
    if (campaignListR) {
      setCampaingListData(sortBy(campaignListR.data, "name"));
      var campaignListTableTemp: any = [];

      //scenario list table data mapping
      campaignListR?.data?.data.map((ci: any) => {
        campaignListTableTemp.push({
          id: ci.id,
          campaignName: ci.name,
          activeSince: new Date(ci.updateDate).toLocaleDateString(),
          //campaign segments need also be added to the response for getAll endpoint
          audience: "segment1", //backend map need
          status: CampaignStatus(ci?.status?.status),
          operations: renderCampaignOperations(ci),
        });
      });
      setcampaingListTableData([...campaignListTableTemp]);
    }
  }, [campaignListR]);

  const renderHeader = () => {
    return (
      <div className="card shadow-sm mb-1">
        <div className="card-body pb-3 pt-3">
          <div className="d-flex align-items-center justify-content-between px-0">
            <div className="d-flex align-items-center">
              <strong>Existing Campaigns</strong>
            </div>
            <div className="ms-4 small">
              <button
                className="btn btn-primary p-2 "
                onClick={() => {
                  history.push("/campaign/create");
                }}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // const renderDetails = () => {
  //   return (
  //     <div className="card mb-4 mt-4 shadow-sm">
  //       <div className="card-header">
  //         <div className="d-flex align-items-center justify-content-between px-0">
  //           <div className="d-flex align-items-center">Campaign Delivered</div>
  //           <div className="ms-4 small"></div>
  //         </div>
  //       </div>
  //       <div className="card-body">//</div>
  //     </div>
  //   );
  // };

  const customTotal = (from: number, to: number, size: number) => <div></div>;

  const renderCampaignOperations = (ci: any) => {
    return (
      <span>
        <Link to={`/campaign/edit/${ci.id}`}>
          <a href="" className="icon-edit">
            Edit
          </a>
        </Link>
        {" | "}
        <Link to={`/campaign/view/${ci.id}`}>
          <a href="" className="icon-edit">
            View
          </a>
        </Link>
      </span>
    );
  };

  const campaignListTableColumns = [
    {
      dataField: "campaignName",
      text: "Campaign Name",
    },
    {
      dataField: "audience",
      text: "Audience",
    },
    {
      dataField: "activeSince",
      text: "Active Since",
    },
    {
      dataField: "operations",
      text: "Operations",
    },

    {
      dataField: "status",
      text: "Status",
    },
  ];

  const selectRow: any = {
    mode: "",
    clickToSelect: false,
    hideSelectAll: false,
    // If we decide to make deploy or delete selected campaigns
    // future this functions for select rows

    /* onSelect: (row:{id:number}, isSelect:boolean) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row.id]);
      } else {
        setselectedItems([...selectedItems.filter((x: any) => x != row.id)]);
      }
    },
    onSelectAll: (isSelect:boolean) => {
      const ids = scenarioListTableDataRef.current.props.data.map(
        (row: {id:number}) => row.id
      );
      if (isSelect) {
        setselectedItems(ids);
        return ids;
      } else {
        setselectedItems([]);
        return [];
      }
    }, */
  };

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPage: 10
  };

  const renderCampaignListTable = () => {
    return (
      <div>
        <div className="card shadow-sm mb-1">
          <div className="card-body">
            <div>
              <BootstrapTable
                ref={campaignListTableDataRef}
                keyField="id"
                data={campaingListTableData}
                columns={campaignListTableColumns}
                /*  selectRow={selectRow} */
                pagination={paginationFactory(options)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="dashboard-main">
      {renderHeader()} {renderCampaignListTable()}
    </div>
  );
};

export default CampaignPage;
