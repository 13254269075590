import axios from "axios";
import { forEach, kebabCase } from "lodash";
import { BooleanLiteral, StringLiteralLike } from "typescript";
import { string } from "yup";

export interface IMetaData {
    type: string;
    source: string;
    readableName: string
    filterDef: IFilterDef[]
}

export class MetaData implements IMetaData {

    type: string;
    source: string;
    readableName: string;
    filterDef: IFilterDef[];

    constructor(type: string, source: string, readableName: string, filterDef: IFilterDef[]) {
        this.type = type;
        this.source = source;
        this.readableName = readableName;
        this.filterDef = filterDef;
      }
}

export interface IExpression {
    key: string
    readableName: string 
    dataType: string
    opList: string[]
    options: IOption[]
}

export class Expression implements IExpression {
    key: string
    readableName: string 
    dataType: string
    opList: string[]
    options: IOption[]

    constructor(key: string, readableName: string, dataType: string, opList: object[], options: IOption []) {
        this.key = key;
        this.readableName = readableName;
        this.dataType = dataType;
        this.opList = opList.map(o => o["t"]);
        this.options = options.map(o => new Option(o.key, o.readableName, o.value));
      }
}

export interface IOption {
    key: string;
    readableName: string;
    value: string;
}

export class Option implements IOption {
    key: string;
    readableName: string;
    value: string;

    constructor(key: string, readableName: string, value: string) {
        this.key = key;
        this.readableName = readableName;
        this.value = value;
      }
}


export interface IFilterDef {
    key: string;
    readableName: string;
    expressions: IExpression[];
}

export class FilterDef implements IFilterDef {
    
    key: string;
    readableName: string;
    expressions: IExpression[]

    constructor(key: string, readableName: string, expressions: IExpression[]) {
        this.key = key;
        this.readableName = readableName;
        this.expressions = expressions    
      }
}

export const FilterUtils = {

    compareRule: (l: IRule, r: IRule) => {
        if(l.ruleId != null && r.ruleId != null) {
            return l.ruleId == r.ruleId;
        }
        else if (l.tempRuleId != null && r.tempRuleId != null) {
            return l.tempRuleId == r.tempRuleId;
        } else {
            return false;
        }

    },

    filterEmpty: (f: IFilter) => {
        let empty = true
        for (let index = 0; index < f.rules.length; index++) {
            const r = f.rules[index];
            if(r.deleted) {
                empty = false;
                return empty;
            }
        }
        return empty;
    },

    nonEmptyRules: (f: IFilter) => f.rules.filter(r => !r.deleted),
}

export interface IFilter {
    filterId: number;
    tempFilterId: number;
    deleted: boolean;
    rules: IRule [];
    isValid: boolean
}

export interface IRule {
    ruleId: number;
    deleted: boolean;
    tempRuleId: number;
    data: any;
}

export interface ISegment {
    name: string;
    desc: string;
}

export async function segmentInit() {

    return axios
      .get("/segment/new", {
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        const _meta: IMetaData[] = response.data.data.map(d => {
          const filterDefs = d.filterDef.map(f => {

            const exList: IExpression[] = f.expressions.map(e => new Expression(e.key, e.readableName, e.dataType, e.opList, e.options));

            return new FilterDef(f.key, f.readableName, exList);
          })
          return new MetaData(d.type, d.source, d.readableName, filterDefs);
        });

        return _meta;
      });
}

export function getSegmentDetail(segmentId: string) {

    return axios
      .get("/segment/" + segmentId, {
        headers: {
          "Content-Type": "application/json",
        },
      })
}

export function saveSegment(segmentId: number, name: string, desc : string, filters: IFilter []) {

    let filterReqs;

    let toRuleReq = (r: IRule) => {

        if(r.deleted && r.ruleId != null) {
            return {ruleId: r.ruleId, isDelete: true};
        } else if(r.deleted && r.ruleId == null) {
            return [];
        } else {
            return {
                ruleId: r.ruleId,
                isDelete: false,
                data : {
                    type : r.data.segmentMeta.type,
                    source: r.data.segmentMeta.source,
                    segmentKey: r.data.filterDef.key,
                    expression: [{
                        op: r.data.operand,
                        left: r.data.expressionKey,
                        right: r.data.value
                    }]
                }
            };
        }
    };

    let toFilterReq = (f: IFilter) => {

        if(f.deleted && f.filterId != null) {
            let ruleReqs = f.rules.map(r => toRuleReq(r));
            return { filterId: f.filterId, isDelete: true, filterRules: ruleReqs};
        } else if (f.deleted && f.filterId == null) {
            return [];
        } else {
            let ruleReqs = f.rules.map(r => toRuleReq(r));
            return { 
                filterId: f.filterId, 
                isDelete: false,
                filterRules: ruleReqs
            };    
        }
    };

    let url = `/segment`;

    //Create a segment
    if(segmentId == null) {
        filterReqs = filters.flatMap(f => toFilterReq(f));
        return axios.put(url, {
            name: name,
            description: desc,
            filters: filterReqs
        });
    } else { //Update existing segment
        url = url + "/" + segmentId.toString();
        filterReqs = filters.flatMap(f => toFilterReq(f));
        return axios.post(url, {
            name: name,
            description: desc,
            filters: filterReqs
        });
    }
}