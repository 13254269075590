import axios from "axios"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import MultiLineChart from "../../components/common/charts/MultiLineChart"
import { apiErrorHandle, ErrorHandler } from "../../components/common/hooksAndFuncts/ApiErrorHandle"
import { RootStore } from "../../redux/store/Store"
import { JSSDKExampleCode, RestCurlExample } from "./Constant"
import { getAudienceReporting } from "../../redux/actions/ReportingActions"

export const AudiencePage = () => {

    const dispatch = useDispatch();

    const account = useSelector((state: RootStore) => state.getAccountReducer);
    const audienceReportingR = useSelector((state: RootStore) => state.getAuidenceReportingReducer);

    const [reportHighlight, setreportHighlight] = useState<any>(null);
    const [userTrend, setuserTrend] = useState<any>({
        datasets: [{ "id": 1, "label": "Daily Users", data: [] }],
        labels: []
    });

    useEffect(() => {
        document.title = "Audience | Herogi Growth Panel"
    }, [account]);

    useEffect(() => {
        dispatch(getAudienceReporting());
    }, [dispatch]);

    useEffect(() => {
        if (audienceReportingR) {

            console.log(audienceReportingR);
            setreportHighlight(audienceReportingR.data?.data);

            let dataset: string [] = [];
            let labels: number [] = [];

            audienceReportingR.data?.data?.last7Days.map(item => {
                dataset.push(item.count);
                labels.push(item.date);
            })

            setuserTrend({
                datasets: [{ "id": 1, "label": "Daily Users", data: dataset }],
                labels: labels
            });
        }
    } , [audienceReportingR]);

    // const getUserHighlight = (): void => {
    //     axios
    //       .get("/userquery/highlight", {})
    //       .then((response) => {
            
    //       })
    //       .catch((error) => {
    //         ErrorHandler(error.response)
    //       });
    //   };

    return (
        <div className="dashboard-main">
            <nav className="nav nav-borders">
                <Link className="link" to="/audience">
                    <span className="nav-link active ml-0">Audience</span>
                </Link>

                <Link className="link" to="/audience/attributes">
                    <a className="nav-link">Tracking Data</a>
                </Link>
            </nav>
            <hr className="mt-0 mb-4"></hr>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="card">
                                <div className="card-body">
                                <div style={{display: "block"}} className="row pb-5 pl-2 text-lg text-black-75">
                                    <div>Highlevel user metrics</div>
                                    <div className="text-sm mt-2">Takes up to 30mins to get most recent data</div>
                                </div>
                                {reportHighlight ?
                                <>
                                    <div className="row">
                                
                                        <div className="col-sm text-center ">
                                            <h5 className="font-weight-bold text-sm">TOTAL</h5>
                                            <div className="text-xl font-weight-bold text-warning">{reportHighlight.total}</div>
                                        </div>
                                        <div className="col-sm text-center ">
                                            <h5 className="font-weight-bold text-sm">ANONYMOUS</h5>
                                            <div className="text-xl font-weight-bold text-warning">{reportHighlight.totalAnonymous}</div>
                                        </div>
                                        <div className="col-sm text-center "></div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-sm text-center ">
                                            <h5 className="font-weight-bold text-sm">EMAIL OPTIN</h5>
                                            <div className="text-xl font-weight-bold text-warning">{reportHighlight.totalEmailOptin}</div>
                                        </div>
                                        <div className="col-sm text-center ">
                                            <h5 className="font-weight-bold text-sm">SMS OPTIN</h5>
                                            <div className="text-xl font-weight-bold text-warning">{reportHighlight.totalSmsOptin}</div>
                                        </div>
                                        <div className="col-sm text-center ">
                                                <h5 className="font-weight-bold text-sm">PUSH OPTIN</h5>
                                                <div className="text-xl font-weight-bold text-warning">{reportHighlight.totalWebpushOptin}</div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-sm text-center ">
                                                <h5 className="font-weight-bold text-sm">EMAIL EXIST</h5>
                                                <div className="text-xl font-weight-bold text-warning">{reportHighlight.totalEmailExist}</div>
                                        </div>
                                        <div className="col-sm text-center ">
                                                <h5 className="font-weight-bold text-sm">MOBILE EXIST</h5>
                                                <div className="text-xl font-weight-bold text-warning">{reportHighlight.totalMobileExist}</div>
                                        </div>
                                        <div className="col-sm text-center ">
                                                <h5 className="font-weight-bold text-sm">PUSH EXIST</h5>
                                                <div className="text-xl font-weight-bold text-warning">{reportHighlight.totalWebpushExist}</div>
                                        </div>
                                    </div>
                                </>
                                : "" }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            
                                    <div style={{display: "block"}} className="row pt-4 pt-2 pb-5 pl-2 text-lg text-black-75">
                                        <div>Weekly New User Trend</div>
                                    </div>
                                    <div className="row">
                                        <MultiLineChart graphData={userTrend.datasets} labelData={userTrend.labels} xUnit={1} />
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    )
}
