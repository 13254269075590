import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { getElasticEvents } from "../../../services/ReportingService";
import ProgressBar, { ProgressItem } from "./ProgressBar";


const DataBox = ({esQuery, title, subtitle, renderContent}) => {

    const [esData, setESData] = useState(null);
    const [graphData, setGraphData] = useState<ProgressItem []>([]);
    const [loading, setLoading] = useState(true);

    const _mapper = (data) => {
        const parsedData: ProgressItem[] = [];
        data.filtered_events.eventprop_type_terms?.buckets.map((bucket) => {
            let item: ProgressItem = {
                label: bucket.key,
                value: bucket.doc_count,
                total: data.filtered_events.doc_count
            }
            parsedData.push(item);
        });
        return parsedData;
    }

        

    useEffect(() => {

        getElasticEvents(esQuery.getQuery())
            .then((res) => {

                const items = _mapper(res.data)
                setGraphData(items);
                setESData(res.data);
                setLoading(false);
            });
        }, [esQuery]);

    return (
        <>
        {loading
            ? 
                <div className="d-flex justify-content-center align-items-center h-100" style={{ minHeight: '50vh' }}>
                    <FontAwesomeIcon style={{color : "#b4b4b4"}} size={"3x"} icon={faChartLine} className="fa-thin" beatFade />
                </div>
            : <div className="card h-100">
                <div className="card-body">
                    <h5 className="card-title font-weight-bolder">{title}</h5>
                    <div className="mb-5 pb-3" style={{borderBottom: "1px solid #b4b4b4"}}>
                        <div className="text-gray-600">{subtitle}</div>
                    </div>
                    {renderContent(graphData)}
                </div>
            </div>
        }
        </>
    );
}

export default DataBox;