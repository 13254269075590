import { useEffect, useRef, useState } from "react";
import AceEditor from "react-ace";
import { useDispatch, useSelector } from "react-redux";
import { getEventUniqueEventParamAction } from "../../../redux/actions/EventActions";
import { useParams } from "react-router";
import { RootStore } from "../../../redux/store/Store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faArrowDown, faExpand, faEye, faRandom, faTerminal } from "@fortawesome/free-solid-svg-icons";

import "ace-builds/src-noconflict/mode-ftl";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-min-noconflict/ext-language_tools";

const RteField = (props: { onChangeProp: any; onBlurProp: any; valueProp: any; type: string; runTypeProp: any, height: string }) => {
  //TODO after event selection get event id as prop or dispatch
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  //set data from editor example
  /*  const editorValueProp = props.editorValueProp;
  const editorsetValueProp = props.seteditorValueProp; */
  /*   const [editorValue, seteditorValue] = props.functions; */
  const [editorValue, seteditorValue] = useState("");
  const [orgEditorValue, setorgEditorValue] = useState("");

  const aceEditorRef = useRef<any>();
  const [aceEditor, setaceEditor] = useState<any>(null);
  const [cursorPosition, setcursorPosition] = useState<any>(null);
  const [uniqueEventParamsData, setuniqueEventParamsData] = useState<[] | null>(null);
  const [sessionData, setsessionData] = useState<[] | null>(null);
  const [modalExpandToggle, setmodalExpandToggle] = useState(false);

  const [showbBtnGroupBar, setshowbBtnGroupBar] = useState(false);

  const campaingEventTemplateParams: any = useSelector((state: RootStore) => state.sendCampaignEventTemplateParamsReducer);

  const [mathOperators, setmathOperators] = useState([
    { name: "Additive", value: "${### + ###}" },
    { name: "Subtraction", value: "${### - ###}" },
    { name: "Multiplication", value: "${### * ###}" },
    { name: "Division", value: "${### / ###}" },
    { name: "Modulo", value: "${### % ###}" },
    { name: "Abs", value: "${ x?abs }" },
    { name: "Round", value: "${ x?round }" },
    { name: "Floor", value: "${ x?floor }" },
    { name: "Ceiling", value: "${ x?ceiling }" },
  ]);

  const [booleanOperators, setbooleanOperators] = useState([
    { name: "Equals", value: "${### == ###}" },
    { name: "Not equals", value: "${### != ###}" },
    { name: "Less than", value: "${### < ###}" },
    { name: "Less than or equal to", value: "${### <= ###}" },
    { name: "Greater than", value: "${### > ###}" },
    { name: "Greater than or equal to", value: "${### >= ###}" },
    { name: "Boolean and", value: "${### && ###}" },
    { name: "Boolean or", value: "${### || ###}" },
  ]);

  const [functionsOperators, setfunctionsOperators] = useState([
    { name: "If", value: "<#if condition>...</#if>" },
    { name: "If/Else", value: "<#if condition>...<#else>...</#if>" },
    {
      name: "If/ElseIf",
      value: "<#if condition>...<#elseif condition2>...<#else>...</#if>",
    },
    { name: "List", value: "<#list sequence as item>${item}</#list>" },
  ]);

  const uniqueEventParams: any = useSelector((state: RootStore) => state.getUniqueEventParamReducer);
  const session: any = useSelector((state: RootStore) => state.sendSessionDataReducer);

  useEffect(() => {
    if (props.type === "textarea") {
      setshowbBtnGroupBar(true);
    }
  }, [props.type]);

  useEffect(() => {
    if (aceEditorRef.current.editor) {
      if (props.type == "textarea") {
        aceEditorRef.current.editor.container.style.minHeight = "100px";
        aceEditorRef.current.editor.resize();
        aceEditorRef.current.editor.session.setUseWrapMode(true);
      } else {
        aceEditorRef.current.editor.container.style.minHeight = "25px";
        aceEditorRef.current.editor.resize();
        aceEditorRef.current.editor.session.setUseWrapMode(true);
      }
    }
  }, [props.type]);

  /* useEffect(() => {
    if (editorValueProp) {
      seteditorValue(editorValueProp);
    }
  }, [editorValueProp]);

  useEffect(() => {
    if (editorValueProp) {
      setorgEditorValue(editorValueProp);
    }
  }, []); */

  useEffect(() => {
    if (aceEditorRef.current) {
      setaceEditor(aceEditorRef.current.editor);
      setcursorPosition(aceEditorRef.current.editor.getCursorPosition());
      /* if (editorValueProp) {
        aceEditorRef.current.editor.session.setValue(editorValueProp);
      } */
      aceEditorRef.current.editor.resize();
      aceEditorRef.current.editor.session.setUseWrapMode(true);
    }
  }, [aceEditorRef]);

  useEffect(() => {
    /* dispatch(getEventUniqueEventParamAction(id.toString())); */
  }, []);

  useEffect(() => {
    if (uniqueEventParams) {
      setuniqueEventParamsData(uniqueEventParams.data);
    }
  }, [uniqueEventParams]);

  useEffect(() => {
    if (session) {
      setsessionData(session.data);
    }
  }, [session]);

  const insertAtPosition = (p: string) => {
    if (aceEditorRef && cursorPosition) {
      aceEditor.session.insert(aceEditorRef.current.editor.getCursorPosition(), p);
    }
  };

  const addEventParam = (p: any) => {
    aceEditor.focus();
    var text: string = "${" + "event." + p.propertyName + ".value()" + "}";
    insertAtPosition(text);
  };

  const addSessionParam = (s: any) => {
    var text: string = "${" + "session." + s.propertyName + ".value()" + "}";
    insertAtPosition(text);
  };

  const addKeyValue = (m: any) => {
    var text: string = m.value;
    insertAtPosition(text);
  };

  const addValidation = (v: any) => {
    var text = "validation." + v.value;
    insertAtPosition(text);
  };

  const addCondition = (c: any) => {
    var text = c.value;
    insertAtPosition(text);
  };

  const addUserParam = (u: any) => {
    var text: string = u;
    insertAtPosition(text);
  };

  const renderRichbox = () => {
    return (
      <div className="richbox">
        <div className="row">
          <div className={showbBtnGroupBar ? "col-12" : "col-11"}>
            <div style={{ padding: 0 }} className="nav-bar-small">
              <div style={showbBtnGroupBar ? { display: "block" } : { display: "none" }} className="btn-group-bar">
                <div className="btn-toolbar  justify-content-between">
                  <div className="btn-group">
                    <div className="btn-group">
                      <button
                        disabled={props.runTypeProp?.values?.campaignRuns === "TRIGGER" ? false : true}
                        className="btn btn-default small dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        E <span className="caret"></span>
                      </button>
                      <ul className="dropdown-menu">
                        {campaingEventTemplateParams?.data
                          ? Object.values(campaingEventTemplateParams?.data).map((p: any) => (
                              <li
                                className="rte-li"
                                onClick={() => {
                                  addEventParam(p);
                                }}
                              >
                                <a>{p.propertyName}</a>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                    <div className="btn-group">
                      <button disabled className="btn btn-default small dropdown-toggle" data-toggle="dropdown">
                        S <span className="caret"></span>
                      </button>
                      <ul className="dropdown-menu ">
                        {sessionData
                          ? Object.values(sessionData).map((p: any) => (
                              <li
                                className="rte-li"
                                onClick={() => {
                                  addSessionParam(p);
                                }}
                              >
                                <a>{p.propertyName}</a>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </div>

                  <div className="btn-group">
                    <button className="btn btn-default small dropdown-toggle" data-toggle="dropdown">
                      U <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu">
                      {/* TODO MAP USER PARAMS WHEN API IS READY */}
                      <li
                        className="rte-li"
                        onClick={() => {
                          addUserParam("User param 1");
                        }}
                      >
                        <a>{"User param 1"}</a>
                      </li>
                      <li
                        className="rte-li"
                        onClick={() => {
                          addUserParam("User param 2");
                        }}
                      >
                        <a>{"User param 2"}</a>
                      </li>
                    </ul>
                  </div>

                  <div className="btn-group">
                    <button className="btn btn-default small " data-toggle="dropdown">
                      Math
                    </button>
                    <ul className="dropdown-menu">
                      {mathOperators
                        ? mathOperators.map((m: any) => (
                            <li
                              className="rte-li"
                              onClick={() => {
                                addKeyValue(m);
                              }}
                            >
                              <a>{m.name}</a>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                  <div className="btn-group">
                    <button className="btn btn-default small " data-toggle="dropdown">
                      <FontAwesomeIcon icon={faRandom} />
                    </button>
                    <ul className="dropdown-menu">
                      {booleanOperators
                        ? booleanOperators.map((b: any) => (
                            <li
                              className="rte-li"
                              onClick={() => {
                                addValidation(b);
                              }}
                            >
                              <a>{b.name}</a>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                  <div className="btn-group">
                    <button className="btn btn-default small  " data-toggle="dropdown">
                      <FontAwesomeIcon icon={faTerminal} />
                    </button>
                    <ul className="dropdown-menu">
                      {functionsOperators
                        ? functionsOperators.map((f: any) => (
                            <li
                              className="rte-li"
                              onClick={() => {
                                addCondition(f);
                              }}
                            >
                              <a>{f.name}</a>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>

                  <div className="btn-group">
                    <span className="btn btn-default small ">
                      <FontAwesomeIcon
                        icon={showbBtnGroupBar ? faAngleUp : faAngleDown}
                        onClick={() => {
                          setshowbBtnGroupBar(!showbBtnGroupBar);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>

              <AceEditor
                className="ace-rte"
                ref={aceEditorRef}
                mode="ftl"
                theme="chrome"
                name="ace-rte"
                editorProps={{ $blockScrolling: Infinity }}
                enableBasicAutocompletion={true}
                enableLiveAutocompletion={false}
                enableSnippets={true}
                onChange={props.onChangeProp}
                onBlur={props.onBlurProp}
                value={props.valueProp}
                width="100%"
                height={props.height ? props.height : "25px"}
              />
            </div>
          </div>
          {!showbBtnGroupBar ? (
            <div style={{ padding: "0" }} className="col-1">
              <span style={{ padding: "0", verticalAlign: "top" }} className="btn btn-default small ">
                <FontAwesomeIcon
                  icon={showbBtnGroupBar ? faAngleUp : faAngleDown}
                  onClick={() => {
                    setshowbBtnGroupBar(!showbBtnGroupBar);
                  }}
                />
              </span>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  // Render editor
  return <div>{renderRichbox()}</div>;
};

export default RteField;
