import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store/Store";
import reportWebVitals from "./reportWebVitals";
import App from "./main/App";
import axios from "axios";



//axios default settings override
if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = "https://webapi.herogi.com";
  axios.defaults.withCredentials = true;
}


const routing = (
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
