//public pages constants
export const LOADING ="Loading";

export const SIGN_IN ="Please Sign in"
export const SIGN_UP ="Please Sign up"
export const LOGIN ="Login"
export const REGISTER ="Register"
//footer constants
export const BANNER ="© 2021 Herogi"
export const FOOTER_HOME ="Home"
export const FOOTER_PRICING ="Pricing"
export const FOOTER_DOCS ="Docs"
export const FOOTER_BLOG ="Blog"
export const FOOTER_CONTACT_US ="Contact Us"
export const FOOTER_SIGN_IN ="Sign In"
export const FOOTER_SIGN_UP ="Sign Up"
//login constants
export const LOGIN_EMAIL_EMPTY_ERROR ="Email is required"
export const LOGIN_EMAIL_ERROR ="Valid email address is required"
export const LOGIN_PASSWORD_ERROR ="Password is required"
//register constants
export const REGISTER_CONTACT_NAME_ERROR ="Contact name is required"
export const REGISTER_COMPANY_NAME_ERROR ="Company name is required"
export const REGISTER_EMAIL_ADDRESS_EMPTY_ERROR ="Email address is required"
export const REGISTER_EMAIL_ADDRESS_ERROR ="Valid email address is required"
export const REGISTER_PASSWORD_EMPTY_ERROR ="Password is required"
export const REGISTER_PASSWORD_MIN_ERROR ="Password is too short, should be 8 chars minimum"
export const REGISTER_PASSWORDRE_EMPTY_ERROR ="Confirm password is required"
export const REGISTER_PASSWORDRE_MIN_ERROR ="Confirm password is too short, should be 8 chars minimum"
export const REGISTER_PASSWORDRE_MATCH_ERROR ="Passwords must match "

//Scenario constants
export const TABLE_SCENARIO_NAME = "Scenario Name";
export const TABLE_LAST_UPDATE = "Last Update Date";
export const TABLE_DEPLOYMENT = "Deployment Date";
export const TABLE_STATUS = "Status";

//modal-form contents

export const DELETE_SESSION_CONTENT = "Are you sure to delete session definition? This operation is not returnable and all session data will be deleted permanently.";

//scenario-design constants

export const STATES_HEADER  ="States";
export const ADD_NEW_STATE  ="Add New State";
export const TRANSITIONS_HEADER = "Transitions";
export const ADD_NEW_TRANSITION  ="Add New Transtion";














