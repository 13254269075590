import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Select from "react-select";
import { apiErrorHandle } from '../../../components/common/hooksAndFuncts/ApiErrorHandle';
import { monthNames } from '../../../constants/utils/constantUtils';
import { IBillingData } from '../types/billingTypes';

const Billing: React.FC = () => {

  const getBillingUrl = "/billing";

  const formSubmitURL = process.env.NODE_ENV === "production" ? "https://webapi.herogi.com" : "";

  useEffect(() => {
    getBilling();
  }, []);

  const [billingData, setbillingData] = useState<IBillingData | null>(null);

  const [products, setProducts] = useState<any>([
    {id: 1, label: "Growth", value: "growth_plan_v1", price: 59},
    {id: 2,  label: "Small Business", value: "smb_plan_v1", price: 189 },
    {id: 3, label: "Startup", value: "startup_plan_v1", price: 479 }
  ]);

  const [volumes, setVolumes] = useState<any>([
    {id: "vol1", label: "10K Users, 100K Events", value: "1" },
    {id: "vol2",  label: "20K Users, 200K Events", value: "2" },
    {id: "vol3", label: "30K Users, 300K Events", value: "3" },
    {id: "vol4", label: "40K Users, 400K Events", value: "4" },
    {id: "vol5", label: "50K Users, 500K Events", value: "5" },
    {id: "vol6", label: "60K Users, 600K Events", value: "6" },
    {id: "vol7", label: "70K Users, 700K Events", value: "7" },
    {id: "vol8", label: "80K Users, 800K Events", value: "8" },
    {id: "vol9", label: "90K Users, 800K Events", value: "9" },
    {id: "vol10", label: "100K Users, 1M Events", value: "10" },
    {id: "vol15", label: "150K Users, 1.5M Events", value: "15" },
    {id: "vol20", label: "200K Users, 2M Events", value: "20" },
    {id: "vol25", label: "250K Users, 2M Events", value: "25" },
    {id: "vol30", label: "300K Users, 3M Events", value: "30" },
    {id: "vol35", label: "350K Users, 3.5M Events", value: "35" },
    {id: "vol40", label: "400K Users, 4M Events", value: "40" },
    {id: "vol45", label: "450K Users, 4.5M Events", value: "45" },
    {id: "vol50", label: "500K Users, 5M Events", value: "50" }
  ]);
  
  const [volumeCost, setVolumeCost] = useState<number>(50);

  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [selectedVolume, setSelectedVolume] = useState<any>(volumes[0]);

  useEffect(() => {
    if(billingData?.account.subscriptionStatus.status.toLowerCase() === 'paid') {
      
      let p = products.find((p: any) => p.id === billingData?.account.product.id);
      setSelectedProduct(p);
      
      if(billingData?.account?.volume) {
        let v = volumes.find((v: any) => v.id === billingData?.account?.volume);
        setSelectedVolume(v);
      }
    }
  }, [billingData]);

  const getBilling = (): void => {
    axios
      .get(getBillingUrl, {})
      .then((response) => {
        setbillingData(response.data.data);
      })
      .catch((error) => {
        apiErrorHandle(error, 'getBillingApiError')
      });
  };

  const formatDate = () => {
    const date = new Date(String(billingData?.account.nextChargeOn));

    const nextCharge = `${monthNames[date.getMonth()]}, ${date.getDate()}, ${date.getFullYear()}`

    return <div> {nextCharge} </div>;
  };

  const calculateTotal = () => {
    if(selectedProduct === null || selectedVolume === null) return "N/A";
    let total = selectedProduct.price + ((selectedVolume.value - 1) * volumeCost);
    return "$" + total;
  }

  const checkoutDisabled = () => {
    return selectedProduct && selectedVolume ? false : true;
  }

  const renderSubscriptionSetup = () => {
    return (
      <div className="card mb-4">
        <div className="card-header">Subscription Setup</div>
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-12">
            <h4>
              Let's get you start!
              <span className='text-sm text-black-50'> (complex campaigns, custom attributes, in-app messaging and more)</span>
              </h4>
              <div className='row mt-3'>
                <Select className='col-md-4' onChange={(s) => {
                  let p = products.find((p: any) => p.value === s?.value);
                  setSelectedProduct(p);
                }} 
                 value={selectedProduct}
                 options = {products}
                 placeholder="Choose a plan"/>
                <Select className='col-md-4' 
                  value={selectedVolume} 
                  options={volumes}
                  onChange={(s) => {
                    setSelectedVolume(s);
                  }}
                placeholder="Volume"/>
                <div className='col-md-4 mt-2'>
                  <a className='text-sm' href='https://herogi.com/pricing' target='_blank'>Learn more!</a>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-md-6'>
                  <span className='text-uppercase-expanded'>Estimated Billing: {calculateTotal()} </span>
                  {billingData?.account.subscriptionStatus.status.toLowerCase() == "paid" ? <span className='text-sm text-black-50'>/ month</span> : ""}
                </div>
              </div>
              <div className='row mt-3'>
                <form className='col-md-12' action={formSubmitURL +  "/billing/stripe/create-checkout-session"} method="POST">
                  <input type="hidden" name="product_key" value={selectedProduct ? selectedProduct.value : "n/a" } />
                  <input type="hidden" name="product_volume" value={selectedVolume.id} />
                  <button id="checkout-and-portal-button" disabled={checkoutDisabled()} className='btn btn-success' type="submit">Upgrade</button>
                  {billingData?.account.subscriptionStatus.status.toLowerCase() == "paid" ? <button className="btn float-right" onClick={(e) => {
                    e.preventDefault();
                    axios.delete("/billing/stripe/cancel-subscription")
                      .then((response) => {
                        getBilling();
                        alert("Subscription cancelled successfully, feel free to revert back, we got your back!");
                      })
                      .catch((error) => {
                        console.log(error);
                        alert("Subscription cancellation failed");
                      });
                  }}>Cancel Subscription</button> : ""
                  }
                </form>
              </div>    
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="dashboard-main">
        <header className="page-header page-header-compact page-header-light border-bottom bg-white">
          <div className="container-fluid">
            <div className="page-header-content">
              <div className="row align-items-center justify-content-between pt-3">
                <div className="col-auto mb-3">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i data-feather="user"></i>
                    </div>
                    Account Settings - Billing
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-fluid mt-4">
          <nav className="nav nav-borders">
            <Link className="link" to="/settings">
              <span className="nav-link ml-0">Profile</span>
            </Link>

            <Link className="link" to="/settings/password">
              <a className="nav-link">Password Reset</a>
            </Link>

            <Link className="link" to="/settings/billing">
              <a className="nav-link active">Billing</a>
            </Link>
          </nav>

          <hr className="mt-0 mb-4" />
          <div className="row">
            <div className="col sm-8">
              <div className="col mb-4">
                <div className="card h-100 border-left-lg border-left-primary">
                  <div className="card-body">
                    <div className="small text-muted">Product</div>
                    <div className="h3">{billingData?.account.product.name}
                      <span className='text-black-50'> ({selectedVolume ? selectedVolume.label : volumes[0].label})</span>
                     </div>
                    <a className="text-arrow-icon small" href="#!">
                      <i data-feather="arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col mb-4">
                <div className="card h-100 border-left-lg border-left-success">
                  <div className="card-body">
                    <div className="small text-muted">Estimate / Account</div>
                    <div className="h3 d-flex align-items-center">
                      {billingData?.account.subscriptionStatus.status == "TRIAL" ?
                        "$0 TRIAL" : 
                         "$" +
                         billingData?.account.amount + " " +
                         billingData?.account.subscriptionStatus.status
                      }
                      
                    </div>
                    <a className="text-arrow-icon small text-success" href="#!">
                      <i data-feather="arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>


            </div>
            <div className="col sm-8">

              <div className="col mb-4">
                <div className="card h-100 border-left-lg border-left-secondary">
                  <div className="card-body">
                    <div className="small text-muted">Subscription Type</div>
                    <div className="h3 d-flex align-items-center">
                      {billingData?.account.subscription.type}
                    </div>
                    <a className="text-arrow-icon small text-success" href="#!">
                      <i data-feather="arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col mb-4">
                <div className="card h-100 border-left-lg border-left-success">
                  <div className="card-body">
                    <div className="small text-muted">Next Charge</div>
                    <div className="h3">{formatDate()}</div>
                    <a className="text-arrow-icon small text-secondary" href="#!">
                      <i data-feather="arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>

            </div>

          </div>
          {renderSubscriptionSetup()}
          {/* <div className="card mb-4">
            <div className="card-header">Billing History</div>
            <div className="card-body">
              <div className="table-responsive table-billing-history">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Invoice/Receipt Id</th>
                      <th scope="col">Billing Period</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Payment Date</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billingData?.transactions.map(t => {
                      return (
                        <tr>
                          <td>{t.invoiceId}</td>
                          <td>{moment(t.paymentPeriodStartOn).format("ll")}  -  {moment(t.paymentPeriodEndOn).format("ll")}</td>
                          <td>${t.amount}</td>
                          <td>{moment(t.createdOn).format("ll")}</td>
                          <td>
                            <span className={"badge " + (t.status.type == "PAID" ? "badge-light" : "badge-success")}>
                              {t.status.type}
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Billing;