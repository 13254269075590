import * as ValidationActionTypes from "../types/types";
import { apiErrorHandle } from "../../components/common/hooksAndFuncts/ApiErrorHandle";
import axios from "axios";

export const getValidationOperandAction = () => async (dispatch: any) => {
  try {
    dispatch({
      type: ValidationActionTypes.GET_VALIDATION_OPERAND_LOADING,
    });

    await axios
      .get("/validation/operand")
      .then((response) => {
        dispatch({
          type: ValidationActionTypes.GET_VALIDATION_OPERAND_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "getValidationOperandApiError");
        dispatch({
          type: ValidationActionTypes.GET_VALIDATION_OPERAND_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const addValidationAction = (bodyObj: object, scenarioId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: ValidationActionTypes.ADD_VALIDATION_LOADING,
    });

    await axios
      .put(`/scenario/${scenarioId}/validation`, bodyObj)
      .then((response) => {
        dispatch({
          type: ValidationActionTypes.ADD_VALIDATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "addValidationApiError");
        dispatch({
          type: ValidationActionTypes.ADD_VALIDATION_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const editValidationAction = (bodyObj: object, scenarioId: string, validationId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: ValidationActionTypes.EDIT_VALIDATION_LOADING,
    });

    await axios
      .post(`/scenario/${scenarioId}/validation/${validationId}`, bodyObj)
      .then((response) => {
        dispatch({
          type: ValidationActionTypes.EDIT_VALIDATION_SUCCESS,
          payload: { bodyObj, response: response },
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "editValidationApiError");
        dispatch({
          type: ValidationActionTypes.EDIT_VALIDATION_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const deleteValidationAction = (scenarioId: string, validationId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: ValidationActionTypes.DELETE_VALIDATION_LOADING,
    });
    await axios
      .delete(`/scenario/${scenarioId}/validation/${validationId}`)
      .then((response) => {
        dispatch({
          type: ValidationActionTypes.DELETE_VALIDATION_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        apiErrorHandle(error, "deleteValidationApiError");
        dispatch({
          type: ValidationActionTypes.DELETE_VALIDATION_FAIL,
          payload: error.response.data,
        });
      });
  } catch (error) {
    console.log(error, "error");
  }
};

export const sendSelectedValidationDataAction = (selectedValidation: object) => (dispatch: any) => {
  try {
    dispatch({
      type: ValidationActionTypes.SEND_VALIDATION_DATA_LOADING,
    });
    dispatch({
      type: ValidationActionTypes.SEND_VALIDATION_DATA_SUCCESS,
      payload: selectedValidation,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: ValidationActionTypes.SEND_VALIDATION_DATA_FAIL,
      payload: error,
    });
  }
};

export const sendSelectedValidationIdAction = (selectedValidationId: string) => (dispatch: any) => {
  try {
    dispatch({
      type: ValidationActionTypes.SEND_VALIDATION_ID_LOADING,
    });
    dispatch({
      type: ValidationActionTypes.SEND_VALIDATION_ID_SUCCESS,
      payload: selectedValidationId,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: ValidationActionTypes.SEND_VALIDATION_ID_FAIL,
      payload: error,
    });
  }
};


export const sendValidationMapDataAction = (validationMapData: any) => (dispatch: any) => {
  try {
    dispatch({
      type: ValidationActionTypes.SEND_VALIDATION_MAP_DATA_LOADING,
    });
    dispatch({
      type: ValidationActionTypes.SEND_VALIDATION_MAP_DATA_SUCCESS,
      payload: validationMapData,
    });
  } catch (error) {
    console.log(error, "error");
    dispatch({
      type: ValidationActionTypes.SEND_VALIDATION_MAP_DATA_FAIL,
      payload: error,
    });
  }
};
