import { BarElement, CategoryScale, Chart, ChartOptions, Legend, LinearScale, LineController, PointElement, registerables, TimeScale, Title, Tooltip } from "chart.js";
import moment from "moment";
import { Line } from "react-chartjs-2";
import 'chartjs-adapter-moment';



function LineChart(props) {

      // Register the necessary components and scales
Chart.register(CategoryScale, LinearScale, PointElement, LineController, Title, Legend, Tooltip, TimeScale, BarElement);
Chart.register(...registerables);

  var chartEventData = {
    labels: [],
   
    datasets: [
      {
        label: "All Events",
        fill: true,
        tension: 0.1,
        backgroundColor: "#FCF3CF",
        borderColor: "#F6D965",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#F6D965",
        pointBackgroundColor: "#FCF3CF",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#FCF3CF",
        pointHoverBorderColor: "#F6D965",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [],
        
        
      },
    ],
  };

  
  const eventsDate = moment().format("MMM") + "-" + moment().format("YY");

  var options: ChartOptions<'line'> = {

   
      title: {
        position:'top',
        display: true,
        fontSize: 16,
        fullWidth: false,
        text: 'Events '+ eventsDate ,
      },
     elements: { point: { radius: 2 } },
      legend: {
        display: true,
        position: "top",
        align: "end",
      },
     
   

    scales: {
      y:
        {
          id: 'y-axis-1"',
          type: 'linear',
          display: true,
          position: 'left',
        },
      x: {
          type: 'time',
          time: {
            unit: 'day',
            stepSize: 7,
            displayFormats: {
              day: 'MMM DD',
            },
          },
          scaleLabel: {
            display: true,
            labelString: 'Days',
          },
        },
    },
   
   
  };

  var eventData;
  var labelData;

  if (props) {

     


    if (props.graphData) {
      eventData = props.graphData;
     /*  alert(eventData) */

      chartEventData["datasets"][0]["data"] = eventData;
    } else {
      eventData = [];
    }
    if (props.labelData) {
      labelData = props.labelData;
      chartEventData["labels"] = labelData;
    } else {
      labelData = [];
    }

    console.log(chartEventData);
  }


  return (
    <div>
      <Line data={chartEventData} options={options} />
    </div>
  );
}

export default LineChart;
