import { useEffect, useState } from "react";
import { putEventParamAction } from "../../../../../redux/actions/EventActions";
import { RootStore } from "../../../../../redux/store/Store";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { aggregationDurationOptions, aggregationTypeOptions, parameterTypeOptions } from "./utils/formUtils";
import * as Yup from "yup";
import moment from "moment";
import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";
import $ from 'jquery';


const AddNewEventParamForm = () => {
  const dispatch = useDispatch();

  const eventId: any = useSelector((state: RootStore) => state.sendEventIdReducer);
  const scenarioId: any = useSelector((state: RootStore) => state.sendScenarioIdReducer);
  const addNewEventParamError: any = useSelector((state: RootStore) => state.putEventParamReducer);

  const [addNewEventParamEData, setaddNewEventParamEData] = useState<any>(null);

  useEffect(() => {
    if (addNewEventParamError.data) {
      if (addNewEventParamError.data.message) {
        setaddNewEventParamEData(addNewEventParamError.data.message);
      }
    }
  }, [addNewEventParamError]);

  useEffect(() => {
    $("#AddNewEventParamModalCloseIconButton").click(function () {
      $("#addNewEventParameterCloseButton")[0].click();
    });
  }, []);

  const setEventParamOptions = () => {
    return <>{parameterTypeOptions ? Object.values(parameterTypeOptions).map((item: any, index) => <option key={index} value={item.value} label={item.key} />) : null}</>;
  };

  const setAggregationTypeOptions = () => {
    return <>{aggregationTypeOptions ? Object.values(aggregationTypeOptions).map((item: any, index) => <option key={index} value={item.value} label={item.key} />) : null}</>;
  };

  const setAggregationDurationTypeOptions = () => {
    return <>{aggregationDurationOptions ? Object.values(aggregationDurationOptions).map((item: any, index) => <option key={index} value={item.value} label={item.key} />) : null}</>;
  };

  const handleAddNewEventParamApi = (bodyObject: any, formObj: any) => {
    var aggInfo: null | string | {};
    var description: string = bodyObject.descriptionForm;
    var propertyName: string = bodyObject.paramName;
    var propertyType: string = bodyObject.paramType;
    var type: string = "";
    var unit: number = 0;
    var durationType: string = "";
    var baseTime = moment.utc(bodyObject.aggregationBaseDate).local().format("YYYY-MM-DD HH:mm");

    if (bodyObject.aggregationType.toLocaleUpperCase() !== "NONE") {
      if (bodyObject.aggregationType.toUpperCase() === "FIXED") {
        durationType = bodyObject.aggregationDurationFixedType;
        unit = bodyObject.aggregationDurationUnitFixed;
      } else if (bodyObject.aggregationType.toUpperCase() === "SLIDING") {
        durationType = bodyObject.aggregationDurationSlidingType;
        unit = bodyObject.aggregationDurationUnitSliding;
      }
    }

    if (bodyObject.aggregationType.toUpperCase() === "NONE" || bodyObject.aggregationType.toUpperCase() === "" || bodyObject.paramType.toUpperCase() === "STRING") {
      aggInfo = null;
    } else {
      type = bodyObject.aggregationType;
      if (type.toUpperCase() === "FIXED") {
        aggInfo = { baseTime, durationType, type, unit };
      } else {
        aggInfo = { durationType, type, unit };
      }
    }
    var sendObj = { aggInfo: aggInfo, description: description, propertyName: propertyName, propertyType: propertyType };

    dispatch(putEventParamAction(sendObj, scenarioId.data, eventId.data, formObj));
  };

  const validationSchema = Yup.object({
    paramName: Yup.string().required("Parameter name is required"),

    aggregationDurationFixedType: Yup.string().when("aggregationDurationFixed", {
      is: (val: any) => val !== "",
      then: Yup.string().when("aggregationType", {
        is: (val: any) => val === "FIXED",
        then: Yup.string().when("paramType", {
          is: (val: any) => val === "NUMERIC",
          then: Yup.string().required(""),
        }),
      }),
    }),

    aggregationDurationUnitFixed: Yup.number().when("aggregationType", {
      is: (val: any) => val === "FIXED",
      then: Yup.number().when("paramType", {
        is: (val: any) => val === "NUMERIC",
        then: Yup.number()
          .test("", "Duration must be positive number", (val: any) => val >= 0)
          .required("Duration is required"),
      }),
    }),

    aggregationBaseDate: Yup.date().when("aggregationType", {
      is: (val: any) => val === "FIXED",
      then: Yup.date().when("paramType", {
        is: (val: any) => val === "NUMERIC",
        then: Yup.date().required("").min(moment.utc().local().format("YYYY-MM-DD"), "field can not be past date"),
      }),
    }),

    aggregationDurationSlidingType: Yup.string().when("aggregationDurationSliding", {
      is: (val: any) => val !== "",
      then: Yup.string().when("aggregationType", {
        is: (val: any) => val === "SLIDING",
        then: Yup.string().when("paramType", {
          is: (val: any) => val === "NUMERIC",
          then: Yup.string().required(""),
        }),
      }),
    }),

    aggregationDurationUnitSliding: Yup.number().when("aggregationType", {
      is: (val: any) => val === "SLIDING",
      then: Yup.number().when("paramType", {
        is: (val: any) => val === "NUMERIC",
        then: Yup.number()
          .test("", "Duration must be positive number", (val: any) => val >= 0)
          .required("Duration is required"),
      }),
    }),
  });

  const formik = useFormik({
    initialValues: {
      paramName: "",
      paramType: "STRING",
      aggregationType: "None",
      aggregationBaseDate: "",
      aggregationDurationUnitFixed: 0,
      aggregationDurationFixedType: "",
      aggregationDurationUnitSliding: 0,
      aggregationDurationSlidingType: "",
      description: "",
    },
    onSubmit: () => {},
    validationSchema: validationSchema,
    validateOnChange: true,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <div>
          <label className="form-label" htmlFor="paramName">
            Parameter Name
          </label>
        </div>
        <input className="form-control" id="paramName" name="paramName" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.paramName} />

        {addNewEventParamEData ? <ErrorMessageComp name={addNewEventParamEData} /> : formik.errors.paramName ? <ErrorMessageComp name={formik.errors.paramName} /> : null}
      </div>

      <div className="mt-3">
        <label className="form-label">Type</label>
        <div>
          <select className="form-control" id="paramType" name="paramType" value={formik.values.paramType} onChange={formik.handleChange} onBlur={formik.handleBlur}>
            {setEventParamOptions()}
          </select>
        </div>
      </div>

      {formik.values.paramType === "NUMERIC" ? (
        <div className="mt-3">
          <label className="form-label">Aggregation Type</label>
          <div>
            <select className="form-control" id="aggregationType" name="aggregationType" value={formik.values.aggregationType} onChange={formik.handleChange} onBlur={formik.handleBlur}>
              {setAggregationTypeOptions()}
            </select>
          </div>
        </div>
      ) : null}

      {formik.values.aggregationType === "FIXED" && formik.values.paramType === "NUMERIC" ? (
        <div className="mt-3">
          <div>
            <div>
              <label className="form-label" htmlFor="aggregationBaseDate">
                Aggregation Base Date
              </label>
            </div>
            <input
              className="form-control"
              id="aggregationBaseDate"
              name="aggregationBaseDate"
              type="date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.aggregationBaseDate}
            />
            {formik.errors.aggregationBaseDate  ? <ErrorMessageComp name={formik.errors.aggregationBaseDate} /> : null}
          </div>

          <div className="mt-3">
            <div className="row">
              <div className="col-sm">
                <div>
                  <div>
                    <label className="form-label" htmlFor="aggregationDurationFixed">
                      Aggregation Duration
                    </label>
                  </div>
                  <input
                    className="form-control"
                    id="aggregationDurationUnitFixed"
                    name="aggregationDurationUnitFixed"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.aggregationDurationUnitFixed}
                  />

                  {formik.errors.aggregationDurationUnitFixed ? <ErrorMessageComp name={formik.errors.aggregationDurationUnitFixed} /> : null}
                </div>
              </div>
              <div className="col-sm">
                <label className="form-label">Duration Type</label>
                <div>
                  <select
                    className="form-control"
                    id="aggregationDurationFixedType"
                    name="aggregationDurationFixedType"
                    value={formik.values.aggregationDurationFixedType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {setAggregationDurationTypeOptions()}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {formik.values.aggregationType === "SLIDING" && formik.values.paramType === "NUMERIC" ? (
        <div className="mt-3">
          <div className="mt-3">
            <div className="row">
              <div className="col-sm">
                <div>
                  <div>
                    <label className="form-label" htmlFor="aggregationDurationUnitSliding">
                      Aggregation Duration
                    </label>
                  </div>
                  <input
                    className="form-control"
                    id="aggregationDurationUnitSliding"
                    name="aggregationDurationUnitSliding"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.aggregationDurationUnitSliding}
                  />

                  {formik.errors.aggregationDurationUnitSliding  ? <ErrorMessageComp name={formik.errors.aggregationDurationUnitSliding} /> : null}
                </div>
              </div>
              <div className="col-sm">
                <label className="form-label">Duration Type</label>
                <div>
                  <select
                    className="form-control"
                    id="aggregationDurationSlidingType"
                    name="aggregationDurationSlidingType"
                    value={formik.values.aggregationDurationSlidingType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {setAggregationDurationTypeOptions()}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div>
        <div className="mt-3">
          <label className="form-label" htmlFor="description">
            Description
          </label>
        </div>
        <textarea className="form-control" id="description" name="description" rows={3} onBlur={formik.handleBlur} value={formik.values.description} onChange={formik.handleChange} />
      </div>

      <div>
        <div className="modal-footer">
          <button
            id="addNewEventParameterCloseButton"
            className="btn btn-light mt-3"
            type="button"
            data-dismiss="modal"
            onClick={() => {
              formik.resetForm();
              if (addNewEventParamError.data) {
                addNewEventParamError.data.message = "";
              }
            }}
          >
            Close
          </button>
          <button
            id="addNewEventParameterCreateButton"
            onClick={() => {
              handleAddNewEventParamApi(formik.values, formik);
            }}
            className="btn btn-success mt-3"
            type="submit"
            disabled={!(formik.isValid && formik.dirty)}
          >
            Create
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddNewEventParamForm;
