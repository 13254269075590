import { sortBy } from "lodash";
import { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { RootStore } from "../../../redux/store/Store";
import { getSegmentListAction } from "../../../redux/actions/SegmentActions";

const SegmentPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const segmentListTableDataRef = useRef<any>(null);
  const segmentListR: any = useSelector((state: RootStore) => state.getSegmentListReducer);

  const [segmentListData, setSegmentListData] = useState<any>(null);
  const [segmentListTableData, setsegmentListTableData] = useState<any>([]);

  useEffect(() => {
    dispatch(getSegmentListAction()); 
  }, []);

  useEffect(() => {
    if (segmentListR) {
      setSegmentListData(sortBy(segmentListR.data, "name"));
      var segmentListTableTemp: any = [];
      const mockData = {
        "status": "success",
        "data": [
            {
                "id": 1,
                "name": "segment1",
                "description": "desc",
                "scenarios":[
                  "scenario1",
                  "scenario2",
                ],
                "status": {
                    "status": "undeployed"
                },
                "creationDate": 1643121073000,
                "updateDate": 1643188347000
            }
        ]
    }

    

      //segment list table data mapping
      segmentListR?.data?.data.map((si: any) => {
        var scenarioCell = si.scenarios + ",".slice(0,-1);
        segmentListTableTemp.push({
          id: si.id,
          segmentName: si.name,
          scenarios:"scenarioMock", //scenarioCell
          lastEdited: new Date(si.updateDate).toLocaleString(),
          operations: renderSegmentOperations(si),
        });
      });
      setsegmentListTableData([...segmentListTableTemp]);
    }
  }, [segmentListR]);

  const renderHeader = () => {
    return (
      <div className="card shadow-sm mb-1">
        <div className="card-body pb-3 pt-3">
          <div className="d-flex align-items-center justify-content-between px-0">
            <div className="d-flex align-items-center">
              <strong>Existing Segments</strong>
            </div>
            <div className="ms-4 small">
              <button
                className="btn btn-primary p-2 "
                onClick={() => {
                  history.push("/segment/create");
                }}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const customTotal = (from: number, to: number, size: number) => <div></div>;

  const renderSegmentOperations = (si: any) => {
    return (
      <span>
        { si.isAllUser == false ? 
        <Link to={`/dashboard/segment/edit/${si.id}`}>
          <a href="" className="icon-edit">
            Edit
          </a>
        </Link>
        : 
        <Link to="/" className="disabledCursor" onClick={ (event) => event.preventDefault() }>
        <a href="" className="icon-edit" style={{color: "gray"}}>
          Edit
        </a>
      </Link>
        }
      </span>
    );
  };

  const campaignListTableColumns = [
    {
      dataField: "segmentName",
      text: "Segment Name",
    },
    // {
    //   dataField: "scenarios",
    //   text: "Scenarios",
    // },

    {
      dataField: "lastEdited",
      text: "Last Edited"
    },
    {
      dataField: "operations",
      text: "Operations",
    },
    
  ];

  const selectRow: any = {
    mode: "",
    clickToSelect: false,
    hideSelectAll: false,
    // If we decide to make deploy or delete selected campaigns
    // future this functions for select rows

    /* onSelect: (row:{id:number}, isSelect:boolean) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row.id]);
      } else {
        setselectedItems([...selectedItems.filter((x: any) => x != row.id)]);
      }
    },
    onSelectAll: (isSelect:boolean) => {
      const ids = scenarioListTableDataRef.current.props.data.map(
        (row: {id:number}) => row.id
      );
      if (isSelect) {
        setselectedItems(ids);
        return ids;
      } else {
        setselectedItems([]);
        return [];
      }
    }, */
  };

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true,
    // withFirstAndLast: false,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    /* firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last", */
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPage: 8,

    /*  onPageChange: (page:number, ) => {
      setPage(page);
    }, */
  };

  const renderSegmentListTable = () => {
    return (
      <div>
        <div className="card shadow-sm mb-1">
          <div className="card-body">
            <div>
              <BootstrapTable
                ref={segmentListTableDataRef}
                keyField="id"
                data={segmentListTableData}
                columns={campaignListTableColumns}
                /*  selectRow={selectRow} */
                pagination={paginationFactory(options)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="dashboard-main">
      {renderHeader()}{renderSegmentListTable()}
    </div>
  );
};

export default SegmentPage;
