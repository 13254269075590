import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ICustomerData, IUtilTimezone } from "../types/accountTypes";
import { apiErrorHandle } from "../../../components/common/hooksAndFuncts/ApiErrorHandle";
import { getCustomerUrl, getSystemTimeZoneUrl, getUtilTimeZoneUrl, postCustomerAppUrl, postCustomerBasicUrl, postCustomerProfileUrl } from "../../../constants/Urls";
import axios from "axios";
import ErrorMessageComp from "../../../components/common/ErrorMessageComp";

const AccountSettings = () => {
 

  const [customerData, setcustomerData] = useState<ICustomerData | null>(null);
  const [timezoneData, settimezoneData] = useState<IUtilTimezone | null>(null);

  const [timezoneCode, settimezoneCode] = useState<string>("");

  const [basicAlert, setbasicAlert] = useState<string>("");
  const [profileAlert, setprofileAlert] = useState<string>("");
  const [accountAlert, setaccountAlert] = useState<string>("");

  const [basicAccountEditToggle, setbasicAccountEditToggle] = useState<boolean>(false);
  const [profileAccountEditToggle, setprofileAccountEditToggle] = useState<boolean>(false);
  const [appAccountEditToggle, setappAccountEditToggle] = useState<boolean>(true);

  const username = useRef<HTMLInputElement>(null);
  const email = useRef<HTMLInputElement>(null);
  const phone = useRef<HTMLInputElement>(null);
  const companyName = useRef<HTMLInputElement>(null);
  const website = useRef<HTMLInputElement>(null);
  const address1 = useRef<HTMLInputElement>(null);
  const address2 = useRef<HTMLInputElement>(null);
  const city = useRef<HTMLInputElement>(null);
  const state = useRef<HTMLInputElement>(null);
  const zipCode = useRef<HTMLInputElement>(null);
  const country = useRef<HTMLInputElement>(null);
  const timezoneRef = useRef<HTMLOptionElement>(null);

  const appName = useRef<HTMLInputElement>(null);

  useEffect(() => {
    getCustomer();
    getUtilTimezone();
  }, []);

  const getUtilTimezone = (): void => {
    axios
      .get(getUtilTimeZoneUrl, {})
      .then((response) => {
        settimezoneData(response.data);
      })
      .catch((error) => {
        apiErrorHandle(error, "getUtilTimeZoneApiError");
      });
  };

  const getSystemTimezone = (): void => {
    axios
      .get(getSystemTimeZoneUrl, {})
      .then((response) => {})
      .catch((error) => {
        apiErrorHandle(error, "getSystemTimeZoneApiError");
      });
  };

  const getCustomer = (): void => {
    axios
      .get(getCustomerUrl, {})
      .then((response) => {
        setcustomerData(response.data.data);
        settimezoneCode(JSON.stringify(response.data.data.timeZone.code).replace(/^"(.*)"$/, "$1"));
      })
      .catch((error) => {
        apiErrorHandle(error, "getCustomerApiError");
      });
  };

  const postCustomerApp = (): void => {
    axios
      .post(postCustomerAppUrl, {
        appName: appName.current!.value,
        timeZone: timezoneCode,
      })
      .then((response) => {
        setcustomerData(response.data.data);
        setaccountAlert("Updated Successfully");
        getSystemTimezone();
        setappAccountEditToggle(!appAccountEditToggle);
      })
      .catch((error) => {
        apiErrorHandle(error, "postCustomerAppUrlApiError");
      });
  };

  const postCustomerBasic = (): void => {
    axios
      .post(postCustomerBasicUrl, {
        contactName: username.current!.value,
        email: email.current!.value,
        phoneNumber: phone.current!.value,
      })
      .then((response) => {
        setcustomerData(response.data.data);
        setbasicAlert("Updated Successfully");
        setbasicAccountEditToggle(!basicAccountEditToggle);
      })
      .catch((error) => {
        if (error.response) {
          setbasicAlert(error.response.data.message);
        }
        apiErrorHandle(error, "postCustomerBasicApiError");
      });
  };

  const postCustomerProfile = (): void => {
    axios
      .post(postCustomerProfileUrl, {
        companyName: companyName.current!.value,
        address1: address1.current!.value,
        address2: address2.current!.value,
        city: city.current!.value,
        state: state.current!.value,
        country: country.current!.value,
        website: website.current!.value,
        zipCode: zipCode.current!.value,
      })
      .then((response) => {
        setcustomerData(response.data.data);
        setprofileAlert("Updated Successfully");
        setprofileAccountEditToggle(!profileAccountEditToggle);
      })
      .catch((error) => {
        if (error.response) {
          setprofileAlert(error.response.data);
        }
        apiErrorHandle(error, "postCustomerProfileApiError");
      });
  };

  const appAccountSubmitHandler = (event: React.FormEvent): void => {
    event.preventDefault();
    try {
      postCustomerApp();
    } catch (error) {
      console.log(error);
    }
  };

  const basicAccountSubmitHandler = (event: React.FormEvent): void => {
    event.preventDefault();

    if (username.current!.value === "") {
      setbasicAlert("Valid username required");
    } else {
      try {
        postCustomerBasic();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const profileAccountSubmitHandler = (event: React.FormEvent): void => {
    event.preventDefault();
    try {
      postCustomerProfile();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="dashboard-main">
        <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
          <div className="container-fluid">
            <div className="page-header-content">
              <div className="row align-items-center justify-content-between pt-3">
                <div className="col-auto mb-3">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i data-feather="user"></i>
                    </div>
                    Account Settings - Profile
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-fluid mt-4">
          <nav className="nav nav-borders">
            <Link className="link" to="/settings">
              <span className="nav-link active ml-0">Profile</span>
            </Link>

            <Link className="link" to="/settings/password">
              <a className="nav-link">Password Reset</a>
            </Link>

            <Link className="link" to="/settings/billing">
              <a className="nav-link">Billing</a>
            </Link>
          </nav>

          <hr className="mt-0 mb-4" />
          <div className="row">
            <div className="col-xl-12">
              <div className="card mb-4">
                <div className="card-header">
                  <div className="row ">
                    <div className="col-auto mr-auto">Basic Account Details</div>
                    <div className="col-auto">
                      <button
                        onClick={() => {
                          setbasicAccountEditToggle(!basicAccountEditToggle);
                        }}
                        className="btn btn-primary p-1"
                        type="button"
                      >
                        {basicAccountEditToggle ? "Cancel" : "Edit"}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  {basicAccountEditToggle ? (
                    <form>
                      <div className="form-group">
                        <label className="small mb-1" htmlFor="inputUsername">
                          Username
                        </label>
                        <input className="form-control" id="inputUsername" type="text" ref={username} defaultValue={customerData?.profile.contactName} />
                      </div>

                      <div className="form-group">
                        <label className="small mb-1" htmlFor="inputEmailAddress">
                          Email address
                        </label>
                        <input className="form-control" id="inputEmailAddress" type="email" ref={email} defaultValue={customerData?.email} />
                      </div>
                      <div className="form-group">
                        <label className="small mb-1" htmlFor="inputPhone">
                          Phone number
                        </label>
                        <input className="form-control" id="inputPhone" type="text" ref={phone} defaultValue={customerData?.profile.phoneNumber} placeholder="Phone number" />
                      </div>
                      <div className="form-group">{basicAlert ? <ErrorMessageComp name={basicAlert} /> : null}</div>

                      <button onClick={basicAccountSubmitHandler} className="btn btn-primary p-2" type="button">
                        Save changes
                      </button>
                    </form>
                  ) : (
                    <div className="form-group">
                      <label className="small mb-1" htmlFor="inputUsername">
                        Username
                      </label>

                      <h4 className="font-weight-normal">{customerData?.profile.contactName}</h4>

                      <label>Email address</label>

                      <h4 className="font-weight-normal">{customerData?.email}</h4>

                      <label>Phone number</label>

                      <h4 className="font-weight-normal">{customerData?.profile.phoneNumber}</h4>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-xl-12">
              <div className="card mb-4">
                <div className="card-header">
                  <div className="row ">
                    <div className="col-auto mr-auto">Profile Account Details</div>
                    <div className="col-auto">
                      <button
                        onClick={() => {
                          setprofileAccountEditToggle(!profileAccountEditToggle);
                        }}
                        className="btn btn-primary p-1"
                        type="button"
                      >
                        {profileAccountEditToggle ? "Cancel" : "Edit"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {profileAccountEditToggle ? (
                    <form>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="small mb-1" htmlFor="inputUsername">
                            Company
                          </label>
                          <input className="form-control" id="inputUsername" type="text" ref={companyName} defaultValue={customerData?.companyName} />
                        </div>

                        <div className="form-group col-md-6">
                          <label className="small mb-1" htmlFor="inputUsername">
                            Address 1
                          </label>
                          <input className="form-control" id="inputUsername" type="text" ref={address1} defaultValue={customerData?.profile.address1} placeholder="Address 1" />
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="small mb-1" htmlFor="inputUsername">
                            Website
                          </label>
                          <input className="form-control" id="inputUsername" type="text" ref={website} defaultValue={customerData?.profile.website} placeholder="Website" />
                        </div>

                        <div className="form-group col-md-6">
                          <label className="small mb-1" htmlFor="inputUsername">
                            Address 2
                          </label>
                          <input className="form-control" id="inputUsername" type="text" ref={address2} defaultValue={customerData?.profile.address2} placeholder="Address 2" />
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6"></div>

                        <div className="form-group col-md-6">
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label className="small mb-1" htmlFor="inputUsername">
                                City
                              </label>
                              <input className="form-control" id="inputUsername" type="text" ref={city} defaultValue={customerData?.profile.city} placeholder="City" />
                            </div>
                            <div className="form-group col-md-6">
                              <label className="small mb-1" htmlFor="inputUsername">
                                State
                              </label>
                              <input className="form-control" id="inputUsername" type="text" ref={state} defaultValue={customerData?.profile.state} placeholder="State" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6"></div>

                        <div className="form-group col-md-6">
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label className="small mb-1" htmlFor="inputUsername">
                                Zip Code
                              </label>
                              <input className="form-control" id="inputUsername" type="text" ref={zipCode} defaultValue={customerData?.profile.zipCode} placeholder="zipCode" />
                            </div>
                            <div className="form-group col-md-6">
                              <label className="small mb-1" htmlFor="inputUsername">
                                Country
                              </label>
                              <input className="form-control" id="inputUsername" type="text" ref={country} defaultValue={customerData?.profile.country} placeholder="Country" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-md-6"></div>

                        <div className="form-group col-md-6"></div>
                      </div>

                      <div className="form-group">{profileAlert ? <ErrorMessageComp name={profileAlert} /> : null}</div>

                      <button onClick={profileAccountSubmitHandler} className="btn btn-primary p-2" type="button">
                        Save changes
                      </button>
                    </form>
                  ) : (
                    <form>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="small mb-1" htmlFor="inputUsername">
                            Company
                          </label>

                          <h4>{customerData?.companyName}</h4>
                        </div>

                        <div className="form-group col-md-6">
                          <label className="small mb-1" htmlFor="inputUsername">
                            Company Adress
                          </label>

                          <h4>{customerData?.profile.address1}</h4>
                          <h4>{customerData?.profile.address2}</h4>
                          <h4>
                            {customerData?.profile.city} , {customerData?.profile.state} {customerData?.profile.zipCode}{" "}
                          </h4>
                          <h4>{customerData?.profile.country}</h4>
                        </div>
                      </div>

                      <div className="form-group">{profileAlert ? <ErrorMessageComp name={profileAlert} /> : null}</div>
                    </form>
                  )}
                </div>
              </div>

              <div>
                <div className="card mb-4">
                  <div className="card-header">
                    <div className="row ">
                      <div className="col-auto mr-auto">App Account Details</div>
                      <div className="col-auto">
                        <button
                          onClick={() => {
                            setappAccountEditToggle(!appAccountEditToggle);
                          }}
                          className="btn btn-primary p-1"
                          type="button"
                        >
                          {!appAccountEditToggle ? "Cancel" : "Edit"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {appAccountEditToggle ? (
                      <form>
                        <div className="form-group">
                          <label className="small mb-1">Application Name</label>
                          <h4> {customerData?.appName}</h4>
                        </div>

                        <div className="form-group">
                          <form>
                            <div className="form-group"></div>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlSelect1">Timezone Select</label>
                              <h4>{customerData?.timeZone ? customerData?.timeZone.offset + " - " + customerData?.timeZone.code : ""} </h4>
                            </div>
                          </form>
                        </div>

                        <div className="form-group">{accountAlert ? <ErrorMessageComp name={accountAlert} /> : null}</div>
                      </form>
                    ) : (
                      <form>
                        <div className="form-group">
                          <label className="small mb-1" htmlFor="inputUsername">
                            Application Name
                          </label>
                          <input className="form-control" id="inputUsername" type="text" ref={appName} defaultValue={customerData?.appName} placeholder="Application Name" />
                        </div>

                        <div className="form-group">
                          <form>
                            <div className="form-group"></div>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlSelect1">Timezone Select</label>

                              <div>
                                <input
                                  list="timezones"
                                  name="timezones"
                                  className="form-control form-control-solid"
                                  defaultValue={timezoneCode}
                                  onChange={(e) => {
                                    settimezoneCode(e.target.value.split(" ")[e.target.value.split(" ").length - 1]);
                                  }}
                                />
                                <datalist id="timezones">
                                  <option selected>{customerData?.timeZone ? customerData?.timeZone.offset + " - " + customerData?.timeZone.code : ""} </option>
                                  {timezoneData?.data.map((item) => (
                                    <option value={item.offset + " " + "-" + " " + item.code}>
                                      {item.offset} - {item.code}
                                    </option>
                                  ))}
                                </datalist>
                              </div>
                            </div>
                          </form>
                        </div>

                        <div className="form-group">{accountAlert ? <ErrorMessageComp name={accountAlert} /> : null}</div>

                        <button onClick={appAccountSubmitHandler} className="btn btn-primary p-2" type="button">
                          Save changes
                        </button>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSettings;
