import { useEffect, useState } from "react";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { RootStore } from "../../../../../redux/store/Store";
import { putStateAction } from "../../../../../redux/actions/StateActions";
import { IActionMap, IStateError } from "../../../types/stateTypes";
import { components } from "react-select";
import * as Yup from "yup";
import ErrorMessageComp from "../../../../../components/common/ErrorMessageComp";
import CustomSelect from "../CustomSelect";
import $ from 'jquery';


const AddNewStateForm = ( ) => {


  
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const actionMap: IActionMap = useSelector((state: RootStore) => state.sendActionMapReducer.data);
  const newStateError: IStateError = useSelector((state: RootStore) => state.putStateReducer.data);

  const [newStateEData, setnewStateEData] = useState<string | null>(null);
  const [actionMapOptions, setactionMapOptions] = useState<any>([]);

  useEffect(() => {
    if (actionMap) {
      const newActionMapOptions: any = [];
      Object.values(actionMap).map((ai: any) => {
        newActionMapOptions.push({ value: ai.id, label: ai.name, template: ai.template.name });
      });
      setactionMapOptions([...newActionMapOptions]);
    }
  }, [actionMap]);

  useEffect(() => {
    if (newStateError) {
      if (newStateError.message) {
        setnewStateEData(newStateError.message);
      }
    }
  }, [newStateError]);

  useEffect(() => {
    $("#AddNewStateModalCloseIconButton").click(function () {
      $("#addNewStateCloseButton")[0].click();
    });
  }, []);

  const handleSubmitState = (bodyObj: object, formObj: object) => {
    dispatch(putStateAction(bodyObj, id, formObj));
  };

  const CustomOption = (innerProps: any) => (
    <components.Option {...innerProps}>
      <div>{innerProps.data.label}</div>
      <small>{innerProps.data.template}</small>
    </components.Option>
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("State name is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      actions: [],
    },
    onSubmit: () => {},
    validationSchema: validationSchema,
  });
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <label className="form-label" htmlFor="name">
              State Name
            </label>
          </div>
          <input
            className="form-control"
            id="name"
            name="name"
            type="text"
            onChange={(e) => {
              formik.handleChange(e);
              setnewStateEData("");
            }}
            value={formik.values.name}
          />

          {newStateEData ? <ErrorMessageComp name={newStateEData} /> : <ErrorMessageComp name={formik.errors.name} />}
        </div>

        <div>
          <div className="mt-3">
            <label className="form-label" htmlFor="eventType">
              Actions
            </label>
          </div>

          <Field name="actions" options={actionMapOptions} component={CustomSelect} placeholder="Select actions..." isMulti={true} components={{ Option: CustomOption }} />
        </div>

        <div>
          <div className="mt-3">
            <label className="form-label" htmlFor="description">
              Description
            </label>
          </div>
          <textarea className="form-control" id="description" name="description" rows={3} onChange={formik.handleChange} value={formik.values.description} />
        </div>

        <div>
          <div className="modal-footer">
            <button
              id="addNewStateCloseButton"
              className="btn btn-primary p-2 mt-3"
              type="button"
              data-dismiss="modal"
              onClick={() => {
                formik.resetForm();
                setnewStateEData("");
                    $("#visualize-toggle-button").click()
                  }
                }
               
               
            >
              Close
            </button>

            <button
              id="addNewStateCreateButton"
              onClick={() => {
                handleSubmitState(formik.values, formik);
             

              }}
              className="btn btn-success p-2 mt-3"
              type="submit"
              disabled={!(formik.isValid && formik.dirty)}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default AddNewStateForm;
