import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import {
  BANNER,
  FOOTER_BLOG,
  FOOTER_CONTACT_US,
  FOOTER_DOCS,
  FOOTER_HOME,
  FOOTER_PRICING,
  FOOTER_SIGN_IN,
  REGISTER,
  REGISTER_COMPANY_NAME_ERROR,
  REGISTER_CONTACT_NAME_ERROR,
  REGISTER_EMAIL_ADDRESS_EMPTY_ERROR,
  REGISTER_EMAIL_ADDRESS_ERROR,
  REGISTER_PASSWORDRE_EMPTY_ERROR,
  REGISTER_PASSWORDRE_MATCH_ERROR,
  REGISTER_PASSWORDRE_MIN_ERROR,
  REGISTER_PASSWORD_EMPTY_ERROR,
  REGISTER_PASSWORD_MIN_ERROR,
  SIGN_UP,
} from "../../constants/languageConstants";
import { set } from "lodash";
import { registerApiUrl } from "../../constants/Urls";
import * as Yup from "yup";
import ErrorMessageComp from "../../components/common/ErrorMessageComp";
import axios from "axios";
import logosmall from "../../assets/images/landing/logo_small_contact.png";


const RegisterPage = () => {
  const [postError, setPostError] = useState<any>(null);
  const [emailAlert, setEmailAlert] = useState<string>("");
  let history = useHistory();

  useEffect(() => {
    document.title = "Register | Customer Growth Panel"
  }, []);

  const registerApi = (postBody: object) => {
    axios
      .post(registerApiUrl, postBody)
      .then((response) => {
        const companyData = {
          email: response.data.data?.email,
          companyName: response.data.data?.companyName,
        };
        
        history.push({
          pathname: "/",
        });
      })
      .catch((error) => {
        if (error.response.status && error.response.status >= 402 && error.response.status <= 599) {
          console.log(error, "registerApiError");
          setPostError(error.response.status + " " + error.response.statusText);
        }

        if (error.response.data.data) {
          var target = {};
          const source = error.response.data.data;

          Object.keys(source).forEach((key) => {
            set(target, key, source[key]);
          });

          // @ts-ignore
          if (target.obj.email !== (null || undefined)) {
            // @ts-ignore
            if (target.obj.email[0].msg !== (null || undefined)) {
              // @ts-ignore
              var errorMsg = target.obj.email[0].msg[0];
              setEmailAlert(errorMsg);
            }
          }
        }
      });
  };

  const handleRegisterSubmit = (registerPostObject: object) => {
    registerApi(registerPostObject);
  };

  const validationSchema = Yup.object().shape({
    contactName: Yup.string().required(REGISTER_CONTACT_NAME_ERROR),
    companyName: Yup.string().required(REGISTER_COMPANY_NAME_ERROR),
    email: Yup.string().required(REGISTER_EMAIL_ADDRESS_EMPTY_ERROR).email(REGISTER_EMAIL_ADDRESS_ERROR),

    password: Yup.string().required(REGISTER_PASSWORD_EMPTY_ERROR).min(8, REGISTER_PASSWORD_MIN_ERROR),

    passwordRe: Yup.string()
      .required(REGISTER_PASSWORDRE_EMPTY_ERROR)
      .min(8, REGISTER_PASSWORDRE_MIN_ERROR)
      .oneOf([Yup.ref("password"), null], REGISTER_PASSWORDRE_MATCH_ERROR),
  });

  const formik = useFormik({
    initialValues: {
      contactName: "",
      companyName: "",
      email: "",
      password: "",
      passwordRe: "",
    },
    onSubmit: (values) => {},
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnMount: true,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4">
            <div className="card shadow-lg border-0 rounded-lg mt-5">
              <div className="card-header text-center ">
                <img style={{ width: "45%" }} src={logosmall} alt="logosmall" />
              </div>

              <div className="card-body">
                <div className="panel-heading">
                  <h4 className="panel-title">{SIGN_UP}</h4>
                </div>
                <div className="panel-body">
                  <div className="form-group">
                    {formik.errors.contactName && formik.touched.contactName ? <ErrorMessageComp name={formik.errors.contactName} /> : null}
                    <input
                      className="form-control "
                      id="contactName"
                      name="contactName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contactName}
                      placeholder="Contact Name"
                    />
                  </div>
                  <div className="form-group">
                    {formik.errors.companyName && formik.touched.companyName && <ErrorMessageComp name={formik.errors.companyName} />}
                    <input
                      className="form-control "
                      id="companyName"
                      name="companyName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.companyName}
                      placeholder="Company Name"
                    />
                  </div>

                  <div className="form-group">
                    {emailAlert ? <ErrorMessageComp name={emailAlert} /> : null}
                    {formik.errors.email && formik.touched.email && <ErrorMessageComp name={formik.errors.email} />}
                    <input
                      className="form-control "
                      id="email"
                      name="email"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      placeholder="Email Address"
                      autoComplete="email"
                    />
                  </div>

                  <div className="form-group">
                    {formik.errors.password && formik.touched.password && <ErrorMessageComp name={formik.errors.password} />}
                    <input
                      className="form-control "
                      id="password"
                      name="password"
                      type="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      placeholder="Password"
                      autoComplete="password"
                    />
                  </div>

                  <div className="form-group">
                    {formik.errors.passwordRe && formik.touched.passwordRe && <ErrorMessageComp name={formik.errors.passwordRe} />}
                    <input
                      className="form-control "
                      id="passwordRe"
                      name="passwordRe"
                      type="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.passwordRe}
                      placeholder="Comfirm Password"
                      autoComplete="password"
                    />
                  </div>

                  <div>
                    <div>{postError ? <ErrorMessageComp name={postError} /> : null}</div>

                    <button
                      onClick={() => {
                        handleRegisterSubmit(formik.values);
                        setEmailAlert("");
                      }}
                      className="btn btn-primary btn-lg btn-block p-3 "
                      type="submit"
                      disabled={!formik.isValid}
                    >
                      {REGISTER}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <footer>
          <div className="col-sm-12 text-center mt-5">
            <span>{BANNER} </span>
            <span>/</span>
            <span>
              <a href="https://herogi.com"> {FOOTER_HOME} </a>
            </span>
            <span>/</span>
            <span>
              <a href="https://herogi.com/pricing"> {FOOTER_PRICING} </a>
            </span>
            <span>/</span>
            <span>
              <a href="https://learn.herogi.com"> {FOOTER_DOCS} </a>
            </span>
            <span>/</span>
            <span>
              <a href="https://blog.herogi.com/"> {FOOTER_BLOG} </a>
            </span>
            <span>/</span>
            <span>
              <a href="https://l1.herogi.com/contact"> {FOOTER_CONTACT_US} </a>
            </span>
            <span>/</span>
            <span>
              <Link to="/login"> {FOOTER_SIGN_IN}</Link>
            </span>
          </div>
        </footer>
      </div>
    </form>
  );
};

export default RegisterPage;
