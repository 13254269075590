import alertify from "alertifyjs";

export const apiErrorHandle = (error: any, apiName: string) => {
  if (error.response) {
    const errorMessage = error.response.status + " " + error.response.statusText;

    if (error.response.status) {
      if (error.response.status === 401) {
        alert(errorMessage);
        window.location.href = "/login";
      }
      if (error.response.status === 403) {
        window.location.href = "/generic-error-page/403";
      }
      if (error.response.status === 404) {
        window.location.href = "/generic-error-page/404 ";
      }
      if (error.response.status === 408) {
        window.location.href = "/generic-error-page/408";
      }
      if (error.response.status === 500) {
        window.location.href = "/generic-error-page/500";
      }
      if (error.response.status === 503) {
        window.location.href = "/generic-error-page/503";
      }
      if (error.response.status === 504) {
        window.location.href = "/generic-error-page/504";
      }
    }

    if (error.response.status && error.response.status >= 505 && error.response.status <= 599) {
      alertify.notify(errorMessage, "fail", 3);
      console.log(error, "Server Errors");
    }
  }
};

export const ErrorHandler = (error) => {
  
  if (error.status === 401) {
    window.location.href = "/login";
  }
  else if(error.status === 403 && error.data.code === 9100) {
    $("#UsageExceedModal").modal("show");
  } else if(error.status === 500) {
    $("#GenericErrorModal").modal("show");
  } 
  else if(error.status === 404) {
    window.location.href = "/generic-error-page/404 ";
  }
  else {
    alert(error.data.message);
  }

}
