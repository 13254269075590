import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { faAngleDown, faBarsStaggered, faBullhorn, faBullseye, faChartPie, faChartSimple, faDatabase, faFile, faInfoCircle, faMagnifyingGlassChart, faSitemap, faTable, faTachometerAlt, faUser, faWrench } from "@fortawesome/free-solid-svg-icons";
import $ from 'jquery';
import { useSelector } from "react-redux";
import { RootStore } from "../../../redux/store/Store";
import { Account } from "../../../services/AccountService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Sidebar = () => {
  const [selectedMenuId, setselectedMenuId] = useState("");

  const [account, setAccount] = useState<Account>(null!);

  const accountReducer: any = useSelector((state: RootStore) => state.getAccountReducer);

  useEffect(() => { 
    if (accountReducer) {
      setAccount(accountReducer.account);
    }
  }, [accountReducer.account]);

    useEffect(() => {
    if(selectedMenuId){
      $(function () {
        $("#accordionSidenav").children().removeClass('active')
        $("#scenarios").removeClass('active');
        $("#campaign").removeClass('active');
        $("#integrations").removeClass('active');
        $("#segments").removeClass('active');
        $("#audience").removeClass('active');
        $("#event-streams").removeClass('active');
        $("#webReport").removeClass('active');
        $("#messagingReport").removeClass('active');
        $("#"+selectedMenuId).addClass('active');
      })
    }
  }, [selectedMenuId])

  return (
    <div className="sidenavbar">
      <nav className="sidenav shadow-right-sm sidenav-light">
        <div className="sidenav-menu">
          <div className="nav accordion" id="accordionSidenav">
            <div className="sidenav-menu-heading "></div>

            <a
              id="dashboard"
              style={{ marginTop: "48px" }}
              className="nav-link bg-body collapsed font-weight-500"
              href="javascript:void(0);"
              data-toggle="collapse"
              data-target="#collapseDashboards"
              aria-expanded="false"
              aria-controls="collapseDashboards"
              onClick={() => {
                setselectedMenuId("dashboard");
              }}
            >
              <div className="nav-link-icon ">
                <FontAwesomeIcon icon={faTachometerAlt} />
              </div>
              <Link className="text-link" to="/">
                Dashboard
              </Link>
              <div className="sidenav-collapse-arrow"></div>
            </a>
            <div className="collapse" id="collapseDashboards" data-parent="#accordionSidenav">
              <nav className="sidenav-menu-nested nav accordion font-weight-500" id="accordionSidenavPages"></nav>
            </div>

            <a
              id="cdp"
              className="nav-link collapsed font-weight-500"
              href="javascript:void(0);"
              data-toggle="collapse"
              data-target="#collapseCDP"
              aria-expanded="false"
              aria-controls="collapseCDP"
              onClick={() => {
                setselectedMenuId("cdp");
              }}
            >
              <div className="nav-link-icon">
                <FontAwesomeIcon icon={faDatabase} />
              </div>
              CDP
              <div className="sidenav-collapse-arrow">
                <FontAwesomeIcon icon={faAngleDown} />
              </div>
            </a>
            <div className="collapse" id="collapseCDP" data-parent="#accordionSidenav">
              <nav className="sidenav-menu-nested nav accordion font-weight-500" id="accordionSidenavPages">
                <Link
                  id="audience"
                  className="nav-link"
                  to="/audience"
                  onClick={() => {
                    setselectedMenuId("audience");
                  }}
                >
                  <div className="nav-link-icon">
                    {" "}
                    <FontAwesomeIcon icon={faUser} />{" "}
                  </div>
                  Audience
                </Link>
                <Link
                  id="segments"
                  className="nav-link"
                  to="/segment"
                  onClick={() => {
                    setselectedMenuId("segments");
                  }}
                >
                  <div className="nav-link-icon">
                    {" "}
                    <FontAwesomeIcon icon={faChartPie} />{" "}
                  </div>Segments
                </Link>
              </nav>
            </div>

            <a
              id="collapseScenariousId"
              className="nav-link collapsed font-weight-500"
              href="javascript:void(0);"
              data-toggle="collapse"
              data-target="#collapseScenarious"
              aria-expanded="false"
              aria-controls="collapseScenarious"
              onClick={() => {
                setselectedMenuId("collapseScenariousId");
              }}
            >
              <div className="nav-link-icon">
                <FontAwesomeIcon icon={faSitemap} />
              </div>
              Marketing Suite
              <div className="sidenav-collapse-arrow">
                <FontAwesomeIcon icon={faAngleDown} />
              </div>
            </a>
            <div className="collapse" id="collapseScenarious" data-parent="#accordionSidenav">
              <nav className="sidenav-menu-nested nav accordion font-weight-500 " id="accordionSidenavPages">
                <Link
                  id="campaign"
                  className="nav-link"
                  to="/campaign"
                  onClick={() => {
                    setselectedMenuId("campaign");
                  }}
                >
                  <div className="nav-link-icon">
                    {" "}
                    <FontAwesomeIcon icon={faBullhorn} />{" "}
                  </div>
                  Campaigns
                </Link>
                <Link
                  id="scenarios"
                  className="nav-link"
                  to="/scenario"
                  onClick={() => {
                    setselectedMenuId("scenarios");
                  }}
                >
                  <div className="nav-link-icon">
                    {" "}
                    <FontAwesomeIcon icon={faBullseye} />{" "}
                  </div>
                  Complex Scenarios
                </Link>
                <Link
                  id="integrations"
                  className="nav-link"
                  to="/integration"
                  onClick={() => {
                    setselectedMenuId("integrations");
                  }}
                >
                  <div className="nav-link-icon">
                    {" "}
                    <FontAwesomeIcon icon={faWrench} />{" "}
                  </div>
                  Integrations
                </Link>
              </nav>
            </div>

            <a
              id="analyticsMenuId"
              className="nav-link collapsed font-weight-500"
              href="javascript:void(0);"
              data-toggle="collapse"
              data-target="#collapseAnalyticsNav"
              aria-expanded="false"
              aria-controls="collapseAnalyticsNav"
              onClick={() => {
                setselectedMenuId("analyticsMenuId");
              }}
            >
              <div className="nav-link-icon">
                <FontAwesomeIcon icon={faChartSimple} />
              </div>
              Analytics
              <div className="sidenav-collapse-arrow">
                <FontAwesomeIcon icon={faAngleDown} />
              </div>
            </a>
            <div className="collapse" id="collapseAnalyticsNav" data-parent="#accordionSidenav">
              <nav className="sidenav-menu-nested nav accordion font-weight-500 " id="accordionSidenavPages">
                <Link
                  id="webReport"
                  className="nav-link"
                  to="/analytics/web-report"
                  onClick={() => {
                    setselectedMenuId("webReport");
                  }}
                >
                  <div className="nav-link-icon">
                    {" "}
                    <FontAwesomeIcon icon={faMagnifyingGlassChart} />{" "}
                  </div>
                  Web Metrics
                </Link>
                <Link
                  id="messagingReport"
                  className="nav-link"
                  to="/analytics/messaging-report"
                  onClick={() => {
                    setselectedMenuId("messagingReport");
                  }}
                >
                  <div className="nav-link-icon">
                    {" "}
                    <FontAwesomeIcon icon={faMagnifyingGlassChart} />{" "}
                  </div>
                  Messaging Metrics
                </Link>
                <Link
                  id="event-streams"
                  className="nav-link"
                  to="/analytics/event-streams"
                  onClick={() => {
                    setselectedMenuId("event-streams");
                  }}
                >
                  <div className="nav-link-icon">
                    {" "}
                    <FontAwesomeIcon icon={faBarsStaggered} />{" "}
                  </div>Event Streams
                </Link>
              </nav>
            </div>
                  
            <a id="collapseHelpId" className="nav-link collapsed font-weight-500" href="javascript:void(0);" data-toggle="collapse" data-target="#collapseHelp" aria-expanded="false" aria-controls="collapseHelp"  onClick={() => {
                setselectedMenuId("collapseHelpId");
              }}>
              <div className="nav-link-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
              </div>
              Help
              <div className="sidenav-collapse-arrow">
                <FontAwesomeIcon icon={faAngleDown} />
              </div>
            </a>
            <div className="collapse" id="collapseHelp" data-parent="#accordionSidenav">
              <nav className="sidenav-menu-nested nav accordion" id="accordionSidenavPages">
                <a className="nav-link font-weight-500" target="_blank" href="http://learn.herogi.com/" rel="noreferrer">
                  <div className="nav-link-icon">
                    {" "}
                    <FontAwesomeIcon icon={faFile} />{" "}
                  </div>
                  Document
                  <span className="badge badge-primary-soft text-primary ml-auto ">Updated</span>
                </a>
              </nav>
            </div>
          </div>
        </div>

        <div className="sidenav-footer">
          <div className="sidenav-footer-content">
            <div className="sidenav-footer-subtitle">Logged in as:</div>
            <div className="sidenav-footer-title">{account?.email ? account.email : "N/A"}</div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
